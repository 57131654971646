import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { LicenseService, LicenseV1 } from '../../Services/LicenseService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcPageContent, IcPopupMenu, IcPopupMenuItem, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminLicensesPageProps extends WithTranslation
{
}


interface AdminLicensesPageState
{
    licenses:   Array<LicenseV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


class $AdminLicensesPage extends React.Component<AdminLicensesPageProps, AdminLicensesPageState>
{
    private readonly BULK_SIZE      = 50;
    private readonly _licenseService:  LicenseService;


    constructor ( props: AdminLicensesPageProps )
    {
        super(props);

        this.state = {
            licenses:   [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._licenseService = LicenseService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const licenses = await this._licenseService.getLicenses(
                0,
                this.BULK_SIZE
            );

            this.setState({
                licenses,
                hasMore:    licenses.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading licenses: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }

    
    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const licenses = await this._licenseService.getLicenses(
                this.state.licenses.length,
                this.BULK_SIZE
            );

            this.setState({
                licenses:   [...this.state.licenses, ...licenses],
                hasMore:    licenses.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading more licenses: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'licenses')}
                        label={this.props.t('adminlicensespage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminlicensespage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton
                            color={IcButtonColor.Secondary}
                            to={LinkUtils.make('admin', 'license', 'add')}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                            
                            {this.props.t('adminlicensespage.btn_add_license')}
                        </IcButton>
                    </IcFloatRow>
                
                    <IcErrorBox error={this.state.error} />
                    
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        {this.props.t('adminlicensespage.txt_col_tag')}
                                    </IcTableCell>
                                    
                                    <IcTableCell>
                                        {this.props.t('adminlicensespage.txt_col_name')}
                                    </IcTableCell>
                                    
                                    <IcTableCell>
                                        {this.props.t('adminlicensespage.txt_col_assignable')}
                                    </IcTableCell>
                                    
                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('adminlicensespage.txt_col_action')}
                                    </IcTableCell>
                                </IcTableRow>
                            </IcTableHead>

                            <IcTableBody>
                                {this.state.licenses.map( ( license ) => (
                                    <IcTableRow key={license.uid}>
                                        <IcTableCell>
                                            {license.tag}
                                        </IcTableCell>

                                        <IcTableCell>
                                            {license.name}
                                        </IcTableCell>

                                        <IcTableCell>
                                            {license.assignable ? this.props.t('adminlicensespage.txt_license_assignable_true') : ''}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcPopupMenu>
                                                <IcPopupMenuItem to={LinkUtils.make('admin', 'license', license.uid, 'edit')}>
                                                    {this.props.t('adminlicensespage.btn_edit')}
                                                </IcPopupMenuItem>

                                                <IcPopupMenuItem to={LinkUtils.make('admin', 'license', license.uid, 'delete')}>
                                                    {this.props.t('adminlicensespage.btn_delete')}
                                                </IcPopupMenuItem>
                                            </IcPopupMenu>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.licenses.length === 0 && !this.state.loading ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={4}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            {this.props.t('adminlicensespage.txt_no_licenses')}
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminLicensesPage = withTranslation()($AdminLicensesPage);
