import React from 'react';
import { Environment } from '../../utils/Environment';
import { ContentImage } from '../ContentImage/ContentImage';
import { ContentFileV1Key } from '../../Services/ContentService';

import './SupportOverlay.css'


export interface SupportOverlayProps
{
}


export class SupportOverlay extends React.Component<SupportOverlayProps>
{
    public render ( )
    {
        if ( ! Environment.links.supportUrl )
        {
            return null;
        }

        return (
            <a
                className='SupportOverlay'
                href={Environment.links.supportUrl}
                target='_blank'
                rel='noreferrer'>
                <ContentImage contentFileKey={ContentFileV1Key.SupportOverlayImage} />
            </a>
        );
    }
}
