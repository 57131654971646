import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationV1 } from '../../Services/OrganisationService';
import { IcDummyContent, IcSeparator, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface OrganisationDetailsProps extends WithTranslation
{
    organisation:   OrganisationV1 | null;
}


class $OrganisationDetails extends React.Component<OrganisationDetailsProps>
{
    public render ( )
    {
        return (
            <div>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('organisationdetails.txt_organisation')}
                </IcText>

                <IcText>
                    {this.props.organisation ?
                        this.props.organisation.name
                    :
                        <IcDummyContent />
                    }
                </IcText>
    
                <IcSeparator />
            </div>
        );
    }
}


export const OrganisationDetails = withTranslation()($OrganisationDetails);
