import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface LoginPageSuccessStepProps extends WithTranslation
{
    redirectURI:    string;
}


class $LoginPageSuccessStep extends React.Component<LoginPageSuccessStepProps>
{
    private _timeoutRedirect:   any | null;


    constructor ( props: LoginPageSuccessStepProps )
    {
        super(props);

        this._timeoutRedirect = null;
    }


    public componentDidMount ( ): void
    {
        if ( this._timeoutRedirect )
        {
            clearTimeout(this._timeoutRedirect);
        }

        this._timeoutRedirect = setTimeout( ( ) =>
        {
            this._timeoutRedirect = null;

            window.location.href = this.props.redirectURI;
        }, 1000);
    }


    public componentWillUnmount ( ): void
    {
        if ( this._timeoutRedirect )
        {
            clearTimeout(this._timeoutRedirect);
            this._timeoutRedirect = null;
        }
    }


    public render ( )
    {
        return (
            <SuccessBox
                message={this.props.t('loginpagesuccessstep.txt_success')}
            />
        );
    }
}


export const LoginPageSuccessStep = withTranslation()($LoginPageSuccessStep);
