import TagManager from 'react-gtm-module';
import { CookieHintService, CookieSelection } from './CookieHintService';
import { Environment } from '../utils/Environment';


export enum TrackingEventType
{
    Register            = 'register',
    Login               = 'login',
    Purchase            = 'purchase',
    ApplicationStarted  = 'application_started'
}


export interface TrackingEvent
{
    event:      TrackingEventType;
    message?:   string;
    value?:     number | string;
}


export class TrackingService
{
    private static _instance:               TrackingService;
    private readonly _cookieHintService:    CookieHintService;
    private _initialized:                   boolean;


    constructor ( )
    {
        this._cookieHintService = CookieHintService.getInstance();
        this._initialized       = false;
    }


    public static getInstance ( ): TrackingService
    {
        if ( ! this._instance )
        {
            this._instance = new TrackingService();
        }

        return this._instance;
    }


    private _onSelection ( selection: CookieSelection ): void
    {
        if ( this._initialized )
        {
            return;
        }

        if ( (selection.analytics || selection.marketing) && Environment.gtm.id )
        {
            (window as any).COOKIE_SELECTION = {
                analytics: selection.analytics,
                marketing: selection.marketing
            };

            TagManager.initialize({
                gtmId: Environment.gtm.id
            });

            this._initialized = true;
        }
        
        if ( (selection.analytics || selection.marketing) && Environment.matomo.url && Environment.matomo.id )
        {
            const _paq = (window as any)._paq = (window as any)._paq || [];
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
                const u=`//${Environment.matomo.url}/`;
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', Environment.matomo.id]);
                const d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode!.insertBefore(g,s);
            })();

            this._initialized = true;
        }
    }


    public init ( ): void
    {
        this._cookieHintService.getSelection().subscribe(this, ( selection ) =>
        {
            this._onSelection(selection);
        });

        const selection = this._cookieHintService.getSelection().get();
        this._onSelection(selection);
    }


    public push ( event: TrackingEvent ): void
    {
        if ( ! this._initialized )
        {
            return;
        }

        if ( Environment.gtm.id )
        {
            TagManager.dataLayer({
                dataLayer: event
            });
        }

        if ( Environment.matomo.url && Environment.matomo.id )
        {
            (window as any)._paq.push({
                event: event.event
            });
        }
    }
}
