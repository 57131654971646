import React from 'react';
import { PaymentMethodV1 } from '../../Services/OrganisationService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcCard, IcCardPadding, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface PaymentMethodDetailsProps extends WithTranslation
{
    paymentMethod:  PaymentMethodV1;
}


class $PaymentMethodDetails extends React.Component<PaymentMethodDetailsProps>
{
    public render ( )
    {
        return (
            <div>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('paymentmethoddetails.txt_title')}
                </IcText>

                <IcCard padding={IcCardPadding.Small}>
                    {this.props.paymentMethod.title}
                </IcCard>
            </div>
        );
    }
}


export const PaymentMethodDetails = withTranslation()($PaymentMethodDetails);
