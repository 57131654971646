import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcCard, IcCardPadding, IcSpinner } from '@indece-common/ic-ui-lib-react';


export interface OrganisationMemberAcceptPageLoadingStepProps extends WithTranslation
{
}


class $OrganisationMemberAcceptPageLoadingStep extends React.Component<OrganisationMemberAcceptPageLoadingStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcSpinner
                    message={this.props.t('organisationmemberacceptpageloadingstep.txt_loading')}
                />
            </IcCard>
        );
    }
}


export const OrganisationMemberAcceptPageLoadingStep = withTranslation()($OrganisationMemberAcceptPageLoadingStep);
