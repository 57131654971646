import React from 'react';
import { IcInputCheckbox } from '@indece-common/ic-ui-lib-react';

import './List.css';


export interface ListItemHeaderCheckboxProps
{
    name:       string;
    disabled?:  boolean;
}


export class ListItemHeaderCheckbox extends React.Component<ListItemHeaderCheckboxProps>
{
    public render ( )
    {
        return (
            <div className='ListItemHeaderCheckbox'>
                <IcInputCheckbox
                    name={this.props.name}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}
