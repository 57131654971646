import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AccountAddSfaPagePasswordStep, AccountAddSfaPagePasswordStepFormData } from './AccountAddSfaPagePasswordStep/AccountAddSfaPagePasswordStep';
import { Delay, sleep } from 'ts-delay';
import { AccountAddSfaPageLoadingStep } from './AccountAddSfaPageLoadingStep/AccountAddSfaPageLoadingStep';
import { AccountAddSfaPageSuccessStep } from './AccountAddSfaPageSuccessStep/AccountAddSfaPageSuccessStep';
import { RespAddSfaV1, UserService, UserSfaV1Type } from '../../Services/UserService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AccountAddSfaPageSfaTotpConfirmStep, AccountAddSfaPageSfaTotpConfirmStepFormData } from './AccountAddSfaPageSfaTotpConfirmStep/AccountAddSfaPageSfaTotpConfirmStep';
import { AccountAddSfaPageSfaTotpStep } from './AccountAddSfaPageSfaTotpStep/AccountAddSfaPageSfaTotpStep';
import { AccountAddSfaPageSfaTypeStep } from './AccountAddSfaPageSfaTypeStep/AccountAddSfaPageSfaTypeStep';
import { AccountAddSfaPageSfaEmailConfirmStep, AccountAddSfaPageSfaEmailConfirmStepFormData } from './AccountAddSfaPageSfaEmailConfirmStep/AccountAddSfaPageSfaEmailConfirmStep';
import { AccountAddSfaPageSfaWebAuthNStep } from './AccountAddSfaPageSfaWebAuthNStep/AccountAddSfaPageSfaWebAuthNStep';
import { AccountAddSfaPageSfaRecoveryStep } from './AccountAddSfaPageSfaRecoveryStep/AccountAddSfaPageSfaRecoveryStep';
import { AccountAddSfaPageSfaEmailStep, AccountAddSfaPageSfaEmailStepFormData } from './AccountAddSfaPageSfaEmailStep/AccountAddSfaPageSfaEmailStep';
import { WebAuthN } from '../../utils/WebAuthN';
import { IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AccountAddSfaPageProps extends RouteComponentProps, WithTranslation
{
}


enum AccountAddSfaPageStep
{
    Loading         = 'LOADING',
    Password        = 'PASSWORD',
    SfaType         = 'SFA_TYPE',
    SfaWebAuthN     = 'SFA_WEBAUTHN',
    SfaTotp         = 'SFA_TOTP',
    SfaTotpConfirm  = 'SFA_TOTP_CONFIRM',
    SfaEmail        = 'SFA_EMAIL',
    SfaEmailConfirm = 'SFA_EMAIL_CONFIRM',
    SfaRecovery     = 'SFA_RECOVERY',
    Success         = 'SUCCESS'
}


interface AccountAddSfaPageFormData
{
    password:   string;
}


interface AccountAddSfaPageState
{
    formData:           AccountAddSfaPageFormData;
    sfaType:            UserSfaV1Type | null;
    availableSfaTypes:  Array<UserSfaV1Type>;
    sfa:                RespAddSfaV1 | null;
    step:               AccountAddSfaPageStep;
    error:              Error | null;
}


class $AccountAddSfaPage extends React.Component<AccountAddSfaPageProps, AccountAddSfaPageState>
{
    private readonly _userService:      UserService;
    private readonly _webAuthN:         WebAuthN;


    constructor ( props: AccountAddSfaPageProps )
    {
        super(props);

        this.state = {
            formData:           {
                password:           ''
            },
            sfaType:            null,
            availableSfaTypes:  [],
            sfa:                null,
            step:               AccountAddSfaPageStep.Password,
            error:              null
        };

        this._userService = UserService.getInstance();
        this._webAuthN = new WebAuthN();

        this._finishPassword = this._finishPassword.bind(this);
        this._finishSfaType = this._finishSfaType.bind(this);
        this._finishSfaTotp = this._finishSfaTotp.bind(this);
        this._finishSfaWebAuthN = this._finishSfaWebAuthN.bind(this);
        this._finishSfaTotpConfirm = this._finishSfaTotpConfirm.bind(this);
        this._finishSfaEmail = this._finishSfaEmail.bind(this);
        this._finishSfaEmailConfirm = this._finishSfaEmailConfirm.bind(this);
        this._finishSfaRecovery = this._finishSfaRecovery.bind(this);
        this._setError = this._setError.bind(this);
    }


    private async _finishPassword ( formData: AccountAddSfaPagePasswordStepFormData ): Promise<void>
    {
        try
        {
            const webAuthNSfaTypes = await this._webAuthN.checkBrowserSupport();

            this.setState({
                formData: {
                    ...this.state.formData,
                    password:   formData.password.trim()
                },
                availableSfaTypes: [
                    ...webAuthNSfaTypes,
                    UserSfaV1Type.Totp,
                    UserSfaV1Type.Email,
                    UserSfaV1Type.Recovery
                ],
                step:   AccountAddSfaPageStep.SfaType
            });
        }
        catch ( err )
        {
            console.error(`Error loading supported sfa types: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountAddSfaPageStep.Password,
                error:  err as Error
            });
        }
    }


    private async _finishSfaType ( sfaType: UserSfaV1Type ): Promise<void>
    {
        try
        {
            if ( sfaType === UserSfaV1Type.Email )
            {
                this.setState({
                    step:   AccountAddSfaPageStep.SfaEmail,
                    sfaType
                });

                return;
            }

            this.setState({
                step:   AccountAddSfaPageStep.Loading,
                sfaType,
                error:  null
            });

            const delay = new Delay(1000);

            const sfa = await this._userService.addSfa({
                password:   this.state.formData.password,
                type:       sfaType,
                email:      null
            });

            await delay.sleep();

            switch ( sfaType )
            {
                case UserSfaV1Type.Totp:
                    this.setState({
                        sfa,
                        step:   AccountAddSfaPageStep.SfaTotp
                    });

                    break;
                case UserSfaV1Type.WebAuthNPlatform:
                case UserSfaV1Type.WebAuthNCrossPlatform:
                    this.setState({
                        sfa,
                        step:   AccountAddSfaPageStep.SfaWebAuthN
                    });

                    break;
                case UserSfaV1Type.Recovery:
                    this.setState({
                        sfa,
                        step:   AccountAddSfaPageStep.SfaRecovery
                    });

                    break;
            }
        }
        catch ( err )
        {
            console.error(`Error registering in: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountAddSfaPageStep.SfaType,
                error:  err as Error
            });
        }
    }


    private async _finishSfaEmail ( formData: AccountAddSfaPageSfaEmailStepFormData ): Promise<void>
    {
        if ( this.state.sfaType !== UserSfaV1Type.Email )
        {
            return;
        }

        try
        {
            this.setState({
                step:   AccountAddSfaPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            const sfa = await this._userService.addSfa({
                password:   this.state.formData.password,
                type:       this.state.sfaType,
                email:      formData.email
            });

            await delay.sleep();

            this.setState({
                sfa,
                step:   AccountAddSfaPageStep.SfaEmailConfirm
            });
        }
        catch ( err )
        {
            console.error(`Error registering in: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountAddSfaPageStep.SfaEmail,
                error:  err as Error
            });
        }
    }


    private async _finishSfaTotp ( ): Promise<void>
    {
        this.setState({
            step:   AccountAddSfaPageStep.SfaTotpConfirm
        });
    }


    private async _finishSfaWebAuthN ( credentialID: string, publickey: string, publickeyAlogrithm: number, attestationObject: string ): Promise<void>
    {
        if ( !this.state.sfa )
        {
            return;
        }
    
        try
        {
            this.setState({
                step:   AccountAddSfaPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.confirmOwnSfa(
                this.state.sfa.sfa_uid,
                {
                    webauthn_credential_id:         credentialID,
                    webauthn_publickey:             publickey,
                    webauthn_publickey_algorithm:   publickeyAlogrithm,
                    webauthn_attestation:           attestationObject
                }
            );

            await delay.sleep();

            this.setState({
                step:   AccountAddSfaPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('account'));
        }
        catch ( err )
        {
            console.error(`Error confirming sfa in: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountAddSfaPageStep.SfaWebAuthN,
                error:  err as Error
            });
        }

        this.setState({
            step:   AccountAddSfaPageStep.Success
        });
    }


    private async _finishSfaTotpConfirm ( formData: AccountAddSfaPageSfaTotpConfirmStepFormData ): Promise<void>
    {
        if ( !this.state.sfa )
        {
            return;
        }
    
        try
        {
            this.setState({
                step:   AccountAddSfaPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.confirmOwnSfa(
                this.state.sfa.sfa_uid,
                {
                    code:   formData.code
                }
            );

            await delay.sleep();

            this.setState({
                step:   AccountAddSfaPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('account'));
        }
        catch ( err )
        {
            console.error(`Error confirming sfa in: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountAddSfaPageStep.SfaTotpConfirm,
                error:  err as Error
            });
        }
    }


    private async _finishSfaEmailConfirm ( formData: AccountAddSfaPageSfaEmailConfirmStepFormData ): Promise<void>
    {
        if ( !this.state.sfa )
        {
            return;
        }
    
        try
        {
            this.setState({
                step:   AccountAddSfaPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.confirmOwnSfa(
                this.state.sfa.sfa_uid,
                {
                    code:   formData.code
                }
            );

            await delay.sleep();

            this.setState({
                step:   AccountAddSfaPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('account'));
        }
        catch ( err )
        {
            console.error(`Error confirming sfa in: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountAddSfaPageStep.SfaEmailConfirm,
                error:  err as Error
            });
        }
    }


    private async _finishSfaRecovery ( ): Promise<void>
    {
        if ( !this.state.sfa )
        {
            return;
        }
    
        try
        {
            this.setState({
                step:   AccountAddSfaPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.confirmOwnSfa(
                this.state.sfa.sfa_uid,
                {}
            );

            await delay.sleep();

            this.setState({
                step:   AccountAddSfaPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('account'));
        }
        catch ( err )
        {
            console.error(`Error confirming sfa in: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountAddSfaPageStep.SfaRecovery,
                error:  err as Error
            });
        }
    }


    private _setError ( error: Error | null ): void
    {
        this.setState({
            error
        });
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('accountaddsfapage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />
                
                {this.state.step === AccountAddSfaPageStep.Loading ?
                    <AccountAddSfaPageLoadingStep />
                : null}
                
                {this.state.step === AccountAddSfaPageStep.Password ?
                    <AccountAddSfaPagePasswordStep
                        onFinish={this._finishPassword}
                    />
                : null}

                {this.state.step === AccountAddSfaPageStep.SfaType ?
                    <AccountAddSfaPageSfaTypeStep
                        availableSfaTypes={this.state.availableSfaTypes}
                        onFinish={this._finishSfaType}
                    />
                : null}
                
                {this.state.step === AccountAddSfaPageStep.SfaWebAuthN && this.state.sfa && this.state.sfaType ?
                    <AccountAddSfaPageSfaWebAuthNStep
                        sfa={this.state.sfa}
                        sfaType={this.state.sfaType}
                        onFinish={this._finishSfaWebAuthN}
                        onError={this._setError}
                    />
                : null}
                
                {this.state.step === AccountAddSfaPageStep.SfaTotp && this.state.sfa ?
                    <AccountAddSfaPageSfaTotpStep
                        sfa={this.state.sfa}
                        onFinish={this._finishSfaTotp}
                    />
                : null}
                
                {this.state.step === AccountAddSfaPageStep.SfaTotpConfirm ?
                    <AccountAddSfaPageSfaTotpConfirmStep
                        onFinish={this._finishSfaTotpConfirm}
                    />
                : null}
                
                {this.state.step === AccountAddSfaPageStep.SfaEmail ?
                    <AccountAddSfaPageSfaEmailStep
                        onFinish={this._finishSfaEmail}
                    />
                : null}
                
                {this.state.step === AccountAddSfaPageStep.SfaEmailConfirm ?
                    <AccountAddSfaPageSfaEmailConfirmStep
                        onFinish={this._finishSfaEmailConfirm}
                    />
                : null}
                
                {this.state.step === AccountAddSfaPageStep.SfaRecovery && this.state.sfa ?
                    <AccountAddSfaPageSfaRecoveryStep
                        sfa={this.state.sfa}
                        onFinish={this._finishSfaRecovery}
                    />
                : null}

                {this.state.step === AccountAddSfaPageStep.Success ?
                    <AccountAddSfaPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AccountAddSfaPage = withTranslation()(withRouter($AccountAddSfaPage));
