import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { UserApiTokenDeletePageLoadingStep } from './UserApiTokenDeletePageLoadingStep/UserApiTokenDeletePageLoadingStep';
import { UserApiTokenDeletePageSuccessStep } from './UserApiTokenDeletePageSuccessStep/UserApiTokenDeletePageSuccessStep';
import { UserApiTokenDeletePageConfirmStep } from './UserApiTokenDeletePageConfirmStep/UserApiTokenDeletePageConfirmStep';
import { UserApiTokenV1, UserService } from '../../Services/UserService';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface UserApiTokenDeletePageRouteParams
{
    userApiTokenUID:    string;
}


export interface UserApiTokenDeletePageProps extends RouteComponentProps<UserApiTokenDeletePageRouteParams>, WithTranslation
{
}


enum UserApiTokenDeletePageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface UserApiTokenDeletePageState
{
    step:           UserApiTokenDeletePageStep;
    userApiToken:   UserApiTokenV1 | null;
    error:          Error | null;
}


class $UserApiTokenDeletePage extends React.Component<UserApiTokenDeletePageProps, UserApiTokenDeletePageState>
{
    private readonly _userService:  UserService;
    

    constructor ( props: UserApiTokenDeletePageProps )
    {
        super(props);

        this.state = {
            step:           UserApiTokenDeletePageStep.Loading,
            userApiToken:   null,
            error:          null
        };

        this._userService = UserService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   UserApiTokenDeletePageStep.Loading,
                error:  null
            });

            const userApiTokens = await this._userService.getOwnApiTokens();
            const userApiToken = userApiTokens.find( o => o.uid === this.props.router.params.userApiTokenUID );
            if ( ! userApiToken )
            {
                throw new Error('Api token not found');
            }

            this.setState({
                step:       UserApiTokenDeletePageStep.Confirm,
                userApiToken
            });
        }
        catch ( err )
        {
            console.error(`Error loading user api token: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.userApiToken )
        {
            return;
        }

        try
        {
            this.setState({
                step:   UserApiTokenDeletePageStep.Loading,
                error:  null
            });

            await this._userService.deleteOwnApiToken(this.state.userApiToken.uid);

            this.setState({
                step:   UserApiTokenDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting user api token: ${(err as Error).message}`, err);

            this.setState({
                step:   UserApiTokenDeletePageStep.Confirm,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('userapitokendeletepage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === UserApiTokenDeletePageStep.Loading ?
                    <UserApiTokenDeletePageLoadingStep />
                : null}

                {this.state.step === UserApiTokenDeletePageStep.Confirm && this.state.userApiToken ?
                    <UserApiTokenDeletePageConfirmStep
                        userApiToken={this.state.userApiToken}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === UserApiTokenDeletePageStep.Success ?
                    <UserApiTokenDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const UserApiTokenDeletePage = withTranslation()(withRouter($UserApiTokenDeletePage));
