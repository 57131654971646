import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from '../../Components/List/List';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { faPen, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { PurchaseService, PurchaseV1 } from '../../Services/PurchaseService';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';
import { CanceledChip } from '../../Components/Chip/CanceledChip';


export interface OrganisationPagePurchasesBoxProps extends WithTranslation, WithLocaleProps
{
    organisationUID:    string;
}


interface OrganisationPagePurchasesBoxState
{
    purchases:  Array<PurchaseV1>;
    loading:    boolean;
    error:      Error | null;
}


class $OrganisationPagePurchasesBox extends React.Component<OrganisationPagePurchasesBoxProps, OrganisationPagePurchasesBoxState>
{
    private readonly _purchaseService:  PurchaseService;


    constructor ( props: OrganisationPagePurchasesBoxProps )
    {
        super(props);

        this.state = {
            purchases:  [],
            loading:    true,
            error:      null
        };

        this._purchaseService = PurchaseService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const purchases = await this._purchaseService.getPurchases(this.props.organisationUID);

            this.setState({
                purchases,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading purchases: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('organisationpagepurchasesbox.txt_purchases')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <List>
                    {this.state.purchases.map( ( purchase ) => (
                        <ListItem key={purchase.uid}>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    grow={true}
                                    to={LinkUtils.make('purchase', purchase.uid)}>
                                    {purchase.package.names.find( o => o.locale === this.props.locale)?.value || '???'}

                                    {purchase.datetime_cancel_at ?
                                        <CanceledChip date={purchase.datetime_cancel_at} />
                                    : null}
                                </ListItemHeaderField>
                    
                                <ListItemHeaderField
                                    grow={true}
                                    text={`${purchase.count}x`}
                                    to={LinkUtils.make('purchase', purchase.uid)}
                                />

                                <ListItemHeaderAction
                                    icon={faPen}
                                    to={LinkUtils.make('purchase', purchase.uid, 'update_count')}
                                />

                                <ListItemHeaderAction
                                    icon={faTrash}
                                    to={LinkUtils.make('purchase', purchase.uid, 'cancel')}
                                />
                            </ListItemHeader>
                        </ListItem>
                    ))}

                    {this.state.purchases.length === 0 && !this.state.loading ?
                        <ListEmpty>
                            {this.props.t('organisationpagepurchasesbox.txt_no_purchases')}
                        </ListEmpty>
                    : null}
                </List>

                <IcSpinner active={this.state.loading} />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        to={LinkUtils.make('packages') + '?organisation_uid=' + this.props.organisationUID}
                        color={IcButtonColor.Secondary}>
                        <FontAwesomeIcon icon={faPlusCircle} />

                        {this.props.t('organisationpagepurchasesbox.btn_add_package')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const OrganisationPagePurchasesBox = withLocale(withTranslation()($OrganisationPagePurchasesBox));
