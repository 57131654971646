import { Country } from '../Model/Country';
import { Currency } from '../Model/Currency';
import { Gender } from '../Model/Gender';
import { BackendService } from './BackendService';
import { LocaleService } from './LocaleService';
import { PackageV1 } from './PackageService';


export interface PurchaseV1Address
{
    title:      string
    gender:     Gender;
    firstname:  string;
    lastname:   string;
    company:    string;
    tax_id:     string;
    street:     string;
    zip:        string;
    city:       string;
    country:    Country;
}


export interface PurchaseV1PricePeriod
{
    is_current:     boolean;
    price_net:      number;
    price_vat:      number;
    price_gross:    number;
    duration:       string | null;
}


export interface PurchaseV1
{
    uid:                string;
    organisation_uid:   string;
    package:            PackageV1;
    package_price_uid:  string;
    payment_interval:   string;
    count:              number;
    next_count:         number;
    country:            Country;
    currency:           Currency;
    rate_vat:           number;
    price_periods:      Array<PurchaseV1PricePeriod>;
    datetime_created:   string;
    datetime_activated: string | null;
    datetime_cancel_at: string | null;
}


export interface FileV1
{
    uid:        string;
    filename:   string;
    mimetype:   string;
}


export interface BillV1
{
    uid:                string;
    number:             string;
    organisation_uid:   string;
    purchase_uid:       string;
    file:               FileV1;
    datetime_created:   string;
    date_bill:          string;
}


export interface CancelPurchaseV1
{
    cancel_at?:     string | null;
}


export interface UpdatePurchaseV1
{
    count:          number;
    cancel_at?:     string;
}


export interface AddPurchaseV1
{
    organisation_uid:   string;
    package_uid:        string;
    packageprice_uid:   string;
    count:              number;
    voucher_code:       string | null;
}


export interface AddPurchaseV1Response
{
    purchase_uid:   string;
    code:           string | null;
}


export interface GetBillsParams
{
    organisation_uid?:  string | null;
    date_start?:        string | null;
    date_end?:          string | null;
}


export interface GetBillsCsvParams
{
    date_start?:        string | null;
    date_end?:          string | null;
}


export class PurchaseService
{
    private static _instance: PurchaseService;
    
    
    public static getInstance ( ): PurchaseService
    {
        if ( ! this._instance )
        {
            this._instance = new PurchaseService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;
    private readonly _localeService: LocaleService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
        this._localeService = LocaleService.getInstance();
    }
    
    
    public async getPurchases ( organisationUID: string ): Promise<Array<PurchaseV1>>
    {
        const locale = this._localeService.getLocale().get();

        const resp = await this._backendService.fetchJson(
            `/api/v1/purchase?` +
            `locale=${encodeURIComponent(locale)}&` +
            `organisation_uid=${encodeURIComponent(organisationUID)}`
        );

        return resp.purchases;
    }
    
    
    public async getPurchase ( purchaseUID: string ): Promise<PurchaseV1>
    {
        const locale = this._localeService.getLocale().get();

        const resp = await this._backendService.fetchJson(
            `/api/v1/purchase/${encodeURIComponent(purchaseUID)}?` +
            `locale=${encodeURIComponent(locale)}`
        );

        return resp.purchase;
    }


    public async addPurchase ( params: AddPurchaseV1 ): Promise<AddPurchaseV1Response>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/purchase`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }
   
   
    public async updatePurchase ( purchaseUID: string, params: UpdatePurchaseV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/purchase/${encodeURIComponent(purchaseUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
   

    public async cancelPurchase ( purchaseUID: string, params: CancelPurchaseV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/purchase/${encodeURIComponent(purchaseUID)}/cancel`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
   
   
    public async getBills ( params: GetBillsParams, from: number, size: number ): Promise<Array<BillV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/bill?` +
            `organisation_uid=${encodeURIComponent(params.organisation_uid || '')}&` +
            `date_start=${encodeURIComponent(params.date_start || '')}&` +
            `date_end=${encodeURIComponent(params.date_end || '')}&` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.bills;
    }
    
    
    public async getBillPdf ( billUID: string ): Promise<Blob>
    {
        const resp = await this._backendService.fetchBlob(
            `/api/v1/bill/${encodeURIComponent(billUID)}/pdf`
        );

        return resp;
    }
    
    
    public getBillCsvURL (  params: GetBillsCsvParams ): string
    {
        return `/api/v1/bill/csv?` +
            `date_start=${encodeURIComponent(params.date_start || '')}&` +
            `date_end=${encodeURIComponent(params.date_end || '')}`;
    }
}
