import React from 'react';
import { Form, Formik } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ContentFileV1Key } from '../../../Services/ContentService';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { SecureConnectionTag } from '../../../Components/SecureConnectionTag/SecureConnectionTag';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcGridAlign, IcGridItem, IcGridPadding, IcGridRow, IcInputCheckbox, IcInputText, IcLink, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface LoginPageCredentialsStepProps extends WithTranslation
{
    onFinish: ( formData: LoginPageCredentialsStepFormData ) => any;
}


export interface LoginPageCredentialsStepFormData
{
    email:          string;
    password:       string;
    stay_loggedin:  boolean;
}


interface LoginPageCredentialsStepState
{
    initialFormData: LoginPageCredentialsStepFormData;
}


class $LoginPageCredentialsStep extends React.Component<LoginPageCredentialsStepProps, LoginPageCredentialsStepState>
{
    constructor ( props: LoginPageCredentialsStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                email:          '',
                password:       '',
                stay_loggedin:  false
            }
        };

        this._login = this._login.bind(this);
    }


    private _login ( values: LoginPageCredentialsStepFormData ): void
    {
        this.props.onFinish(values);
    }


    public render ( )
    {
        const MyFormik  = Formik<LoginPageCredentialsStepFormData>;

        return (
            <MyFormik
                onSubmit={this._login}
                initialValues={this.state.initialFormData}
                enableReinitialize={true}>
                <Form>
                    <IcCard padding={IcCardPadding.None}>
                        <IcGridRow padding={IcGridPadding.None} align={IcGridAlign.Stretch}>
                            <IcGridItem s={12} m={8} l={6} padding={IcGridPadding.Large}>
                                <IcText size={IcTextSize.Small} bold={true}>
                                    {this.props.t('loginpagecredentialsstep.txt_subtitle')}
                                </IcText>
                                
                                <IcText size={IcTextSize.Heading1}>
                                    {this.props.t('loginpagecredentialsstep.txt_title')}
                                </IcText>

                                <IcText>
                                    {this.props.t('loginpagecredentialsstep.txt_register')} <IcLink to={LinkUtils.make('register')}>
                                        {this.props.t('loginpagecredentialsstep.btn_register')}
                                    </IcLink>
                                </IcText>

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='email'
                                            type='email'
                                            label={this.props.t('loginpagecredentialsstep.inp_email')}
                                            required={true}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='password'
                                            type='password'
                                            label={this.props.t('loginpagecredentialsstep.inp_password')}
                                            required={true}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputCheckbox
                                            name='stay_loggedin'
                                            label={this.props.t('loginpagecredentialsstep.inp_stay_loggedin')}
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <RequiredHint />

                                <IcButton
                                    type='submit'
                                    fullWidth={true}>
                                    {this.props.t('loginpagecredentialsstep.btn_login')}
                                </IcButton>

                                <IcButton
                                    to={LinkUtils.make('reset_password')}
                                    color={IcButtonColor.Link}
                                    fullWidth={true}>
                                    {this.props.t('loginpagecredentialsstep.btn_reset_password')}
                                </IcButton>

                                <SecureConnectionTag />
                            </IcGridItem>

                            <IcGridItem s={0} m={4} l={6}>
                                <BoxImage>
                                    <ContentImage contentFileKey={ContentFileV1Key.LoginCredentialsImage} />

                                    <SupportOverlay />
                                </BoxImage>
                            </IcGridItem>
                        </IcGridRow>
                    </IcCard>
                </Form>
            </MyFormik>
        );
    }
}


export const LoginPageCredentialsStep = withTranslation()($LoginPageCredentialsStep);
