import React from 'react';
import { SetupAddUserStep } from './SetupAddUserStep/SetupAddUserStep';
import { SetupSuccessStep } from './SetupSuccessStep/SetupSuccessStep';
import { SetupConfigureVatStep } from './SetupConfigureVatStep/SetupConfigureVatStep';
import { SetupServerInfoStep } from './SetupServerInfoStep/SetupServerInfoStep';
import { SetupCodeStep } from './SetupCodeStep/SetupCodeStep';
import { SetupConfigureSmtpStep } from './SetupConfigureSmtpStep/SetupConfigureSmtpStep';
import { SetupConfigureLocalesStep } from './SetupConfigureLocalesStep/SetupConfigureLocalesStep';
import { IcPageContent } from '@indece-common/ic-ui-lib-react';


export interface SetupPageProps
{
}


enum SetupStep
{
    Code                = 'code',
    AddUser             = 'add_user',
    ServerInfo          = 'server_info',
    ConfigureLocales    = 'configure_locales',
    ConfigureSmtp       = 'configure_smtp',
    ConfigureVat        = 'configure_vat',
    Success             = 'success'
}


interface SetupPageState
{
    step:       SetupStep;
    userUID:    string | null;
}


export class SetupPage extends React.Component<SetupPageProps, SetupPageState>
{
    constructor ( props: SetupPageProps )
    {
        super(props);

        this.state = {
            step:       SetupStep.Code,
            userUID:    null
        };

        this._finishCode = this._finishCode.bind(this);
        this._finishAddUser = this._finishAddUser.bind(this);
        this._finishServerInfo = this._finishServerInfo.bind(this);
        this._finishConfigureLocales = this._finishConfigureLocales.bind(this);
        this._finishConfigureSmtp = this._finishConfigureSmtp.bind(this);
        this._finishConfigureVat = this._finishConfigureVat.bind(this);
    }


    private _finishCode ( ): void
    {
        this.setState({
            step:   SetupStep.AddUser
        });
    }


    private _finishAddUser ( userUID: string ): void
    {
        this.setState({
            step:   SetupStep.ServerInfo,
            userUID
        });
    }
   
   
    private _finishServerInfo ( ): void
    {
        this.setState({
            step:   SetupStep.ConfigureLocales
        });
    }

   
    private _finishConfigureLocales ( ): void
    {
        this.setState({
            step:   SetupStep.ConfigureSmtp
        });
    }
   
   
    private _finishConfigureSmtp ( ): void
    {
        this.setState({
            step:   SetupStep.ConfigureVat
        });
    }
   

    private _finishConfigureVat ( ): void
    {
        this.setState({
            step:   SetupStep.Success
        });
    }


    public render ( )
    {
        return (
            <IcPageContent>
                {this.state.step === SetupStep.Code ?
                    <SetupCodeStep
                        onFinish={this._finishCode}
                    />
                : null}

                {this.state.step === SetupStep.AddUser ?
                    <SetupAddUserStep
                        onFinish={this._finishAddUser}
                    />
                : null}
               
                {this.state.step === SetupStep.ServerInfo ?
                    <SetupServerInfoStep
                        onFinish={this._finishServerInfo}
                    />
                : null}
                
                {this.state.step === SetupStep.ConfigureLocales ?
                    <SetupConfigureLocalesStep
                        onFinish={this._finishConfigureLocales}
                    />
                : null}
                
                {this.state.step === SetupStep.ConfigureSmtp ?
                    <SetupConfigureSmtpStep
                        onFinish={this._finishConfigureSmtp}
                    />
                : null}
                
                {this.state.step === SetupStep.ConfigureVat ?
                    <SetupConfigureVatStep
                        onFinish={this._finishConfigureVat}
                    />
                : null}
                
                {this.state.step === SetupStep.Success ?
                    <SetupSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}
