import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { Form, Formik } from 'formik';
import { AddVoucherV1Response, VoucherService, VoucherV1Type, VoucherV1Types } from '../../Services/VoucherService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { PackageService, PackageV1 } from '../../Services/PackageService';
import { InputDuration } from '../../Components/Input/InputDuration';
import { Formatter } from '../../utils/Formatter';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputMultiSelect, IcInputSelect, IcInputText, IcPageContent, IcSpinner, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../Components/RequiredHint/RequiredHint';


export interface AdminVoucherAddPageProps extends RouteComponentProps, WithTranslation, WithLocaleProps
{
}


interface AdminVoucherAddPageFormData
{
    type:                       VoucherV1Type;
    code:                       string;
    package_uids:               Array<string>;
    max_count:                  string;
    max_duration:               string;
    price_reduction_percent:    string;
}


interface AdminVoucherAddPageState
{
    initialFormData:    AdminVoucherAddPageFormData;
    packages:           Array<PackageV1>;
    result:             AddVoucherV1Response | null;
    error:              Error | null;
    loading:            boolean;
    success:            string | null;
}


class $AdminVoucherAddPage extends React.Component<AdminVoucherAddPageProps, AdminVoucherAddPageState>
{
    private readonly _packageService:   PackageService;
    private readonly _voucherService:   VoucherService;
    

    constructor ( props: AdminVoucherAddPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                type:                       '' as VoucherV1Type,
                code:                       '',
                package_uids:               [],
                max_count:                  '',
                max_duration:               '',
                price_reduction_percent:    '50'
            },
            packages:   [],
            result:     null,
            error:      null,
            loading:    true,
            success:    null
        };

        this._packageService = PackageService.getInstance();
        this._voucherService = VoucherService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._add       = this._add.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const packages = await this._packageService.getPackages('DE', 0, 100); // TODO

            this.setState({
                packages,
                error:      null,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading packages: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _add ( formData: AdminVoucherAddPageFormData ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const result = await this._voucherService.addVoucher({
                type:       formData.type,
                code:       formData.code.trim() || null,
                details:    {
                    package_uids:               formData.package_uids.filter( o => !!o ),
                    max_count:                  formData.max_count.trim() ? parseInt(formData.max_count.trim(), 10) : null,
                    max_duration:               formData.max_duration.trim() || null,
                    price_reduction_percent:    parseInt(formData.max_count.trim(), 10)
                }
            });

            this.setState({
                result,
                error:      null,
                loading:    false,
                success:    this.props.t('adminvoucheraddpage.txt_success')
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'vouchers'));
        }
        catch ( err )
        {
            console.error(`Error adding voucher: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<AdminVoucherAddPageFormData>;

        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'vouchers')}
                        label={this.props.t('adminvoucherspage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'voucher', 'add')}
                        label={this.props.t('adminvoucheraddpage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle title={this.props.t('adminvoucheraddpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._add}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcGridRow>
                                <IcGridItem s={12}>
                                    <IcInputSelect
                                        label={this.props.t('adminvoucheraddpage.inp_type')}
                                        name='type'
                                        options={VoucherV1Types.map( ( voucherType ) => ({
                                            label:  Formatter.voucherType(voucherType),
                                            value:  voucherType
                                        }))}
                                        required={true}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12}>
                                    <IcInputText
                                        label={this.props.t('adminvoucheraddpage.inp_code')}
                                        name='code'
                                    />
                                </IcGridItem>

                                <IcGridItem s={12}>
                                    <IcInputMultiSelect
                                        label={this.props.t('adminvoucheraddpage.inp_packages')}
                                        name='package_uids'
                                        options={this.state.packages.map( ( pkg ) => ({
                                            label:  pkg.names.find( o => o.locale === this.props.locale )?.value || '???',
                                            value:  pkg.uid
                                        }))}
                                        required={true}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12}>
                                    <IcInputText
                                        label={this.props.t('adminvoucheraddpage.inp_max_count')}
                                        name='max_count'
                                    />
                                </IcGridItem>

                                <IcGridItem s={12}>
                                    <InputDuration
                                        label={this.props.t('adminvoucheraddpage.inp_max_duration')}
                                        name='max_duration'
                                    />
                                </IcGridItem>

                                <IcGridItem s={12}>
                                    <IcInputText
                                        label={this.props.t('adminvoucheraddpage.inp_price_reduction_percent')}
                                        name='price_reduction_percent'
                                        required={true}
                                    />
                                </IcGridItem>
                            </IcGridRow>

                            <RequiredHint />

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton
                                    type='button'
                                    color={IcButtonColor.Link}
                                    onClick={this._cancel}>
                                    {this.props.t('adminvoucheraddpage.btn_cancel')}
                                </IcButton>

                                <IcButton
                                    type='submit'
                                    disabled={this.state.loading}>
                                    {this.props.t('adminvoucheraddpage.btn_add')}
                                </IcButton>
                            </IcFloatRow>
                        </IcCard>
                    </Form>
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const AdminVoucherAddPage = withLocale(withTranslation()(withRouter($AdminVoucherAddPage)));
