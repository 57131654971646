import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserService, UserV1 } from '../../Services/UserService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { UserDeletePageLoadingStep } from './UserDeletePageLoadingStep/UserDeletePageLoadingStep';
import { UserDeletePageSuccessStep } from './UserDeletePageSuccessStep/UserDeletePageSuccessStep';
import { UserDeletePageConfirmStep } from './UserDeletePageConfirmStep/UserDeletePageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface UserDeletePageRouteParams
{
    userUID: string;
}


export interface UserDeletePageProps extends RouteComponentProps<UserDeletePageRouteParams>, WithTranslation
{
}


enum UserDeletePageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface UserDeletePageState
{
    step:   UserDeletePageStep;
    user:   UserV1 | null;
    error:  Error | null;
}


class $UserDeletePage extends React.Component<UserDeletePageProps, UserDeletePageState>
{
    private readonly _userService:  UserService;
    

    constructor ( props: UserDeletePageProps )
    {
        super(props);

        this.state = {
            step:   UserDeletePageStep.Loading,
            user:   null,
            error:  null
        };

        this._userService = UserService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:       UserDeletePageStep.Loading,
                error:      null
            });

            const user = await this._userService.getUser(this.props.router.params.userUID);

            this.setState({
                step:   UserDeletePageStep.Confirm,
                user
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.user )
        {
            return;
        }

        try
        {
            this.setState({
                step:       UserDeletePageStep.Loading,
                error:      null
            });

            await this._userService.deleteUser(this.state.user.uid);

            this.setState({
                step:   UserDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-2);
        }
        catch ( err )
        {
            console.error(`Error deleting user: ${(err as Error).message}`, err);

            this.setState({
                step:       UserDeletePageStep.Confirm,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('userdeletepage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === UserDeletePageStep.Loading ?
                    <UserDeletePageLoadingStep />
                : null}

                {this.state.step === UserDeletePageStep.Confirm && this.state.user ?
                    <UserDeletePageConfirmStep
                        user={this.state.user}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === UserDeletePageStep.Success ?
                    <UserDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const UserDeletePage = withTranslation()(withRouter($UserDeletePage));
