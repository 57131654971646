import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ArrayHelpers, FieldArray, Form, Formik, FormikErrors } from 'formik';
import { ApplicationService } from '../../Services/ApplicationService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { EnabledLocales, Locale } from '../../Model/Locale';
import { Formatter } from '../../utils/Formatter';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { ErrorCode, ErrorWithCode } from '../../utils/ErrorTranslator';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcInputSelect, IcInputText, IcPageContent, IcSeparator, IcSpinner, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../Components/RequiredHint/RequiredHint';


export interface AdminApplicationCategoryAddPageProps extends RouteComponentProps, WithTranslation, WithLocaleProps
{
}


interface AdminApplicationCategoryAddPageFormDataLocalizedString
{
    locale: string;
    value:  string;
}


interface AdminApplicationCategoryAddPageFormData
{
    names:      Array<AdminApplicationCategoryAddPageFormDataLocalizedString>;
    slogans:    Array<AdminApplicationCategoryAddPageFormDataLocalizedString>;
}


interface AdminApplicationCategoryAddPageState
{
    initialFormData:        AdminApplicationCategoryAddPageFormData;
    error:                  Error | null;
    loading:                boolean;
    success:                string | null;
}


class $AdminApplicationCategoryAddPage extends React.Component<AdminApplicationCategoryAddPageProps, AdminApplicationCategoryAddPageState>
{
    private readonly _applicationService:   ApplicationService;
    

    constructor ( props: AdminApplicationCategoryAddPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                names:      [],
                slogans:    []
            },
            error:      null,
            loading:    false,
            success:    null
        };

        this._applicationService = ApplicationService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._validate = this._validate.bind(this);
        this._add = this._add.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private _validate ( formData: AdminApplicationCategoryAddPageFormData ): FormikErrors<AdminApplicationCategoryAddPageFormData>
    {
        const errors: FormikErrors<AdminApplicationCategoryAddPageFormData> = {};

        return errors;
    }


    private async _add ( formData: AdminApplicationCategoryAddPageFormData ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const names = formData.names.filter( o => o.locale && o.value.trim() );
            if ( names.length === 0 ) 
            {
                throw new ErrorWithCode(ErrorCode.AtLeastOneNameRequired);
            }


            await this._applicationService.addApplicationCategory({
                names:          formData.names.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                    locale:         o.locale as Locale,
                    value:          o.value.trim()
                })),
                slogans:        formData.slogans.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                    locale:         o.locale as Locale,
                    value:          o.value.trim()
                }))
            });

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('adminapplicationcategoryaddpage.txt_success')
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'applicationcategories'));
        }
        catch ( err )
        {
            console.error(`Error adding application category: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public render ( )
    {
        const MyFormik = Formik<AdminApplicationCategoryAddPageFormData>;

        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'applicationcategories')}
                        label={this.props.t('adminapplicationcategoriespage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'applicationcategory', 'add')}
                        label={this.props.t('adminapplicationcategoryaddpage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminapplicationcategoryaddpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._add}
                    validate={this._validate}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    {({ values }) => (
                        <Form>
                            <IcCard padding={IcCardPadding.Small}>
                                <FieldArray name='names'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading2}>
                                                {this.props.t('adminapplicationcategoryaddpage.txt_names')}
                                            </IcText>
                                    
                                            <List>
                                                {values.names.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`names.${index}.locale`}
                                                                    label={this.props.t('adminapplicationcategoryaddpage.inp_name_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>
                                                                
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`names.${index}.value`}
                                                                    label={this.props.t('adminapplicationcategoryaddpage.inp_name_name')}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationcategoryaddpage.btn_delete_name')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.names.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationcategoryaddpage.txt_no_names')}
                                                    </ListEmpty>
                                                : null}
                                            </List>
                                            
                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminapplicationcategoryaddpage.btn_add_name')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='slogans'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading2}>
                                                {this.props.t('adminapplicationcategoryaddpage.txt_slogans')}
                                            </IcText>

                                            <List>
                                                {values.slogans.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`slogans.${index}.locale`}
                                                                    label={this.props.t('adminapplicationcategoryaddpage.inp_slogan_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>
                                                                
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`slogans.${index}.value`}
                                                                    label={this.props.t('adminapplicationcategoryaddpage.inp_slogan_slogan')}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationcategoryaddpage.btn_delete_slogan')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.slogans.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationcategoryaddpage.txt_no_slogans')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminapplicationcategoryaddpage.btn_add_slogan')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <RequiredHint />
                                
                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        {this.props.t('adminapplicationcategoryaddpage.btn_cancel')}
                                    </IcButton>

                                    <IcButton
                                        type='submit'
                                        disabled={this.state.loading}>
                                        {this.props.t('adminapplicationcategoryaddpage.btn_add')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcCard>
                        </Form>
                    )}
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const AdminApplicationCategoryAddPage = withLocale(withTranslation()(withRouter($AdminApplicationCategoryAddPage)));
