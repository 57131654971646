import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';
import { RespAddApiTokenV1 } from '../../../Services/UserService';
import { IcButton, IcCode, IcFloatRow, IcFloatRowAlign, IcText } from '@indece-common/ic-ui-lib-react';


export interface UserApiTokenAddPageSuccessStepProps extends WithTranslation
{
    result:     RespAddApiTokenV1;
    onFinish:   ( ) => any;
}


class $UserApiTokenAddPageSuccessStep extends React.Component<UserApiTokenAddPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox>
                <IcText>
                    {this.props.t('userapitokenaddpagesuccessstep.txt_success')}
                </IcText>

                <IcCode code={this.props.result.authorization_code} />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        onClick={ ( ) => this.props.onFinish() }>
                        {this.props.t('userapitokenaddpagesuccessstep.btn_finish')}
                    </IcButton>
                </IcFloatRow>
            </SuccessBox>
        );
    }
}


export const UserApiTokenAddPageSuccessStep = withTranslation()($UserApiTokenAddPageSuccessStep);
