import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApplicationCategoryV1, ApplicationService } from '../../Services/ApplicationService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminApplicationCategoryDeletePageLoadingStep } from './AdminApplicationCategoryDeletePageLoadingStep/AdminApplicationCategoryDeletePageLoadingStep';
import { AdminApplicationCategoryDeletePageSuccessStep } from './AdminApplicationCategoryDeletePageSuccessStep/AdminApplicationCategoryDeletePageSuccessStep';
import { AdminApplicationCategoryDeletePageConfirmStep } from './AdminApplicationCategoryDeletePageConfirmStep/AdminApplicationCategoryDeletePageConfirmStep';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminApplicationCategoryDeletePageRouteParams
{
    applicationCategoryUID: string;
}


export interface AdminApplicationCategoryDeletePageProps extends RouteComponentProps<AdminApplicationCategoryDeletePageRouteParams>, WithTranslation
{
}


enum AdminApplicationCategoryDeletePageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AdminApplicationCategoryDeletePageState
{
    step:                   AdminApplicationCategoryDeletePageStep;
    applicationCategory:    ApplicationCategoryV1 | null;
    error:                  Error | null;
}


class $AdminApplicationCategoryDeletePage extends React.Component<AdminApplicationCategoryDeletePageProps, AdminApplicationCategoryDeletePageState>
{
    private readonly _applicationService:  ApplicationService;
    

    constructor ( props: AdminApplicationCategoryDeletePageProps )
    {
        super(props);

        this.state = {
            step:                   AdminApplicationCategoryDeletePageStep.Loading,
            applicationCategory:    null,
            error:                  null
        };

        this._applicationService = ApplicationService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminApplicationCategoryDeletePageStep.Loading,
                error:  null
            });

            const applicationCategory = await this._applicationService.getApplicationCategory(this.props.router.params.applicationCategoryUID);

            this.setState({
                step:   AdminApplicationCategoryDeletePageStep.Confirm,
                applicationCategory
            });
        }
        catch ( err )
        {
            console.error(`Error loading application: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.applicationCategory )
        {
            return;
        }

        try
        {
            this.setState({
                step:   AdminApplicationCategoryDeletePageStep.Loading,
                error:  null
            });

            await this._applicationService.deleteApplicationCategory(this.state.applicationCategory.uid);

            this.setState({
                step:   AdminApplicationCategoryDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting application category: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminApplicationCategoryDeletePageStep.Confirm,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'applicationcategories')}
                        label={this.props.t('adminapplicationcategoriespage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'applicationcategory', this.props.router.params.applicationCategoryUID, 'delete')}
                        label={this.props.t('adminapplicationcategorydeletepage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminapplicationcategorydeletepage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminApplicationCategoryDeletePageStep.Loading ?
                    <AdminApplicationCategoryDeletePageLoadingStep />
                : null}

                {this.state.step === AdminApplicationCategoryDeletePageStep.Confirm && this.state.applicationCategory ?
                    <AdminApplicationCategoryDeletePageConfirmStep
                        applicationCategory={this.state.applicationCategory}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === AdminApplicationCategoryDeletePageStep.Success ?
                    <AdminApplicationCategoryDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminApplicationCategoryDeletePage = withTranslation()(withRouter($AdminApplicationCategoryDeletePage));
