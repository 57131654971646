import { StateSubject } from 'ts-subject';
import { BackendService } from './BackendService';
import { Gender } from '../Model/Gender';
import { Locale } from '../Model/Locale';
import { LicenseV1 } from './LicenseService';
import { Country } from '../Model/Country';


export enum UserV1GlobalRole
{
    Admin   = 'ADMIN',
    Sales   = 'SALES'
}


export const UserV1GlobalRoles: Array<UserV1GlobalRole> = [
    UserV1GlobalRole.Sales,
    UserV1GlobalRole.Admin
];


export interface UserV1License
{
    organisation_uid:   string;
    license:            LicenseV1;
}


export enum UserV1OrganisationRole
{
    Member  = 'MEMBER',
    Owner   = 'OWNER'
}


export const UserV1OrganisationRoles: Array<UserV1OrganisationRole> = [
    UserV1OrganisationRole.Member,
    UserV1OrganisationRole.Owner
];


export interface UserV1Organisation
{
    uid:        string;
    country:    Country;
    role:       UserV1OrganisationRole;
    accepted:   boolean;
}


export interface UserV1
{
    uid:                string;
    locale:             Locale;
    gender:             Gender | null;
    title:              string | null;
    firstname:          string;
    lastname:           string;
    email:              string;
    invitation_pending: boolean;
    global_roles:       Array<UserV1GlobalRole>;
    organisations:      Array<UserV1Organisation>;
    licenses:           Array<UserV1License>;
    locked:             boolean;
    datetime_created:   string;
    datetime_invited:   string | null;
}


export enum UserSfaV1Type
{
    Email                   = 'EMAIL',
    WebAuthNPlatform        = 'WEBAUTHN_PLATFORM',
    WebAuthNCrossPlatform   = 'WEBAUTHN_CROSSPLATFORM',
    Totp                    = 'TOTP',
    Recovery                = 'RECOVERY'
}


export const UserSfaV1Types: Array<UserSfaV1Type> = [
    UserSfaV1Type.WebAuthNPlatform,
    UserSfaV1Type.WebAuthNCrossPlatform,
    UserSfaV1Type.Totp,
    UserSfaV1Type.Email,
    UserSfaV1Type.Recovery
];


export interface UserSfaV1
{
    uid:                    string;
    type:                   UserSfaV1Type;
    name:                   string;
    webauthn_credential_id: string | null;
}


export interface ReqGetUsersV1
{
    organisation_uid?:  string | null;
    query?:             string | null;
}


export interface ReqLoginV1
{
    email:          string | null;
    password:       string;
    stay_loggedin:  boolean;
}


export interface RespLoginV1
{
    redirect_uri:       string | null;
    sfa_required:       boolean;
    new_sfa_required:   boolean;
    sfas:               Array<UserSfaV1>;
}


export interface ReqLoginSfaV1
{
    sfa_uid:    string;
}


export interface RespLoginSfaV1
{
    webauthn_credential_id: string | null;
    webauthn_challenge:     string | null;
}


export interface ReqLoginConfirmSfaV1
{
    code?:                      string | null;
    webauthn_authdata?:         string | null;
    webauthn_clientdatajson?:   string | null;
    webauthn_signature?:        string | null;
}


export interface RespLoginConfirmSfaV1
{
    redirect_uri:       string;
}


export interface ResetPasswordV1Request
{
    email:  string;
}


export interface ResetPasswordConfirmV1Request
{
    email:          string;
    code:           string;
    new_password:   string;
}


export interface ResetPasswordConfirmV1Response
{
    resetpassword_token:    string;
    sfa_required:           boolean;
    sfas:                   Array<UserSfaV1>;
}


export interface ResetPasswordSfaV1Request
{
    resetpassword_token:    string;
    sfa_uid:                string;
}


export interface ResetPasswordSfaV1Response
{
    webauthn_credential_id: string | null;
    webauthn_challenge:     string | null;
}


export interface ResetPasswordSfaConfirmV1Request
{
    resetpassword_token:        string;
    code?:                      string | null;
    webauthn_authdata?:         string | null;
    webauthn_clientdatajson?:   string | null;
    webauthn_signature?:        string | null;
}


export interface UserApiTokenV1
{
    uid:                string;
    application_uid:    string;
    name:               string;
    datetime_created:   string;
    datetime_last_used: string | null;
}


export interface ReqAddUserV1
{
    locale:                 Locale;
    gender:                 Gender | null;
    title:                  string | null;
    firstname:              string;
    lastname:               string;
    email:                  string;
    password:               string | null;
    global_roles:           Array<UserV1GlobalRole> | null;
    organisation_uid:       string | null;
    organisation_name:      string | null;
    organisation_country:   string | null;
    organisation_role:      UserV1OrganisationRole | null;
    invitation_code:        string | null;
    invitation_user_uid:    string | null;
    accept_marketing:       boolean | null;
}


export interface RespAddUserV1
{
    user_uid:       string;
    usersfa_uid:    string | null;
}


export interface ReqUpdateOwnUserEmailV1
{
    password:   string;
    email:      string;
}


export interface ReqConfirmEmailV1
{
    code:   string;
}


export interface ReqUpdateUserV1
{
    locale:             Locale;
    gender:             Gender | null;
    title:              string | null;
    firstname:          string;
    lastname:           string;
    email:              string;
    password:           string | null;
    global_roles:       Array<UserV1GlobalRole> | null;
}


export interface ReqUpdateOwnPasswordV1
{
    password_old?:  string;
    password_new:   string;
}


export interface ReqAddApiTokenV1
{
    application_uid:    string;
    name:               string;
}


export interface RespAddApiTokenV1
{
    apitoken_uid:       string;
    authorization_code: string;
}


export interface ReqAddSfaV1
{
    type:       UserSfaV1Type;
    password:   string;
    email:      string | null;
}


export interface RespAddSfaV1
{
    sfa_uid:                string;
    totp_secret?:           string | null;
    totp_provisioning_uri?: string | null;
    webauthn_challenge?:    string | null;
    recovery_codes?:        Array<string> | null;
}


export interface ReqConfirmSfaV1
{
    code?:                          string | null;
    webauthn_credential_id?:        string | null;
    webauthn_publickey?:            string | null;
    webauthn_publickey_algorithm?:  number | null;
    webauthn_attestation?:          string | null;
}


export function isAdmin ( user: UserV1 ): boolean
{
    return user.global_roles.includes(UserV1GlobalRole.Admin);
}


export function isAdminOrSales ( user: UserV1 ): boolean
{
    return user.global_roles.includes(UserV1GlobalRole.Admin) || user.global_roles.includes(UserV1GlobalRole.Sales);
}


export function isOwner ( user: UserV1, organisationUID?: string ): boolean
{
    return !!user.organisations.find( o => o.role === UserV1OrganisationRole.Owner && (!organisationUID || o.uid === organisationUID) );
}


export function hasLicenseForApplication ( user: UserV1, applicationUID: string ): boolean
{
    return !!user.licenses.find( ( o ) => o.license.application_uids.includes(applicationUID) );
}


export class UserService
{
    private static _instance: UserService;
    private readonly _backendService: BackendService;
    private readonly _subjectLoggedIn: StateSubject<UserV1 | null>;


    public static getInstance ( ): UserService
    {
        if ( ! this._instance )
        {
            this._instance = new UserService();
        }

        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
        this._subjectLoggedIn = new StateSubject<UserV1 | null>(null);
    }


    public async load ( ): Promise<void>
    {
        try
        {
            const user = await this.getOwnUser();

            this._subjectLoggedIn.next(user);
        }
        catch ( err )
        {
            console.log(`Not logged in: ${(err as Error).message}`);

            this._subjectLoggedIn.next(null);
        }
    }
    
    
    public unload ( ): void
    {
        this._subjectLoggedIn.next(null);
    }

    
    public async getUser ( userUID: string ): Promise<UserV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user/${encodeURIComponent(userUID)}`
        );

        return resp.user;
    }
    

    public async getUserByInvitation ( userUID: string, invitationCode: string ): Promise<UserV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user/${encodeURIComponent(userUID)}/invitation/${encodeURIComponent(invitationCode)}`
        );

        return resp.user;
    }

    
    public async getOwnUser ( ): Promise<UserV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user/self`
        );

        return resp.user;
    }

  
    public async getUsers ( params: ReqGetUsersV1,
                            from: number,
                            size: number ): Promise<Array<UserV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user?` + 
            `organisation_uid=${encodeURIComponent(params.organisation_uid || '')}&` +
            `query=${encodeURIComponent(params.query || '')}&` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.users;
    }


    public async login ( params: ReqLoginV1 ): Promise<RespLoginV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/login`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }


    public async loginSfa ( params: ReqLoginSfaV1 ): Promise<RespLoginSfaV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/login/sfa`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }
    

    public async loginConfirmSfa ( params: ReqLoginConfirmSfaV1 ): Promise<RespLoginConfirmSfaV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/login/sfa/confirm`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }
    
    
    public async logout ( ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/logout`,
            {
                method:     'POST',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        this._subjectLoggedIn.next(null);
    }


    public async resetPassword ( params: ResetPasswordV1Request ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/reset-password`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async resetPasswordConfirm ( params: ResetPasswordConfirmV1Request ): Promise<ResetPasswordConfirmV1Response>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user/reset-password/confirm`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }


    public async resetPasswordSfa ( params: ResetPasswordSfaV1Request ): Promise<ResetPasswordSfaV1Response>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user/reset-password/sfa`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }
    

    public async resetPasswordSfaConfirm ( params: ResetPasswordSfaConfirmV1Request ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/reset-password/sfa/confirm`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async getSfas ( ): Promise<Array<UserSfaV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user/self/sfa`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.sfas;
    }


    public async getOwnApiTokens ( ): Promise<Array<UserApiTokenV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user/self/apitoken`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.apitokens;
    }


    public async addOwnApiToken ( params: ReqAddApiTokenV1 ): Promise<RespAddApiTokenV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user/self/apitoken`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }


    public async deleteOwnApiToken ( userApiTokenUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/self/apitoken/${encodeURIComponent(userApiTokenUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }


    public async addSfa ( params: ReqAddSfaV1 ): Promise<RespAddSfaV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user/self/sfa`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }


    public async confirmOwnSfa ( sfaUID: string, params: ReqConfirmSfaV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/self/sfa/${encodeURIComponent(sfaUID)}/confirm`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async confirmSfa ( userUID: string, sfaUID: string, params: ReqConfirmSfaV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/${encodeURIComponent(userUID)}/sfa/${encodeURIComponent(sfaUID)}/confirm`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async deleteSfa ( sfaUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/self/sfa/${encodeURIComponent(sfaUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }


    public async updateOwnUserPassword ( params: ReqUpdateOwnPasswordV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/self/password`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
    
    
    /*public async deleteOwnUser ( ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/user/v1/user/self`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        this._subjectLoggedIn.next(null);
    }*/


    public isLoggedIn ( ): StateSubject<UserV1 | null>
    {
        return this._subjectLoggedIn;
    }


    public async addUser ( params: ReqAddUserV1 ): Promise<RespAddUserV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/user`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }


    public async updateOwnUserEmail ( params: ReqUpdateOwnUserEmailV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/self/email`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async resendUpdateOwnUserEmail ( ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/self/email/resend`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify({})
            }
        );
    }


    public async confirmEmail ( params: ReqConfirmEmailV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/self/email/confirm`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async updateUser ( userUID: string, params: ReqUpdateUserV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/${encodeURIComponent(userUID)}`,
            {
                method:     'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async lockUser ( userUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/${encodeURIComponent(userUID)}/lock`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify({})
            }
        );
    }


    public async unlockUser ( userUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/${encodeURIComponent(userUID)}/unlock`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify({})
            }
        );
    }


    public async deleteUser ( userUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/user/${encodeURIComponent(userUID)}`,
            {
                method:     'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
