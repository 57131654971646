import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { Locales } from '../../Model/Locale';
import { ConfigPropertyV1, ConfigPropertyV1Countries, ConfigPropertyV1Key, ConfigPropertyV1Locales, ConfigPropertyV1Vat, ConfigService } from '../../Services/ConfigService';
import { Formatter } from '../../utils/Formatter';
import { Countries } from '../../Model/Country';
import { Currencies, Currency } from '../../Model/Currency';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcInputMultiSelect, IcInputSelect, IcInputText, IcSpinner, IcSpinnerSize, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export interface AdminConfigPageLocalesBoxProps extends WithTranslation
{
}


interface AdminConfigPageLocalesBoxFormData
{
    locales:            Array<string>;
    countries:          Array<string>;
    default_country:    string;
    default_rate_vat:   string;
    default_currency:   Currency;
}


interface AdminConfigPageLocalesBoxState
{
    initialFormData:    AdminConfigPageLocalesBoxFormData;
    config:             Partial<Record<ConfigPropertyV1Key, ConfigPropertyV1>>;
    edit:               boolean;
    loading:            boolean;
    error:              Error | null;
}


class $AdminConfigPageLocalesBox extends React.Component<AdminConfigPageLocalesBoxProps, AdminConfigPageLocalesBoxState>
{
    private readonly _configService:    ConfigService;


    constructor ( props: AdminConfigPageLocalesBoxProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                locales:            [],
                countries:          [],
                default_country:    '',
                default_rate_vat:   '0.0',
                default_currency:   '' as Currency
            },
            config:     {},
            edit:       false,
            loading:    true,
            error:      null
        };

        this._configService = ConfigService.getInstance();

        this._enableEdit = this._enableEdit.bind(this);
        this._disableEdit = this._disableEdit.bind(this);
        this._submit = this._submit.bind(this);
    }


    private _enableEdit ( )
    {
        this.setState({
            edit:   true,
            initialFormData: {
                locales:            (JSON.parse(this.state.config[ConfigPropertyV1Key.Locales]?.value || '{}') as ConfigPropertyV1Locales).locales || [],
                countries:          (JSON.parse(this.state.config[ConfigPropertyV1Key.Countries]?.value || '{}') as ConfigPropertyV1Countries).countries || [],
                default_country:    this.state.config[ConfigPropertyV1Key.DefaultCountry]?.value || '',
                default_rate_vat:   '' + ((JSON.parse(this.state.config[ConfigPropertyV1Key.Vat]?.value || '{}') as ConfigPropertyV1Vat).default?.rate_vat || '0.0'),
                default_currency:   ((JSON.parse(this.state.config[ConfigPropertyV1Key.Vat]?.value || '{}') as ConfigPropertyV1Vat).default?.currency || '').toUpperCase() as Currency
            }
        });
    }


    private _disableEdit ( )
    {
        this.setState({
            edit:   false
        });
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const config = await this._configService.getConfig();

            this.setState({
                error:      null,
                loading:    false,
                config
            });
        }
        catch ( err )
        {
            console.error(`Error loading config: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _submit ( values: AdminConfigPageLocalesBoxFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.Locales,
                JSON.stringify({
                    locales: values.locales
                } as ConfigPropertyV1Locales)
            );
            
            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.Countries,
                JSON.stringify({
                    countries: values.countries
                } as ConfigPropertyV1Countries)
            );
            
            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.DefaultCountry,
                values.default_country
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.Vat,
                JSON.stringify({
                    default: {
                        currency:   values.default_currency.toLowerCase(),
                        rate_vat:   parseFloat(values.default_rate_vat)
                    },
                    countries: {}
                } as ConfigPropertyV1Vat)
            );

            this.setState({
                loading:    false,
                edit:       false
            });

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error storing config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading2}>
                    {this.props.t('adminconfigpagelocalesbox.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <Formik
                    onSubmit={this._submit}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <List>
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={(JSON.parse(this.state.config[ConfigPropertyV1Key.Locales]?.value || '{}').locales || []).map(Formatter.locale).join(', ')}
                                            subtext={this.props.t('adminconfigpagelocalesbox.txt_locales')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputMultiSelect
                                                label={this.props.t('adminconfigpagelocalesbox.inp_locales')}
                                                name='locales'
                                                required={true}
                                                options={Locales.map( ( locale ) => ({
                                                    value:  locale,
                                                    label:  Formatter.locale(locale)
                                                }))}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                        
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={(JSON.parse(this.state.config[ConfigPropertyV1Key.Countries]?.value || '{}').countries || []).map(Formatter.country).join(', ')}
                                            subtext={this.props.t('adminconfigpagelocalesbox.txt_countries')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputMultiSelect
                                                label={this.props.t('adminconfigpagelocalesbox.inp_countries')}
                                                name='countries'
                                                required={true}
                                                options={Countries.map( ( country ) => ({
                                                    value:  country,
                                                    label:  Formatter.country(country)
                                                }))}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>

                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.DefaultCountry]?.value || ''}
                                            subtext={this.props.t('adminconfigpagelocalesbox.txt_default_country')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputSelect
                                                label={this.props.t('adminconfigpagelocalesbox.inp_default_country')}
                                                name='default_country'
                                                required={true}
                                                options={Countries.map( ( country ) => ({
                                                    label:  Formatter.country(country),
                                                    value:  country
                                                }))}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>

                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={Formatter.currency(JSON.parse(this.state.config[ConfigPropertyV1Key.Vat]?.value || '{}').default?.currency)}
                                            subtext={this.props.t('adminconfigpagelocalesbox.txt_default_currency')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputSelect
                                                label={this.props.t('adminconfigpagelocalesbox.inp_default_currency')}
                                                name='default_currency'
                                                required={true}
                                                options={Currencies.map( ( currency ) => ({
                                                    value:  currency,
                                                    label:  Formatter.currency(currency)
                                                }))}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>

                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={Formatter.percent(JSON.parse(this.state.config[ConfigPropertyV1Key.Vat]?.value || '{}').default?.rate_vat || 0.0)}
                                            subtext={this.props.t('adminconfigpagelocalesbox.txt_default_rate_vat')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpagelocalesbox.inp_default_rate_vat')}
                                                name='default_rate_vat'
                                                required={true}
                                                validators={[
                                                    Validator.minFloat(0),
                                                    Validator.maxFloat(1)
                                                ]}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                        </List>

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcSpinner
                                size={IcSpinnerSize.Small}
                                active={this.state.loading}
                            />

                            {!this.state.edit && !this.state.loading ?
                                <IcButton
                                    color={IcButtonColor.Secondary}
                                    type='button'
                                    onClick={this._enableEdit}>
                                    <FontAwesomeIcon icon={faPen} />

                                    {this.props.t('adminconfigpagelocalesbox.btn_edit')}
                                </IcButton>
                            : null}

                            {this.state.edit && !this.state.loading ? 
                                <IcButton
                                    color={IcButtonColor.Link}
                                    type='button'
                                    onClick={this._disableEdit}>
                                    {this.props.t('adminconfigpagelocalesbox.btn_cancel')}
                                </IcButton>
                            : null}

                            {this.state.edit && !this.state.loading ? 
                                <IcButton type='submit'>
                                    <FontAwesomeIcon icon={faCheck} />

                                    {this.props.t('adminconfigpagelocalesbox.btn_save')}
                                </IcButton>
                            : null}
                        </IcFloatRow>
                    </Form>
                </Formik>
            </IcCard>
        );
    }
}


export const AdminConfigPageLocalesBox = withTranslation()($AdminConfigPageLocalesBox);
