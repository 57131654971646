import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthContextProps, withAuth } from 'oidc-react';
import { EnabledLocales, Locale } from '../../Model/Locale';
import { Formatter } from '../../utils/Formatter';
import { LocaleService } from '../../Services/LocaleService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { Environment } from '../../utils/Environment';
import { Button } from '../Button/Button';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { UserService, UserV1 } from '../../Services/UserService';
import { IcSideNavService, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './NavBar.css';

import IconDE from 'country-flag-icons/react/3x2/DE';
import IconGB from 'country-flag-icons/react/3x2/GB';
import IconUS from 'country-flag-icons/react/3x2/US';


const LocaleIcons: Record<Locale, React.ReactElement> = {
    [Locale.DE_DE]: <IconDE />,
    [Locale.EN_GB]: <IconGB />,
    [Locale.EN_US]: <IconUS />
};


export interface NavBarProps extends WithTranslation, WithLocaleProps, AuthContextProps
{
}


interface NavBarState
{
    ownUser:            UserV1 | null;
    showSelectLocale:   boolean;
}


class $NavBar extends React.Component<NavBarProps, NavBarState>
{
    private readonly _userService:      UserService;
    private readonly _icSideNavService: IcSideNavService;
    private readonly _localeService:    LocaleService;


    constructor ( props: NavBarProps )
    {
        super(props);

        this.state = {
            ownUser:            null,
            showSelectLocale:   false
        };

        this._userService = UserService.getInstance();
        this._icSideNavService = IcSideNavService.getInstance();
        this._localeService = LocaleService.getInstance();

        this._showSelectLocale = this._showSelectLocale.bind(this);
    }


    private _showSelectLocale ( ): void
    {
        this.setState({
            showSelectLocale: true
        });
    }
   
    
    private _selectLocale ( locale: Locale ): void
    {
        this.setState({
            showSelectLocale: false
        });

        this._localeService.setLocale(locale);
    }


    public componentDidMount ( ): void
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <div className={`NavBar ${this.state.ownUser ? 'loggedin' : ''}`}>
                <div className='NavBar-inner'>
                    <Link to={LinkUtils.make()} className='NavBar-logo'>
                        <img src='/logo' title={Environment.server.name} alt='Home' />
                    </Link>

                    <div className='NavBar-space' />

                    <div className='NavBar-items'>

                        {!this.state.ownUser ?
                            <Link to={LinkUtils.make('packages')} className='NavBar-item'>
                                {this.props.t('navbar.btn_packages')}
                            </Link>
                        : null}

                        {!this.state.ownUser && Environment.links.supportUrl  ?
                            <a
                                href={Environment.links.supportUrl}
                                className='NavBar-item'
                                target='_blank'
                                rel='noreferrer'>
                                {this.props.t('navbar.btn_support')}
                            </a>
                        : null}

                        {this.state.ownUser ?
                            <Link to={LinkUtils.make('account')} className='NavBar-item'>
                                {Formatter.username(this.state.ownUser)}
                            </Link>
                        : null}

                        {!this.state.ownUser  ?
                            <div onClick={ ( ) => this.props.signIn() } className='NavBar-item'>
                                {this.props.t('navbar.btn_login')}

                                <FontAwesomeIcon icon={faSignIn} />
                            </div>
                        : null}

                        {!this.state.ownUser  ?
                            <Button to={LinkUtils.make('register')} className='NavBar-itembtn'>
                                {this.props.t('navbar.btn_register')}
                            </Button>
                        : null}
                    </div>

                        {EnabledLocales.length > 1 ?
                        <div className='NavBar-locale'>
                            <div className='NavBar-locale-current' onClick={this._showSelectLocale}>
                                {LocaleIcons[this.props.locale]}
                            </div>

                            <div className={`NavBar-locale-dropdown ${this.state.showSelectLocale ? 'expanded' : ''}`}>
                                {EnabledLocales.map( ( locale ) => (
                                    <div className='Navbar-locale-dropdown-item' key={locale} onClick={ ( ) => this._selectLocale(locale) }>
                                        <div className='Navbar-locale-dropdown-item-icon'>
                                            {LocaleIcons[locale]}
                                        </div>

                                        {Formatter.locale(locale)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    : null}

                    <div className='NavBar-menubutton' onClick={ ( ) => this._icSideNavService.toggle() }>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                </div>
            </div>
        )
    }
}


export const NavBar = withLocale(withTranslation()(withAuth($NavBar)));
