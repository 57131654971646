import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { VoucherV1 } from '../../../Services/VoucherService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText } from '@indece-common/ic-ui-lib-react';


export interface AdminVoucherDeletePageConfirmStepProps extends WithTranslation
{
    voucher:    VoucherV1;
    onCancel:   ( ) => any;
    onFinish:   ( ) => any;
}


class $AdminVoucherDeletePageConfirmStep extends React.Component<AdminVoucherDeletePageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText>
                    {this.props.t('adminvoucherdeletepageconfirmstep.txt_confirm')}
                </IcText>

                <List>
                    <ListItem>
                        <ListItemHeader>
                            <ListItemHeaderField
                                grow={true}
                                text={this.props.voucher.code}
                            />
                        </ListItemHeader>
                    </ListItem>
                </List>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('adminvoucherdeletepageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}>
                        {this.props.t('adminvoucherdeletepageconfirmstep.btn_delete')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AdminVoucherDeletePageConfirmStep = withTranslation()($AdminVoucherDeletePageConfirmStep);
