import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationV1 } from '../../Services/OrganisationService';
import { IcDummyContent, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface OrganisationPaymentDetailsProps extends WithTranslation
{
    organisation:   OrganisationV1 | null;
}


class $OrganisationPaymentDetails extends React.Component<OrganisationPaymentDetailsProps>
{
    public render ( )
    {
        return (
            <div>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('organisationpaymentdetails.txt_paymentaddress')}
                </IcText>

                <IcText>
                    {this.props.organisation && this.props.organisation.payment_address ?
                        <>
                            <div>
                                {this.props.organisation.payment_address.title} {this.props.organisation.payment_address.firstname} {this.props.organisation.payment_address.lastname} 
                            </div>

                            <div>
                                {this.props.organisation.name}
                            </div>

                            <div>
                                {this.props.organisation.payment_address.street}
                            </div>

                            <div>
                                {this.props.organisation.payment_address.zip} {this.props.organisation.payment_address.city}
                            </div>
                        </>
                    : null }

                    {this.props.organisation && this.props.organisation.payment_email ?
                        <div>
                            {this.props.organisation.payment_email}
                        </div>
                    : null}

                    {this.props.organisation && !this.props.organisation.payment_address && !this.props.organisation.payment_email ?
                        <div>
                            {this.props.t('organisationpaymentdetails.txt_no_payment_address')}
                        </div>
                    : null }

                    {!this.props.organisation ?
                        <div>
                            <IcDummyContent /><br />
                            <IcDummyContent /><br />
                            <IcDummyContent /><br />
                            <IcDummyContent />
                        </div>
                    : null}
                </IcText>
            </div>
        );
    }
}


export const OrganisationPaymentDetails = withTranslation()($OrganisationPaymentDetails);
