export enum Gender
{
    Male    = 'MALE',
    Female  = 'FEMALE',
    Diverse = 'DIVERSE'
}


export const Genders: Array<Gender> = [
    Gender.Female,
    Gender.Male,
    Gender.Diverse
];
