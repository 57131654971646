import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface OrganisationPaymentMethodAddPageSuccessStepProps extends WithTranslation
{
}


class $OrganisationPaymentMethodAddPageSuccessStep extends React.Component<OrganisationPaymentMethodAddPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                message={this.props.t('organisationpaymentmethodaddpagesuccessstep.txt_success')}
            />
        );
    }
}


export const OrganisationPaymentMethodAddPageSuccessStep = withTranslation()($OrganisationPaymentMethodAddPageSuccessStep);
