import { StateSubject, Subject } from 'ts-subject';
import Cookies from 'universal-cookie';


export interface CookieSelection
{
    analytics: boolean;
    marketing: boolean;
}


export const DEFAULT_COOKIE_SELECTION: CookieSelection = {
    analytics: false,
    marketing: false
};


export class CookieHintService
{
    private readonly COOKIE_NAME = 'ic_cookies';
    private static _instance:       CookieHintService;
    private _subjectSelection:      StateSubject<CookieSelection>;
    private _subjectShow:           Subject<void>;
    private _wasSelected:           boolean;
    private readonly _cookies:      Cookies;


    constructor ( )
    {
        this._subjectSelection = new StateSubject<CookieSelection>({
            ...DEFAULT_COOKIE_SELECTION
        });
        this._subjectShow = new Subject();
        this._wasSelected = false;

        this._cookies = new Cookies(null, {path: '/'});

        const cookie = this._cookies.get(this.COOKIE_NAME);

        if ( cookie )
        {
            try
            {
                this._subjectSelection.next(JSON.parse(atob(cookie)));
                this._wasSelected = true;
            }
            catch ( err )
            {
                console.info(`Invalid cookie selection: ${(err as Error).message}`, err);
            }
        }
    }


    public static getInstance ( ): CookieHintService
    {
        if ( ! this._instance )
        {
            this._instance = new CookieHintService();
        }

        return this._instance;
    }


    public wasSelected ( ): boolean
    {
        return this._wasSelected;
    }


    public getSelection ( ): StateSubject<CookieSelection>
    {
        return this._subjectSelection;
    }


    public setSelection ( selection: CookieSelection ): void
    {
        this._cookies.set(this.COOKIE_NAME, btoa(JSON.stringify(selection)), {
            path:       '/',
            secure:     false,
            httpOnly:   false,
            maxAge:     60 * 60 * 24 * 365 * 10
        });

        this._subjectSelection.next(selection);
        this._wasSelected = true;
    }


    public onShow ( ): Subject<void>
    {
        return this._subjectShow;
    }


    public show ( ): void
    {
        this._subjectShow.next();
    }
}
