import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';
import { IcButton, IcFloatRow, IcFloatRowAlign } from '@indece-common/ic-ui-lib-react';


export interface UserAddPageSuccessStepProps extends WithTranslation
{
    onFinish:           ( ) => any;
    onAssignLicenses:   ( ) => any;
}


class $UserAddPageSuccessStep extends React.Component<UserAddPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <div className='UserAddPageSuccessStep'>
                <SuccessBox
                    message={this.props.t('useraddpagesuccessstep.txt_success')}
                />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton onClick={this.props.onFinish}>
                        {this.props.t('useraddpagesuccessstep.btn_finish')}
                    </IcButton>
                    
                    <IcButton onClick={this.props.onAssignLicenses}>
                        {this.props.t('useraddpagesuccessstep.btn_assign_licenses')}
                    </IcButton>
                </IcFloatRow>
            </div>
        );
    }
}


export const UserAddPageSuccessStep = withTranslation()($UserAddPageSuccessStep);
