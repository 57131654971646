import React from 'react';

import './LabelValueList.css';


export interface LabelValueProps
{
    label:      string;
    children?:  React.ReactNode | null;
}


export class LabelValue extends React.Component<LabelValueProps>
{
    public render ( )
    {
        return [
            <div className='LabelValue-label' key={this.props.label + ':label'}>
                {this.props.label}
            </div>,

            <div className='LabelValue-value' key={this.props.label + ':value'}>
                {this.props.children}
            </div>
        ];
    }
}
