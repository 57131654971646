import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { OrganisationService, OrganisationV1, PaymentMethodV1 } from '../../Services/OrganisationService';
import { OrganisationPaymentMethodDeletePageLoadingStep } from './OrganisationPaymentMethodDeletePageLoadingStep/OrganisationPaymentMethodDeletePageLoadingStep';
import { OrganisationPaymentMethodDeletePageSuccessStep } from './OrganisationPaymentMethodDeletePageSuccessStep/OrganisationPaymentMethodDeletePageSuccessStep';
import { OrganisationPaymentMethodDeletePageConfirmStep } from './OrganisationPaymentMethodDeletePageConfirmStep/OrganisationPaymentMethodDeletePageConfirmStep';
import { sleep } from 'ts-delay';
import { IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface OrganisationPaymentMethodDeletePageRouteParams
{
    organisationUID:    string;
    paymentMethodUID:   string;
}


export interface OrganisationPaymentMethodDeletePageProps extends WithTranslation, RouteComponentProps<OrganisationPaymentMethodDeletePageRouteParams>
{
}


enum OrganisationPaymentMethodDeletePageStep
{
    Loading     = 'LOADING',
    Confirm     = 'CONFIRM',
    Success     = 'SUCCESS'
}


interface OrganisationPaymentMethodDeletePageState
{
    currStep:               OrganisationPaymentMethodDeletePageStep;
    paymentMethod:          PaymentMethodV1 | null;
    organisation:           OrganisationV1 | null;
    error:                  Error | null;
}


class $OrganisationPaymentMethodDeletePage extends React.Component<OrganisationPaymentMethodDeletePageProps, OrganisationPaymentMethodDeletePageState>
{
    private readonly _organisationService:  OrganisationService;


    constructor ( props: OrganisationPaymentMethodDeletePageProps )
    {
        super(props);

        this.state = {
            currStep:               OrganisationPaymentMethodDeletePageStep.Loading,
            paymentMethod:          null,
            organisation:           null,
            error:                  null
        };

        this._organisationService = OrganisationService.getInstance();

        this._finishConfirm = this._finishConfirm.bind(this);
        this._cancel = this._cancel.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( !this.state.organisation || !this.state.paymentMethod )
        {
            return;
        }
        
        try
        {
            this.setState({
                currStep:   OrganisationPaymentMethodDeletePageStep.Loading,
                error:      null
            });

            await this._organisationService.deleteOrganisationPaymentMethod(
                this.state.organisation.uid,
                this.state.paymentMethod.uid
            );

            this.setState({
                error:      null,
                currStep:   OrganisationPaymentMethodDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('organisation', this.props.router.params.organisationUID));
        }
        catch ( err )
        {
            console.error(`Error deleting payment method for organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   OrganisationPaymentMethodDeletePageStep.Confirm
            });
        }
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   OrganisationPaymentMethodDeletePageStep.Loading,
                error:      null
            });

            const organisation = await this._organisationService.getOrganisation(this.props.router.params.organisationUID);
            const paymentMethods = await this._organisationService.getOrganisationPaymentMethods(organisation.uid);
            const paymentMethod = paymentMethods.find( o => o.uid );
            if ( ! paymentMethod )
            {
                throw new Error('Payment method not found');
            }
            
            if ( paymentMethod.default )
            {
                throw new Error('Payment method is used as default');
            }

            this.setState({
                currStep:   OrganisationPaymentMethodDeletePageStep.Confirm,
                organisation,
                paymentMethod
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation and payment method: ${(err as Error).message}`, err);

            this.setState({
                currStep:   OrganisationPaymentMethodDeletePageStep.Loading,
                error:      err as Error
            });
        }
    }

    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('organisationpaymentmethoddeletepage.txt_delete_payment_method')}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === OrganisationPaymentMethodDeletePageStep.Loading ?
                    <OrganisationPaymentMethodDeletePageLoadingStep />
                : null}

                {this.state.currStep === OrganisationPaymentMethodDeletePageStep.Confirm && this.state.organisation && this.state.paymentMethod ?
                    <OrganisationPaymentMethodDeletePageConfirmStep
                        organisation={this.state.organisation}
                        paymentMethod={this.state.paymentMethod}
                        onFinish={this._finishConfirm}
                        onCancel={this._cancel}
                    />
                : null}

                {this.state.currStep === OrganisationPaymentMethodDeletePageStep.Success ?
                    <OrganisationPaymentMethodDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const OrganisationPaymentMethodDeletePage = withTranslation()(withRouter($OrganisationPaymentMethodDeletePage));
