import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface OrganisationMemberAcceptPageConfirmStepProps extends WithTranslation
{
    organisation:   OrganisationV1;
    onCancel:       ( ) => any;
    onFinish:       ( ) => any;
}


class $OrganisationMemberAcceptPageConfirmStep extends React.Component<OrganisationMemberAcceptPageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('organisationmemberacceptpageconfirmstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('organisationmemberacceptpageconfirmstep.txt_join')}
                </IcText>

                <IcText>
                    {this.props.organisation.name}
                </IcText>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('organisationmemberacceptpageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}>
                        {this.props.t('organisationmemberacceptpageconfirmstep.btn_confirm')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const OrganisationMemberAcceptPageConfirmStep = withTranslation()($OrganisationMemberAcceptPageConfirmStep);
