import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AuthContextProps, withAuth } from 'oidc-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderAll, faCogs, faFileInvoice, faLaptopCode, faSignIn, faSignOut, faSitemap, faTags, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { UserService, UserV1, isAdmin, isOwner } from '../../Services/UserService';
import { Environment } from '../../utils/Environment';
import { Button, ButtonStyle } from '../Button/Button';
import { IcSideNav, IcSideNavItem, IcSideNavSeparator, IcSideNavService, IcSideNavSpacer, IcText, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './SideNav.css';


export interface SideNavProps extends WithTranslation, AuthContextProps
{
}


interface SideNavState
{
    ownUser:    UserV1 | null;
    expanded:   boolean;
}


class $SideNav extends React.Component<SideNavProps, SideNavState>
{
    private readonly _userService:      UserService;
    private readonly _icSideNavService: IcSideNavService;


    constructor ( props: SideNavProps )
    {
        super(props);

        this.state = {
            ownUser:    null,
            expanded:   false
        };

        this._userService = UserService.getInstance();
        this._icSideNavService = IcSideNavService.getInstance();
    }


    public componentDidMount ( ): void
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        this._icSideNavService.isExpanded().subscribe(this, ( expanded ) =>
        {
            this.setState({
                expanded
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        const expanded = this._icSideNavService.isExpanded().get();
        this.setState({
            ownUser,
            expanded
        });
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
        this._icSideNavService.isExpanded().unsubscribe(this);
    }


    public render ( )
    {
        if ( !this.state.ownUser && !this.state.expanded )
        {
            return null;
        }

        return (
            <IcSideNav>
                <IcSideNavItem to={LinkUtils.make()}>
                    <FontAwesomeIcon
                        icon={faBorderAll}
                        fixedWidth={true}
                    />

                    {this.props.t('sidenav.btn_home')}
                </IcSideNavItem>

                <IcSideNavSeparator />

                {!this.state.ownUser ?
                    <IcSideNavItem to={LinkUtils.make('packages')}>
                        <FontAwesomeIcon
                            icon={faTags}
                            fixedWidth={true}
                        />

                        {this.props.t('sidenav.btn_packages')}
                    </IcSideNavItem>
                : null}

                {this.state.ownUser && isOwner(this.state.ownUser) ?
                    <IcSideNavItem to={LinkUtils.make('account', 'users')}>
                        <FontAwesomeIcon
                            icon={faUserGear}
                            fixedWidth={true}
                        />

                        {this.props.t('sidenav.btn_users')}
                    </IcSideNavItem>
                : null}

                {this.state.ownUser && isOwner(this.state.ownUser) ?
                    <IcSideNavItem to={LinkUtils.make('bills')}>
                        <FontAwesomeIcon
                            icon={faFileInvoice}
                            fixedWidth={true}
                        />

                        {this.props.t('sidenav.btn_bills')}
                    </IcSideNavItem>
                : null}

                {this.state.ownUser && isOwner(this.state.ownUser) && this.state.ownUser.organisations.length === 1 ?
                    <IcSideNavItem to={LinkUtils.make('organisation', this.state.ownUser.organisations[0].uid)}>
                        <FontAwesomeIcon
                            icon={faSitemap}
                            fixedWidth={true}
                        />

                        {this.props.t('sidenav.btn_organisation')}
                    </IcSideNavItem>
                : null}

                {this.state.ownUser && isOwner(this.state.ownUser) && this.state.ownUser.organisations.length !== 1 ?
                    <IcSideNavItem to={LinkUtils.make('organisations')}>
                        <FontAwesomeIcon
                            icon={faSitemap}
                            fixedWidth={true}
                        />

                        {this.props.t('sidenav.btn_organisations')}
                    </IcSideNavItem>
                : null}

                {this.state.ownUser && Environment.server.developer_tools_enabled ?
                    <IcSideNavItem to={LinkUtils.make('developer')}>
                        <FontAwesomeIcon
                            icon={faLaptopCode}
                            fixedWidth={true}
                        />

                        {this.props.t('sidenav.btn_developer')}
                    </IcSideNavItem>
                : null}

                {this.state.ownUser && isAdmin(this.state.ownUser) ?
                    <IcSideNavItem to={LinkUtils.make('admin')}>
                        <FontAwesomeIcon
                            icon={faCogs}
                            fixedWidth={true}
                        />

                        {this.props.t('sidenav.btn_administration')}
                    </IcSideNavItem>
                : null}

                {this.state.ownUser ?
                    <IcSideNavItem onClick={ ( ) => this.props.signOutRedirect() }>
                        <FontAwesomeIcon
                            icon={faSignOut}
                            fixedWidth={true}
                        />
                        
                        {this.props.t('sidenav.btn_logout')}
                    </IcSideNavItem>
                : null}

                {!this.state.ownUser ?
                    <IcSideNavItem onClick={ ( ) => this.props.signIn() }>
                        <FontAwesomeIcon
                            icon={faSignIn}
                            fixedWidth={true}
                        />
                        
                        {this.props.t('sidenav.btn_login')}
                    </IcSideNavItem>
                : null}

                {!this.state.ownUser ?
                    <div className='SideNav-itembtn'>
                        <Button
                            to={LinkUtils.make('register')}>
                            {this.props.t('sidenav.btn_register')}
                        </Button>
                    </div>
                : null}

                <IcSideNavSpacer />

                {Environment.links.supportUrl ?
                    <div className='SideNav-support'>
                        <div className='SideNav-support-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="79" height="62" viewBox="0 0 79 62" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.5 3.25H47.5V33.7L48 33.25L49.5 16.75H56H67L73 18.75L75 21.25V42.75V48.75L73 50.75L64.5 52.25V58.75L52.5 52.25H41L34 50.75V44.75V36.25H35.5L43 37.75L45.7778 35.25H32L21.5 39.75L14 43.25V37.25L4.5 35.25V3.25Z" fill="white"/>
                                <path d="M25.9375 26.1562C24.4844 26.1562 23.5156 27.2461 23.5156 28.5781C23.5156 29.9102 24.4844 31 25.9375 31C27.2695 31 28.3594 30.0312 28.3594 28.5781C28.3594 27.2461 27.2695 26.1562 25.9375 26.1562ZM27.875 7.75H23.5156C20.4883 7.75 18.1875 10.1719 18.1875 13.1992C18.1875 14.2891 19.0352 15.1367 20.125 15.1367C21.0938 15.1367 22.0625 14.2891 22.0625 13.1992C22.0625 12.3516 22.668 11.625 23.5156 11.625H27.875C28.9648 11.625 29.8125 12.4727 29.8125 13.5625C29.8125 14.2891 29.3281 14.8945 28.7227 15.2578L24.9688 17.1953C24.3633 17.4375 24 18.1641 24 18.8906V21.3125C24 22.4023 24.8477 23.25 25.9375 23.25C26.9062 23.25 27.875 22.4023 27.875 21.3125V20.1016L30.418 18.6484C32.3555 17.6797 33.6875 15.7422 33.6875 13.5625C33.6875 10.4141 31.0234 7.75 27.875 7.75ZM70.5 15.5H55V19.375H70.5C72.5586 19.375 74.375 21.1914 74.375 23.25V46.5C74.375 48.6797 72.5586 50.375 70.5 50.375H62.75V56.4297L52.0938 50.375H39.5C37.3203 50.375 35.625 48.6797 35.625 46.5V42.625H31.75V46.5C31.75 50.8594 35.1406 54.25 39.3789 54.25H51.125L64.2031 61.7578C64.5664 62 64.8086 62 65.1719 62C65.8984 62 66.625 61.5156 66.625 60.5469V54.25H70.5C74.7383 54.25 78.25 50.8594 78.25 46.6211V23.25C78.25 19.0117 74.7383 15.5 70.5 15.5ZM51.125 31V7.75C51.125 3.51172 47.6133 0 43.375 0H8.5C4.14062 0 0.75 3.51172 0.75 7.75V31C0.75 35.3594 4.14062 38.75 8.5 38.75L12.375 38.8711V45.0469C12.375 46.0156 12.9805 46.5 13.8281 46.5C14.0703 46.5 14.3125 46.5 14.6758 46.2578L27.875 38.75H43.375C47.6133 38.75 51.125 35.3594 51.125 31ZM26.7852 34.875L16.25 40.9297V34.875H8.5C6.32031 34.875 4.625 33.1797 4.625 31V7.75C4.625 5.69141 6.32031 3.875 8.5 3.875H43.375C45.4336 3.875 47.25 5.69141 47.25 7.75V31C47.25 33.1797 45.4336 34.875 43.375 34.875H26.7852Z" fill="#A0AEC0"/>
                            </svg>
                        </div>

                        <IcText>
                            {this.props.t('sidenav.txt_support')}
                        </IcText>

                        <Button
                            href={Environment.links.supportUrl}
                            style={ButtonStyle.Secondary}
                            target='_blank'>
                            {this.props.t('sidenav.btn_support')}
                        </Button>
                    </div>
                : null}
            </IcSideNav>
        );
    }
}


export const SideNav = withAuth(withTranslation()($SideNav));
