import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface AdminApplicationCategoryDeletePageSuccessStepProps extends WithTranslation
{
}


class $AdminApplicationCategoryDeletePageSuccessStep extends React.Component<AdminApplicationCategoryDeletePageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                message={this.props.t('adminapplicationcategorydeletepagesuccessstep.txt_success')}
            />
        );
    }
}


export const AdminApplicationCategoryDeletePageSuccessStep = withTranslation()($AdminApplicationCategoryDeletePageSuccessStep);
