import DayJS from 'dayjs';
import { Country } from '../Model/Country';
import { Currency } from '../Model/Currency';
import { LocalizedMultiString, LocalizedString } from '../Model/Locale';
import { BackendService } from './BackendService';
import { LicenseV1 } from './LicenseService';
import { VoucherV1 } from './VoucherService';


export enum PaymentService
{
    Free            = 'FREE',
    Paypal          = 'PAYPAL',
    Creditcard      = 'CREDITCARD',
    SepaDirectDebit = 'SEPA_DIRECT_DEBIT'
}


export const PaymentServices: Array<PaymentService> = [
    PaymentService.Paypal,
    PaymentService.Creditcard,
    PaymentService.SepaDirectDebit
];


export interface PackageV1License
{
    license:    LicenseV1;
    count:      number;
}


export interface PackageV1PricePeriod
{
    net:        number;
    vat:        number;
    gross:      number;
    duration:   string | null;
}


export interface PackageV1Price
{
    uid:                string;
    countries:          Array<Country>;
    currency:           Currency;
    payment_interval:   string;
    rate_vat:           number;
    periods:            Array<PackageV1PricePeriod>;
}


export function isFreePrice ( price: PackageV1Price ): boolean
{
    for ( const pricePeriod of price.periods )
    {
        if ( pricePeriod.net > 0 )
        {
            return false;
        }
    }
    
    return true;
}


export function maxFreeDays ( pkg: PackageV1 ): number
{
    let max = 0;

    for ( const price of pkg.details.prices )
    {
        let freeDays = 0;

        for ( const pricePeriod of price.periods )
        {
            if ( pricePeriod.net > 0 )
            {
                break;
            }

            if ( ! pricePeriod.duration )
            {
                return Infinity;
            }

            freeDays += DayJS.duration(pricePeriod.duration).asDays();
        }

        max = Math.max(max, freeDays);
    }

    return max;
}


export interface PriceInterval
{
    pricePeriod:    PackageV1PricePeriod;
    price:          PackageV1Price;
}


export function maxFreeInterval ( prices: Array<PackageV1Price> ): string | null
{
    let maxInterval: string | null = null;

    for ( const price of prices )
    {
        if ( isFreePrice(price) )
        {
            continue;
        }

        for ( const pricePeriod of price.periods )
        {
            if ( pricePeriod.net > 0 || !pricePeriod.duration )
            {
                break;
            }

            if ( maxInterval === null ||
                 DayJS.duration(pricePeriod.duration).asSeconds() > DayJS.duration(maxInterval).asSeconds() )
            {
                maxInterval = pricePeriod.duration;
            }
        }
    }

    return maxInterval;
}


export function minInterval ( prices: Array<PackageV1Price> ): string | null
{
    let minInterval: string | null = null;

    for ( const price of prices )
    {
        if ( isFreePrice(price) )
        {
            continue;
        }

        if ( minInterval === null ||
             DayJS.duration(price.payment_interval).asSeconds() < DayJS.duration(minInterval).asSeconds() )
        {
            minInterval = price.payment_interval;
        }
    }

    return minInterval;
}


export function minPriceInterval ( prices: Array<PackageV1Price> ): PriceInterval | null
{
    let minInterval: PriceInterval | null = null;
    let minNetPerDay: number | null = null;

    for ( const price of prices )
    {
        for ( const pricePeriod of price.periods )
        {
            if ( pricePeriod.net <= 0 )
            {
                continue;
            }

            const netPerDay = pricePeriod.net / DayJS.duration(price.payment_interval).asDays();
            if ( minNetPerDay === null || netPerDay < minNetPerDay )
            {
                minNetPerDay = netPerDay;
                minInterval = {
                    pricePeriod,
                    price
                };
            }
        }
    }

    return minInterval;
}


export function applyVoucherOnPrice ( pkgPrice: PackageV1Price, voucher: VoucherV1 ): PackageV1Price
{
    const periods: Array<PackageV1PricePeriod> = [];

    const maxDuration = voucher.details.max_duration ? DayJS.duration(voucher.details.max_duration) : null;
    const factor = Math.round(100 - voucher.details.price_reduction_percent) * 0.01;
    let durationSum = DayJS.duration('PT0S');

    for ( const period of pkgPrice.periods )
    {
        if ( maxDuration &&
             (durationSum.asDays() >= maxDuration.asDays()) )
        {
            // Does not apply

            periods.push({
                duration:   period.duration,
                net:        period.net,
                gross:      period.gross,
                vat:        period.vat
            });
        }
        else if ( maxDuration &&
                  (!period.duration || durationSum.add(period.duration).asDays() > maxDuration.asDays()) )
        {
            // Partially applies

            const durationPart1 = maxDuration.subtract(durationSum);
            const durationPart2 = period.duration ? DayJS(period.duration).subtract(durationPart1) : null;

            periods.push({
                duration:   durationPart1.toISOString(),
                net:        Math.round(period.net * factor),
                gross:      Math.round(period.net * factor) + Math.round(period.vat * factor),
                vat:        Math.round(period.vat * factor)
            });

            periods.push({
                duration:   durationPart2?.toISOString() || null,
                net:        period.net,
                gross:      period.gross,
                vat:        period.vat
            });
        }
        else
        {
            // Fully applies
    
            periods.push({
                duration:   period.duration,
                net:        Math.round(period.net * factor),
                gross:      Math.round(period.net * factor) + Math.round(period.vat * factor),
                vat:        Math.round(period.vat * factor)
            });
        }

        if ( period.duration )
        {
            durationSum = durationSum.add(period.duration);
        }
    }

    return {
        uid:                pkgPrice.uid,
        countries:          pkgPrice.countries,
        currency:           pkgPrice.currency,
        payment_interval:   pkgPrice.payment_interval,
        rate_vat:           pkgPrice.rate_vat,
        periods:            periods
    }
}


export function minPricePerMonth ( pkg: PackageV1 ): number
{
    let min = 0;

    for ( const price of pkg.details.prices )
    {
        for ( const pricePeriod of price.periods )
        {
            if ( pricePeriod.net <= 0 )
            {
                continue;
            }

            let intervalDuration = price.payment_interval;

            if ( pricePeriod.duration &&
                 DayJS.duration(pricePeriod.duration).asMonths() < DayJS.duration(intervalDuration).asMonths() )
            {
                intervalDuration = pricePeriod.duration;
            }

            min = Math.min(min, pricePeriod.net / DayJS.duration(intervalDuration).asMonths());
        }
    }

    return min;
}


// Calc amount for new interval
// Uses non-standard 1 year = 12 months for conversion
export function calcAmountForInterval ( srcAmount: number, srcInterval: string, dstInterval: string ): number
{
    const srcIntervalDuration = DayJS.duration(srcInterval);
    const dstIntervalDuration = DayJS.duration(dstInterval);

    let factor = 0;

    if ( (((srcIntervalDuration.asMonths() % 1) === 0) ||
          ((srcIntervalDuration.asYears() % 1) === 0)) && 
         (((dstIntervalDuration.asMonths() % 1) === 0) ||
         ((dstIntervalDuration.asYears() % 1) === 0)) )
    {
        factor = (dstIntervalDuration.years() * 12 + dstIntervalDuration.months()) /
                 (srcIntervalDuration.years() * 12 + srcIntervalDuration.months());
    }
    else
    {
        factor = dstIntervalDuration.asYears() / srcIntervalDuration.asYears();
    }

    return srcAmount * factor;
}


export interface PackageV1Details
{
    descriptions:           Array<LocalizedString>;
    features:               Array<LocalizedMultiString>;
    licenses:               Array<PackageV1License>;
    prices:                 Array<PackageV1Price>;
    max_total_count:        number | null;
    max_concurrent_count:   number | null;
    max_duration:           string | null;
    max_repeat:             number | null;
    is_bundle:              boolean;
}


export enum PackageV1Visibility
{
    Public  = 'PUBLIC',
    Private = 'PRIVATE'
}


export const PackageV1Visibilities: Array<PackageV1Visibility> = [
    PackageV1Visibility.Public,
    PackageV1Visibility.Private
];


export enum PackageV1Status
{
    OnRequest   = 'ON_REQUEST',
    Available   = 'AVAILABLE',
    ComingSoon  = 'COMING_SOON',
    Disabled    = 'DISABLED'
}


export const PackageV1Statuses: Array<PackageV1Status> = [
    PackageV1Status.OnRequest,
    PackageV1Status.Available,
    PackageV1Status.ComingSoon,
    PackageV1Status.Disabled
];


export interface PackageV1
{
    uid:        string;
    visibility: PackageV1Visibility;
    status:     PackageV1Status;
    names:      Array<LocalizedString>;
    details:    PackageV1Details;
}


export interface AddPackageV1License
{
    license_uid:    string;
    count:          number;
}


export interface AddPackageV1PricePeriod
{
    net:        number;
    duration:   string | null;
}


export interface AddPackageV1Price
{
    countries:          Array<Country>;
    currency:           Currency;
    payment_interval:   string;
    periods:            Array<AddPackageV1PricePeriod>;
}


export interface AddPackageV1Details
{
    descriptions:           Array<LocalizedString>;
    features:               Array<LocalizedMultiString>;
    licenses:               Array<AddPackageV1License>;
    max_total_count:        number | null;
    max_concurrent_count:   number | null;
    max_duration:           string | null;
    max_repeat:             number | null;
    is_bundle:              boolean;
}


export interface AddPackageV1
{
    visibility: PackageV1Visibility;
    status:     PackageV1Status;
    names:      Array<LocalizedString>;
    details:    AddPackageV1Details;
}


export interface UpdatePackageV1License
{
    license_uid:    string;
    count:          number;
}


export interface UpdatePackageV1PricePeriod
{
    net:        number;
    duration:   string | null;
}


export enum UpdatePackageV1PriceMode
{
    ApplyForAll     = 'APPLY_FOR_ALL',
    ApplyForNew     = 'APPLY_FOR_NEW'
}


export enum DeletePackageV1PriceMode
{
    Immediately     = 'IMMEDIATELY',
    PhaseOut        = 'PHASE_OUT',
    Replace         = 'REPLACE'
}


export interface UpdatePackageV1Price
{
    uid:                string | null;
    countries:          Array<Country>;
    currency:           Currency;
    payment_interval:   string;
    periods:            Array<UpdatePackageV1PricePeriod>;
}


export interface UpdatePackageV1Details
{
    descriptions:           Array<LocalizedString>;
    features:               Array<LocalizedMultiString>;
    licenses:               Array<UpdatePackageV1License>;
    prices:                 Array<UpdatePackageV1Price>;
    max_total_count:        number | null;
    max_concurrent_count:   number | null;
    max_duration:           string | null;
    max_repeat:             number | null;
    is_bundle:              boolean;
}


export interface DeletePackageV1Price
{
    mode:                   DeletePackageV1PriceMode;
    new_packageprice_uid:   string | null;
}


export interface UpdatePackageV1
{
    visibility: PackageV1Visibility;
    status:     PackageV1Status;
    names:      Array<LocalizedString>;
    details:    UpdatePackageV1Details;
}


export class PackageService
{
    private static _instance: PackageService;
    
    
    public static getInstance ( ): PackageService
    {
        if ( ! this._instance )
        {
            this._instance = new PackageService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getPackages ( country: Country, from: number, size: number ): Promise<Array<PackageV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/package?` +
            `country=${encodeURIComponent(country)}&` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.packages;
    }
    

    public async getPackage ( packageUID: string, country: Country ): Promise<PackageV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/package/${encodeURIComponent(packageUID)}?` +
            `country=${encodeURIComponent(country)}`
        );

        return resp.package;
    }
    

    public async addPackage ( params: AddPackageV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/package`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.package_uid;
    }


    public async addPackagePrice ( packageUID: string, params: AddPackageV1Price ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/package/${encodeURIComponent(packageUID)}/price`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.packageprice_uid;
    }


    public async updatePackage ( packageUID: string, params: UpdatePackageV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/package/${encodeURIComponent(packageUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async deletePackage ( packageUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/package/${encodeURIComponent(packageUID)}`,
            {
                method:     'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }


    public async deletePackagePrice ( packageUID: string, packagePriceUID: string, params: DeletePackageV1Price ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/package/${encodeURIComponent(packageUID)}/price/${encodeURIComponent(packagePriceUID)}/delete`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
}
