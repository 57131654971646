import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationV1, PaymentMethodV1 } from '../../../Services/OrganisationService';
import { VoucherV1 } from '../../../Services/VoucherService';
import { PurchasePageFormStepCountBox } from './PurchasePageFormStepCountBox';
import { PackageV1, PackageV1Price, isFreePrice } from '../../../Services/PackageService';
import { PurchasePageFormStepSelectOrganisationBox } from './PurchasePageFormStepSelectOrganisationBox';
import { PurchasePageFormStepEditOrganisationBox } from './PurchasePageFormStepEditOrganisationBox';
import { PurchasePageFormStepCompleteOrganisationBox } from './PurchasePageFormStepCompleteOrganisationBox';
import { PurchasePageFormStepAssignmentBox, PurchasePageFormStepAssignmentBoxAssignment } from './PurchasePageFormStepAssignmentBox';
import { PurchasePageFormStepTotalBox } from './PurchasePageFormStepTotalBox';
import { PurchasePageFormStepOrganisationBox } from './PurchasePageFormStepOrganisationBox';
import { PurchasePageFormStepPaymentMethodBox } from './PurchasePageFormStepPaymentMethodBox';
import { IcGridItem, IcGridRow } from '@indece-common/ic-ui-lib-react';


export interface PurchasePageFormStepProps extends WithTranslation
{
    package:                    PackageV1;
    packagePrice:               PackageV1Price;
    organisation:               OrganisationV1 | null;
    paymentMethod:              PaymentMethodV1 | null;
    count:                      number;
    voucher:                    VoucherV1 | null;
    assignments:                Array<PurchasePageFormStepAssignmentBoxAssignment>;
    verifyPaymentMethodUID:     string | null;
    onUpdateOrganisation:       ( organisation: OrganisationV1 ) => any;
    onUpdatePaymentMethod:      ( paymentMethod: PaymentMethodV1 ) => any;
    onUpdateCount:              ( count: number ) => any;
    onUpdateVoucher:            ( voucher: VoucherV1 ) => any;
    onUpdateAssignments:        ( assignments: Array<PurchasePageFormStepAssignmentBoxAssignment> ) => any;
    onFinish:                   ( ) => any;
}


interface PurchasePageFormStepState
{
    selectOrganisation:     boolean;
    completeOrganisation:   boolean;
    editOrganisation:       boolean;
}


class $PurchasePageFormStep extends React.Component<PurchasePageFormStepProps, PurchasePageFormStepState>
{
    constructor ( props: PurchasePageFormStepProps )
    {
        super(props);

        this.state = {
            selectOrganisation:     true,
            completeOrganisation:   false,
            editOrganisation:       false
        };

        this._updateOrganisation = this._updateOrganisation.bind(this);
        this._showSelectOrganisation = this._showSelectOrganisation.bind(this);
        this._showEditOrganisation = this._showEditOrganisation.bind(this);
        this._cancelEditOrganisation = this._cancelEditOrganisation.bind(this);
        this._cancelCompleteOrganisation = this._cancelCompleteOrganisation.bind(this);
    }


    private _updateOrganisation ( organisation: OrganisationV1 ): void
    {
        this.props.onUpdateOrganisation(organisation);
        
        this.setState({
            selectOrganisation:     !organisation,
            completeOrganisation:   !!organisation && !isFreePrice(this.props.packagePrice) && !organisation.payment_address,
            editOrganisation:       false
        });
    }


    private _showSelectOrganisation ( ): void
    {
        this.setState({
            selectOrganisation:     true
        });
    }


    private _showEditOrganisation ( ): void
    {
        this.setState({
            editOrganisation:   true
        });
    }


    private _cancelEditOrganisation ( ): void
    {
        this.setState({
            editOrganisation:   false
        });
    }


    private _cancelCompleteOrganisation ( ): void
    {
        this.setState({
            completeOrganisation:   false
        });
    }


    public componentDidMount ( ): void
    {
        this.setState({
            selectOrganisation:     !this.props.organisation,
            completeOrganisation:   !!this.props.organisation && !isFreePrice(this.props.packagePrice) && !this.props.organisation.payment_address,
            editOrganisation:       false
        });
    }


    public componentDidUpdate ( prevProps: Readonly<PurchasePageFormStepProps> ): void
    {
        if ( this.props.organisation?.uid !== prevProps.organisation?.uid )
        {
            this.setState({
                selectOrganisation:     !this.props.organisation,
                completeOrganisation:   !!this.props.organisation && !isFreePrice(this.props.packagePrice) && !this.props.organisation.payment_address,
                editOrganisation:       false
            });
        }
    }


    public render ( )
    {
        return (
            <IcGridRow>
                <IcGridItem s={12} m={8}>
                    <IcGridRow>
                        <IcGridItem s={12}>
                            <PurchasePageFormStepCountBox
                                count={this.props.count}
                                package={this.props.package}
                                onChange={this.props.onUpdateCount}
                            />
                        </IcGridItem>

                        <IcGridItem s={12}>
                            {this.state.selectOrganisation ?
                                <PurchasePageFormStepSelectOrganisationBox
                                    onChange={this._updateOrganisation}
                                />
                            : null}
                            
                            {this.state.editOrganisation && this.props.organisation ?
                                <PurchasePageFormStepEditOrganisationBox
                                    organisation={this.props.organisation}
                                    onCancel={this._cancelEditOrganisation}
                                    onChange={this._updateOrganisation}
                                />
                            : null}
                            
                            {this.state.completeOrganisation && this.props.organisation ?
                                <PurchasePageFormStepCompleteOrganisationBox
                                    organisation={this.props.organisation}
                                    onCancel={this._cancelCompleteOrganisation}
                                    onChange={this._updateOrganisation}
                                />
                            : null}

                            {!this.state.selectOrganisation && !this.state.completeOrganisation && !this.state.editOrganisation && this.props.organisation ?
                                <PurchasePageFormStepOrganisationBox
                                    organisation={this.props.organisation}
                                    packagePrice={this.props.packagePrice}
                                    onSelect={this._showSelectOrganisation}
                                    onEdit={this._showEditOrganisation}
                                />
                            : null}
                        </IcGridItem>
                        
                        {this.props.organisation ?
                            <IcGridItem s={12}>
                                <PurchasePageFormStepAssignmentBox
                                    organisation={this.props.organisation}
                                    package={this.props.package}
                                    assignments={this.props.assignments}
                                    count={this.props.count}
                                    onChange={this.props.onUpdateAssignments}
                                />
                            </IcGridItem>
                        : null}

                        {this.props.organisation && !isFreePrice(this.props.packagePrice) ?
                            <IcGridItem s={12}>
                                <PurchasePageFormStepPaymentMethodBox
                                    organisation={this.props.organisation}
                                    verifyPaymentMethodUID={this.props.verifyPaymentMethodUID}
                                    onChange={this.props.onUpdatePaymentMethod}
                                />
                            </IcGridItem>
                        : null}
                    </IcGridRow>
                </IcGridItem>

                <IcGridItem s={12} m={4}>
                    <PurchasePageFormStepTotalBox
                        package={this.props.package}
                        packagePrice={this.props.packagePrice}
                        organisation={this.props.organisation}
                        paymentMethod={this.props.paymentMethod}
                        count={this.props.count}
                        voucher={this.props.voucher}
                        onAddVoucher={this.props.onUpdateVoucher}
                        onPurchase={this.props.onFinish}
                    />
                </IcGridItem>
            </IcGridRow>
        );
    }
}


export const PurchasePageFormStep = withTranslation()($PurchasePageFormStep);
