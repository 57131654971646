import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcPageContent, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface ClientMissingLicensePageRouteParams
{
    licenseTag:    string;
}


export interface ClientMissingLicensePageProps extends WithTranslation, RouteComponentProps<ClientMissingLicensePageRouteParams>
{
}


class $ClientMissingLicensePage extends React.Component<ClientMissingLicensePageProps>
{
    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('clientmissinglicensepage.txt_title')}
                    hidden={true}
                />

                <IcCard padding={IcCardPadding.Large}>
                    <IcText size={IcTextSize.Heading1}>
                        {this.props.t('clientmissinglicensepage.txt_title')}
                    </IcText>

                    <IcText>
                        {this.props.t('clientmissinglicensepage.txt_required')}
                    </IcText>

                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton
                            color={IcButtonColor.Link}
                            to={LinkUtils.make()}>
                            {this.props.t('clientmissinglicensepage.btn_cancel')}
                        </IcButton>

                        <IcButton
                            to={LinkUtils.make('packages')}>
                            {this.props.t('clientmissinglicensepage.btn_show_packages')}
                        </IcButton>
                    </IcFloatRow>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const ClientMissingLicensePage = withRouter(withTranslation()($ClientMissingLicensePage));
