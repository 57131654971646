import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PackageService, PackageV1 } from '../../Services/PackageService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminPackageDeletePageLoadingStep } from './AdminPackageDeletePageLoadingStep/AdminPackageDeletePageLoadingStep';
import { AdminPackageDeletePageSuccessStep } from './AdminPackageDeletePageSuccessStep/AdminPackageDeletePageSuccessStep';
import { AdminPackageDeletePageConfirmStep } from './AdminPackageDeletePageConfirmStep/AdminPackageDeletePageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminPackageDeletePageRouteParams
{
    packageUID: string;
}


export interface AdminPackageDeletePageProps extends RouteComponentProps<AdminPackageDeletePageRouteParams>, WithTranslation
{
}


enum AdminPackageDeletePageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AdminPackageDeletePageState
{
    step:       AdminPackageDeletePageStep;
    package:    PackageV1 | null;
    error:      Error | null;
}


class $AdminPackageDeletePage extends React.Component<AdminPackageDeletePageProps, AdminPackageDeletePageState>
{
    private readonly _packageService:  PackageService;
    

    constructor ( props: AdminPackageDeletePageProps )
    {
        super(props);

        this.state = {
            step:       AdminPackageDeletePageStep.Loading,
            package:    null,
            error:      null
        };

        this._packageService = PackageService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminPackageDeletePageStep.Loading,
                error:  null
            });

            const pkg = await this._packageService.getPackage(this.props.router.params.packageUID, 'DE');   // TODO

            this.setState({
                step:       AdminPackageDeletePageStep.Confirm,
                package:    pkg
            });
        }
        catch ( err )
        {
            console.error(`Error loading package: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.package )
        {
            return;
        }

        try
        {
            this.setState({
                step:   AdminPackageDeletePageStep.Loading,
                error:  null
            });

            await this._packageService.deletePackage(this.state.package.uid);

            this.setState({
                step:   AdminPackageDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting package: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminPackageDeletePageStep.Confirm,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminpackagedeletepage.txt_delete_package')} />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminPackageDeletePageStep.Loading ?
                    <AdminPackageDeletePageLoadingStep />
                : null}

                {this.state.step === AdminPackageDeletePageStep.Confirm && this.state.package ?
                    <AdminPackageDeletePageConfirmStep
                        package={this.state.package}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === AdminPackageDeletePageStep.Success ?
                    <AdminPackageDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminPackageDeletePage = withTranslation()(withRouter($AdminPackageDeletePage));
