import React from 'react';

import './TabButtons.css';


export interface TabButtonOption<T extends string>
{
    label:  string;
    value:  T;
}


export interface TabButtonsProps<T extends string>
{
    options:    Array<TabButtonOption<T>>;
    value:      T | null;
    onSelect:   ( value: T ) => any;
}


export class TabButtons<T extends string> extends React.Component<TabButtonsProps<T>>
{
    public render ( )
    {
        return (
            <div className='TabButtons'>
                {this.props.options.map( ( option, i ) => (
                    <button
                        key={i}
                        type='button'
                        onClick={ ( ) => this.props.onSelect(option.value) }
                        className={`TabButtons-button ${this.props.value === option.value ? 'active' : ''}`}>
                        {option.label}
                    </button>
                ))}

                <div className='TabButtons-spacer' />
            </div>
        );
    }
}
