import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationV1, PaymentMethodV1 } from '../../../Services/OrganisationService';
import { PaymentMethodDetails } from '../../../BusinessComponents/PaymentMethodDetails/PaymentMethodDetails';
import { OrganisationDetails } from '../../../Components/OrganisationDetails/OrganisationDetails';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText } from '@indece-common/ic-ui-lib-react';


export interface OrganisationPaymentMethodDeletePageConfirmStepProps extends WithTranslation
{
    organisation:   OrganisationV1;
    paymentMethod:  PaymentMethodV1;
    onFinish:       ( ) => any;
    onCancel:       ( ) => any;
}


class $OrganisationPaymentMethodDeletePageConfirmStep extends React.Component<OrganisationPaymentMethodDeletePageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText>
                    {this.props.t('organisationpaymentmethoddeletepageconfirmstep.txt_confirm')}
                </IcText>

                <OrganisationDetails organisation={this.props.organisation} />

                <PaymentMethodDetails paymentMethod={this.props.paymentMethod} />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('organisationpaymentmethoddeletepageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}>
                        {this.props.t('organisationpaymentmethoddeletepageconfirmstep.btn_confirm')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const OrganisationPaymentMethodDeletePageConfirmStep = withTranslation()($OrganisationPaymentMethodDeletePageConfirmStep);
