import React from 'react';
import { Form, Formik } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { ContentFileV1Key } from '../../../Services/ContentService';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcGridAlign, IcGridItem, IcGridPadding, IcGridRow, IcInputText, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface LoginPageSfaEmailStepProps extends WithTranslation
{
    onFinish:   ( formData: LoginPageSfaEmailStepFormData ) => any;
    onOtherSfa: ( ) => any;
}


export interface LoginPageSfaEmailStepFormData
{
    code:  string;
}


interface LoginPageSfaEmailStepState
{
    initialFormData: LoginPageSfaEmailStepFormData;
}


class $LoginPageSfaEmailStep extends React.Component<LoginPageSfaEmailStepProps, LoginPageSfaEmailStepState>
{
    constructor ( props: LoginPageSfaEmailStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                code:  ''
            }
        };

        this._confirm = this._confirm.bind(this);
        this._resend = this._resend.bind(this);
    }


    private _confirm ( values: LoginPageSfaEmailStepFormData ): void
    {
        this.props.onFinish(values);
    }


    private async _resend ( ): Promise<void>
    {
        // TODO
    }


    public render ( )
    {
        const MyFormik  = Formik<LoginPageSfaEmailStepFormData>;

        return (
            <IcCard padding={IcCardPadding.None}>
                <IcGridRow padding={IcGridPadding.None} align={IcGridAlign.Stretch}>
                    <IcGridItem s={12} m={8} l={6} padding={IcGridPadding.Large}>
                        <IcText size={IcTextSize.Small} bold={true}>
                            {this.props.t('loginpagesfaemailstep.txt_subtitle')}
                        </IcText>
                        
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('loginpagesfaemailstep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('loginpagesfaemailstep.txt_code')}
                        </IcText>

                        <MyFormik
                            onSubmit={this._confirm}
                            initialValues={this.state.initialFormData}
                            enableReinitialize={true}>
                            <Form>
                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='code'
                                            autoComplete='off'
                                            label={this.props.t('loginpagesfaemailstep.inp_code')}
                                            required={true}
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <RequiredHint />

                                <IcButton
                                    type='submit'
                                    fullWidth={true}>
                                    {this.props.t('loginpagesfaemailstep.btn_confirm')}
                                </IcButton>

                                <IcGridRow padding={IcGridPadding.Small}>
                                    <IcGridItem s={6}>
                                        <IcButton
                                            type='button'
                                            color={IcButtonColor.Secondary}
                                            fullWidth={true}
                                            onClick={this._resend}>
                                            {this.props.t('loginpagesfaemailstep.btn_resend')}
                                        </IcButton>
                                    </IcGridItem>
                                
                                    <IcGridItem s={6}>
                                        <IcButton
                                            type='button'
                                            color={IcButtonColor.Secondary}
                                            fullWidth={true}
                                            onClick={ ( ) => this.props.onOtherSfa() }>
                                            {this.props.t('loginpagesfaemailstep.btn_other_sfa')}
                                        </IcButton>
                                    </IcGridItem>
                                </IcGridRow>
                            </Form>
                        </MyFormik>
                    </IcGridItem>

                    <IcGridItem s={0} m={4} l={6}>
                        <BoxImage>
                            <ContentImage contentFileKey={ContentFileV1Key.LoginSfaEmailImage} />

                            <SupportOverlay />
                        </BoxImage>
                    </IcGridItem>
                </IcGridRow>
            </IcCard>
        );
    }
}


export const LoginPageSfaEmailStep = withTranslation()($LoginPageSfaEmailStep);
