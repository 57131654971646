import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserService, UserV1 } from '../../Services/UserService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { UserLockPageLoadingStep } from './UserLockPageLoadingStep/UserLockPageLoadingStep';
import { UserLockPageSuccessStep } from './UserLockPageSuccessStep/UserLockPageSuccessStep';
import { UserLockPageConfirmStep } from './UserLockPageConfirmStep/UserLockPageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface UserLockPageRouteParams
{
    userUID: string;
}


export interface UserLockPageProps extends RouteComponentProps<UserLockPageRouteParams>, WithTranslation
{
}


enum UserLockPageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface UserLockPageState
{
    step:   UserLockPageStep;
    user:   UserV1 | null;
    error:  Error | null;
}


class $UserLockPage extends React.Component<UserLockPageProps, UserLockPageState>
{
    private readonly _userService:  UserService;
    

    constructor ( props: UserLockPageProps )
    {
        super(props);

        this.state = {
            step:   UserLockPageStep.Loading,
            user:   null,
            error:  null
        };

        this._userService = UserService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:       UserLockPageStep.Loading,
                error:      null
            });

            const user = await this._userService.getUser(this.props.router.params.userUID);
            const ownUser = await this._userService.getOwnUser();
            if ( user.uid === ownUser.uid )
            {
                throw new Error('You can not lock your own user');
            }

            this.setState({
                step:   UserLockPageStep.Confirm,
                user
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.user )
        {
            return;
        }

        try
        {
            this.setState({
                step:       UserLockPageStep.Loading,
                error:      null
            });

            await this._userService.lockUser(this.state.user.uid);

            this.setState({
                step:   UserLockPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting user: ${(err as Error).message}`, err);

            this.setState({
                step:       UserLockPageStep.Confirm,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('userlockpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === UserLockPageStep.Loading ?
                    <UserLockPageLoadingStep />
                : null}

                {this.state.step === UserLockPageStep.Confirm && this.state.user ?
                    <UserLockPageConfirmStep
                        user={this.state.user}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === UserLockPageStep.Success ?
                    <UserLockPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const UserLockPage = withTranslation()(withRouter($UserLockPage));
