import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UpdatePackageV1PriceMode } from '../../../Services/PackageService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign } from '@indece-common/ic-ui-lib-react';


export interface AdminPackagePriceEditPageModeStepProps extends WithTranslation
{
    onFinish: ( mode: UpdatePackageV1PriceMode ) => any;
    onCancel: ( ) => any;
}


class $AdminPackagePriceEditPageModeStep extends React.Component<AdminPackagePriceEditPageModeStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcButton
                    onClick={ ( ) => this.props.onFinish(UpdatePackageV1PriceMode.ApplyForAll)}>
                    {this.props.t('adminpackagepriceeditpagemodestep.btn_apply_for_all')}
                </IcButton>

                <IcButton
                    onClick={ ( ) => this.props.onFinish(UpdatePackageV1PriceMode.ApplyForNew)}>
                    {this.props.t('adminpackagepriceeditpagemodestep.btn_apply_for_new')}
                </IcButton>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        type='button'
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}
                        testID='adminpackagepriceeditpagemodestep.btn_cancel'>
                        {this.props.t('adminpackagepriceeditpagemodestep.btn_cancel')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AdminPackagePriceEditPageModeStep = withTranslation()($AdminPackagePriceEditPageModeStep);
