import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApplicationCategoryV1 } from '../../../Services/ApplicationService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { WithLocaleProps, withLocale } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText } from '@indece-common/ic-ui-lib-react';


export interface AdminApplicationCategoryDeletePageConfirmStepProps extends WithTranslation, WithLocaleProps
{
    applicationCategory:    ApplicationCategoryV1;
    onCancel:               ( ) => any;
    onFinish:               ( ) => any;
}


class $AdminApplicationCategoryDeletePageConfirmStep extends React.Component<AdminApplicationCategoryDeletePageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText>
                    {this.props.t('adminapplicationcategorydeletepageconfirmstep.txt_confirm')}
                </IcText>
                
                <List>
                    <ListItem>
                        <ListItemHeader>
                            <ListItemHeaderField
                                grow={true}
                                text={this.props.applicationCategory.names.find( ( o ) => o.locale === this.props.locale)?.value || ''}
                            />
                        </ListItemHeader>
                    </ListItem>
                </List>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('adminapplicationcategorydeletepageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}>
                        {this.props.t('adminapplicationcategorydeletepageconfirmstep.btn_delete')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AdminApplicationCategoryDeletePageConfirmStep = withLocale(withTranslation()($AdminApplicationCategoryDeletePageConfirmStep));
