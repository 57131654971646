import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserService, UserV1 } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface UserResetPasswordPageRouteParams
{
    userUID:    string;
}


export interface UserResetPasswordPageProps extends WithTranslation, RouteComponentProps<UserResetPasswordPageRouteParams>
{
}


enum UserResetPasswordPageStep
{
    Confirm     = 'CONFIRM',
    Loading     = 'LOADING',
    Success     = 'SUCCESS'
}


interface UserResetPasswordPageState
{
    currStep:   UserResetPasswordPageStep;
    error:      Error | null;
    user:       UserV1 | null;
}


class $UserResetPasswordPage extends React.Component<UserResetPasswordPageProps, UserResetPasswordPageState>
{
    private readonly _userService:  UserService;


    constructor ( props: UserResetPasswordPageProps )
    {
        super(props);

        this.state = {
            currStep:   UserResetPasswordPageStep.Loading,
            error:      null,
            user:       null
        };

        this._userService = UserService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   UserResetPasswordPageStep.Loading,
                error:      null
            });

            const user = await this._userService.getUser(this.props.router.params.userUID);

            this.setState({
                currStep:   UserResetPasswordPageStep.Confirm,
                user
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                error:  err as Error 
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('userresetpasswordpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />
            </IcPageContent>
        );
    }
}


export const UserResetPasswordPage = withTranslation()(withRouter($UserResetPasswordPage));
