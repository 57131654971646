import { Locale } from '../Model/Locale';
import { BackendService } from './BackendService';


export enum ContentV1Key
{
    Imprint                 = 'IMPRINT',
    Terms                   = 'TERMS',
    PrivacyPolicy           = 'PRIVACYPOLICY',
    TeaserTitle             = 'TEASER_TITLE',
    TeaserSubtitle          = 'TEASER_SUBTITLE',
    PackagesTitle           = 'PACKAGES_TITLE',
    PackagesSubtitle        = 'PACKAGES_SUBTITLE',
    PackagesText            = 'PACKAGES_TEXT',
    PackagesContactTitle    = 'PACKAGES_CONTACT_TITLE',
    PackagesContactText     = 'PACKAGES_CONTACT_TEXT',
    ContactTitle            = 'CONTACT_TITLE',
    ContactSubtitle         = 'CONTACT_SUBTITLE',
    SupportTitle            = 'SUPPORT_TITLE',
    SupportText             = 'SUPPORT_TEXT'
}


export const ContentV1Keys: Array<ContentV1Key> = [
    ContentV1Key.Imprint,
    ContentV1Key.Terms,
    ContentV1Key.PrivacyPolicy,
    ContentV1Key.TeaserTitle,
    ContentV1Key.TeaserSubtitle,
    ContentV1Key.PackagesTitle,
    ContentV1Key.PackagesSubtitle,
    ContentV1Key.PackagesText,
    ContentV1Key.PackagesContactTitle,
    ContentV1Key.PackagesContactText,
    ContentV1Key.ContactTitle,
    ContentV1Key.ContactSubtitle,
    ContentV1Key.SupportTitle,
    ContentV1Key.SupportText
];


export interface ContentV1
{
    key:        ContentV1Key;
    locale:     Locale;
    content:    string;
}


export interface UpdateContentV1
{
    locale:     Locale;
    content:    string;
}


export enum ContentFileV1Key
{
    TeaserImage                 = 'TEASER_IMAGE',
    LoginCredentialsImage       = 'LOGIN_CREDENTIALS_IMAGE',
    LoginSfaImage               = 'LOGIN_SFA_IMAGE',
    LoginSfaEmailImage          = 'LOGIN_SFAEMAIL_IMAGE',
    LoginSfaTotpImage           = 'LOGIN_SFATOTP_IMAGE',
    LoginSfaRecoveryImage       = 'LOGIN_SFARECOVERY_IMAGE',
    LoginSfaWebAuthNImage       = 'LOGIN_SFAWEBAUTHN_IMAGE',
    RegisterCredentialsImage    = 'REGISTER_CREDENTIALS_IMAGE',
    RegisterOrganisationImage   = 'REGISTER_ORGANISATION_IMAGE',
    RegisterConfirmEmailImage   = 'REGISTER_CONFIRMEMAIL_IMAGE',
    ContactImage                = 'CONTACT_IMAGE',
    SupportOverlayImage         = 'SUPPORT_OVERLAY_IMAGE'
}


export const ContentFileV1Keys: Array<ContentFileV1Key> = [
    ContentFileV1Key.TeaserImage,
    ContentFileV1Key.LoginCredentialsImage,
    ContentFileV1Key.LoginSfaImage,
    ContentFileV1Key.LoginSfaEmailImage,
    ContentFileV1Key.LoginSfaTotpImage,
    ContentFileV1Key.LoginSfaRecoveryImage,
    ContentFileV1Key.LoginSfaWebAuthNImage,
    ContentFileV1Key.RegisterCredentialsImage,
    ContentFileV1Key.RegisterOrganisationImage,
    ContentFileV1Key.RegisterConfirmEmailImage,
    ContentFileV1Key.ContactImage,
    ContentFileV1Key.SupportOverlayImage
];


export interface ContentFileV1
{
    key:        ContentFileV1Key;
    locale:     Locale;
    file_uid:   string | null;
}


export interface UpdateContentFileV1
{
    locale:     Locale;
    file_uid:   string | null;
}


export class ContentService
{
    private static _instance: ContentService;
    
    
    public static getInstance ( ): ContentService
    {
        if ( ! this._instance )
        {
            this._instance = new ContentService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getContent ( contentKey: ContentV1Key, locale: Locale ): Promise<ContentV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/content/${encodeURIComponent(contentKey)}?` +
            `locale=${encodeURIComponent(locale)}`
        );

        return resp.content;
    }


    public async updateContent ( contentKey: ContentV1Key, params: UpdateContentV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/content/${encodeURIComponent(contentKey)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async getContentFile ( contentFileKey: ContentFileV1Key, locale: Locale ): Promise<ContentFileV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/contentfile/${encodeURIComponent(contentFileKey)}?` +
            `locale=${encodeURIComponent(locale)}`
        );

        return resp.contentfile;
    }


    public async updateContentFile ( contentFileKey: ContentFileV1Key, params: UpdateContentFileV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/contentfile/${encodeURIComponent(contentFileKey)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
}
