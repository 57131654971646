import React from 'react';

import './Box.css';


export interface BoxImageProps
{
    className?: string;
    children?:  React.ReactNode | undefined;
}


export class BoxImage extends React.Component<BoxImageProps>
{
    public render ( )
    {
        return (
            <div className={`BoxImage ${this.props.className || ''}`}>
                {this.props.children}
            </div>
        );
    }
}
