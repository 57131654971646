import React from 'react';
import { PackageDetails } from '../../Components/PackageDetails/PackageDetails';
import { PurchaseV1 } from '../../Services/PurchaseService';


export interface PurchaseDetailsProps
{
    purchase:       PurchaseV1;
    count?:         number;
}


export class PurchaseDetails extends React.Component<PurchaseDetailsProps>
{
    public render ( )
    {
        return (
            <PackageDetails
                package={this.props.purchase.package}
                packagePrice={this.props.purchase.package.details.prices.find( o => o.uid === this.props.purchase.package_price_uid )!}
                country={this.props.purchase.country}
                count={this.props.count !== undefined ? this.props.count : this.props.purchase.count}
            />
        );
    }
}
