import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { PackageV1Price } from '../../../Services/PackageService';
import { Currencies, Currency } from '../../../Model/Currency';
import { Country, EnabledCountries } from '../../../Model/Country';
import { Formatter } from '../../../utils/Formatter';
import { InputDuration } from '../../../Components/Input/InputDuration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { ListItemHeaderAction } from '../../../Components/List/ListItemHeaderAction';
import { ListEmpty } from '../../../Components/List/ListEmpty';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputMultiSelect, IcInputSelect, IcInputText } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


interface AdminPackagePriceEditPageFormStepFormDataPeriod
{
    net:            string;
    duration:       string;
}


export interface AdminPackagePriceEditPageFormStepFormData
{
    countries:          Array<Country>;
    currency:           Currency;
    payment_interval:   string;
    periods:            Array<AdminPackagePriceEditPageFormStepFormDataPeriod>;
}


export interface AdminPackagePriceEditPageFormStepProps extends WithTranslation, WithLocaleProps
{
    packagePrice:   PackageV1Price;
    onCancel:       ( ) => any;
    onFinish:       ( formData: AdminPackagePriceEditPageFormStepFormData ) => any;
}


interface AdminPackagePriceEditPageFormStepState
{
    initialFormData:    AdminPackagePriceEditPageFormStepFormData;
}


class $AdminPackagePriceEditPageFormStep extends React.Component<AdminPackagePriceEditPageFormStepProps, AdminPackagePriceEditPageFormStepState>
{
    constructor ( props: AdminPackagePriceEditPageFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                countries:          [],
                currency:           '' as Currency,
                payment_interval:   '',
                periods:            []
            }
        };
    }


    private _applyPackagePrice ( pkgPrice: PackageV1Price ): void
    {
        this.setState({
            initialFormData: {
                countries:          pkgPrice.countries,
                currency:           pkgPrice.currency,
                payment_interval:   pkgPrice.payment_interval,
                periods:            pkgPrice.periods.map( ( period ) => ({
                    net:                '' + period.net,
                    duration:           period.duration || ''
                }))
            }
        });
    }


    public componentDidMount ( ): void
    {
        this._applyPackagePrice(this.props.packagePrice);
    }


    public componentDidUpdate ( prevProps: AdminPackagePriceEditPageFormStepProps ): void
    {
        if ( prevProps.packagePrice !== this.props.packagePrice )
        {
            this._applyPackagePrice(this.props.packagePrice);
        }
    }


    public render ( )
    {
        const MyFormik = Formik<AdminPackagePriceEditPageFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    {({ values }) => (
                        <Form>
                            <IcGridRow>
                                <IcGridItem s={12}>
                                    <IcInputMultiSelect
                                        name='countries'
                                        label={this.props.t('adminpackageeditpage.inp_price_countries')}
                                        options={EnabledCountries.map( ( country ) => ({
                                            label: Formatter.country(country),
                                            value: country
                                        }))}
                                        required={true}
                                    />
                                </IcGridItem>
                                
                                <IcGridItem s={12}>
                                    <InputDuration
                                        name='payment_interval'
                                        label={this.props.t('adminpackageeditpage.inp_price_payment_interval')}
                                        required={true}
                                    />
                                </IcGridItem>
                                    
                                <IcGridItem s={12}>
                                    <IcInputSelect
                                        name='currency'
                                        label={this.props.t('adminpackageeditpage.inp_price_currency')}
                                        options={Currencies.map( ( currency ) => ({
                                            label: Formatter.currency(currency),
                                            value: currency
                                        }))}
                                        required={true}
                                    />
                                </IcGridItem>
                            </IcGridRow>
                            
                            <FieldArray name='periods'>
                                {( arrayHelpers: ArrayHelpers<Array<AdminPackagePriceEditPageFormStepFormDataPeriod>> ) => (
                                    <div>
                                        <List>
                                            {values.periods.map( ( _, indexPeriod ) => (
                                                <ListItem key={indexPeriod}>
                                                    <ListItemHeader>
                                                        <ListItemHeaderField grow={true}>
                                                            <IcInputText
                                                                name={`periods.${indexPeriod}.net`}
                                                                label={this.props.t('adminpackageeditpage.inp_price_period_net')}
                                                                required={true}
                                                            />
                                                        </ListItemHeaderField>

                                                        <ListItemHeaderField grow={true}>
                                                            <InputDuration
                                                                name={`periods.${indexPeriod}.duration`}
                                                                label={this.props.t('adminpackageeditpage.inp_price_period_duration')}
                                                            />
                                                        </ListItemHeaderField>

                                                        <ListItemHeaderAction
                                                            icon={faTimes}
                                                            onClick={() => arrayHelpers.remove(indexPeriod)}
                                                            title={this.props.t('adminpackageeditpage.btn_delete_period')}
                                                        />
                                                    </ListItemHeader>
                                                </ListItem>
                                            ))}

                                            {values.periods.length === 0 ?
                                                <ListEmpty>
                                                    {this.props.t('adminpackageeditpage.txt_no_periods')}
                                                </ListEmpty>
                                            : null}
                                        </List>

                                        <IcFloatRow align={IcFloatRowAlign.Right}>
                                            <IcButton
                                                type='button'
                                                color={IcButtonColor.Secondary}
                                                onClick={() => arrayHelpers.push({
                                                    net: '',
                                                    duration: ''
                                                })}>
                                                <FontAwesomeIcon icon={faPlus} />

                                                {this.props.t('adminpackageeditpage.btn_add_period')}
                                            </IcButton>
                                        </IcFloatRow>
                                    </div>
                                )}
                            </FieldArray>

                            <RequiredHint />

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton
                                    type='button'
                                    color={IcButtonColor.Link}
                                    onClick={this.props.onCancel}
                                    testID='adminpackagepriceeditpageformstep.btn_cancel'>
                                    {this.props.t('adminpackagepriceeditpageformstep.btn_cancel')}
                                </IcButton>

                                <IcButton
                                    type='submit'
                                    testID='adminpackagepriceeditpageformstep.btn_update'>
                                    {this.props.t('adminpackagepriceeditpageformstep.btn_update')}
                                </IcButton>
                            </IcFloatRow>
                        </Form>
                    )}
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminPackagePriceEditPageFormStep = withLocale(withTranslation()($AdminPackagePriceEditPageFormStep));
