import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserService, UserV1, UserV1License } from '../../Services/UserService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { UserLicenseUnassignPageLoadingStep } from './UserLicenseUnassignPageLoadingStep/UserLicenseUnassignPageLoadingStep';
import { UserLicenseUnassignPageSuccessStep } from './UserLicenseUnassignPageSuccessStep/UserLicenseUnassignPageSuccessStep';
import { UserLicenseUnassignPageConfirmStep } from './UserLicenseUnassignPageConfirmStep/UserLicenseUnassignPageConfirmStep';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface UserLicenseUnassignPageRouteParams
{
    organisationUID:    string;
    userUID:            string;
    licenseUID:         string;
}


export interface UserLicenseUnassignPageProps extends RouteComponentProps<UserLicenseUnassignPageRouteParams>, WithTranslation
{
}


enum UserLicenseUnassignPageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface UserLicenseUnassignPageState
{
    step:           UserLicenseUnassignPageStep;
    organisation:   OrganisationV1 | null;
    user:           UserV1 | null;
    license:        UserV1License | null;
    error:          Error | null;
}


class $UserLicenseUnassignPage extends React.Component<UserLicenseUnassignPageProps, UserLicenseUnassignPageState>
{
    private readonly _userService:          UserService;
    private readonly _organisationService:  OrganisationService;
    

    constructor ( props: UserLicenseUnassignPageProps )
    {
        super(props);

        this.state = {
            step:           UserLicenseUnassignPageStep.Loading,
            organisation:   null,
            user:           null,
            license:        null,
            error:          null
        };

        this._userService = UserService.getInstance();
        this._organisationService = OrganisationService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   UserLicenseUnassignPageStep.Loading,
                error:  null
            });

            const user = await this._userService.getUser(this.props.router.params.userUID);
            const organisation = await this._organisationService.getOrganisation(this.props.router.params.organisationUID);
            const license = user.licenses.find( o => 
                (o.license.uid === this.props.router.params.licenseUID) && (o.organisation_uid === this.props.router.params.organisationUID)
            );
            if ( ! license )
            {
                throw new Error('License not found'); // TODO
            }

            this.setState({
                step:   UserLicenseUnassignPageStep.Confirm,
                user,
                organisation,
                license
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( !this.state.user || !this.state.license )
        {
            return;
        }

        try
        {
            this.setState({
                step:   UserLicenseUnassignPageStep.Loading,
                error:  null
            });

            await this._organisationService.unassignOrganisationMemberLicense(
                this.state.license.organisation_uid,
                this.state.user.uid,
                this.state.license.license.uid,
            );

            await this._userService.load();

            this.setState({
                step:   UserLicenseUnassignPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error unassigning license from user: ${(err as Error).message}`, err);

            this.setState({
                step:   UserLicenseUnassignPageStep.Confirm,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('userlicenseunassignpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === UserLicenseUnassignPageStep.Loading ?
                    <UserLicenseUnassignPageLoadingStep />
                : null}

                {this.state.step === UserLicenseUnassignPageStep.Confirm && this.state.organisation && this.state.user && this.state.license ?
                    <UserLicenseUnassignPageConfirmStep
                        organisation={this.state.organisation}
                        user={this.state.user}
                        license={this.state.license}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === UserLicenseUnassignPageStep.Success ?
                    <UserLicenseUnassignPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const UserLicenseUnassignPage = withTranslation()(withRouter($UserLicenseUnassignPage));
