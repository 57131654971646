import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface AdminLicenseDeletePageSuccessStepProps extends WithTranslation
{
}


class $AdminLicenseDeletePageSuccessStep extends React.Component<AdminLicenseDeletePageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                testID='adminlicensedeletepagesuccessstep.txt_success'
                message={this.props.t('adminlicensedeletepagesuccessstep.txt_success')}
            />
        );
    }
}


export const AdminLicenseDeletePageSuccessStep = withTranslation()($AdminLicenseDeletePageSuccessStep);
