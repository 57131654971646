import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { Form, Formik } from 'formik';
import { ContentService, ContentV1, ContentV1Key } from '../../Services/ContentService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { Locale } from '../../Model/Locale';
import { InputTextarea } from '../../Components/Input/InputTextarea';
import { Formatter } from '../../utils/Formatter';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcDummyContent, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcPageContent, IcSpinner, IcText, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminContentEditPageRouteParams
{
    contentKey: string;
    locale:     string;
}


export interface AdminContentEditPageProps extends RouteComponentProps<AdminContentEditPageRouteParams>, WithTranslation
{
}


interface AdminContentEditPageFormData
{
    content:    string;
}


interface AdminContentEditPageState
{
    initialFormData:    AdminContentEditPageFormData;
    content:            ContentV1 | null;
    error:              Error | null;
    loading:            boolean;
    success:            string | null;
}


class $AdminContentEditPage extends React.Component<AdminContentEditPageProps, AdminContentEditPageState>
{
    private readonly _contentService:  ContentService;
    

    constructor ( props: AdminContentEditPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                content:    ''
            },
            content:    null,
            error:      null,
            loading:    true,
            success:    null
        };

        this._contentService = ContentService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._update    = this._update.bind(this);
    }

    
    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const content = await this._contentService.getContent(
                this.props.router.params.contentKey as ContentV1Key,
                this.props.router.params.locale as Locale,
            );

            this.setState({
                error:      null,
                loading:    false,
                content,
                initialFormData: {
                    content:    content.content
                }
            });
        }
        catch ( err )
        {
            console.error(`Error loading content: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _update ( formData: AdminContentEditPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.content )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            await this._contentService.updateContent(
                this.state.content.key,
                {
                    locale: this.state.content.locale,
                    content: formData.content.trim()
                }
            );

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('admincontenteditpage.txt_success'),
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'contents'));
        }
        catch ( err )
        {
            console.error(`Error updating content: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<AdminContentEditPageFormData>;

        return (
            <IcPageContent>
                <PageTitle title={this.props.t('admincontenteditpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._update}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText>
                                {this.state.content ?
                                    `${Formatter.contentKey(this.state.content.key)} (${Formatter.locale(this.state.content.locale)})`
                                :
                                    <IcDummyContent />
                                }
                            </IcText>

                            <InputTextarea
                                name='content'
                                label={this.props.t('admincontenteditpage.inp_content')}
                            />
                            
                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton
                                    type='button'
                                    color={IcButtonColor.Link}
                                    onClick={this._cancel}>
                                    {this.props.t('admincontenteditpage.btn_cancel')}
                                </IcButton>

                                <IcButton
                                    type='submit'
                                    disabled={this.state.loading}>
                                    {this.props.t('admincontenteditpage.btn_update')}
                                </IcButton>
                            </IcFloatRow>
                        </IcCard>
                    </Form>
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const AdminContentEditPage = withTranslation()(withRouter($AdminContentEditPage));
