import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WithLocaleProps, withLocale } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText } from '@indece-common/ic-ui-lib-react';


export interface AdminPackagePriceAddPageConfirmStepProps extends WithTranslation, WithLocaleProps
{
    onCancel:       ( ) => any;
    onFinish:       ( ) => any;
}


class $AdminPackagePriceAddPageConfirmStep extends React.Component<AdminPackagePriceAddPageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText>
                    {this.props.t('adminpackagepriceaddpageconfirmstep.txt_confirm')}
                </IcText>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('adminpackagepriceaddpageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}>
                        {this.props.t('adminpackagepriceaddpageconfirmstep.btn_delete')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AdminPackagePriceAddPageConfirmStep = withLocale(withTranslation()($AdminPackagePriceAddPageConfirmStep));
