import { UserSfaV1Type } from '../Services/UserService';


export class WebAuthN
{
    public async checkBrowserSupport ( ): Promise<Array<UserSfaV1Type>>
    {
        if ( ! window.PublicKeyCredential )
        {
            return [];
        }
        
        const available = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
        if ( ! available )
        {
            return [
                UserSfaV1Type.WebAuthNCrossPlatform
            ];
        }

        return [
            UserSfaV1Type.WebAuthNPlatform,
            UserSfaV1Type.WebAuthNCrossPlatform
        ];
    }


    public base64EncodeToUint8Array ( raw: string ): Uint8Array
    {
        return Uint8Array.from(atob(raw), c => c.charCodeAt(0));
    }


    public async getCredentials ( credentialID: string, challenge: string ): Promise<PublicKeyCredential | null>
    {
        const credentialIDEncoded =  this.base64EncodeToUint8Array(credentialID);
        const challengeEncoded =  this.base64EncodeToUint8Array(challenge);

        const resp = await navigator.credentials.get({
            publicKey: {
                challenge: challengeEncoded,
                allowCredentials: [{
                    id: credentialIDEncoded,
                    type: 'public-key',
                    transports: ['usb', 'ble', 'nfc', 'hybrid', 'internal'],
                }],
                timeout: 60000,
                userVerification: 'discouraged'
            }
        });

        return resp as PublicKeyCredential;
    }
}
