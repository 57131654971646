import React from 'react';
import Cookies from 'universal-cookie';
import i18n, { t } from 'i18next';
import DayJS from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { LoginPage } from './Pages/LoginPage/LoginPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CookieHintModal } from './Modals/CookieHintModal/CookieHintModal';
import { HomePage } from './Pages/HomePage/HomePage';
import { TrackingService } from './Services/TrackingService';
import { RouteGuard, UserProfileRole } from './Components/RouteGuard/RouteGuard';
import { AccountPage } from './Pages/AccountPage/AccountPage';
import { PurchasePage } from './Pages/PurchasePage/PurchasePage';
import { AccountUpdatePasswordPage } from './Pages/AccountUpdatePasswordPage/AccountUpdatePasswordPage';
import { Locale } from './Model/Locale';
import { initReactI18next } from 'react-i18next';
import { MetadataService } from './Services/MetadataService';
import { RegisterPage } from './Pages/RegisterPage/RegisterPage';
import { AccountUsersPage } from './Pages/AccountUsersPage/AccountUsersPage';
import { PackagesPage } from './Pages/PackagesPage/PackagesPage';
import { NavBar } from './Components/NavBar/NavBar';
import { Error404Page } from './Pages/Error404Page/Error404Page';
import { AdminPage } from './Pages/AdminPage/AdminPage';
import { SetupPage } from './Pages/SetupPage/SetupPage';
import { Environment } from './utils/Environment';
import { AuthController } from './Controllers/AuthController/AuthController';
import { ScrollToTopController } from './Controllers/ScrollToTopController/ScrollToTopController';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { AdminUsersPage } from './Pages/AdminUsersPage/AdminUsersPage';
import { AdminLicensesPage } from './Pages/AdminLicensesPage/AdminLicensesPage';
import { AdminLicenseAddPage } from './Pages/AdminLicenseAddPage/AdminLicenseAddPage';
import { AdminClientsPage } from './Pages/AdminClientsPage/AdminClientsPage';
import { AdminClientAddPage } from './Pages/AdminClientAddPage/AdminClientAddPage';
import { LoginSuccessPage } from './Pages/LoginSuccessPage/LoginSuccessPage';
import { AdminPackagesPage } from './Pages/AdminPackagesPage/AdminPackagesPage';
import { AdminPackageAddPage } from './Pages/AdminPackageAddPage/AdminPackageAddPage';
import { LocaleService } from './Services/LocaleService';
import { LocaleController } from './Controllers/LocaleController/LocaleController';
import { AdminClientEditPage } from './Pages/AdminClientEditPage/AdminClientEditPage';
import { AdminLicenseEditPage } from './Pages/AdminLicenseEditPage/AdminLicenseEditPage';
import { AdminPackageEditPage } from './Pages/AdminPackageEditPage/AdminPackageEditPage';
import { Footer } from './Components/Footer/Footer';
import { ImprintPage } from './Pages/ImprintPage/ImprintPage';
import { AdminContentEditPage } from './Pages/AdminContentEditPage/AdminContentEditPage';
import { AdminContentsPage } from './Pages/AdminContentsPage/AdminContentsPage';
import { PrivacyPolicyPage } from './Pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { ContactPage } from './Pages/ContactPage/ContactPage';
import { ResetPasswordPage } from './Pages/ResetPasswordPage/ResetPasswordPage';
import { AccountAddSfaPage } from './Pages/AccountAddSfaPage/AccountAddSfaPage';
import { AccountUpdateEmailPage } from './Pages/AccountUpdateEmailPage/AccountUpdateEmailPage';
import { AccountDeleteSfaPage } from './Pages/AccountDeleteSfaPage/AccountDeleteSfaPage';
import { AdminApplicationEditPage } from './Pages/AdminApplicationEditPage/AdminApplicationEditPage';
import { AdminApplicationAddPage } from './Pages/AdminApplicationAddPage/AdminApplicationAddPage';
import { AdminApplicationsPage } from './Pages/AdminApplicationsPage/AdminApplicationsPage';
import { AdminMessagesPage } from './Pages/AdminMessagesPage/AdminMessagesPage';
import { UserPage } from './Pages/UserPage/UserPage';
import { OrganisationPage } from './Pages/OrganisationPage/OrganisationPage';
import { ApplicationDetailsPage } from './Pages/ApplicationDetailsPage/ApplicationDetailsPage';
import { AdminConfigPage } from './Pages/AdminConfigPage/AdminConfigPage';
import { UserAddPage } from './Pages/UserAddPage/UserAddPage';
import { AdminClientDeletePage } from './Pages/AdminClientDeletePage/AdminClientDeletePage';
import { OrganisationMemberAddPage } from './Pages/OrganisationMemberAddPage/OrganisationMemberAddPage';
import { UserDeletePage } from './Pages/UserDeletePage/UserDeletePage';
import { UserLicenseAssignPage } from './Pages/UserLicenseAssignPage/UserLicenseAssignPage';
import { UserLicenseUnassignPage } from './Pages/UserLicenseUnassignPage/UserLicenseUnassignPage';
import { OrganisationPaymentMethodAddPage } from './Pages/OrganisationPaymentMethodAddPage/OrganisationPaymentMethodAddPage';
import { PurchaseDetailsPage } from './Pages/PurchaseDetailsPage/PurchaseDetailsPage';
import { PurchaseUpdateCountPage } from './Pages/PurchaseUpdateCountPage/PurchaseUpdateCountPage';
import { PurchaseCancelPage } from './Pages/PurchaseCancelPage/PurchaseCancelPage';
import { OrganisationEditPage } from './Pages/OrganisationEditPage/OrganisationEditPage';
import { OrganisationAddPage } from './Pages/OrganisationAddPage/OrganisationAddPage';
import { UserEditPage } from './Pages/UserEditPage/UserEditPage';
import { AdminApplicationDeletePage } from './Pages/AdminApplicationDeletePage/AdminApplicationDeletePage';
import { AdminLicenseDeletePage } from './Pages/AdminLicenseDeletePage/AdminLicenseDeletePage';
import { BillsPage } from './Pages/BillsPage/BillsPage';
import { OrganisationsPage } from './Pages/OrganisationsPage/OrganisationsPage';
import { TermsPage } from './Pages/TermsPage/TermsPage';
import { AdminClientDetailsPage } from './Pages/AdminClientDetailsPage/AdminClientDetailsPage';
import { AdminPackageDeletePage } from './Pages/AdminPackageDeletePage/AdminPackageDeletePage';
import { AdminApplicationCategoriesPage } from './Pages/AdminApplicationCategoriesPage/AdminApplicationCategoriesPage';
import { AdminApplicationCategoryAddPage } from './Pages/AdminApplicationCategoryAddPage/AdminApplicationCategoryAddPage';
import { AdminContentFilesPage } from './Pages/AdminContentFilesPage/AdminContentFilesPage';
import { AdminContentFileEditPage } from './Pages/AdminContentFileEditPage/AdminContentFileEditPage';
import { OrganisationPaymentMethodSetDefaultPage } from './Pages/OrganisationPaymentMethodSetDefaultPage/OrganisationPaymentMethodSetDefaultPage';
import { OrganisationPaymentMethodDeletePage } from './Pages/OrganisationPaymentMethodDeletePage/OrganisationPaymentMethodDeletePage';
import { AdminApplicationCategoryDeletePage } from './Pages/AdminApplicationCategoryDeletePage/AdminApplicationCategoryDeletePage';
import { AdminApplicationCategoryEditPage } from './Pages/AdminApplicationCategoryEditPage/AdminApplicationCategoryEditPage';
import { AdminStatusPage } from './Pages/AdminStatusPage/AdminStatusPage';
import { OrganisationMemberDeletePage } from './Pages/OrganisationMemberDeletePage/OrganisationMemberDeletePage';
import { LogoutSuccessPage } from './Pages/LogoutSuccessPage/LogoutSuccessPage';
import { AdminLogsPage } from './Pages/AdminLogsPage/AdminLogsPage';
import { AdminVoucherDeletePage } from './Pages/AdminVoucherDeletePage/AdminVoucherDeletePage';
import { AdminVoucherAddPage } from './Pages/AdminVoucherAddPage/AdminVoucherAddPage';
import { AdminVouchersPage } from './Pages/AdminVouchersPage/AdminVouchersPage';
import { UserV1OrganisationRole } from './Services/UserService';
import { OrganisationMemberAcceptPage } from './Pages/OrganisationMemberAcceptPage/OrganisationMemberAcceptPage';
import { OrganisationDeletePage } from './Pages/OrganisationDeletePage/OrganisationDeletePage';
import { UserLockPage } from './Pages/UserLockPage/UserLockPage';
import { UserUnlockPage } from './Pages/UserUnlockPage/UserUnlockPage';
import { AdminJobsPage } from './Pages/AdminJobsPage/AdminJobsPage';
import { AdminJobAddSyncAllUsersPage } from './Pages/AdminJobAddSyncAllUsersPage/AdminJobAddSyncAllUsersPage';
import { ClientMissingLicensePage } from './Pages/ClientMissingLicensePage/ClientMissingLicensePage';
import { AdminJobAddSyncAllOrganisationsPage } from './Pages/AdminJobAddSyncAllOrganisationsPage/AdminJobAddSyncAllOrganisationsPage';
import { SideNav } from './Components/SideNav/SideNav';
import { DeveloperPage } from './Pages/DeveloperPage/DeveloperPage';
import { UserApiTokenDeletePage } from './Pages/UserApiTokenDeletePage/UserApiTokenDeletePage';
import { UserApiTokenAddPage } from './Pages/UserApiTokenAddPage/UserApiTokenAddPage';
import { UserResetPasswordPage } from './Pages/UserResetPasswordPage/UserResetPasswordPage';
import { AdminPackageDetailsPage } from './Pages/AdminPackageDetailsPage/AdminPackageDetailsPage';
import { AdminPackagePriceEditPage } from './Pages/AdminPackagePriceEditPage/AdminPackagePriceEditPage';
import { Validator, IcErrorBox } from '@indece-common/ic-ui-lib-react';
import { ErrorTranslator } from './utils/ErrorTranslator';
import { AdminPackagePriceDeletePage } from './Pages/AdminPackagePriceDeletePage/AdminPackagePriceDeletePage';
import { AdminPackagePriceAddPage } from './Pages/AdminPackagePriceAddPage/AdminPackagePriceAddPage';

import './App.css';

import Locale_DE_DE from './locale/de-DE.json';
import Locale_EN_GB from './locale/en-GB.json';
import Locale_EN_US from './locale/en-US.json';


export const resources: Record<Locale, any> = {
    [Locale.DE_DE]: {
        translation: Locale_DE_DE,
    },
    [Locale.EN_GB]: {
        translation: Locale_EN_GB,
    },
    [Locale.EN_US]: {
        translation: Locale_EN_US,
    }
};

i18n.use(initReactI18next).init({
    lng: LocaleService.getInstance().getLocale().get(),
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    compatibilityJSON: 'v3',
    pluralSeparator: '#',
    resources,
});

DayJS.extend(Duration);
DayJS.extend(RelativeTime);

const oidcConfig: AuthProviderProps = {
    onSignIn: ( userData ) =>
    {
        const cookies = new Cookies(null, {path: '/'});

        cookies.set('ic-licenser-signedin', '1', {
            path:       '/',
            secure:     false,
            httpOnly:   false
        });
    },
    onSignOut: ( ) => 
    {
        const cookies = new Cookies(null, {path: '/'});

        cookies.remove('ic-licenser-signedin');
    },
    autoSignIn: false,
    authority: Environment.oauth.authority,
    clientId: Environment.oauth.clientId,
    redirectUri: Environment.oauth.redirectUri + '/login-success',
    postLogoutRedirectUri: Environment.oauth.redirectUri + '/logout-success'
};

Validator.formatter = ( key, args ) => t(`validator.${key}`, args).toString();
IcErrorBox.translate = ( err ) => ErrorTranslator.translate(err) || err.message;


export class App extends React.Component
{
    private readonly _trackingService:  TrackingService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: any )
    {
        super(props);

        this._trackingService = TrackingService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._metadataService.setTitle('');
        this._metadataService.setDescription('');
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._trackingService.init();
    }


    public render ( )
    {
        return (
            <BrowserRouter>
                <AuthProvider {...oidcConfig}>
                    <div className='App'>
                        <NavBar />

                        <div className='App-content'>
                            <SideNav />
                            <div className='App-content-main'>
                                <Routes>
                                    <Route
                                        path='/'
                                        element={<HomePage />}
                                    />

                                    <Route
                                        path='/register'
                                        element={<RegisterPage />}
                                    />

                                    <Route
                                        path='/join/:userUID/:invitationCode'
                                        element={<RegisterPage />}
                                    />

                                    <Route
                                        path='/login'
                                        element={<LoginPage />}
                                    />

                                    <Route
                                        path='/reset_password'
                                        element={<ResetPasswordPage />}
                                    />

                                    <Route
                                        path='/packages'
                                        element={<PackagesPage />}
                                    />

                                    <Route
                                        path='/application/:applicationUID'
                                        element={<ApplicationDetailsPage />}
                                    />

                                    <Route
                                        path='/client-missing-license/:licenseTag'
                                        element={<ClientMissingLicensePage />}
                                    />

                                    <Route
                                        path='/account'
                                        element={<RouteGuard component={AccountPage} />}
                                    />

                                    <Route
                                        path='/account/update_password'
                                        element={<RouteGuard component={AccountUpdatePasswordPage} />}
                                    />

                                    <Route
                                        path='/account/update_email'
                                        element={<RouteGuard component={AccountUpdateEmailPage} />}
                                    />

                                    <Route
                                        path='/account/sfa/add'
                                        element={<RouteGuard component={AccountAddSfaPage} />}
                                    />

                                    <Route
                                        path='/account/sfa/:sfaUID/delete'
                                        element={<RouteGuard component={AccountDeleteSfaPage} />}
                                    />

                                    <Route
                                        path='/bills'
                                        element={<RouteGuard component={BillsPage} />}
                                    />

                                    <Route
                                        path='/purchase/:purchaseUID'
                                        element={<RouteGuard component={PurchaseDetailsPage} />}
                                    />

                                    <Route
                                        path='/purchase/:purchaseUID/update_count'
                                        element={<RouteGuard component={PurchaseUpdateCountPage} />}
                                    />

                                    <Route
                                        path='/purchase/:purchaseUID/cancel'
                                        element={<RouteGuard component={PurchaseCancelPage} />}
                                    />

                                    <Route
                                        path='/organisations'
                                        element={<RouteGuard component={OrganisationsPage} />}
                                    />

                                    <Route
                                        path='/organisation/add'
                                        element={<RouteGuard component={OrganisationAddPage} />}
                                    />

                                    <Route
                                        path='/organisation/:organisationUID'
                                        element={<RouteGuard component={OrganisationPage} />}
                                    />
                
                                    <Route
                                        path='/organisation/:organisationUID/join'
                                        element={<RouteGuard component={OrganisationMemberAcceptPage} />}
                                    />
                
                                    <Route
                                        path='/organisation/:organisationUID/delete'
                                        element={<RouteGuard component={OrganisationDeletePage} />}
                                    />
                
                                    <Route
                                        path='/organisation/:organisationUID/addmember'
                                        element={<RouteGuard component={OrganisationMemberAddPage} />}
                                    />
                
                                    <Route
                                        path='/organisation/:organisationUID/paymentmethod/add'
                                        element={<RouteGuard component={OrganisationPaymentMethodAddPage} />}
                                    />
                
                                    <Route
                                        path='/organisation/:organisationUID/paymentmethod/:paymentMethodUID/verify'
                                        element={<RouteGuard component={OrganisationPaymentMethodAddPage} />}
                                    />

                                    <Route
                                        path='/organisation/:organisationUID/paymentmethod/:paymentMethodUID/set_default'
                                        element={<RouteGuard component={OrganisationPaymentMethodSetDefaultPage} />}
                                    />

                                    <Route
                                        path='/organisation/:organisationUID/paymentmethod/:paymentMethodUID/delete'
                                        element={<RouteGuard component={OrganisationPaymentMethodDeletePage} />}
                                    />

                                    <Route
                                        path='/organisation/:organisationUID/edit'
                                        element={<RouteGuard
                                            organisationRole={UserV1OrganisationRole.Owner}
                                            component={OrganisationEditPage}
                                        />}
                                    />

                                    <Route
                                        path='/account/users'
                                        element={<RouteGuard component={AccountUsersPage} />}
                                    />

                                    <Route
                                        path='/user/add'
                                        element={<RouteGuard component={UserAddPage} />}
                                    />

                                    <Route
                                        path='/purchase'
                                        element={<RouteGuard component={PurchasePage} />}
                                    />

                                    <Route
                                        path='/purchase/paymentmethod/:paymentMethodUID/verify'
                                        element={<RouteGuard component={PurchasePage} />}
                                    />

                                    <Route
                                        path='/admin'
                                        element={<RouteGuard
                                            component={AdminPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/messages'
                                        element={<RouteGuard
                                            component={AdminMessagesPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/status'
                                        element={<RouteGuard
                                            component={AdminStatusPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/logs'
                                        element={<RouteGuard
                                            component={AdminLogsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/applicationcategories'
                                        element={<RouteGuard
                                            component={AdminApplicationCategoriesPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/applicationcategory/add'
                                        element={<RouteGuard
                                            component={AdminApplicationCategoryAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/applicationcategory/:applicationCategoryUID/edit'
                                        element={<RouteGuard
                                            component={AdminApplicationCategoryEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/applicationcategory/:applicationCategoryUID/delete'
                                        element={<RouteGuard
                                            component={AdminApplicationCategoryDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/applications'
                                        element={<RouteGuard
                                            component={AdminApplicationsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/application/add'
                                        element={<RouteGuard
                                            component={AdminApplicationAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/application/:applicationUID/edit'
                                        element={<RouteGuard
                                            component={AdminApplicationEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/application/:applicationUID/delete'
                                        element={<RouteGuard
                                            component={AdminApplicationDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/users'
                                        element={<RouteGuard
                                            component={AdminUsersPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/vouchers'
                                        element={<RouteGuard
                                            component={AdminVouchersPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/voucher/add'
                                        element={<RouteGuard
                                            component={AdminVoucherAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    {/* TODO
                                    <Route
                                        path='/admin/voucher/:voucherUID/edit'
                                        element={<RouteGuard
                                            component={AdminVoucherEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />*/}

                                    <Route
                                        path='/admin/voucher/:voucherUID/delete'
                                        element={<RouteGuard
                                            component={AdminVoucherDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/user/:userUID'
                                        element={<RouteGuard
                                            component={UserPage}
                                            globalRoles={[UserProfileRole.Loggedin]}
                                        />}
                                    />

                                    <Route
                                        path='/user/:userUID/edit'
                                        element={<RouteGuard
                                            component={UserEditPage}
                                            organisationRole={UserV1OrganisationRole.Owner}
                                            globalRoles={[UserProfileRole.Loggedin]}
                                        />}
                                    />

                                    <Route
                                        path='/user/:userUID/reset-password'
                                        element={<RouteGuard
                                            component={UserResetPasswordPage}
                                            organisationRole={UserV1OrganisationRole.Owner}
                                            globalRoles={[UserProfileRole.Loggedin]}
                                        />}
                                    />

                                    <Route
                                        path='/user/:userUID/lock'
                                        element={<RouteGuard
                                            component={UserLockPage}
                                            globalRoles={[UserProfileRole.Loggedin]}
                                        />}
                                    />

                                    <Route
                                        path='/user/:userUID/unlock'
                                        element={<RouteGuard
                                            component={UserUnlockPage}
                                            globalRoles={[UserProfileRole.Loggedin]}
                                        />}
                                    />

                                    <Route
                                        path='/user/:userUID/delete'
                                        element={<RouteGuard
                                            component={UserDeletePage}
                                            globalRoles={[UserProfileRole.Loggedin]}
                                        />}
                                    />

                                    <Route
                                        path='/user/:userUID/license/assign'
                                        element={<RouteGuard
                                            component={UserLicenseAssignPage}
                                            globalRoles={[UserProfileRole.Loggedin]}
                                        />}
                                    />

                                    <Route
                                        path='/organisation/:organisationUID/user/:userUID/license/:licenseUID/unassign'
                                        element={<RouteGuard
                                            component={UserLicenseUnassignPage}
                                            globalRoles={[UserProfileRole.Loggedin]}
                                        />}
                                    />

                                    <Route
                                        path='/organisation/:organisationUID/user/:userUID/delete'
                                        element={<RouteGuard
                                            component={OrganisationMemberDeletePage}
                                            globalRoles={[UserProfileRole.Loggedin]}
                                        />}
                                    />

                                    {Environment.server.developer_tools_enabled ?
                                        <Route
                                            path='/developer'
                                            element={<RouteGuard
                                                component={DeveloperPage}
                                                globalRoles={[UserProfileRole.Loggedin]}
                                            />}
                                        />
                                    : null}

                                    {Environment.server.developer_tools_enabled ?
                                        <Route
                                            path='/user/apitoken/add'
                                            element={<RouteGuard
                                                component={UserApiTokenAddPage}
                                                globalRoles={[UserProfileRole.Loggedin]}
                                            />}
                                        />
                                    : null}

                                    {Environment.server.developer_tools_enabled ?
                                        <Route
                                            path='/user/apitoken/:userApiTokenUID/delete'
                                            element={<RouteGuard
                                                component={UserApiTokenDeletePage}
                                                globalRoles={[UserProfileRole.Loggedin]}
                                            />}
                                        />
                                    : null}

                                    <Route
                                        path='/admin/licenses'
                                        element={<RouteGuard
                                            component={AdminLicensesPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/license/add'
                                        element={<RouteGuard
                                            component={AdminLicenseAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/license/:licenseUID/edit'
                                        element={<RouteGuard
                                            component={AdminLicenseEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/license/:licenseUID/delete'
                                        element={<RouteGuard
                                            component={AdminLicenseDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/packages'
                                        element={<RouteGuard
                                            component={AdminPackagesPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/package/add'
                                        element={<RouteGuard
                                            component={AdminPackageAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/package/:packageUID'
                                        element={<RouteGuard
                                            component={AdminPackageDetailsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/package/:packageUID/edit'
                                        element={<RouteGuard
                                            component={AdminPackageEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/package/:packageUID/price/add'
                                        element={<RouteGuard
                                            component={AdminPackagePriceAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/package/:packageUID/price/:packagePriceUID/edit'
                                        element={<RouteGuard
                                            component={AdminPackagePriceEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/package/:packageUID/price/:packagePriceUID/delete'
                                        element={<RouteGuard
                                            component={AdminPackagePriceDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/package/:packageUID/delete'
                                        element={<RouteGuard
                                            component={AdminPackageDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/clients'
                                        element={<RouteGuard
                                            component={AdminClientsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/client/add'
                                        element={<RouteGuard
                                            component={AdminClientAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/client/:clientUID'
                                        element={<RouteGuard
                                            component={AdminClientDetailsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/client/:clientUID/edit'
                                        element={<RouteGuard
                                            component={AdminClientEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/client/:clientUID/delete'
                                        element={<RouteGuard
                                            component={AdminClientDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/contents'
                                        element={<RouteGuard
                                            component={AdminContentsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/content/:contentKey/:locale/edit'
                                        element={<RouteGuard
                                            component={AdminContentEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/contentfiles'
                                        element={<RouteGuard
                                            component={AdminContentFilesPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/contentfile/:contentFileKey/:locale/edit'
                                        element={<RouteGuard
                                            component={AdminContentFileEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/config'
                                        element={<RouteGuard
                                            component={AdminConfigPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/jobs'
                                        element={<RouteGuard
                                            component={AdminJobsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/job/add/sync-all-users'
                                        element={<RouteGuard
                                            component={AdminJobAddSyncAllUsersPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/job/add/sync-all-organisations'
                                        element={<RouteGuard
                                            component={AdminJobAddSyncAllOrganisationsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/contact'
                                        element={<ContactPage />}
                                    />

                                    <Route
                                        path='/setup'
                                        element={<SetupPage />}
                                    />

                                    <Route
                                        path='/imprint'
                                        element={<ImprintPage />}
                                    />

                                    <Route
                                        path='/terms'
                                        element={<TermsPage />}
                                    />

                                    <Route
                                        path='/privacypolicy'
                                        element={<PrivacyPolicyPage />}
                                    />

                                    <Route
                                        path='/login-success'
                                        element={<LoginSuccessPage />}
                                    />

                                    <Route
                                        path='/logout-success'
                                        element={<LogoutSuccessPage />}
                                    />

                                    <Route
                                        path='*'
                                        element={<Error404Page />}
                                    />
                                </Routes>
                            </div>
                        </div>

                        <Footer />
                    </div>

                    <CookieHintModal />

                    <LocaleController />
                    <AuthController />
                    <ScrollToTopController />
                </AuthProvider>
            </BrowserRouter>
        );
    }
}
