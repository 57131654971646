import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface UserUnlockPageSuccessStepProps extends WithTranslation
{
}


class $UserUnlockPageSuccessStep extends React.Component<UserUnlockPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                testID='userunlockpagesuccessstep.txt_success'
                message={this.props.t('userunlockpagesuccessstep.txt_success')}
            />
        );
    }
}


export const UserUnlockPageSuccessStep = withTranslation()($UserUnlockPageSuccessStep);
