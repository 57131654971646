import React from 'react';
import { Form, Formik } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AuthContextProps, withAuth } from 'oidc-react';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AccountUpdateEmailPageEmailStepProps extends WithTranslation, AuthContextProps
{
    onCancel: ( ) => any;
    onFinish: ( formData: AccountUpdateEmailPageEmailStepFormData ) => any;
}


export interface AccountUpdateEmailPageEmailStepFormData
{
    password:   string;
    email:      string;
}


interface AccountUpdateEmailPageEmailStepState
{
    initialFormData: AccountUpdateEmailPageEmailStepFormData;
}


class $AccountUpdateEmailPageEmailStep extends React.Component<AccountUpdateEmailPageEmailStepProps, AccountUpdateEmailPageEmailStepState>
{
    constructor ( props: AccountUpdateEmailPageEmailStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                password:   '',
                email:      ''
            }
        };

        this._submit = this._submit.bind(this);
    }


    private _submit ( values: AccountUpdateEmailPageEmailStepFormData ): void
    {
        this.props.onFinish(values);
    }


    public render ( )
    {
        const MyFormik  = Formik<AccountUpdateEmailPageEmailStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <MyFormik
                    onSubmit={this._submit}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('accountupdateemailpageemailstep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('accountupdateemailpageemailstep.txt_email')}
                        </IcText>

                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='password'
                                    type='password'
                                    label={this.props.t('accountupdateemailpageemailstep.inp_password')}
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='email'
                                    type='email'
                                    label={this.props.t('accountupdateemailpageemailstep.inp_email')}
                                    required={true}
                                    validators={[
                                        Validator.email
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                onClick={this.props.onCancel}
                                color={IcButtonColor.Link}>
                                {this.props.t('accountupdateemailpageemailstep.btn_cancel')}
                            </IcButton>

                            <IcButton type='submit'>
                                {this.props.t('accountupdateemailpageemailstep.btn_continue')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const AccountUpdateEmailPageEmailStep = withTranslation()(withAuth($AccountUpdateEmailPageEmailStep));
