import { BackendService } from './BackendService';


export interface ClientV1Config
{
    redirect_uris:              Array<string>;
    logout_uris:                Array<string>;
    cors_uris:                  Array<string>;
    on_user_delete_uri:         string | null;
    on_user_purge_uri:          string | null;
    on_organisation_delete_uri: string | null;
    on_organisation_purge_uri:  string | null;
}


export interface ClientV1
{
    uid:        string;
    client_id:  string;
    config:     ClientV1Config;
}


export interface AddClientV1
{
    client_id:  string;
    config:     ClientV1Config;
}


export interface UpdateClientV1
{
    config:     ClientV1Config;
}


export class ClientService
{
    private static _instance: ClientService;
    
    
    public static getInstance ( ): ClientService
    {
        if ( ! this._instance )
        {
            this._instance = new ClientService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getClients ( from: number, size: number ): Promise<Array<ClientV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/client?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.clients;
    }


    public async getClient ( clientUID: string ): Promise<ClientV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/client/${encodeURIComponent(clientUID)}`
        );

        return resp.client;
    }
    

    public async addClient ( params: AddClientV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/client`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.client_uid;
    }
    

    public async updateClient ( clientUID: string, params: UpdateClientV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/client/${encodeURIComponent(clientUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
    

    public async deleteClient ( clientUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/client/${encodeURIComponent(clientUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
