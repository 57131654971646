import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApplicationService, ApplicationV1 } from '../../Services/ApplicationService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminApplicationDeletePageLoadingStep } from './AdminApplicationDeletePageLoadingStep/AdminApplicationDeletePageLoadingStep';
import { AdminApplicationDeletePageSuccessStep } from './AdminApplicationDeletePageSuccessStep/AdminApplicationDeletePageSuccessStep';
import { AdminApplicationDeletePageConfirmStep } from './AdminApplicationDeletePageConfirmStep/AdminApplicationDeletePageConfirmStep';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminApplicationDeletePageRouteParams
{
    applicationUID: string;
}


export interface AdminApplicationDeletePageProps extends RouteComponentProps<AdminApplicationDeletePageRouteParams>, WithTranslation
{
}


enum AdminApplicationDeletePageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AdminApplicationDeletePageState
{
    step:           AdminApplicationDeletePageStep;
    application:    ApplicationV1 | null;
    error:          Error | null;
}


class $AdminApplicationDeletePage extends React.Component<AdminApplicationDeletePageProps, AdminApplicationDeletePageState>
{
    private readonly _applicationService:  ApplicationService;
    

    constructor ( props: AdminApplicationDeletePageProps )
    {
        super(props);

        this.state = {
            step:           AdminApplicationDeletePageStep.Loading,
            application:    null,
            error:          null
        };

        this._applicationService = ApplicationService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminApplicationDeletePageStep.Loading,
                error:  null
            });

            const application = await this._applicationService.getApplication(this.props.router.params.applicationUID);

            this.setState({
                step:   AdminApplicationDeletePageStep.Confirm,
                application
            });
        }
        catch ( err )
        {
            console.error(`Error loading application: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.application )
        {
            return;
        }

        try
        {
            this.setState({
                step:   AdminApplicationDeletePageStep.Loading,
                error:  null
            });

            await this._applicationService.deleteApplication(this.state.application.uid);

            this.setState({
                step:   AdminApplicationDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting application: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminApplicationDeletePageStep.Confirm,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'applications')}
                        label={this.props.t('adminapplicationspage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'application', this.props.router.params.applicationUID, 'delete')}
                        label={this.props.t('adminapplicationdeletepage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle title={this.props.t('adminapplicationdeletepage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminApplicationDeletePageStep.Loading ?
                    <AdminApplicationDeletePageLoadingStep />
                : null}

                {this.state.step === AdminApplicationDeletePageStep.Confirm && this.state.application ?
                    <AdminApplicationDeletePageConfirmStep
                        application={this.state.application}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === AdminApplicationDeletePageStep.Success ?
                    <AdminApplicationDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminApplicationDeletePage = withTranslation()(withRouter($AdminApplicationDeletePage));
