import React from 'react';
import DayJS from 'dayjs';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from '../../Components/List/List';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { BillV1, PurchaseService } from '../../Services/PurchaseService';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IcCard, IcCardPadding, IcErrorBox, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface BillsBoxProps extends WithTranslation
{
    organisationUID?:   string;
}


interface BillsBoxState
{
    bills:      Array<BillV1>;
    loading:    boolean;
    error:      Error | null;
}


class $BillsBox extends React.Component<BillsBoxProps, BillsBoxState>
{
    private readonly _purchaseService:  PurchaseService;


    constructor ( props: BillsBoxProps )
    {
        super(props);

        this.state = {
            bills:      [],
            loading:    true,
            error:      null
        };

        this._purchaseService = PurchaseService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const bills = await this._purchaseService.getBills(
                {
                    organisation_uid: this.props.organisationUID
                },
                0,
                3
            );

            this.setState({
                bills,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading bills: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _showPdf ( billUID: string ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const pdf = await this._purchaseService.getBillPdf(billUID);

            const url = URL.createObjectURL(pdf);

            window.open(url, '_blank');

            this.setState({
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error downloading bill: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                {/* TODO   <Link
                    to={LinkUtils.make('bills') + (this.props.organisationUID ? `?organisation_uid=${encodeURIComponent(this.props.organisationUID)}` : '')}>
                    {this.props.t('billsbox.btn_more')}
                </Link>*/}
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('billsbox.txt_bills')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <List>
                    {this.state.bills.map( ( bill ) => (
                        <ListItem key={bill.uid}>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    grow={true}
                                    text={bill.file.filename}
                                    subtext={DayJS(bill.date_bill).format('YYYY-MM-DD')}
                                />

                                <ListItemHeaderAction
                                    icon={faDownload}
                                    title={this.props.t('billsbox.btn_download')}
                                    onClick={ ( ) => this._showPdf(bill.uid) }
                                />
                            </ListItemHeader>
                        </ListItem>
                    ))}

                    {this.state.bills.length === 0 && !this.state.loading ?
                        <ListEmpty>
                            {this.props.t('billsbox.txt_no_bills')}
                        </ListEmpty>
                    : null}
                </List>

                <IcSpinner active={this.state.loading} />
            </IcCard>
        );
    }
}


export const BillsBox = withTranslation()($BillsBox);
