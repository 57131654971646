import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PurchaseV1 } from '../../../Services/PurchaseService';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { PurchaseDetails } from '../../../BusinessComponents/PurchaseDetails/PurchaseDetails';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText } from '@indece-common/ic-ui-lib-react';


export interface PurchaseUpdateCountPageConfirmStepProps extends WithTranslation
{
    purchase:       PurchaseV1;
    organisation:   OrganisationV1;
    count:          number;
    onCancel:       ( ) => any;
    onFinish:       ( ) => any;
}


class $PurchaseUpdateCountPageConfirmStep extends React.Component<PurchaseUpdateCountPageConfirmStepProps>
{   
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText>
                    {this.props.t('purchaseupdatecountpageconfirmstep.txt_confirm')}
                </IcText>

                <PurchaseDetails
                    purchase={this.props.purchase}
                    count={this.props.count}
                />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('purchaseupdatecountpageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton onClick={this.props.onFinish}>
                        {this.props.t('purchaseupdatecountpageconfirmstep.btn_purchase')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const PurchaseUpdateCountPageConfirmStep = withTranslation()($PurchaseUpdateCountPageConfirmStep);
