import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserService, UserV1, UserV1OrganisationRole, isAdmin } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { LabelValueList } from '../../Components/LabelValueList/LabelValueList';
import { LabelValue } from '../../Components/LabelValueList/LabelValue';
import { Formatter } from '../../utils/Formatter';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { faBan, faEye, faPlusCircle, faRefresh, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { Environment } from '../../utils/Environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { UserLicensesBox } from '../../BusinessComponents/UserLicensesBox/UserLicensesBox';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcChip, IcDummyContent, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcPageContent, IcSpinner, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { InvitedChip } from '../../Components/Chip/InvitedChip';
import { LockedChip } from '../../Components/Chip/LockedChip';


export interface UserPageRouteParams
{
    userUID:    string;
}


export interface UserPageProps extends RouteComponentProps<UserPageRouteParams>, WithTranslation
{
}


interface UserPageFormDataOrganisation
{
    role:   UserV1OrganisationRole;
}


interface UserPageFormData
{
    license_tag:    string;
    organisations:  Record<string, UserPageFormDataOrganisation>;
}


interface UserPageState
{
    initalFormData:     UserPageFormData;
    user:               UserV1 | null;
    ownUser:            UserV1 | null;
    organisations:      Record<string, OrganisationV1>;
    loading:            boolean;
    error:              Error | null;
}


class $UserPage extends React.Component<UserPageProps, UserPageState>
{
    private readonly _userService:  UserService;
    private readonly _organisationService:  OrganisationService;


    constructor ( props: UserPageProps )
    {
        super(props);

        this.state = {
            initalFormData:     {
                license_tag:        '',
                organisations:      {}
            },
            user:               null,
            ownUser:            null,
            organisations:      {},
            loading:            true,
            error:              null
        };

        this._userService = UserService.getInstance();
        this._organisationService = OrganisationService.getInstance();

        this._updateOrganisationRole = this._updateOrganisationRole.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                user:       null,
                error:      null,
                loading:    true
            });

            const user = await this._userService.getUser(this.props.router.params.userUID);
            const formDataOrganisations: Record<string, UserPageFormDataOrganisation> = {};
            
            const organisations: Record<string, OrganisationV1> = {};

            for ( const userOrganisation of user.organisations )
            {
                formDataOrganisations[userOrganisation.uid] = {
                    role: userOrganisation.role
                };

                const organisation = await this._organisationService.getOrganisation(userOrganisation.uid);
                organisations[organisation.uid] = organisation;
            }

            this.setState({
                initalFormData: {
                    ...this.state.initalFormData,
                    organisations: formDataOrganisations
                },
                user,
                organisations,
                error:      null,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _updateOrganisationRole ( formData: UserPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.user )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            /* TODO */

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error assigning license to user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });

        await this._load();
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('userpage.txt_user')} />

                <IcErrorBox error={this.state.error} />

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading3}>
                                {this.props.t('userpage.txt_general')}
                            </IcText>

                            <LabelValueList>
                                <LabelValue label={this.props.t('userpage.txt_name')}>
                                    {this.state.user ?
                                        <>
                                            {Formatter.username(this.state.user)}

                                            {this.state.user.invitation_pending ?
                                                <InvitedChip date={this.state.user.datetime_invited || ''} />
                                            : null}

                                            {this.state.user.locked ?
                                                <LockedChip />
                                            : null}
                                        </>
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>

                                <LabelValue label={this.props.t('userpage.txt_email')}>
                                    {this.state.user ?
                                        this.state.user.email
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>
                            </LabelValueList>

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                {this.state.user && !this.state.user.locked ?
                                    <IcButton
                                        to={LinkUtils.make('user', this.state.user.uid, 'lock')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faBan} />
                                        
                                        {this.props.t('userpage.btn_lock')}
                                    </IcButton>
                                : null}

                                {this.state.user && this.state.user.locked ?
                                    <IcButton
                                        to={LinkUtils.make('user', this.state.user.uid, 'unlock')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                        
                                        {this.props.t('userpage.btn_unlock')}
                                    </IcButton>
                                : null}

                                {this.state.user ?
                                    <IcButton
                                        to={LinkUtils.make('user', this.state.user.uid, 'edit')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                        
                                        {this.props.t('userpage.btn_edit')}
                                    </IcButton>
                                : null}

                                {this.state.user ?
                                    <IcButton
                                        to={LinkUtils.make('user', this.state.user.uid, 'reset-password')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faRefresh} />
                                        
                                        {this.props.t('userpage.btn_reset_password_sfa')}
                                    </IcButton>
                                : null}
                                
                                {this.state.user ?
                                    <IcButton
                                        to={LinkUtils.make('user', this.state.user.uid, 'delete')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faTrash} />
                                        
                                        {this.props.t('userpage.btn_delete')}
                                    </IcButton>
                                : null}
                            </IcFloatRow>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading3}>
                                {this.props.t('userpage.txt_organisations')}
                            </IcText>

                            {this.state.user ?
                                <List>
                                    {this.state.user.organisations.map( ( userOrganisation ) => (
                                        <ListItem key={userOrganisation.uid}>
                                            <ListItemHeader>
                                                <ListItemHeaderField
                                                    to={LinkUtils.make('organisation', userOrganisation.uid)}
                                                    grow={true}>
                                                    {this.state.organisations[userOrganisation.uid]?.name}

                                                    {!userOrganisation.accepted ? 
                                                        <IcChip label={this.props.t('userpage.txt_join_sent')} />
                                                    : null}
                                                </ListItemHeaderField>

                                                <ListItemHeaderField
                                                    grow={true}
                                                    text={Formatter.userOrganisationRole(userOrganisation.role)}
                                                    subtext={this.props.t('userpage.inp_organisation_role')}
                                                />

                                                {(this.state.ownUser && isAdmin(this.state.ownUser)) || userOrganisation.role === UserV1OrganisationRole.Owner ?
                                                    <ListItemHeaderAction
                                                        icon={faEye}
                                                        to={LinkUtils.make('organisation', userOrganisation.uid)}
                                                        title={this.props.t('userpage.btn_organisation_show')}
                                                    />
                                                : null}

                                                {(this.state.ownUser && isAdmin(this.state.ownUser)) || userOrganisation.role === UserV1OrganisationRole.Owner ?
                                                    <ListItemHeaderAction
                                                        icon={faTimes}
                                                        to={LinkUtils.make('organisation', userOrganisation.uid, 'user', this.state.user!.uid, 'delete')}
                                                        title={this.props.t('userpage.btn_organisation_user_remove')}
                                                    />
                                                : null}
                                            </ListItemHeader>
                                        </ListItem>
                                    ))}

                                    {this.state.user.organisations.length === 0 && !this.state.loading ?
                                        <ListEmpty>
                                            {this.props.t('userpage.txt_no_organisations')}
                                        </ListEmpty>
                                    : null}
                                </List>
                            : null}

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                {this.state.user && (!Environment.server.max_organisations_per_user || this.state.user.organisations.length < Environment.server.max_organisations_per_user) ?
                                    <IcButton
                                        to={LinkUtils.make('user', this.state.user.uid, 'organisation', 'assign')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                        {this.props.t('userpage.btn_assign_organisation')}
                                    </IcButton>
                                : null}
                            </IcFloatRow>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <UserLicensesBox
                            user={this.state.user}
                            ownUser={this.state.ownUser}
                        />
                    </IcGridItem>
                </IcGridRow>

                <IcSpinner active={this.state.loading} />
            </IcPageContent>
        )
    }
}


export const UserPage = withTranslation()(withRouter($UserPage));
