import React from 'react';
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfigPropertyV1Key, ConfigPropertyV1Vat, ConfigService } from '../../../Services/ConfigService';
import { Countries, Country } from '../../../Model/Country';
import { Formatter } from '../../../utils/Formatter';
import { Currencies, Currency } from '../../../Model/Currency';
import { IcButton, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcInputSelect, IcInputText, IcSpinner, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { WithTranslation, withTranslation } from 'react-i18next';


export interface SetupConfigureVatStepProps extends WithTranslation
{
    onFinish:   ( ) => any;
}


interface SetupConfigureVatStepFormCountry
{
    country:    Country;
    currency:   Currency;
    rate_vat:   string;
}


interface SetupConfigureVatStepFormDefault
{
    currency:   Currency;
    rate_vat:   string;
}


interface SetupConfigureVatStepFormData
{
    default:    SetupConfigureVatStepFormDefault;
    countries:  Array<SetupConfigureVatStepFormCountry>;
}


interface SetupConfigureVatStepState
{
    initialFormData:    SetupConfigureVatStepFormData;
    loading:            boolean;
    error:              Error | null;
}


class $SetupConfigureVatStep extends React.Component<SetupConfigureVatStepProps, SetupConfigureVatStepState>
{
    private readonly _configService: ConfigService;


    constructor ( props: SetupConfigureVatStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                default: {
                    currency:   Currency.EUR,
                    rate_vat:   '0.19'
                },
                countries: []
            },
            loading:    false,
            error:      null
        };

        this._configService = ConfigService.getInstance();

        this._submit = this._submit.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            let initialFormData = this.state.initialFormData;

            const config = await this._configService.getConfig();
            if ( config.VAT && config.VAT.value )
            {
                const vatConfig = JSON.parse(config.VAT.value) as ConfigPropertyV1Vat;

                initialFormData = {
                    ...initialFormData,
                    default: {
                        currency: vatConfig.default?.currency?.toUpperCase() as Currency,
                        rate_vat: '' + (vatConfig.default?.rate_vat || 0)
                    },
                    countries: Object.entries(vatConfig.countries).map( ( entry ) => ({
                        country:    entry[0] as Country,
                        currency:   entry[1].currency.toUpperCase() as Currency,
                        rate_vat:   '' + (entry[1].rate_vat || 0)
                    }))
                }
            }

            this.setState({
                initialFormData,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _submit ( values: SetupConfigureVatStepFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            const vatConfig: ConfigPropertyV1Vat = {
                default: {
                    currency:   values.default.currency.toLowerCase() as any,
                    rate_vat:   parseFloat(values.default.rate_vat)
                },
                countries: {}
            };

            for ( const country of values.countries )
            {
                vatConfig.countries[country.country] = {
                    currency:   country.currency.toLowerCase() as any,
                    rate_vat:   parseFloat(country.rate_vat)
                };
            }

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.Vat,
                JSON.stringify(vatConfig)
            );

            this.setState({
                loading:    false
            });

            this.props.onFinish();
        }
        catch ( err )
        {
            console.error(`Error updating config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<SetupConfigureVatStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small} bold={true}>
                    {this.props.t('setupconfigurevatstep.txt_subtitle')}
                </IcText>

                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('setupconfigurevatstep.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <MyFormik
                    initialValues={this.state.initialFormData}
                    onSubmit={this._submit}
                    enableReinitialize={true}>
                    {({ values }) => (
                        <Form>
                            <FieldArray
                                name='countries'>
                                {( arrayHelpers: ArrayHelpers ) => (
                                    <div className='SetupConfigureVatStep-hosts'>
                                        <IcButton
                                            type='button'
                                            onClick={() => arrayHelpers.push({
                                                labels: []
                                            })}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            Add a country
                                        </IcButton>

                                        <div className='SetupConfigureVatStep-hosts-list'>
                                            {values.countries.length === 0 ?
                                                <div className='SetupConfigureVatStep-hosts-empty'>
                                                    No countries added yet
                                                </div>
                                            : null}
                                        </div>

                                        <IcInputSelect
                                            name={`default.currency`}
                                            label='Currency'
                                            options={Currencies.map( ( currency ) => ({
                                                label:  Formatter.currency(currency),
                                                value:  currency
                                            }))}
                                            required={true}
                                        />

                                        <IcInputText
                                            name={`default.rate_vat`}
                                            label='MwSt-Satz'
                                            required={true}
                                            validators={[
                                                Validator.minFloat(0),
                                                Validator.maxFloat(1)
                                            ]}
                                        />

                                        {values.countries.map( ( country, index ) => (
                                            <div key={index} className='AdminAddEventPage-host'>
                                                <div className='AdminAddEventPage-host-form'>
                                                    <IcInputSelect
                                                        name={`countries.${index}.country`}
                                                        label='Country'
                                                        options={Countries.map( ( country ) => ({
                                                            label:  Formatter.country(country),
                                                            value:  country
                                                        }))}
                                                        required={true}
                                                    />

                                                    <IcInputSelect
                                                        name={`countries.${index}.currency`}
                                                        label='Currency'
                                                        options={Currencies.map( ( currency ) => ({
                                                            label:  Formatter.currency(currency),
                                                            value:  currency
                                                        }))}
                                                        required={true}
                                                    />

                                                    <IcInputText
                                                        name={`countries.${index}.rate_vat`}
                                                        label='MwSt-Satz'
                                                        required={true}
                                                        validators={[
                                                            Validator.minFloat(0),
                                                            Validator.maxFloat(1)
                                                        ]}
                                                    />
                                                </div>

                                                <IcButton
                                                    title='Delete country'
                                                    type='button'
                                                    onClick={() => arrayHelpers.remove(index)}>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </IcButton>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </FieldArray>

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton
                                    type='submit'
                                    disabled={this.state.loading}>
                                    Save
                                </IcButton>
                            </IcFloatRow>
                        </Form>
                    )}
                </MyFormik>

                <IcSpinner active={this.state.loading} />
            </IcCard>
        );
    }
}


export const SetupConfigureVatStep = withTranslation()($SetupConfigureVatStep);
