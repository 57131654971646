import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationService, OrganisationV1, PaymentMethodV1 } from '../../../Services/OrganisationService';
import { PendingPaymentMethod, SessionService } from '../../../Services/SessionService';
import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import { StripeService } from '../../../Services/StripeService';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Environment } from '../../../utils/Environment';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Formatter } from '../../../utils/Formatter';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface PurchasePageFormStepPaymentMethodBoxProps extends WithTranslation
{
    organisation:           OrganisationV1;
    verifyPaymentMethodUID: string | null;
    onChange:               ( paymentMethd: PaymentMethodV1 ) => any;
}


interface PurchasePageFormStepPaymentMethodBoxState
{
    paymentMethods:         Array<PaymentMethodV1>;
    pendingPaymentMethod:   PendingPaymentMethod | null;
    stripe:                 Stripe | null;
    loading:                boolean;
    error:                  Error | null;
}


class $PurchasePageFormStepPaymentMethodBox extends React.Component<PurchasePageFormStepPaymentMethodBoxProps, PurchasePageFormStepPaymentMethodBoxState>
{
    private readonly _organisationService:  OrganisationService;
    private readonly _stripeService:        StripeService;
    private readonly _sessionService:       SessionService;
    private _retrived:                      boolean;


    constructor ( props: PurchasePageFormStepPaymentMethodBoxProps )
    {
        super(props);

        this.state = {
            paymentMethods:         [],
            pendingPaymentMethod:   null,
            stripe:                 null,
            loading:                true,
            error:                  null
        };

        this._organisationService = OrganisationService.getInstance();
        this._stripeService = StripeService.getInstance();
        this._sessionService = SessionService.getInstance();

        this._retrived = false;

        this._add = this._add.bind(this);
        this._cancel = this._cancel.bind(this);
        this._submitAdd = this._submitAdd.bind(this);
    }


    private async _cancel ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            if ( this.state.pendingPaymentMethod )
            {
                await this._organisationService.deleteOrganisationPaymentMethod(
                    this.props.organisation.uid,
                    this.state.pendingPaymentMethod.paymentmethod_uid,
                );

                this._sessionService.deletePendingPaymentMethod();
            }

            const paymentMethods = await this._organisationService.getOrganisationPaymentMethods(this.props.organisation.uid);

            this.setState({
                loading:                false,
                pendingPaymentMethod:   null,
                paymentMethods
            });
        }
        catch ( err )
        {
            console.error(`Error deleting pending payment methods: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const stripe = await this._stripeService.getStripe();
            const paymentMethods = await this._organisationService.getOrganisationPaymentMethods(this.props.organisation.uid);
            const paymentMethod = paymentMethods.find( o => o.verified && o.default )

            if ( !paymentMethod )
            {
                this.setState({
                    stripe
                });

                await this._add();
            }
            else
            {
                this.setState({
                    stripe,
                    paymentMethods,
                    loading:    false
                });

                this.props.onChange(paymentMethod);
            }
        }
        catch ( err )
        {
            console.error(`Error loading payment methods: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _loadVerify ( pendingPaymentMethod: PendingPaymentMethod ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const stripe = await this._stripeService.getStripe();
            const result = await stripe.retrieveSetupIntent(pendingPaymentMethod.client_secret);
            if ( !result.setupIntent )
            {
                throw new Error(`No setup intent could be retrieved: ${result.error.message}`);
            }
            
            switch (result.setupIntent.status ) {
                case 'succeeded':
                console.log('Success! Your payment method has been saved.');
                break;

                case 'processing':
                console.log("Processing payment details. We'll update you when processing is complete.");
                break;

                case 'requires_payment_method':
                    throw new Error('Adding payment method failed');
            }

            await this._organisationService.verifyOrganisationPaymentMethod(
                this.props.organisation.uid,
                pendingPaymentMethod.paymentmethod_uid,
                {}
            );

            this._sessionService.deletePendingPaymentMethod();

            const paymentMethods = await this._organisationService.getOrganisationPaymentMethods(this.props.organisation.uid);
            const paymentMethod = paymentMethods.find( o => o.uid === pendingPaymentMethod.paymentmethod_uid );
            if ( ! paymentMethod )
            {
                throw new Error(`Payment method was added but not found`);
            }

            this.setState({
                paymentMethods,
                pendingPaymentMethod: null,
                loading: false
            });

            this.props.onChange(paymentMethod);
        }
        catch ( err )
        {
            console.error(`Error verifying payment method: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _add ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const result = await this._organisationService.addOrganisationPaymentMethod(
                this.props.organisation.uid,
                {}
            );

            const paymentMethods = await this._organisationService.getOrganisationPaymentMethods(this.props.organisation.uid);

            const pendingPaymentMethod: PendingPaymentMethod = {
                paymentmethod_uid:  result.paymentmethod_uid,
                client_secret:      result.stripe_client_secret
            };

            this._sessionService.setPendingPaymentMethod(pendingPaymentMethod);

            this.setState({
                paymentMethods,
                pendingPaymentMethod,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error creating payment method: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _submitAdd ( evt: React.FormEvent, elements: StripeElements ): Promise<void>
    {
        evt.preventDefault();

        this.setState({
            loading:    true
        });

        try
        {
            if ( ! this.state.stripe )
            {
                throw new Error('Stripe not loaded');
            }

            if ( ! this.state.pendingPaymentMethod )
            {
                throw new Error('No pending payment method');
            }

            const result = await this.state.stripe.confirmSetup({
                elements: elements!,
                confirmParams: {
                    return_url: `${Environment.server.baseUrl}/purchase/paymentmethod/${encodeURIComponent(this.state.pendingPaymentMethod.paymentmethod_uid)}/verify`,
                }
            });

            if ( result.error )
            {
                throw new Error(result.error.message);
            }

            this.setState({
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error confirming stripe setup: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }

    public async componentDidMount ( ): Promise<void>
    {
        const pendingPaymentMethod = this._sessionService.getPendingPaymentMethod();

        if ( pendingPaymentMethod &&
             this.props.verifyPaymentMethodUID &&
             pendingPaymentMethod.paymentmethod_uid === this.props.verifyPaymentMethodUID &&
             !this._retrived )
        {
            this._retrived = true;
            await this._loadVerify(pendingPaymentMethod);
        }
        else
        {
            await this._load();
        }
    }


    public async componentDidUpdate ( prevProps: PurchasePageFormStepPaymentMethodBoxProps ): Promise<void>
    {
        if ( this.props.organisation.uid !== prevProps.organisation.uid )
        {
            await this._load();
        }
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Medium}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('purchasepageformsteppaymentmethodbox.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <IcSpinner active={this.state.loading} />

                {!this.state.loading && this.state.pendingPaymentMethod && this.state.stripe ?
                    <>
                        <IcText>
                            {this.props.t('purchasepageformsteppaymentmethodbox.txt_add_paymentmethod')}
                        </IcText>

                        <br />

                        <Elements
                            stripe={this.state.stripe}
                            options={{
                                clientSecret: this.state.pendingPaymentMethod.client_secret
                            }}>
                            <ElementsConsumer>
                                {({ elements }) => (
                                    <form onSubmit={ ( evt ) => this._submitAdd(evt, elements!) }>
                                        <PaymentElement />

                                        <IcSpinner active={this.state.loading} />

                                        <IcFloatRow align={IcFloatRowAlign.Right}>
                                            {this.state.paymentMethods.find( o => o.default ) ?
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Link}
                                                    onClick={this._cancel}>
                                                    {this.props.t('purchasepageformsteppaymentmethodbox.btn_cancel')}
                                                </IcButton>
                                            : null}

                                            <IcButton
                                                type='submit'
                                                disabled={this.state.loading}>
                                                {this.props.t('purchasepageformsteppaymentmethodbox.btn_enable')}
                                            </IcButton>
                                        </IcFloatRow>
                                    </form>
                                )}
                            </ElementsConsumer>
                        </Elements>
                    </>
                : null}

                {!this.state.loading && !this.state.pendingPaymentMethod ?
                    <>
                        <List>
                            {this.state.paymentMethods.filter( o => o.default ).map( ( paymentMethod )=> (
                                <ListItem key={paymentMethod.uid}>
                                    <ListItemHeader>
                                        <ListItemHeaderField
                                            grow={true}
                                            text={paymentMethod.title}
                                            subtext={Formatter.paymentService(paymentMethod.payment_service)}
                                        />

                                        {paymentMethod.expiry_label ?
                                            <ListItemHeaderField
                                                grow={true}
                                                text={paymentMethod.expiry_label}
                                                subtext={this.props.t('purchasepageformsteppaymentmethodbox.txt_expiry')}
                                            />
                                        : null}
                                    </ListItemHeader>
                                </ListItem>
                            ))}
                        </List>

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                onClick={this._add}
                                color={IcButtonColor.Secondary}>
                                <FontAwesomeIcon icon={faPlusCircle} />

                                {this.props.t('purchasepageformsteppaymentmethodbox.btn_add')}
                            </IcButton>
                        </IcFloatRow>
                    </>
                : null}
            </IcCard>
        )
    }
}


export const PurchasePageFormStepPaymentMethodBox = withTranslation()($PurchasePageFormStepPaymentMethodBox);
