import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LocaleService } from '../../Services/LocaleService';


export interface LocaleControllerProps extends WithTranslation
{
}


class $LocaleController extends React.Component<LocaleControllerProps>
{
    private readonly _localeService:   LocaleService;


    constructor ( props: LocaleControllerProps )
    {
        super(props);

        this._localeService = LocaleService.getInstance();
    }


    public componentDidMount ( ): void
    {
        this._localeService.getLocale().subscribe(this, ( locale ) =>
        {
            this.props.i18n.changeLanguage(locale);
        });

        const locale = this._localeService.getLocale().get();
        this.props.i18n.changeLanguage(locale);
    }


    public componentWillUnmount ( ): void
    {
        this._localeService.getLocale().unsubscribe(this);
    }
    

    public render ( )
    {
        return null;
    }
}


export const LocaleController = withTranslation()($LocaleController);
