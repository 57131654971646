import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PurchaseV1 } from '../../../Services/PurchaseService';
import { Form, Formik } from 'formik';
import { PurchaseDetails } from '../../../BusinessComponents/PurchaseDetails/PurchaseDetails';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcText, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface PurchaseUpdateCountPageSelectCountStepFormData
{
    count:  string;
}


export interface PurchaseUpdateCountPageSelectCountStepProps extends WithTranslation
{
    purchase:   PurchaseV1;
    onCancel:   ( ) => any;
    onFinish:   ( formData: PurchaseUpdateCountPageSelectCountStepFormData ) => any;
}


class $PurchaseUpdateCountPageSelectCountStep extends React.Component<PurchaseUpdateCountPageSelectCountStepProps>
{   
    public render ( )
    {
        const MyFormik = Formik<PurchaseUpdateCountPageSelectCountStepFormData>;
    
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText>
                    {this.props.t('purchaseupdatecountpageselectcountstep.txt_select_count')}
                </IcText>

                <PurchaseDetails purchase={this.props.purchase} />

                <MyFormik
                    initialValues={{count: '' + this.props.purchase.count}}
                    onSubmit={this.props.onFinish}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='count'
                                    label={this.props.t('purchaseupdatecountpageselectcountstep.inp_new_count')}
                                    required={true}
                                    validators={[
                                        Validator.min(1),
                                        Validator.max(this.props.purchase.package.details.max_concurrent_count || 100000)
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}>
                                {this.props.t('purchaseupdatecountpageselectcountstep.btn_cancel')}
                            </IcButton>

                            <IcButton type='submit'>
                                {this.props.t('purchaseupdatecountpageselectcountstep.btn_continue')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const PurchaseUpdateCountPageSelectCountStep = withTranslation()($PurchaseUpdateCountPageSelectCountStep);
