import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Formatter } from '../../utils/Formatter';
import { IcChip } from '@indece-common/ic-ui-lib-react';


export interface CanceledChipProps extends WithTranslation
{
    date:   string;
}


class $CanceledChip extends React.Component<CanceledChipProps>
{
    public render ( )
    {
        return (
            <IcChip>
                <FontAwesomeIcon icon={faLock} />

                {this.props.t('canceledchip.txt_label', {date: Formatter.date(this.props.date)})}
            </IcChip>
        );
    }
}


export const CanceledChip = withTranslation()($CanceledChip);
