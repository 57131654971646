import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface LoginSuccessPageProps extends RouteComponentProps, WithTranslation
{
}


class $LoginSuccessPage extends React.Component<LoginSuccessPageProps>
{
    private _timeoutRedirect: any | null;


    constructor ( props: LoginSuccessPageProps )
    {
        super(props);

        this._timeoutRedirect = null;
    }


    public componentDidMount ( ): void
    {
        if ( this._timeoutRedirect )
        {
            clearTimeout(this._timeoutRedirect);
        }

        this._timeoutRedirect = setTimeout( ( ) =>
        {
            this._timeoutRedirect = null;

            try
            {
                const afterLoginURL = window.sessionStorage.getItem('ic-licenser-afterloginpath');

                if ( afterLoginURL )
                {
                    window.sessionStorage.removeItem('ic-licenser-afterloginpath');

                    this.props.router.navigate(afterLoginURL);
                }
                else
                {
                    this.props.router.navigate(LinkUtils.make());
                }
            }
            catch ( err )
            {
                console.error(`Error navigating to after-login path: ${(err as Error).message}`, err);

                this.props.router.navigate(LinkUtils.make());
           }
        }, 500);
    }


    public componentWillUnmount ( ): void
    {
        if ( this._timeoutRedirect )
        {
            clearTimeout(this._timeoutRedirect);
            this._timeoutRedirect = null;
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <SuccessBox
                    message={this.props.t('loginsuccesspage.txt_success')}
                />
            </IcPageContent>
        );
    }
}


export const LoginSuccessPage = withTranslation()(withRouter($LoginSuccessPage));
