import { TCountryCode, countries } from 'countries-list';
import { Locale } from './Locale';
import { Environment } from '../utils/Environment';


export type Country = TCountryCode;


export const Countries: Array<Country> = Object.keys(countries) as Array<Country>;


export const EnabledCountries: Array<Country> = Environment.server.countries.length > 0 ? 
        Countries.filter( ( c ) => Environment.server.countries.includes(c) )
    :
        Countries;


export function localeToCountry ( locale: Locale ): Country | null
{
    const parts = locale.split('-');
    if ( parts.length < 2 || !countries[parts[1] as Country] )
    {
        return null;
    }

    const country = parts[1] as Country;

    if ( ! EnabledCountries.includes(country) )
    {
        return null;
    }
    
    return country;
}
