import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserService, UserV1 } from '../../Services/UserService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { UserLicenseAssignPageLoadingStep } from './UserLicenseAssignPageLoadingStep/UserLicenseAssignPageLoadingStep';
import { UserLicenseAssignPageSuccessStep } from './UserLicenseAssignPageSuccessStep/UserLicenseAssignPageSuccessStep';
import { UserLicenseAssignPageSelectLicensesStep } from './UserLicenseAssignPageSelectLicensesStep/UserLicenseAssignPageSelectLicensesStep';
import { LicenseV1 } from '../../Services/LicenseService';
import { OrganisationService, OrganisationV1, OrganisationV1License } from '../../Services/OrganisationService';
import { UserLicenseAssignPageSelectOrganisationStep } from './UserLicenseAssignPageSelectOrganisationStep/UserLicenseAssignPageSelectOrganisationStep';
import { UserLicenseAssignPagePurchaseStep } from './UserLicenseAssignPagePurchaseStep/UserLicenseAssignPagePurchaseStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface UserLicenseAssignPageRouteParams
{
    userUID: string;
}


export interface UserLicenseAssignPageProps extends RouteComponentProps<UserLicenseAssignPageRouteParams>, WithTranslation
{
}


enum UserLicenseAssignPageStep
{
    Loading             = 'LOADING',
    SelectOrganisation  = 'SELECT_ORGANISATION',
    SelectLicenses      = 'SELECT_LICENSES',
    Purchase            = 'PURCHASE',
    Success             = 'SUCCESS'
}


interface UserLicenseAssignPageState
{
    step:           UserLicenseAssignPageStep;
    licenses:       Array<OrganisationV1License>;
    organisations:  Array<OrganisationV1>;
    organisation:   OrganisationV1 | null;
    user:           UserV1 | null;
    error:          Error | null;
}


class $UserLicenseAssignPage extends React.Component<UserLicenseAssignPageProps, UserLicenseAssignPageState>
{
    private readonly _userService:          UserService;
    private readonly _organisationService:  OrganisationService;
    

    constructor ( props: UserLicenseAssignPageProps )
    {
        super(props);

        this.state = {
            step:           UserLicenseAssignPageStep.Loading,
            licenses:       [],
            organisations:  [],
            organisation:   null,
            user:           null,
            error:          null
        };

        this._userService = UserService.getInstance();
        this._organisationService = OrganisationService.getInstance();

        this._cancel                    = this._cancel.bind(this);
        this._showSelectOrganisation    = this._showSelectOrganisation.bind(this);
        this._finishSelectOrganisation  = this._finishSelectOrganisation.bind(this);
        this._finishSelectLicenses      = this._finishSelectLicenses.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:       UserLicenseAssignPageStep.Loading,
                error:      null
            });

            const user = await this._userService.getUser(this.props.router.params.userUID);
            const organisations: Array<OrganisationV1> = [];
            for ( const userOrganisation of user.organisations )
            {
                const organisation = await this._organisationService.getOrganisation(userOrganisation.uid);
                organisations.push(organisation);
            }

            let organisation: OrganisationV1 | null = null;
            let licenses: Array<OrganisationV1License> = [];

            if ( user.organisations.length === 1 )
            {
                organisation = organisations[0];

                licenses = await this._organisationService.getOrganisationLicenses(organisation.uid, 0, 100);   // TODO

                if ( licenses.length === 0 )
                {
                    this.setState({
                        step:   UserLicenseAssignPageStep.Purchase,
                        licenses,
                        organisations,
                        organisation,
                        user
                    });

                    return;
                }
            }

            this.setState({
                step:   organisation ? UserLicenseAssignPageStep.SelectLicenses : UserLicenseAssignPageStep.SelectOrganisation,
                licenses,
                organisations,
                organisation,
                user
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private _showSelectOrganisation ( ): void
    {
        this.setState({
            step:           UserLicenseAssignPageStep.SelectOrganisation,
            organisation:   null
        });
    }


    private async _finishSelectOrganisation ( organisation: OrganisationV1 ): Promise<void>
    {
        try
        {
            this.setState({
                step:       UserLicenseAssignPageStep.Loading,
                error:      null
            });

            const licenses = await this._organisationService.getOrganisationLicenses(organisation.uid, 0, 100);   // TODO

            if ( licenses.length === 0 )
            {
                this.setState({
                    step:   UserLicenseAssignPageStep.Purchase,
                    licenses,
                    organisation
                });

                return;
            }
            
            this.setState({
                step:   UserLicenseAssignPageStep.SelectLicenses,
                licenses,
                organisation
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation licenses: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private async _finishSelectLicenses ( licenses: Array<LicenseV1> ): Promise<void>
    {
        if ( !this.state.user || !this.state.organisation )
        {
            return;
        }

        try
        {
            this.setState({
                step:       UserLicenseAssignPageStep.Loading,
                error:      null
            });

            for ( const license of licenses )
            {
                await this._organisationService.assignOrganisationMemberLicense(
                    this.state.organisation.uid,
                    this.state.user.uid,
                    {
                        license_uid: license.uid
                    }
                );
            }

            await this._userService.load();

            this.setState({
                step:   UserLicenseAssignPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error assigning license to user: ${(err as Error).message}`, err);

            this.setState({
                step:       UserLicenseAssignPageStep.SelectLicenses,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('userlicenseassignpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === UserLicenseAssignPageStep.Loading ?
                    <UserLicenseAssignPageLoadingStep />
                : null}

                {this.state.step === UserLicenseAssignPageStep.Purchase && this.state.organisation ?
                    <UserLicenseAssignPagePurchaseStep
                        organisations={this.state.organisations}
                        organisation={this.state.organisation}
                        onCancel={this._cancel}
                        onSelectOrganisation={this._showSelectOrganisation}
                    />
                : null}

                {this.state.step === UserLicenseAssignPageStep.SelectOrganisation && this.state.user ?
                    <UserLicenseAssignPageSelectOrganisationStep
                        organisations={this.state.organisations}
                        user={this.state.user}
                        onCancel={this._cancel}
                        onFinish={this._finishSelectOrganisation}
                    />
                : null}

                {this.state.step === UserLicenseAssignPageStep.SelectLicenses && this.state.user ?
                    <UserLicenseAssignPageSelectLicensesStep
                        organisations={this.state.organisations}
                        licenses={this.state.licenses}
                        user={this.state.user}
                        onCancel={this._cancel}
                        onSelectOrganisation={this._showSelectOrganisation}
                        onFinish={this._finishSelectLicenses}
                    />
                : null}

                {this.state.step === UserLicenseAssignPageStep.Success ?
                    <UserLicenseAssignPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const UserLicenseAssignPage = withTranslation()(withRouter($UserLicenseAssignPage));
