import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserV1, isOwner } from '../../Services/UserService';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Environment } from '../../utils/Environment';
import { Formatter } from '../../utils/Formatter';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { OrganisationV1 } from '../../Services/OrganisationService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcChip, IcFloatRow, IcFloatRowAlign, IcPopupMenu, IcPopupMenuItem, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AccountPageOrganisationsBoxProps extends WithTranslation
{
    user:           UserV1 | null;
    organisations:  Record<string, OrganisationV1>;
}


class $AccountPageOrganisationsBox extends React.Component<AccountPageOrganisationsBoxProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading2}>
                    {this.props.t('accountpageorganisationsbox.txt_title')}
                </IcText>

                <List>
                    {this.props.user?.organisations.map( ( userOrganisation ) => (
                        <ListItem key={userOrganisation.uid}>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    grow={true}
                                    to={this.props.user  && isOwner(this.props.user) ? LinkUtils.make('organisation', userOrganisation.uid) : undefined}>
                                    {this.props.organisations[userOrganisation.uid]?.name}
                                    
                                    {!userOrganisation.accepted ? 
                                        <IcChip label={this.props.t('accountpage.txt_join_sent')} />
                                    : null}
                                </ListItemHeaderField>

                                <ListItemHeaderField
                                    grow={true}
                                    to={this.props.user  && isOwner(this.props.user) ? LinkUtils.make('organisation', userOrganisation.uid) : undefined}
                                    text={Formatter.userOrganisationRole(userOrganisation.role)}
                                />                                

                                {this.props.user && !userOrganisation.accepted ?
                                    <ListItemHeaderAction
                                        icon={faCheck}
                                        to={LinkUtils.make('organisation', userOrganisation.uid, 'join')}
                                    />
                                : null}

                                {this.props.user && isOwner(this.props.user) ?
                                    <ListItemHeaderField>
                                        <IcPopupMenu>
                                            <IcPopupMenuItem to={LinkUtils.make('organisation', userOrganisation.uid)}>
                                                {this.props.t('accountpagesfasbox.btn_show')}
                                            </IcPopupMenuItem>

                                            <IcPopupMenuItem to={LinkUtils.make('organisation', userOrganisation.uid, 'edit')}>
                                                {this.props.t('accountpagesfasbox.btn_edit')}
                                            </IcPopupMenuItem>
                                        </IcPopupMenu>
                                    </ListItemHeaderField>
                                : null}
                            </ListItemHeader>
                        </ListItem>
                    ))}

                    {this.props.user && this.props.user.organisations.length === 0 ?
                        <ListEmpty>
                            {this.props.t('accountpageorganisationsbox.txt_no_organisations')}
                        </ListEmpty>
                    : null}
                </List>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    {this.props.user && (!Environment.server.max_organisations_per_user || this.props.user.organisations.length < Environment.server.max_organisations_per_user) ?
                        <IcButton
                            to={LinkUtils.make('user', this.props.user.uid, 'organisation', 'assign')}
                            color={IcButtonColor.Secondary}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                            {this.props.t('accountpageorganisationsbox.btn_add')}
                        </IcButton>
                    : null}
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AccountPageOrganisationsBox = withTranslation()($AccountPageOrganisationsBox);
