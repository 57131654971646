import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { RespAddApiTokenV1, UserService } from '../../Services/UserService';
import { UserApiTokenAddPageLoadingStep } from './UserApiTokenAddPageLoadingStep/UserApiTokenAddPageLoadingStep';
import { UserApiTokenAddPageSuccessStep } from './UserApiTokenAddPageSuccessStep/UserApiTokenAddPageSuccessStep';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { UserApiTokenAddPageFormStep, UserApiTokenAddPageFormStepFormData } from './UserApiTokenAddPageFormStep/UserApiTokenAddPageFormStep';
import { ApplicationService, ApplicationV1 } from '../../Services/ApplicationService';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface UserApiTokenAddPageProps extends WithTranslation, WithLocaleProps, RouteComponentProps
{
}


enum UserApiTokenAddPageStep
{
    Form        = 'FORM',
    Loading     = 'LOADING',
    Success     = 'SUCCESS'
}


interface UserApiTokenAddPageState
{
    step:           UserApiTokenAddPageStep;
    applications:   Array<ApplicationV1>;
    result:         RespAddApiTokenV1 | null;
    error:          Error | null;
}


class $UserApiTokenAddPage extends React.Component<UserApiTokenAddPageProps, UserApiTokenAddPageState>
{
    private readonly _applicationService:   ApplicationService;
    private readonly _userService:          UserService;


    constructor ( props: UserApiTokenAddPageProps )
    {
        super(props);

        this.state = {
            step:           UserApiTokenAddPageStep.Loading,
            applications:   [],
            result:         null,
            error:          null
        };

        this._applicationService = ApplicationService.getInstance();
        this._userService = UserService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishForm = this._finishForm.bind(this);
        this._finishSuccess = this._finishSuccess.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   UserApiTokenAddPageStep.Loading,
                error:  null
            });

            let applications = await this._applicationService.getApplications(0, 100); // TODO
            applications = applications.filter( o => o.client_uid );

            this.setState({
                step:       UserApiTokenAddPageStep.Form,
                applications
            });
        }
        catch ( err )
        {
            console.error(`Error loading applications: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }



    private async _finishForm ( formData: UserApiTokenAddPageFormStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   UserApiTokenAddPageStep.Loading,
                error:  null
            });

            const result = await this._userService.addOwnApiToken(
                {
                    application_uid:    formData.application_uid,
                    name:               formData.name.trim()
                }
            );

            this.setState({
                error:      null,
                result,
                step:       UserApiTokenAddPageStep.Success
            });
        }
        catch ( err )
        {
            console.error(`Error adding user to organisation: ${(err as Error).message}`, err);

            this.setState({
                error:  err as Error,
                step:   UserApiTokenAddPageStep.Form
            });
        }
    }


    private async _finishSuccess ( ): Promise<void>
    {
        this.props.router.navigate(-1);
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('userapitokenaddpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === UserApiTokenAddPageStep.Loading ?
                    <UserApiTokenAddPageLoadingStep />
                : null}

                {this.state.step === UserApiTokenAddPageStep.Form ?
                    <UserApiTokenAddPageFormStep
                        applications={this.state.applications}
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.step === UserApiTokenAddPageStep.Success && this.state.result ?
                    <UserApiTokenAddPageSuccessStep
                        result={this.state.result}
                        onFinish={this._finishSuccess}
                    />
                : null}
            </IcPageContent>
        );
    }
}


export const UserApiTokenAddPage = withLocale(withTranslation()(withRouter($UserApiTokenAddPage)));
