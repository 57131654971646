import React from 'react';

import './PackageTiles.css';


export interface PackageTilesProps
{
    children?:  React.ReactNode;
}


export class PackageTiles extends React.Component<PackageTilesProps>
{
    public render ( )
    {
        return (
            <div className='PackageTiles'>
                {this.props.children}
            </div>
        );
    }
}
