import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { Form, Formik, FormikErrors } from 'formik';
import { isAdmin, UserService, UserV1, UserV1GlobalRole, UserV1GlobalRoles } from '../../Services/UserService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { EnabledLocales, Locale } from '../../Model/Locale';
import { Formatter } from '../../utils/Formatter';
import { Gender, Genders } from '../../Model/Gender';
import { LocaleService } from '../../Services/LocaleService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcGridItem, IcGridPadding, IcGridRow, IcInputSelect, IcInputText, IcSpinner, Validator, RouteComponentProps, withRouter, IcFloatRow, IcFloatRowAlign, IcInputMultiSelect, IcPageContent, IcText } from '@indece-common/ic-ui-lib-react';


export interface AdminEditUserRouteParams
{
    userUID:  string;
}


export interface UserEditPageProps extends RouteComponentProps<AdminEditUserRouteParams>, WithTranslation
{
}


interface UserEditPageFormData
{
    locale:             Locale;
    gender:             Gender | '';
    title:              string;
    firstname:          string;
    lastname:           string;
    email:              string;
    password:           string;
    password_confirm:   string;
    global_roles:       Array<UserV1GlobalRole>;
}


interface UserEditPageState
{
    initialFormData:    UserEditPageFormData;
    ownUser:            UserV1 | null;
    user:               UserV1 | null;
    error:              Error | null;
    loading:            boolean;
    success:            string | null;
}


class $UserEditPage extends React.Component<UserEditPageProps, UserEditPageState>
{
    private readonly _userService:      UserService;
    private readonly _localeService:    LocaleService;
    

    constructor ( props: UserEditPageProps )
    {
        super(props);

        this._userService = UserService.getInstance();
        this._localeService = LocaleService.getInstance();

        this.state = {
            initialFormData: {
                locale:             this._localeService.getLocale().get(),
                gender:             '',
                title:              '',
                firstname:          '',
                lastname:           '',
                email:              '',
                password:           '',
                password_confirm:   '',
                global_roles:       [],
            },
            ownUser:    null,
            user:       null,
            error:      null,
            loading:    true,
            success:    null
        };

        this._validate = this._validate.bind(this);
        this._cancel = this._cancel.bind(this);
        this._update = this._update.bind(this);
    }


    private _validate ( formData: UserEditPageFormData ): FormikErrors<UserEditPageFormData>
    {
        const errors: FormikErrors<UserEditPageFormData> = {};

        if ( (formData.password.trim() || formData.password_confirm.trim()) && formData.password.trim() !== formData.password_confirm.trim() )
        {
            errors.password_confirm = this.props.t('usereditpage.err_password_mismatch');
        }

        return errors;
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const user = await this._userService.getUser(this.props.router.params.userUID);

            this.setState({
                error:      null,
                loading:    false,
                user,
                initialFormData: {
                    locale:             user.locale,
                    gender:             user.gender || '',
                    title:              user.title || '',
                    firstname:          user.firstname,
                    lastname:           user.lastname,
                    email:              user.email,
                    password:           '',
                    password_confirm:   '',
                    global_roles:       user.global_roles,
                }
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _update ( formData: UserEditPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.user )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            await this._userService.updateUser(
                this.state.user.uid,
                {
                    locale:         formData.locale,
                    gender:         formData.gender ? formData.gender : null,
                    title:          formData.title.trim() || null,
                    firstname:      formData.firstname.trim(),
                    lastname:       formData.lastname.trim(),
                    email:          formData.email.trim(),
                    password:       formData.password.trim() || null,
                    global_roles:   formData.global_roles.filter( ( s ) => !!s ),
                }
            );

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('usereditpage.txt_success')
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });

        await this._load();
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        const MyFormik = Formik<UserEditPageFormData>;

        return (
            <IcPageContent>
                <PageTitle title={this.props.t('usereditpage.txt_edit_user')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._update}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcGridRow padding={IcGridPadding.Small}>
                                <IcGridItem s={6}>
                                    <IcInputText
                                        label={this.props.t('usereditpage.inp_title')}
                                        name='title'
                                    />
                                </IcGridItem>

                                <IcGridItem s={6}>
                                    <IcInputSelect
                                        label={this.props.t('usereditpage.inp_gender')}
                                        name='gender'
                                        options={Genders.map( ( gender ) => ({
                                            label:  Formatter.gender(gender),
                                            value:  gender
                                        }))}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputText
                                        label={this.props.t('usereditpage.inp_firstname')}
                                        name='firstname'
                                        required={true}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputText
                                        label={this.props.t('usereditpage.inp_lastname')}
                                        name='lastname'
                                        required={true}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputText
                                        label={this.props.t('usereditpage.inp_email')}
                                        name='email'
                                        required={true}
                                        validators={[
                                            Validator.email
                                        ]}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputSelect
                                        label={this.props.t('usereditpage.inp_locale')}
                                        name='locale'
                                        required={true}
                                        options={EnabledLocales.map( ( locale ) => ({
                                            label:  Formatter.locale(locale),
                                            value:  locale
                                        }))}
                                    />
                                </IcGridItem>
                            </IcGridRow>

                            {this.state.ownUser && isAdmin(this.state.ownUser) ?
                                <>
                                    <IcGridRow padding={IcGridPadding.Small}>
                                        <IcGridItem s={12} m={6}>
                                            <IcInputText
                                                type='password'
                                                label={this.props.t('usereditpage.inp_password')}
                                                name='password'
                                                validators={[
                                                    Validator.password
                                                ]}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12} m={6}>
                                            <IcInputText
                                                type='password'
                                                label={this.props.t('usereditpage.inp_password_confirm')}
                                                name='password_confirm'
                                            />
                                        </IcGridItem>
                                    </IcGridRow>

                                    <IcText>
                                        {this.props.t('validator.password.policy', {length: Validator.PASSWORD_MIN_LENGTH})}
                                    </IcText>
                                </>
                            : null}

                            {this.state.ownUser && isAdmin(this.state.ownUser) ?
                                <IcGridRow padding={IcGridPadding.Small}>
                                    <IcGridItem s={12} m={6}>
                                        <IcInputMultiSelect
                                            label={this.props.t('usereditpage.inp_global_roles')}
                                            name='global_roles'
                                            options={UserV1GlobalRoles.map( ( globalRole ) => ({
                                                label:  Formatter.userGlobalRole(globalRole),
                                                value:  globalRole
                                            }))}
                                        />
                                    </IcGridItem>
                                </IcGridRow>
                            : null}

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton
                                    type='button'
                                    color={IcButtonColor.Link}
                                    onClick={this._cancel}>
                                    {this.props.t('usereditpage.btn_cancel')}
                                </IcButton>

                                <IcButton
                                    type='submit'
                                    disabled={this.state.loading}>
                                    {this.props.t('usereditpage.btn_update')}
                                </IcButton>
                            </IcFloatRow>
                        </IcCard>
                    </Form>
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const UserEditPage = withTranslation()(withRouter($UserEditPage));
