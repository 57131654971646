import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserService, UserV1, UserV1OrganisationRole, isAdmin } from '../../../Services/UserService';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { OrganisationPaymentDetails } from '../../../Components/OrganisationPaymentDetails/OrganisationPaymentDetails';
import { PackageV1Price, isFreePrice } from '../../../Services/PackageService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcSeparator, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface PurchasePageFormStepOrganisationBoxProps extends WithTranslation
{
    organisation:   OrganisationV1;
    packagePrice:   PackageV1Price;
    onSelect:       ( ) => any;
    onEdit:         ( ) => any;
}


interface PurchasePageFormStepOrganisationBoxState
{
    ownUser:    UserV1 | null;
}


class $PurchasePageFormStepOrganisationBox extends React.Component<PurchasePageFormStepOrganisationBoxProps, PurchasePageFormStepOrganisationBoxState>
{
    private readonly _userService:  UserService;


    constructor ( props: PurchasePageFormStepOrganisationBoxProps )
    {
        super(props);

        this.state = {
            ownUser:    null
        };

        this._userService = UserService.getInstance();
    }


    public componentDidMount ( ): void
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Medium}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('purchasepageformsteporganisationbox.txt_title')}
                </IcText>

                <IcText>
                    {this.props.organisation.name}
                </IcText>

                {!isFreePrice(this.props.packagePrice) ?
                    <>
                        <br />

                        <OrganisationPaymentDetails
                            organisation={this.props.organisation}
                        />
                    </>
                : null}

                <IcSeparator />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    {this.state.ownUser && (isAdmin(this.state.ownUser) || this.state.ownUser.organisations.filter( o => o.role === UserV1OrganisationRole.Owner).length > 1) ?
                        <IcButton
                            color={IcButtonColor.Secondary}
                            onClick={this.props.onSelect}>
                            {this.props.t('purchasepageformsteporganisationbox.btn_select_other')}
                        </IcButton>
                    : null}

                    <IcButton
                        color={IcButtonColor.Secondary}
                        onClick={this.props.onEdit}>
                        <FontAwesomeIcon icon={faPenToSquare} />

                        {this.props.t('purchasepageformsteporganisationbox.btn_edit')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const PurchasePageFormStepOrganisationBox = withTranslation()($PurchasePageFormStepOrganisationBox);
