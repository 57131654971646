import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcCard, IcCardPadding, IcGridItem, IcGridRow, IcText } from '@indece-common/ic-ui-lib-react';


export interface UserAddPageMethodStepProps extends WithTranslation
{
    onFinish: ( invite: boolean ) => any;
}


class $UserAddPageMethodStep extends React.Component<UserAddPageMethodStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText>
                    {this.props.t('useraddpagemethodstep.txt_info')}
                </IcText>

                <IcGridRow>
                    <IcGridItem s={6}>
                        <IcButton onClick={ ( ) => this.props.onFinish(true)}>
                            {this.props.t('useraddpagemethodstep.btn_invite')}
                        </IcButton>
                    </IcGridItem>

                    <IcGridItem s={6}>
                        <IcButton onClick={ ( ) => this.props.onFinish(false)}>
                            {this.props.t('useraddpagemethodstep.btn_create')}
                        </IcButton>
                    </IcGridItem>
                </IcGridRow>
            </IcCard>
        );
    }
}


export const UserAddPageMethodStep = withTranslation()($UserAddPageMethodStep);
