import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserService } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AccountUpdatePasswordPageLoadingStep } from './AccountUpdatePasswordPageLoadingStep/AccountUpdatePasswordPageLoadingStep';
import { AccountUpdatePasswordPageSuccessStep } from './AccountUpdatePasswordPageSuccessStep/AccountUpdatePasswordPageSuccessStep';
import { AccountUpdatePasswordPageFormStep, AccountUpdatePasswordPageFormStepFormData } from './AccountUpdatePasswordPageFormStep/AccountUpdatePasswordPageFormStep';
import { sleep } from 'ts-delay';
import { IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AccountUpdatePasswordPageProps extends RouteComponentProps, WithTranslation
{
}


enum AccountUpdatePasswordPageStep
{
    Form    = 'FORM',
    Loading = 'LOADING',
    Success = 'SUCCESS'
}


interface AccountUpdatePasswordPageState
{
    step:   AccountUpdatePasswordPageStep;
    error:  Error | null;
}


class $AccountUpdatePasswordPage extends React.Component<AccountUpdatePasswordPageProps, AccountUpdatePasswordPageState>
{
    private readonly _userService:  UserService;
    
    
    constructor ( props: AccountUpdatePasswordPageProps )
    {
        super(props);
        
        this.state = {
            step:   AccountUpdatePasswordPageStep.Form,
            error:  null
        };
        
        this._userService = UserService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishForm = this._finishForm.bind(this);
    }
    

    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishForm ( formData: AccountUpdatePasswordPageFormStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AccountUpdatePasswordPageStep.Loading,
                error:  null
            });

            if ( formData.password_new !== formData.password_confirm )
            {
                throw new Error(this.props.t('accountupdatepasswordpage.err_password_missmatch'))
            }

            await this._userService.updateOwnUserPassword({
                password_old:   formData.password_old,
                password_new:   formData.password_new,
            });

            this.setState({
                step:   AccountUpdatePasswordPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('account'));
        }
        catch ( err )
        {
            console.error(`Error updating password: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountUpdatePasswordPageStep.Form,
                error:  err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('accountupdatepasswordpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AccountUpdatePasswordPageStep.Form ?
                    <AccountUpdatePasswordPageFormStep
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.step === AccountUpdatePasswordPageStep.Loading ?
                    <AccountUpdatePasswordPageLoadingStep />
                : null}

                {this.state.step === AccountUpdatePasswordPageStep.Success ?
                    <AccountUpdatePasswordPageSuccessStep />
                : null}
            </IcPageContent>
        );
    } 
}


export const AccountUpdatePasswordPage = withTranslation()(withRouter($AccountUpdatePasswordPage));
