import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { OrganisationDeletePageConfirmStep } from './OrganisationDeletePageConfirmStep/OrganisationDeletePageConfirmStep';
import { OrganisationDeletePageSuccessStep } from './OrganisationDeletePageSuccessStep/OrganisationDeletePageSuccessStep';
import { OrganisationDeletePageLoadingStep } from './OrganisationDeletePageLoadingStep/OrganisationDeletePageLoadingStep';
import { PurchaseService, PurchaseV1 } from '../../Services/PurchaseService';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface OrganisationDeletePageRouteParams
{
    organisationUID:  string;
}


export interface OrganisationDeletePageProps extends RouteComponentProps<OrganisationDeletePageRouteParams>, WithTranslation
{
}


enum OrganisationDeletePageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface OrganisationDeletePageState
{
    currStep:       OrganisationDeletePageStep;
    organisation:   OrganisationV1 | null;
    purchases:      Array<PurchaseV1>;
    error:          Error | null;
}


class $OrganisationDeletePage extends React.Component<OrganisationDeletePageProps, OrganisationDeletePageState>
{
    private readonly _organisationService:  OrganisationService;
    private readonly _purchaseService:      PurchaseService;
    

    constructor ( props: OrganisationDeletePageProps )
    {
        super(props);

        this.state = {
            currStep:       OrganisationDeletePageStep.Loading,
            organisation:   null,
            purchases:      [],
            error:          null
        };

        this._organisationService = OrganisationService.getInstance();
        this._purchaseService = PurchaseService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   OrganisationDeletePageStep.Loading,
                error:      null
            });

            const organisation = await this._organisationService.getOrganisation(this.props.router.params.organisationUID);
            const purchases = await this._purchaseService.getPurchases(organisation.uid);

            this.setState({
                error:      null,
                currStep:   OrganisationDeletePageStep.Confirm,
                organisation,
                purchases
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        try
        {
            if ( ! this.state.organisation )
            {
                throw new Error('No organisation loaded');
            }
         
            this.setState({
                currStep:   OrganisationDeletePageStep.Loading,
                error:      null
            });

            await this._organisationService.deleteOrganisation(
                this.state.organisation.uid
            );

            this.setState({
                currStep:   OrganisationDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating organisation: ${(err as Error).message}`, err);

            this.setState({
                currStep:   OrganisationDeletePageStep.Confirm,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('organisationdeletepage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === OrganisationDeletePageStep.Loading ?
                    <OrganisationDeletePageLoadingStep />
                : null}

                {this.state.currStep === OrganisationDeletePageStep.Confirm && this.state.organisation ?
                    <OrganisationDeletePageConfirmStep
                        organisation={this.state.organisation}
                        purchases={this.state.purchases}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.currStep === OrganisationDeletePageStep.Success ?
                    <OrganisationDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    } 
}


export const OrganisationDeletePage = withTranslation()(withRouter($OrganisationDeletePage));
