import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Environment } from '../utils/Environment';


export class StripeService
{
    private static _instance: StripeService;
    
    
    public static getInstance ( ): StripeService
    {
        if ( ! this._instance )
        {
            this._instance = new StripeService();
        }
        
        return this._instance;
    }


    private _stripe: Stripe | null;


    constructor ( )
    {
        this._stripe = null;
    }
    
    
    public async getStripe ( ): Promise<Stripe>
    {
        if ( ! Environment.stripe.publishableKey )
        {
            throw new Error('Stripe is not configure');
        }

        if ( ! this._stripe )
        {
            this._stripe = await loadStripe(Environment.stripe.publishableKey);
        }

        if ( ! this._stripe )
        {
            throw new Error('Could not load stripe');
        }
        
        return this._stripe;
    }
}
