import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { LicenseV1 } from '../../../Services/LicenseService';
import { ApplicationV1 } from '../../../Services/ApplicationService';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputMultiSelect, IcInputText, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AdminLicenseEditPageFormStepFormData
{
    tag:                string;
    name:               string;
    application_uids:   Array<string>;
}


export interface AdminLicenseEditPageFormStepProps extends WithTranslation, WithLocaleProps
{
    license:        LicenseV1;
    applications:   Array<ApplicationV1>;
    onCancel:       ( ) => any;
    onFinish:       ( formData: AdminLicenseEditPageFormStepFormData ) => any;
}


interface AdminLicenseEditPageFormStepState
{
    initialFormData:    AdminLicenseEditPageFormStepFormData;
}


class $AdminLicenseEditPageFormStep extends React.Component<AdminLicenseEditPageFormStepProps, AdminLicenseEditPageFormStepState>
{
    constructor ( props: AdminLicenseEditPageFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                tag:                '',
                name:               '',
                application_uids:   []
            }
        };
    }


    private _applyLicense ( license: LicenseV1 ): void
    {
        this.setState({
            initialFormData: {
                tag:                license.tag,
                name:               license.name,
                application_uids:   license.application_uids
            }
        });
    }


    public componentDidMount ( ): void
    {
        this._applyLicense(this.props.license);
    }


    public componentDidUpdate ( prevProps: AdminLicenseEditPageFormStepProps ): void
    {
        if ( prevProps.license !== this.props.license )
        {
            this._applyLicense(this.props.license);
        }
    }


    public render ( )
    {
        const MyFormik = Formik<AdminLicenseEditPageFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    label={this.props.t('adminlicenseeditpageformstep.inp_tag')}
                                    name='tag'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('adminlicenseeditpageformstep.inp_name')}
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputMultiSelect
                                    label={this.props.t('adminlicenseeditpageformstep.inp_applications')}
                                    name='application_uids'
                                    options={this.props.applications.map( ( application ) => ({
                                        label:  application.names.find( o => o.locale === this.props.locale )?.value || '???',
                                        value:  application.uid
                                    }))}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}
                                testID='adminlicenseeditpageformstep.btn_cancel'>
                                {this.props.t('adminlicenseeditpageformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'
                                testID='adminlicenseeditpageformstep.btn_update'>
                                {this.props.t('adminlicenseeditpageformstep.btn_update')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminLicenseEditPageFormStep = withLocale(withTranslation()($AdminLicenseEditPageFormStep));
