import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PackageService, PackageV1 } from '../../Services/PackageService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { localeToCountry } from '../../Model/Country';
import { Environment } from '../../utils/Environment';
import { AdminPackagePriceAddPageFormStep, AdminPackagePriceAddPageFormStepFormData } from './AdminPackagePriceAddPageFormStep/AdminPackagePriceAddPageFormStep';
import { AdminPackagePriceAddPageLoadingStep } from './AdminPackagePriceAddPageLoadingStep/AdminPackagePriceAddPageLoadingStep';
import { AdminPackagePriceAddPageSuccessStep } from './AdminPackagePriceAddPageSuccessStep/AdminPackagePriceAddPageSuccessStep';
import { sleep } from 'ts-delay';
import { AdminPackagePriceAddPageConfirmStep } from './AdminPackagePriceAddPageConfirmStep/AdminPackagePriceAddPageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminPackagePriceAddPageRouteParams
{
    packageUID: string;
}


export interface AdminPackagePriceAddPageProps extends RouteComponentProps<AdminPackagePriceAddPageRouteParams>, WithTranslation, WithLocaleProps
{
}


enum AdminPackagePriceAddPageStep
{
    Loading = 'LOADING',
    Form    = 'FORM',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AdminPackagePriceAddPageState
{
    currStep:   AdminPackagePriceAddPageStep;
    formData:   AdminPackagePriceAddPageFormStepFormData | null;
    package:    PackageV1 | null;
    error:      Error | null;
}


class $AdminPackagePriceAddPage extends React.Component<AdminPackagePriceAddPageProps, AdminPackagePriceAddPageState>
{
    private readonly _packageService:   PackageService;


    constructor ( props: AdminPackagePriceAddPageProps )
    {
        super(props);

        this.state = {
            currStep:       AdminPackagePriceAddPageStep.Loading,
            formData:       null,
            package:        null,
            error:          null
        };

        this._packageService = PackageService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishForm = this._finishForm.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   AdminPackagePriceAddPageStep.Loading,
                error:      null
            });

            const country = localeToCountry(this.props.locale) || Environment.server.default_country;
            const pkg = await this._packageService.getPackage(this.props.router.params.packageUID, country);

            this.setState({
                package:        pkg,
                currStep:       AdminPackagePriceAddPageStep.Form
            });
        }
        catch ( err )
        {
            console.error(`Error loading package: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   AdminPackagePriceAddPageStep.Loading
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishForm ( formData: AdminPackagePriceAddPageFormStepFormData ): Promise<void>
    {
        if ( !this.state.package )
        {
            return;
        }

        this.setState({
            currStep:   AdminPackagePriceAddPageStep.Confirm,
            formData
        });
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( !this.state.package || !this.state.formData )
        {
            return;
        }

        try
        {
            this.setState({
                currStep:   AdminPackagePriceAddPageStep.Loading,
                error:      null
            });

            await this._packageService.addPackagePrice(
                this.state.package.uid,
                {
                    countries:          this.state.formData.countries,
                    currency:           this.state.formData.currency,
                    payment_interval:   this.state.formData.payment_interval,
                    periods:            this.state.formData.periods.map( ( p ) => ({
                        net:                parseInt(p.net || '0', 10),
                        duration:           p.duration.trim() || null
                    }))
                }
            );

            this.setState({
                currStep:   AdminPackagePriceAddPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating package price: ${(err as Error).message}`, err);

            this.setState({
                currStep:   AdminPackagePriceAddPageStep.Form,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminpackagepriceaddpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === AdminPackagePriceAddPageStep.Loading ?
                    <AdminPackagePriceAddPageLoadingStep />
                : null}

                {this.state.currStep === AdminPackagePriceAddPageStep.Form && this.state.package ?
                    <AdminPackagePriceAddPageFormStep
                        package={this.state.package}
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.currStep === AdminPackagePriceAddPageStep.Confirm ?
                    <AdminPackagePriceAddPageConfirmStep
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.currStep === AdminPackagePriceAddPageStep.Success ?
                    <AdminPackagePriceAddPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminPackagePriceAddPage = withTranslation()(withLocale(withRouter($AdminPackagePriceAddPage)));
