import DayJS from 'dayjs';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BillV1, PurchaseService } from '../../Services/PurchaseService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { Form, Formik } from 'formik';
import { InputDate } from '../../Components/Input/InputDate';
import { isAdmin, UserService, UserV1 } from '../../Services/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcGridAlign, IcGridItem, IcGridPadding, IcGridRow, IcLink, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableHead, IcTableRow, tableColWidthFr, RouteComponentProps, withRouter, IcPageContent, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';


export interface BillsPageProps extends RouteComponentProps, WithTranslation
{
}


interface BillsPageFormData
{
    date_start: string;
    date_end:   string;
}


interface BillsPageState
{
    initialFormData:    BillsPageFormData;
    ownUser:            UserV1 | null;
    bills:              Array<BillV1>;
    hasMore:            boolean;
    loading:            boolean;
    error:              Error | null;
}


class $BillsPage extends React.Component<BillsPageProps, BillsPageState>
{
    private readonly BULK_SIZE          = 50;
    private readonly _purchaseService:  PurchaseService;
    private readonly _userService:      UserService;
    

    constructor ( props: BillsPageProps )
    {
        super(props);

        this.state = {
            initialFormData:    {
                date_start:     '',
                date_end:       ''
            },
            ownUser:    null,
            bills:      [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._userService = UserService.getInstance();
        this._purchaseService = PurchaseService.getInstance();

        this._loadMore = this._loadMore.bind(this);
        this._submit = this._submit.bind(this);
        this._showCsv = this._showCsv.bind(this);
    }


    private async _load ( formData: BillsPageFormData | null ): Promise<void>
    {
        try
        {
            this.setState({
                bills:      [],
                error:      null,
                loading:    true
            });

            const bills = await this._purchaseService.getBills(
                {
                    date_start: formData?.date_start?.trim() ? DayJS(formData?.date_start).format('YYYY-MM-DD') : null,
                    date_end:   formData?.date_end?.trim() ? DayJS(formData?.date_end).format('YYYY-MM-DD') : null
                },
                0,
                this.BULK_SIZE
            );

            this.setState({
                bills,
                initialFormData: {
                    date_start: formData?.date_start?.trim() ? DayJS(formData?.date_start).format('YYYY-MM-DD') : '',
                    date_end:   formData?.date_end?.trim() ? DayJS(formData?.date_end).format('YYYY-MM-DD') : ''
                },
                hasMore:    bills.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading bills: ${(err as Error).message}`, err);

            this.setState({
                bills:      [],
                loading:    false,
                error:      err as Error,
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const bills = await this._purchaseService.getBills(
                {
                    date_start: this.state.initialFormData?.date_start?.trim() ? DayJS(this.state.initialFormData?.date_start).format('YYYY-MM-DD') : null,
                    date_end:   this.state.initialFormData?.date_end?.trim() ? DayJS(this.state.initialFormData?.date_end).format('YYYY-MM-DD') : null
                },
                this.state.bills.length,
                this.BULK_SIZE
            );

            this.setState({
                bills:      [...this.state.bills, ...bills],
                hasMore:    bills.length >= this.BULK_SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more bills: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _submit ( formData: BillsPageFormData ): Promise<void>
    {
        await this._load(formData);
    }


    private async _showPdf ( billUID: string ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const pdf = await this._purchaseService.getBillPdf(billUID);

            const url = URL.createObjectURL(pdf);

            window.open(url, '_blank');

            this.setState({
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error downloading bill: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _showCsv ( ): void
    {
        const url = this._purchaseService.getBillCsvURL({
            date_start: this.state.initialFormData.date_start?.trim() ? DayJS(this.state.initialFormData.date_start).format('YYYY-MM-DD') : null,
            date_end:   this.state.initialFormData.date_end?.trim() ? DayJS(this.state.initialFormData.date_end).format('YYYY-MM-DD') : null,
        });

        window.open(url, '_blank');
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });

        await this._load(null);
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        const MyFormik = Formik<BillsPageFormData>;

        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('billspage.txt_bills')}
                    hidden={true}
                />

                <IcCard padding={IcCardPadding.Small}>
                    <IcText size={IcTextSize.Heading1}>
                        {this.props.t('billspage.txt_bills')}
                    </IcText>

                    <MyFormik
                        initialValues={this.state.initialFormData}
                        onSubmit={this._submit}
                        enableReinitialize={true}>
                        <Form>
                            <IcGridRow
                                padding={IcGridPadding.Small}
                                align={IcGridAlign.Center}>
                                <IcGridItem s={6} m={5} l={3}>
                                    <InputDate
                                        name='date_start'
                                        label={this.props.t('billspage.inp_date_start')}
                                    />
                                </IcGridItem>

                                <IcGridItem s={6} m={5} l={3}>
                                    <InputDate
                                        name='date_end'
                                        label={this.props.t('billspage.inp_date_end')}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={5} l={3}>
                                    <IcButton
                                        type='submit'
                                        fullWidth={true}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faSearch} />

                                        {this.props.t('billspage.btn_filter')}
                                    </IcButton>
                                </IcGridItem>

                                {this.state.ownUser && isAdmin(this.state.ownUser) ?
                                    <IcGridItem s={12} m={5} l={3}>
                                        <IcButton
                                            type='button'
                                            fullWidth={true}
                                            color={IcButtonColor.Secondary}
                                            onClick={this._showCsv}>
                                            <FontAwesomeIcon icon={faDownload} />

                                            {this.props.t('billspage.btn_download_csv')}
                                        </IcButton>
                                    </IcGridItem>
                                : null}
                            </IcGridRow>
                        </Form>
                    </MyFormik>

                    <IcErrorBox error={this.state.error} />

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                tableColWidthFr(1.5),
                                tableColWidthFr(1),
                                tableColWidthFr(1),
                                tableColWidthFr(1.5)
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        {this.props.t('billspage.txt_col_bill')}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {this.props.t('billspage.txt_col_date')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('billspage.txt_col_total')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('billspage.txt_col_action')}
                                    </IcTableCell>
                                </IcTableRow>
                            </IcTableHead>
                
                            <IcTableBody>
                                {this.state.bills.map( ( bill ) => (
                                    <IcTableRow key={bill.uid}>
                                        <IcTableCell>
                                            {bill.file.filename}
                                        </IcTableCell>

                                        <IcTableCell>
                                            {DayJS(bill.date_bill).format('YYYY-MM-DD')}
                                        </IcTableCell>
                                        
                                        <IcTableCell align={IcTableCellAlign.Right}>
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcLink onClick={ ( ) => this._showPdf(bill.uid) }>
                                                {this.props.t('billspage.btn_download')}

                                                <FontAwesomeIcon icon={faFilePdf} />
                                            </IcLink>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.bills.length === 0 && !this.state.loading ?
                                    <IcTableRow>
                                        <IcTableCell
                                            disabled={true}
                                            colSpan={4}
                                            align={IcTableCellAlign.Center}>
                                            {this.props.t('billspage.txt_no_bills')}
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    } 
}


export const BillsPage = withTranslation()(withRouter($BillsPage));
