import { BackendService } from './BackendService';


export enum LogV1Type
{
    UserAdded                       = 'USER_ADDED',
    UserUpdated                     = 'USER_UPDATED',
    UserLocked                      = 'USER_LOCKED',
    UserUnlocked                    = 'USER_UNLOCKED',
    UserPasswordResetted            = 'USER_PASSWORD_RESETTED',
    UserDeleted                     = 'USER_DELETED',
	UserLoggedIn                    = 'USER_LOGGEDIN',
	UserLoggedOut                   = 'USER_LOGGEDOUT',
	UserSfaAdded                    = 'USERSFA_ADDED',
	UserSfaDeleted                  = 'USERSFA_DELETED',
    OrganisationAdded               = 'ORGANISATION_ADDED',
    OrganisationUpdated             = 'ORGANISATION_UPDATED',
	OrganisationDeleted             = 'ORGANISATION_DELETED',
	PaymentMethodAdded              = 'PAYMENTMETHOD_ADDED',
	PaymentMethodSetDefault         = 'PAYMENTMETHOD_SET_DEFAULT',
	PaymentMethodDeleted            = 'PAYMENTMETHOD_DELETED',
    OrganisationMemberAdded         = 'ORGANISATIONMEMBER_ADDED',
    OrganisationMemberUpdated       = 'ORGANISATIONMEMBER_UPDATED',
    OrganisationLicenseAssigned     = 'ORGANISATIONLICENSE_ASSIGNED',
    OrganisationLicenseUnassigned   = 'ORGANISATIONLICENSE_UNASSIGNED',
    PurchaseAdded                   = 'PURCHASE_ADDED',
	PurchaseActivated               = 'PURCHASE_ACTIVATED',
	PurchaseUpdated                 = 'PURCHASE_UPDATED',
	PurchaseCanceled                = 'PURCHASE_CANCELED',
	PurchaseDeactivated             = 'PURCHASE_DEACTIVATED',
	ApplicationAdded                = 'APPLICATION_ADDED',
	ApplicationUpdated              = 'APPLICATION_UPDATED',
	ApplicationDeleted              = 'APPLICATION_DELETED',
	ApplicationCategoryAdded        = 'APPLICATIONCATEGORY_ADDED',
	ApplicationCategoryUpdated      = 'APPLICATIONCATEGORY_UPDATED',
	ApplicationCategoryDeleted      = 'APPLICATIONCATEGORY_DELETED',
    ClientAdded                     = 'CLIENT_ADDED',
	ClientUpdated                   = 'CLIENT_UPDATED',
	ClientDeleted                   = 'CLIENT_DELETED',
	ApiTokenAdded                   = 'APITOKEN_ADDED',
	ApiTokenUpdated                 = 'APITOKEN_UPDATED',
	ApiTokenDeleted                 = 'APITOKEN_DELETED',
	PackageAdded                    = 'PACKAGE_ADDED',
	PackageUpdated                  = 'PACKAGE_UPDATED',
	PackageDeleted                  = 'PACKAGE_DELETED',
	VoucherAdded                    = 'VOUCHER_ADDED',
	VoucherUpdated                  = 'VOUCHER_UPDATED',
	VoucherDeleted                  = 'VOUCHER_DELETED'
}


export interface LogV1User
{
    uid:        string;
    firstname:  string;
    lastname:   string;
    email:      string;
}


export interface LogV1
{
    uid:                string;
    type:               LogV1Type;
    user:               LogV1User | null;
    details:            string;
    datetime_created:   string;
}


export class LogService
{
    private static _instance: LogService;
    
    
    public static getInstance ( ): LogService
    {
        if ( ! this._instance )
        {
            this._instance = new LogService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getLogs ( from: number, size: number ): Promise<Array<LogV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/log?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.logs;
    }
}
