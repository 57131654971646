import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderAction } from '../../../Components/List/ListItemHeaderAction';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { OrganisationService, OrganisationV1 } from '../../../Services/OrganisationService';
import { ListEmpty } from '../../../Components/List/ListEmpty';
import { IcCard, IcCardPadding, IcErrorBox, IcSearchbar, IcSpinner, IcText } from '@indece-common/ic-ui-lib-react';


export interface UserAddPageOrganisationStepProps extends WithTranslation
{
    organisations:  Array<OrganisationV1>;
    onFinish:       ( organisation: OrganisationV1 ) => any;
}


interface UserAddPageOrganisationStepState
{
    organisations:  Array<OrganisationV1>;
    query:          string | null;
    loading:        boolean;
    error:          Error | null;
}


class $UserAddPageOrganisationStep extends React.Component<UserAddPageOrganisationStepProps, UserAddPageOrganisationStepState>
{
    private readonly _organisationService:  OrganisationService;


    constructor ( props: UserAddPageOrganisationStepProps )
    {
        super(props);

        this.state = {
            organisations:  [],
            query:          null,
            loading:        false,
            error:          null
        };

        this._organisationService = OrganisationService.getInstance();

        this._load = this._load.bind(this);
    }


    private async _load ( query: string | null ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const organisations = await this._organisationService.getOrganisations(
                {
                    query: query ? query.trim() : null
                },
                0,
                100
            );

            this.setState({
                organisations,
                query: query ? query.trim() : null,
                loading: false,
                error:  null
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisations: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this.setState({
            organisations: this.props.organisations
        });
    }


    public render ( )
    {
        return (
            <div className='UserAddPageOrganisationStep'>
                <IcCard padding={IcCardPadding.Large}>
                    <IcText>
                        {this.props.t('useraddpageorganisationstep.txt_select_organisation')}
                    </IcText>
                    
                    <IcErrorBox error={this.state.error} />

                    <IcSearchbar
                        onSearch={ ( _, query ) => this._load(query) }
                    />

                    <List>
                        {this.state.organisations.map( ( organisation ) => (
                            <ListItem key={organisation.uid}>
                                <ListItemHeader>
                                    <ListItemHeaderField
                                        text={organisation.name}
                                        grow={true}
                                    />

                                    <ListItemHeaderAction
                                        icon={faCaretRight}
                                        title={this.props.t('useraddpageorganisationstep.btn_select')}
                                        onClick={ ( ) => this.props.onFinish(organisation) }
                                    />
                                </ListItemHeader>
                            </ListItem>
                        ))}

                        {this.state.organisations.length === 0 ?
                            <ListEmpty>
                                {this.props.t('useraddpageorganisationstep.txt_no_organisations')}
                            </ListEmpty>
                        : null}
                    </List>
                
                    <IcSpinner active={this.state.loading} />
                </IcCard>
            </div>
        );
    }
}


export const UserAddPageOrganisationStep = withTranslation()($UserAddPageOrganisationStep);
