import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { IcChip } from '@indece-common/ic-ui-lib-react';


export interface LockedChipProps extends WithTranslation
{
}


class $LockedChip extends React.Component<LockedChipProps>
{
    public render ( )
    {
        return (
            <IcChip>
                <FontAwesomeIcon icon={faLock} />

                {this.props.t('lockedchip.txt_label')}
            </IcChip>
        );
    }
}


export const LockedChip = withTranslation()($LockedChip);
