import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserService, UserV1OrganisationRole } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { LocaleService } from '../../Services/LocaleService';
import { Locale } from '../../Model/Locale';
import { Gender } from '../../Model/Gender';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { UserAddPageMethodStep } from './UserAddPageMethodStep/UserAddPageMethodStep';
import { UserAddPageSuccessStep } from './UserAddPageSuccessStep/UserAddPageSuccessStep';
import { UserAddPageLoadingStep } from './UserAddPageLoadingStep/UserAddPageLoadingStep';
import { UserAddPageOrganisationStep } from './UserAddPageOrganisationStep/UserAddPageOrganisationStep';
import { UserAddPageDataStep, UserAddPageDataStepFormData } from './UserAddPageDataStep/UserAddPageDataStep';
import { IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface UserAddPageProps extends RouteComponentProps, WithTranslation
{
}


interface UserAddPageFormData
{
    invite:             boolean;
    organisation:       OrganisationV1 | null;
    organisation_role:  UserV1OrganisationRole | null;
    locale:             Locale;
    gender:             Gender;
    title:              string | null;
    firstname:          string;
    lastname:           string;
    email:              string;
    password:           string | null;
    password_confirm:   string | null;
}


enum UserAddPageStep
{
    Loading         = 'LOADING',
    Method          = 'METHOD',
    Organisation    = 'ORGANISATION',
    Data            = 'DATA',
    Success         = 'SUCCESS'
}


interface UserAddPageState
{
    currStep:           UserAddPageStep;
    organisations:      Array<OrganisationV1>;
    formData:           UserAddPageFormData;
    userUID:            string | null;
    error:              Error | null;
}


class $UserAddPage extends React.Component<UserAddPageProps, UserAddPageState>
{
    private readonly _userService:          UserService;
    private readonly _organisationService:  OrganisationService;
    private readonly _localeService:        LocaleService;
    

    constructor ( props: UserAddPageProps )
    {
        super(props);

        this._userService = UserService.getInstance();
        this._organisationService = OrganisationService.getInstance();
        this._localeService = LocaleService.getInstance();

        this.state = {
            currStep:       UserAddPageStep.Loading,
            formData:       {
                invite:             false,
                locale:             this._localeService.getLocale().get(),
                gender:             Gender.Male,
                title:              '',
                firstname:          '',
                lastname:           '',
                email:              '',
                password:           null,
                password_confirm:   null,
                organisation:       null,
                organisation_role:  UserV1OrganisationRole.Member
            },
            organisations:  [],
            userUID:        null,
            error:          null
        };

        this._cancel = this._cancel.bind(this);
        this._finishMethod = this._finishMethod.bind(this);
        this._finishOrganisation = this._finishOrganisation.bind(this);
        this._finishData = this._finishData.bind(this);
        this._finishSuccess = this._finishSuccess.bind(this);
        this._assignLicenses = this._assignLicenses.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private _finishMethod ( invite: boolean ): void
    {
        if ( this.state.organisations.length === 1 )
        {
            this.setState({
                currStep:   UserAddPageStep.Data,
                formData:   {
                    ...this.state.formData,
                    invite,
                    organisation:       this.state.organisations[0],
                    organisation_role:  UserV1OrganisationRole.Member
                }
            });
        }
        else
        {
            this.setState({
                currStep:   UserAddPageStep.Organisation,
                formData:   {
                    ...this.state.formData,
                    invite
                }
            });
        }
    }


    private _finishOrganisation ( organisation: OrganisationV1 ): void
    {
        this.setState({
            currStep:   UserAddPageStep.Data,
            formData:   {
                ...this.state.formData,
                organisation:       organisation,
                organisation_role:  UserV1OrganisationRole.Member
            }
        });
    }


    private async _finishData ( formData: UserAddPageDataStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   UserAddPageStep.Loading,
                error:      null,
                formData:   {
                    ...this.state.formData,
                    locale:                 formData.locale,
                    gender:                 formData.gender,
                    title:                  formData.title?.trim() || null,
                    firstname:              formData.firstname.trim(),
                    lastname:               formData.lastname.trim(),
                    email:                  formData.email.trim(),
                    password:               formData.password?.trim() || null,
                }
            });

            const result = await this._userService.addUser({
                locale:                 formData.locale,
                gender:                 formData.gender,
                title:                  formData.title?.trim() || null,
                firstname:              formData.firstname.trim(),
                lastname:               formData.lastname.trim(),
                email:                  formData.email.trim(),
                password:               formData.password?.trim() || null,
                global_roles:           null,
                organisation_uid:       this.state.formData.organisation ? this.state.formData.organisation.uid : null,
                organisation_name:      null,
                organisation_country:   null,
                organisation_role:      this.state.formData.organisation ? this.state.formData.organisation_role : null,
                invitation_code:        null,
                invitation_user_uid:    null,
                accept_marketing:       null
            });

            this.setState({
                error:      null,
                userUID:    result.user_uid,
                currStep:   UserAddPageStep.Success
            });
        }
        catch ( err )
        {
            console.error(`Error adding user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   UserAddPageStep.Data
            });
        }
    }


    private _finishSuccess ( ): void
    {
        this.props.router.navigate(-1);
    }


    private _assignLicenses ( ): void
    {
        if ( ! this.state.userUID )
        {
            return;
        }
    
        this.props.router.navigate(LinkUtils.make('user', this.state.userUID, 'license', 'assign'));
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   UserAddPageStep.Loading,
                error:      null
            });

            const organisations = await this._organisationService.getOrganisations({}, 0, 100); // TODO

            this.setState({
                currStep:   UserAddPageStep.Method,
                organisations,
                error:  null
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisations: ${(err as Error).message}`, err);

            this.setState({
                currStep:   UserAddPageStep.Loading,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }

    
    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('useraddpage.txt_add_user')} />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === UserAddPageStep.Loading ?
                    <UserAddPageLoadingStep />
                : null}

                {this.state.currStep === UserAddPageStep.Method ?
                    <UserAddPageMethodStep
                        onFinish={this._finishMethod}
                    />
                : null}

                {this.state.currStep === UserAddPageStep.Organisation ?
                    <UserAddPageOrganisationStep
                        organisations={this.state.organisations}
                        onFinish={this._finishOrganisation}
                    />
                : null}

                {this.state.currStep === UserAddPageStep.Data ?
                    <UserAddPageDataStep
                        organisation={this.state.formData.organisation}
                        invite={this.state.formData.invite}
                        formData={this.state.formData}
                        onCancel={this._cancel}
                        onFinish={this._finishData}
                    />
                : null}

                {this.state.currStep === UserAddPageStep.Success ?
                    <UserAddPageSuccessStep
                        onFinish={this._finishSuccess}
                        onAssignLicenses={this._assignLicenses}
                    />
                : null}
            </IcPageContent>
        );
    } 
}


export const UserAddPage = withTranslation()(withRouter($UserAddPage));
