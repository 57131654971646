import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserService, UserV1 } from '../../../Services/UserService';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeaderCheckbox } from '../../../Components/List/ListItemHeaderCheckbox';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { Formatter } from '../../../utils/Formatter';
import { Form, Formik } from 'formik';
import { FormObserver } from '../../../utils/FormUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { PackageV1 } from '../../../Services/PackageService';
import { ListEmpty } from '../../../Components/List/ListEmpty';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcChip, IcFloatRow, IcFloatRowAlign, IcGridAlign, IcGridItem, IcGridPadding, IcGridRow, IcInputText, IcSearchbar, IcSpinner, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface PurchasePageFormStepAssignmentBoxAssignment
{
    uid:        string;
    user:       UserV1 | null;
    firstname:  string | null;
    lastname:   string | null;
    email:      string | null;
}


export interface PurchasePageFormStepAssignmentBoxProps extends WithTranslation
{
    organisation:   OrganisationV1;
    package:        PackageV1;
    count:          number;
    assignments:    Array<PurchasePageFormStepAssignmentBoxAssignment>;
    onChange:       ( assignments: Array<PurchasePageFormStepAssignmentBoxAssignment> ) => any;
}


interface PurchasePageFormStepAssignmentBoxInvitationFormData
{
    email:      string;
    firstname:  string;
    lastname:   string;
}


interface PurchasePageFormStepAssignmentBoxSelectFormData
{
    assignments:    Record<string, boolean>;
    users:          Record<string, boolean>;
}


interface PurchasePageFormStepAssignmentBoxState
{
    initialInviteFormData:  PurchasePageFormStepAssignmentBoxInvitationFormData;
    initialSelectFormData:  PurchasePageFormStepAssignmentBoxSelectFormData;
    users:                  Array<UserV1>;
    query:                  string | null;
    add:                    boolean;
    loading:                boolean;
    error:                  Error | null;
}


class $PurchasePageFormStepAssignmentBox extends React.Component<PurchasePageFormStepAssignmentBoxProps, PurchasePageFormStepAssignmentBoxState>
{
    private readonly _userService:  UserService;


    constructor ( props: PurchasePageFormStepAssignmentBoxProps )
    {
        super(props);

        this.state = {
            initialInviteFormData: {
                email:      '',
                firstname:  '',
                lastname:   ''
            },
            initialSelectFormData: {
                assignments: {},
                users: {}
            },
            users:          [],
            query:          null,
            add:            false,
            loading:        true,
            error:          null
        };

        this._userService = UserService.getInstance();

        this._load = this._load.bind(this);
        this._showAdd = this._showAdd.bind(this);
        this._selectFormChange = this._selectFormChange.bind(this);
        this._submitInvite = this._submitInvite.bind(this);
    }


    private _showAdd ( ): void
    {
        this.setState({
            add:    true
        });
    }


    private async _load ( query: string | null ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const users = await this._userService.getUsers(
                {
                    query: query ? query.trim() : null,
                    organisation_uid: this.props.organisation.uid
                },
                0,
                100
            );

            this.setState({
                users,
                query: query ? query.trim() : null,
                loading: false,
                error:  null
            });
        }
        catch ( err )
        {
            console.error(`Error loading users: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _submitInvite ( formData: PurchasePageFormStepAssignmentBoxInvitationFormData ): void
    {
        if ( !formData.email.trim() )
        {
            return;
        }

        const assignmentUID = crypto.randomUUID();

        this.props.onChange([
            ...this.props.assignments,
            {
                uid:        assignmentUID,
                user:       null,
                email:      formData.email.trim(),
                firstname:  formData.firstname.trim(),
                lastname:   formData.lastname.trim()
            }
        ]);

        this.setState({
            add:    false,
            initialInviteFormData: {
                email: '',
                firstname: '',
                lastname: ''
            },
            initialSelectFormData: {
                ...this.state.initialSelectFormData,
                assignments: {
                    ...this.state.initialSelectFormData.assignments,
                    [assignmentUID]: true
                }
            }
        });
    }


    private _selectFormChange ( formData: PurchasePageFormStepAssignmentBoxSelectFormData ): void
    {
        let assignments = this.props.assignments
            .filter( o => formData.assignments[o.uid] );

        const newAssignmentUIDs: Record<string, boolean> = {};

        for ( const userUID in formData.users )
        {
            if ( ! formData.users[userUID] )
            {
                continue;
            }

            const assignmentUID = crypto.randomUUID();

            assignments.push({
                uid:        assignmentUID,
                user:       this.state.users.find( o => o.uid === userUID )!,
                firstname:  null,
                lastname:   null,
                email:      null
            });

            newAssignmentUIDs[assignmentUID] = true
        }

        this.props.onChange(assignments);

        this.setState({
            initialSelectFormData: {
                assignments: {
                    ...formData.assignments,
                    ...newAssignmentUIDs
                },
                users: {}
            }
        });
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load(null);
    }


    public async componentDidUpdate ( prevProps: Readonly<PurchasePageFormStepAssignmentBoxProps> ): Promise<void>
    {
        if ( prevProps.organisation.uid !== this.props.organisation.uid )
        {
            this.setState({
                initialSelectFormData: {
                    assignments: {},
                    users: {}
                }
            });

            this.props.onChange([]);

            await this._load(null);
        }
    }


    public render ( )
    {
        const MyInviteFormik = Formik<PurchasePageFormStepAssignmentBoxInvitationFormData>;
        const MySelectFormik = Formik<PurchasePageFormStepAssignmentBoxSelectFormData>;

        const countGlobal = this.props.package.details.licenses.filter( o => !o.license.assignable ).reduce( ( s, o ) => (s + o.count), 0);
        const assignableLicenses = this.props.package.details.licenses.filter( o => o.license.assignable );
        const countMinAssignable = assignableLicenses.length > 0 ? Math.min(...assignableLicenses.map( o => o.count*this.props.count) ) : 0;
        const countAssigned = this.props.assignments.length;

        return (
            <IcCard padding={IcCardPadding.Medium}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('purchasepageformstepassignmentbox.txt_title')}
                </IcText>

                {countMinAssignable > 0 ?
                    <IcText>
                        {this.props.t('purchasepageformstepassignmentbox.txt_assign')}
                    </IcText>
                : null}

                {countGlobal > 0 ?
                    <>
                        <br />

                        <IcText>
                            {this.props.t('purchasepageformstepassignmentbox.txt_global', {count: countGlobal})}
                        </IcText>
                    </>
                : null}

                {countMinAssignable > 0 ?
                    <>
                        <br />

                        <IcGridRow
                            padding={IcGridPadding.None}
                            align={IcGridAlign.Center}>
                            <IcGridItem s={6} m={7}>
                                <IcText>
                                    {this.props.t('purchasepageformstepassignmentbox.txt_count_assigned', {available: countMinAssignable, assigned: countAssigned})}
                                </IcText>
                            </IcGridItem>

                            <IcGridItem s={6} m={5}>
                                <IcSearchbar
                                    onSearch={ ( _, query ) => this._load(query) }
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <MySelectFormik
                            onSubmit={ ( ) => { }}
                            enableReinitialize={true}
                            initialValues={this.state.initialSelectFormData}>
                            <Form>
                                <FormObserver onChange={this._selectFormChange} />
                                
                                <List>
                                    {this.state.users.filter( o => !this.props.assignments.find( a => a.user?.uid === o.uid) ).map( ( user ) => (
                                        <ListItem key={user.uid}>
                                            <ListItemHeader>
                                                <ListItemHeaderCheckbox
                                                    name={`users.${user.uid}`}
                                                    disabled={countAssigned >= countMinAssignable}
                                                />

                                                <ListItemHeaderField
                                                    grow={true}>
                                                    {Formatter.username(user)}
                                                </ListItemHeaderField>

                                                <ListItemHeaderField
                                                    grow={true}
                                                    text={user.email}
                                                />
                                            </ListItemHeader>
                                        </ListItem>
                                    ))}

                                    <IcSpinner active={this.state.loading} />

                                    {!this.state.loading && this.state.users.length === 0 ?
                                        <ListEmpty>
                                            {this.props.t('purchasepageformstepassignmentbox.txt_no_users_found')}
                                        </ListEmpty>
                                    : null}

                                    {this.props.assignments.map( ( assignment ) => (
                                        <ListItem key={assignment.uid}>
                                            <ListItemHeader>
                                                <ListItemHeaderCheckbox
                                                    name={`assignments.${assignment.uid}`}
                                                />

                                                <ListItemHeaderField
                                                    grow={true}>
                                                    {assignment.user ? Formatter.username(assignment.user) : `${assignment.firstname} ${assignment.lastname}`}

                                                    {!assignment.user ?
                                                        <IcChip
                                                            label={this.props.t('purchasepageformstepassignmentbox.txt_new_user')}
                                                        />
                                                    : null}
                                                </ListItemHeaderField>

                                                <ListItemHeaderField
                                                    grow={true}
                                                    text={assignment.user?.email || assignment.email || '???'}
                                                />
                                            </ListItemHeader>
                                        </ListItem>
                                    ))}
                                </List>
                            </Form>
                        </MySelectFormik>

                        {this.state.add ?
                            <MyInviteFormik
                                initialValues={this.state.initialInviteFormData}
                                enableReinitialize={true}
                                onSubmit={this._submitInvite}>
                                <Form>
                                    <IcGridRow padding={IcGridPadding.Medium}>
                                        <IcGridItem s={12} m={4}>
                                            <IcInputText
                                                name='firstname'
                                                label={this.props.t('purchasepageformstepassignmentbox.inp_firstname')}
                                                required={true}
                                            />
                                        </IcGridItem>
                                            
                                        <IcGridItem s={12} m={4}>
                                            <IcInputText
                                                name='lastname'
                                                label={this.props.t('purchasepageformstepassignmentbox.inp_lastname')}
                                                required={true}
                                            />
                                        </IcGridItem>
                                            
                                        <IcGridItem s={12} m={4}>
                                            <IcInputText
                                                type='email'
                                                name='email'
                                                label={this.props.t('purchasepageformstepassignmentbox.inp_email')}
                                                required={true}
                                                validators={[
                                                    Validator.email
                                                ]}
                                            />
                                        </IcGridItem>
                                    </IcGridRow>

                                    <RequiredHint />

                                    <IcFloatRow align={IcFloatRowAlign.Right}>
                                        <IcButton
                                            type='submit'
                                            disabled={countAssigned >= countMinAssignable}>
                                            {this.props.t('purchasepageformstepassignmentbox.btn_add')}

                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </IcButton>
                                    </IcFloatRow>
                                </Form>
                            </MyInviteFormik>
                        : null}

                        {!this.state.add ?
                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton
                                    color={IcButtonColor.Secondary}
                                    onClick={this._showAdd}
                                    disabled={countAssigned >= countMinAssignable}>
                                    {this.props.t('purchasepageformstepassignmentbox.btn_show_add')}

                                    <FontAwesomeIcon icon={faPlusSquare} />
                                </IcButton>
                            </IcFloatRow>
                        : null}
                    </>
                : null}
            </IcCard>
        );
    }
}


export const PurchasePageFormStepAssignmentBox = withTranslation()($PurchasePageFormStepAssignmentBox);
