import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface AccountUpdatePasswordPageSuccessStepProps extends WithTranslation
{
}


class $AccountUpdatePasswordPageSuccessStep extends React.Component<AccountUpdatePasswordPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                message={this.props.t('accountupdatepasswordpagesuccessstep.txt_success')}
            />
        );
    }
}


export const AccountUpdatePasswordPageSuccessStep = withTranslation()($AccountUpdatePasswordPageSuccessStep);
