import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';

import './SecureConnectionTag.css';


export interface SecureConnectionTagProps extends WithTranslation
{
}


class $SecureConnectionTag extends React.Component<SecureConnectionTagProps>
{
    public render ( )
    {
        return (
            <div className='SecureConnectionTag'>
                <IcText size={IcTextSize.Small}>
                    <FontAwesomeIcon icon={faLock} />

                    <div className='SecureConnectionTag-label'>
                        {this.props.t('secureconnectiontag.txt_secure')}
                    </div>
                </IcText>
            </div>
        );
    }
}


export const SecureConnectionTag = withTranslation()($SecureConnectionTag);
