import React from 'react';
import { Form, Formik } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AuthContextProps, withAuth } from 'oidc-react';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AccountUpdateEmailPageConfirmEmailStepProps extends WithTranslation, AuthContextProps
{
    onCancel: ( ) => any;
    onFinish: ( formData: AccountUpdateEmailPageConfirmEmailStepFormData ) => any;
}


export interface AccountUpdateEmailPageConfirmEmailStepFormData
{
    code:   string;
}


interface AccountUpdateEmailPageConfirmEmailStepState
{
    initialFormData: AccountUpdateEmailPageConfirmEmailStepFormData;
}


class $AccountUpdateEmailPageConfirmEmailStep extends React.Component<AccountUpdateEmailPageConfirmEmailStepProps, AccountUpdateEmailPageConfirmEmailStepState>
{
    constructor ( props: AccountUpdateEmailPageConfirmEmailStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
               code:    ''
            }
        };

        this._submit = this._submit.bind(this);
    }


    private _submit ( values: AccountUpdateEmailPageConfirmEmailStepFormData ): void
    {
        this.props.onFinish(values);
    }


    public render ( )
    {
        const MyFormik  = Formik<AccountUpdateEmailPageConfirmEmailStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <MyFormik
                    onSubmit={this._submit}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('accountupdateemailpageconfirmemailstep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('accountupdateemailpageconfirmemailstep.txt_code')}
                        </IcText>

                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='code'
                                    label={this.props.t('accountupdateemailpageconfirmemailstep.inp_code')}
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                onClick={this.props.onCancel}
                                color={IcButtonColor.Link}>
                                {this.props.t('accountupdateemailpageconfirmemailstep.btn_cancel')}
                            </IcButton>

                            <IcButton type='submit'>
                                {this.props.t('accountupdateemailpageconfirmemailstep.btn_confirm')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const AccountUpdateEmailPageConfirmEmailStep = withTranslation()(withAuth($AccountUpdateEmailPageConfirmEmailStep));
