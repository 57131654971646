import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContactPageMessageStep, ContactPageMessageStepFormData } from './ContactPageMessageStep/ContactPageMessageStep';
import { ContactPageLoadingStep } from './ContactPageLoadingStep/ContactPageLoadingStep';
import { ContactPageSuccessStep } from './ContactPageSuccessStep/ContactPageSuccessStep';
import { Delay } from 'ts-delay';
import { MessageService } from '../../Services/MessageService';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface ContactPageProps extends WithTranslation, RouteComponentProps
{
}


enum ContactPageStep
{
    Loading     = 'LOADING',
    Message     = 'MESSAGE',
    Success     = 'SUCCESS'
}


interface ContactPageState
{
    step:           ContactPageStep;
    packageUID:     string | null;
    error:          Error | null;
}


class $ContactPage extends React.Component<ContactPageProps, ContactPageState>
{
    private readonly _messageService:   MessageService;


    constructor ( props: ContactPageProps )
    {
        super(props);

        this.state = {
            step:       ContactPageStep.Message,
            packageUID: null,
            error:      null
        };

        this._messageService = MessageService.getInstance();

        this._finishMessage = this._finishMessage.bind(this);
    }


    private async _finishMessage ( formData: ContactPageMessageStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   ContactPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._messageService.addMessage({
                email:          formData.email.trim() || null,
                phone:          formData.phone.trim() || null,
                name:           formData.name.trim() || null,
                message:        formData.message,
                package_uid:    this.state.packageUID
            })

            await delay.sleep();

            this.setState({
                step:   ContactPageStep.Success,
                error:  null
            });
        }
        catch ( err)
        {
            console.error(`Error sending contact message: ${(err as Error).message}`, err);

            this.setState({
                step:   ContactPageStep.Message,
                error:  err as Error
            });
        }
    }


    public componentDidMount ( ): void
    {
        const search = new URLSearchParams(this.props.router.location.search);
        const packageUID = search.get('package_uid') || '';
       
        this.setState({
            packageUID: packageUID || null
        });
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('contactpage.txt_contact')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === ContactPageStep.Loading ?
                    <ContactPageLoadingStep />
                : null}

                {this.state.step === ContactPageStep.Message ?
                    <ContactPageMessageStep
                        onFinish={this._finishMessage}
                    />
                : null}

                {this.state.step === ContactPageStep.Success ?
                    <ContactPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const ContactPage = withTranslation()(withRouter($ContactPage));
