import { IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';


export interface RequiredHintProps extends WithTranslation
{
}


class $RequiredHint extends React.Component<RequiredHintProps>
{
    public render ( )
    {
        return (
            <IcText size={IcTextSize.Small} className='RequiredHint'>
                * {this.props.t('requiredhint.txt_required')}
            </IcText>
        );
    }
}


export const RequiredHint = withTranslation()($RequiredHint);
