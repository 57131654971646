import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import { ApplicationCategoryV1, ApplicationService, ApplicationV1, ApplicationV1Asset, ApplicationV1AssetType, ApplicationV1AssetTypes } from '../../Services/ApplicationService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { InputTextarea } from '../../Components/Input/InputTextarea';
import { EnabledLocales, Locale, localizedMultiStringsToLocalizedStrings, localizedStringsToLocalizedMultiStrings } from '../../Model/Locale';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { Formatter } from '../../utils/Formatter';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { List } from '../../Components/List/List';
import { ListItemBody } from '../../Components/List/ListItemBody';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { InputFile } from '../../Components/Input/InputFile';
import { FileService } from '../../Services/FileService';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClientService, ClientV1 } from '../../Services/ClientService';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputMultiSelect, IcInputSelect, IcInputText, IcPageContent, IcSeparator, IcSpinner, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminEditApplicationRouteParams
{
    applicationUID:  string;
}


export interface AdminApplicationEditPageProps extends RouteComponentProps<AdminEditApplicationRouteParams>, WithTranslation, WithLocaleProps
{
}


interface AdminApplicationEditPageFormDataAsset
{
    file:       File | null;
    file_uid:   string | null;
    type:       string;
    locales:    Array<string>;
}


interface AdminApplicationEditPageFormDataLocalizedString
{
    locale: string;
    value:  string;
}


interface AdminApplicationEditPageFormData
{
    names:                      Array<AdminApplicationEditPageFormDataLocalizedString>;
    slogans:                    Array<AdminApplicationEditPageFormDataLocalizedString>;
    outlines:                   Array<AdminApplicationEditPageFormDataLocalizedString>;
    descriptions:               Array<AdminApplicationEditPageFormDataLocalizedString>;
    features:                   Array<AdminApplicationEditPageFormDataLocalizedString>;
    assets:                     Array<AdminApplicationEditPageFormDataAsset>;
    creator:                    string;
    home_uri:                   string;
    login_uri:                  string;
    support_uri:                string;
    applicationcategory_uid:    string;
    client_uid:                 string;
}


interface AdminApplicationEditPageState
{
    initialFormData:        AdminApplicationEditPageFormData;
    application:            ApplicationV1 | null;
    applicationCategories:  Array<ApplicationCategoryV1>;
    clients:                Array<ClientV1>;
    error:                  Error | null;
    loading:                boolean;
    success:                string | null;
}


class $AdminApplicationEditPage extends React.Component<AdminApplicationEditPageProps, AdminApplicationEditPageState>
{
    private readonly _fileService:          FileService;
    private readonly _clientService:        ClientService;
    private readonly _applicationService:   ApplicationService;
    

    constructor ( props: AdminApplicationEditPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                names:                      [],
                slogans:                    [],
                outlines:                   [],
                descriptions:               [],
                features:                   [],
                assets:                     [],
                creator:                    '',
                home_uri:                   '',
                login_uri:                  '',
                support_uri:                '',
                applicationcategory_uid:    '',
                client_uid:                 ''
            },
            application:            null,
            applicationCategories:  [],
            clients:                [],
            error:                  null,
            loading:                true,
            success:                null
        };

        this._fileService = FileService.getInstance();
        this._clientService = ClientService.getInstance();
        this._applicationService = ApplicationService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._update    = this._update.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const application = await this._applicationService.getApplication(this.props.router.params.applicationUID);
            const applicationCategories = await this._applicationService.getApplicationCategories(0, 100); // TODO
            const clients = await this._clientService.getClients(0, 100); // TODO

            this.setState({
                error:      null,
                loading:    false,
                application,
                applicationCategories,
                clients,
                initialFormData: {
                    names:          application.names.map( ( o ) => ({
                        locale: o.locale,
                        value:  o.value
                    })),
                    slogans:        application.slogans.map( ( o ) => ({
                        locale: o.locale,
                        value:  o.value
                    })),
                    outlines:       application.outlines.map( ( o ) => ({
                        locale: o.locale,
                        value:  o.value
                    })),
                    descriptions:   application.descriptions.map( ( o ) => ({
                        locale: o.locale,
                        value:  o.value
                    })),
                    features:       localizedMultiStringsToLocalizedStrings(application.features.map( ( o ) => ({
                        locale: o.locale,
                        values: o.values
                    }))),
                    assets:   application.assets.map( ( o ) => ({
                        file:       null,
                        file_uid:   o.file_uid,
                        type:       o.type,
                        locales:    o.locales
                    })),
                    creator:                    application.creator,
                    home_uri:                   application.home_uri,
                    login_uri:                  application.login_uri,
                    support_uri:                application.support_uri || '',
                    applicationcategory_uid:    application.applicationcategory_uid || '',
                    client_uid:                 application.client_uid || ''
                }
            });
        }
        catch ( err )
        {
            console.error(`Error loading application: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _update ( formData: AdminApplicationEditPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.application )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const assets: Array<ApplicationV1Asset> = [];
            for ( const asset of formData.assets )
            {
                if ( asset.locales.length === 0 || !asset.type )
                {
                    continue;
                }

                if ( !asset.file )
                {
                    if ( asset.file_uid )
                    {
                        assets.push({
                            file_uid:   asset.file_uid,
                            type:       asset.type as ApplicationV1AssetType,
                            locales:    asset.locales as Array<Locale>
                        });
                    }

                    continue;
                }

                const fileUID = await this._fileService.addFile({
                    file: asset.file
                });

                assets.push({
                    file_uid:   fileUID,
                    type:       asset.type as ApplicationV1AssetType,
                    locales:    asset.locales as Array<Locale>
                });
            }

            await this._applicationService.updateApplication(
                this.state.application.uid,
                {
                    names:          formData.names.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                        locale:         o.locale as Locale,
                        value:          o.value.trim()
                    })),
                    slogans:        formData.slogans.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                        locale:         o.locale as Locale,
                        value:          o.value.trim()
                    })),
                    outlines:       formData.outlines.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                        locale:         o.locale as Locale,
                        value:          o.value.trim()
                    })),
                    descriptions:   formData.descriptions.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                        locale:         o.locale as Locale,
                        value:          o.value.trim()
                    })),
                    features:   localizedStringsToLocalizedMultiStrings(formData.features.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                        locale:         o.locale as Locale,
                        value:          o.value.trim()
                    }))),
                    assets,
                    creator:                    formData.creator.trim(),
                    home_uri:                   formData.home_uri.trim(),
                    login_uri:                  formData.login_uri.trim(),
                    support_uri:                formData.support_uri.trim() || null,
                    applicationcategory_uid:    formData.applicationcategory_uid,
                    client_uid:                 formData.client_uid || null
                }
            );

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('adminapplicationeditpage.txt_success')
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'applications'));
        }
        catch ( err )
        {
            console.error(`Error updating application: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<AdminApplicationEditPageFormData>;

        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'applications')}
                        label={this.props.t('adminapplicationspage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'application', this.props.router.params.applicationUID, 'edit')}
                        label={this.props.t('adminapplicationeditpage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle title={this.props.t('adminapplicationeditpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._update}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    {({ values }) => (
                        <Form>
                            <IcCard padding={IcCardPadding.Small}>
                                <FieldArray name='names'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading2}>
                                                {this.props.t('adminapplicationeditpage.txt_names')}
                                            </IcText>

                                            <List>
                                                {values.names.map( ( name, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`names.${index}.locale`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_name_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>
                                                                
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`names.${index}.value`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_name_name')}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationeditpage.btn_delete_name')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.names.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationeditpage.txt_no_names')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />

                                                    {this.props.t('adminapplicationeditpage.btn_add_name')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='slogans'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading2}>
                                                {this.props.t('adminapplicationeditpage.txt_slogans')}
                                            </IcText>

                                            <List>
                                                {values.slogans.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`slogans.${index}.locale`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_slogan_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>
                                                                
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`slogans.${index}.value`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_slogan_slogan')}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationeditpage.btn_delete_slogan')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.slogans.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationeditpage.txt_no_slogans')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminapplicationeditpage.btn_add_slogan')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='outlines'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading2}>
                                                {this.props.t('adminapplicationeditpage.txt_outlines')}
                                            </IcText>

                                            <List>
                                                {values.outlines.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`outlines.${index}.locale`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_outline_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationeditpage.btn_delete_outline')}
                                                            />
                                                        </ListItemHeader>

                                                        <ListItemBody>
                                                            <InputTextarea
                                                                name={`outlines.${index}.value`}
                                                                label={this.props.t('adminapplicationeditpage.inp_outline_outline')}
                                                                required={true}
                                                            />
                                                        </ListItemBody>
                                                    </ListItem>
                                                ))}

                                                {values.outlines.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationeditpage.txt_no_outlines')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminapplicationeditpage.btn_add_outline')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />
                                            
                                <FieldArray name='descriptions'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading2}>
                                                {this.props.t('adminapplicationeditpage.txt_descriptions')}
                                            </IcText>

                                            <List>
                                                {values.descriptions.map( ( name, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`descriptions.${index}.locale`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_description_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationeditpage.btn_delete_description')}
                                                            />
                                                        </ListItemHeader>

                                                        <ListItemBody>
                                                            <InputTextarea
                                                                name={`descriptions.${index}.value`}
                                                                label={this.props.t('adminapplicationeditpage.inp_description_description')}
                                                                required={true}
                                                            />
                                                        </ListItemBody>
                                                    </ListItem>
                                                ))}

                                                {values.descriptions.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationeditpage.txt_no_descriptions')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminapplicationeditpage.btn_add_description')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='features'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading2}>
                                                {this.props.t('adminapplicationeditpage.txt_features')}
                                            </IcText>

                                            <List>
                                                {values.features.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`features.${index}.locale`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_feature_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`features.${index}.value`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_feature_feature')}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationeditpage.btn_delete_feature')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.features.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationeditpage.txt_no_features')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminapplicationeditpage.btn_add_feature')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='assets'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading2}>
                                                {this.props.t('adminapplicationeditpage.txt_assets')}
                                            </IcText>

                                            <List>
                                                {values.assets.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputMultiSelect
                                                                    name={`assets.${index}.locales`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_asset_locales')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>
                                        
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`assets.${index}.type`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_asset_type')}
                                                                    options={ApplicationV1AssetTypes.map( ( assetType ) => ({
                                                                        label: assetType,
                                                                        value: assetType
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>
                                                                
                                                            <ListItemHeaderField
                                                                noPadding={true}
                                                                grow={true}>
                                                                <InputFile
                                                                    name={`assets.${index}.file`}
                                                                    label={this.props.t('adminapplicationeditpage.inp_asset_file')}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationeditpage.btn_delete_asset')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.assets.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationeditpage.txt_no_assets')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : null,
                                                        type: '',
                                                        file: null
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminapplicationeditpage.btn_add_asset')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label={this.props.t('adminapplicationeditpage.inp_creator')}
                                            name='creator'
                                            required={true}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label={this.props.t('adminapplicationeditpage.inp_home_uri')}
                                            name='home_uri'
                                            required={true}
                                        />
                                    </IcGridItem>
                                        
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label={this.props.t('adminapplicationeditpage.inp_login_uri')}
                                            name='login_uri'
                                            required={true}
                                        />
                                    </IcGridItem>
                                        
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label={this.props.t('adminapplicationeditpage.inp_support_uri')}
                                            name='support_uri'
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputSelect
                                            label={this.props.t('adminapplicationeditpage.inp_category')}
                                            name='applicationcategory_uid'
                                            options={this.state.applicationCategories.map( ( c ) => ({
                                                label:  c.names.find( o => o.locale === this.props.locale )?.value || '???',
                                                value:  c.uid
                                            }))}
                                            required={true}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputSelect
                                            label={this.props.t('adminapplicationeditpage.inp_client')}
                                            name='client_uid'
                                            options={this.state.clients.map( ( c ) => ({
                                                label:  c.client_id,
                                                value:  c.uid
                                            }))}
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        {this.props.t('adminapplicationeditpage.btn_cancel')}
                                    </IcButton>

                                    <IcButton
                                        type='submit'
                                        disabled={this.state.loading}>
                                        {this.props.t('adminapplicationeditpage.btn_update')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcCard>
                        </Form>
                    )}
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const AdminApplicationEditPage = withLocale(withTranslation()(withRouter($AdminApplicationEditPage)));
