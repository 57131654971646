import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { PackageService, PackageV1 } from '../../Services/PackageService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Formatter } from '../../utils/Formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcLink, IcPageContent, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { localeToCountry } from '../../Model/Country';
import { Environment } from '../../utils/Environment';


export interface AdminPackagesPageProps extends WithTranslation, WithLocaleProps
{
}


interface AdminPackagesPageState
{
    packages:   Array<PackageV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


class $AdminPackagesPage extends React.Component<AdminPackagesPageProps, AdminPackagesPageState>
{
    private readonly BULK_SIZE          = 50;
    private readonly _packageService:   PackageService;


    constructor ( props: AdminPackagesPageProps )
    {
        super(props);

        this.state = {
            packages:   [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._packageService = PackageService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const packages = await this._packageService.getPackages(
                localeToCountry(this.props.locale) || Environment.server.default_country,
                0,
                this.BULK_SIZE
            );

            this.setState({
                packages,
                hasMore:    packages.length >= this.BULK_SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading packages: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const packages = await this._packageService.getPackages(
                localeToCountry(this.props.locale) || Environment.server.default_country,
                this.state.packages.length,
                this.BULK_SIZE
            );

            this.setState({
                packages:   [...this.state.packages, ...packages],
                hasMore:    packages.length >= this.BULK_SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more packages: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public async componentDidUpdate ( prevProps: Readonly<AdminPackagesPageProps> ): Promise<void>
    {
        if ( prevProps.locale !== this.props.locale )
        {
            await this._load();
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'packages')}
                        label={this.props.t('adminpackagespage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminpackagespage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton
                            color={IcButtonColor.Secondary}
                            to={LinkUtils.make('admin', 'package', 'add')}>
                            <FontAwesomeIcon icon={faPlusCircle} />

                            {this.props.t('adminpackagespage.btn_add_package')}
                        </IcButton>
                    </IcFloatRow>

                    <IcErrorBox error={this.state.error} />
                    
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        {this.props.t('adminpackagespage.txt_col_name')}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {this.props.t('adminpackagespage.txt_col_licenses')}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {this.props.t('adminpackagespage.txt_col_status_visibility')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('adminpackagespage.txt_col_action')}
                                    </IcTableCell>
                                </IcTableRow>
                            </IcTableHead>

                            <IcTableBody>
                                {this.state.packages.map( ( pkg ) => (
                                    <IcTableRow key={pkg.uid}>
                                        <IcTableCell>
                                            {pkg.names.find( o => o.locale === this.props.locale)?.value || '???'}
                                        </IcTableCell>

                                        <IcTableCell>
                                            {pkg.details.licenses.map(Formatter.packageLicense).join(', ') || '-'}
                                        </IcTableCell>

                                        <IcTableCell>
                                            {Formatter.packageStatus(pkg.status)} / {Formatter.packageVisibility(pkg.visibility)}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcLink to={LinkUtils.make('admin', 'package', pkg.uid)}>
                                                <FontAwesomeIcon icon={faEye} />

                                                {this.props.t('adminpackagespage.btn_show')}
                                            </IcLink>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.packages.length === 0 && !this.state.loading ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={2}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            {this.props.t('adminpackagespage.txt_no_packages')}
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminPackagesPage = withLocale(withTranslation()($AdminPackagesPage));
