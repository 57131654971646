import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LicenseService, LicenseV1 } from '../../Services/LicenseService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminLicenseDeletePageLoadingStep } from './AdminLicenseDeletePageLoadingStep/AdminLicenseDeletePageLoadingStep';
import { AdminLicenseDeletePageSuccessStep } from './AdminLicenseDeletePageSuccessStep/AdminLicenseDeletePageSuccessStep';
import { AdminLicenseDeletePageConfirmStep } from './AdminLicenseDeletePageConfirmStep/AdminLicenseDeletePageConfirmStep';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminLicenseDeletePageRouteParams
{
    licenseUID: string;
}


export interface AdminLicenseDeletePageProps extends RouteComponentProps<AdminLicenseDeletePageRouteParams>, WithTranslation
{
}


enum AdminLicenseDeletePageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AdminLicenseDeletePageState
{
    step:       AdminLicenseDeletePageStep;
    license:    LicenseV1 | null;
    error:      Error | null;
}


class $AdminLicenseDeletePage extends React.Component<AdminLicenseDeletePageProps, AdminLicenseDeletePageState>
{
    private readonly _licenseService:  LicenseService;
    

    constructor ( props: AdminLicenseDeletePageProps )
    {
        super(props);

        this.state = {
            step:       AdminLicenseDeletePageStep.Loading,
            license:    null,
            error:      null
        };

        this._licenseService = LicenseService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminLicenseDeletePageStep.Loading,
                error:  null
            });

            const license = await this._licenseService.getLicense(this.props.router.params.licenseUID);

            this.setState({
                step:   AdminLicenseDeletePageStep.Confirm,
                license
            });
        }
        catch ( err )
        {
            console.error(`Error loading license: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.license )
        {
            return;
        }

        try
        {
            this.setState({
                step:   AdminLicenseDeletePageStep.Loading,
                error:  null
            });

            await this._licenseService.deleteLicense(this.state.license.uid);

            this.setState({
                step:   AdminLicenseDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting license: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminLicenseDeletePageStep.Confirm,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'licenses')}
                        label={this.props.t('adminlicensespage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'license', this.props.router.params.licenseUID, 'delete')}
                        label={this.props.t('adminlicensedeletepage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle
                    title={this.props.t('adminlicensedeletepage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminLicenseDeletePageStep.Loading ?
                    <AdminLicenseDeletePageLoadingStep />
                : null}

                {this.state.step === AdminLicenseDeletePageStep.Confirm && this.state.license ?
                    <AdminLicenseDeletePageConfirmStep
                        license={this.state.license}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === AdminLicenseDeletePageStep.Success ?
                    <AdminLicenseDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminLicenseDeletePage = withTranslation()(withRouter($AdminLicenseDeletePage));
