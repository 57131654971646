import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DeletePackageV1PriceMode, PackageService, PackageV1, PackageV1Price } from '../../Services/PackageService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { localeToCountry } from '../../Model/Country';
import { Environment } from '../../utils/Environment';
import { AdminPackagePriceDeletePageLoadingStep } from './AdminPackagePriceDeletePageLoadingStep/AdminPackagePriceDeletePageLoadingStep';
import { AdminPackagePriceDeletePageSuccessStep } from './AdminPackagePriceDeletePageSuccessStep/AdminPackagePriceDeletePageSuccessStep';
import { sleep } from 'ts-delay';
import { AdminPackagePriceDeletePageModeStep } from './AdminPackagePriceDeletePageModeStep/AdminPackagePriceDeletePageModeStep';
import { AdminPackagePriceDeletePageConfirmStep } from './AdminPackagePriceDeletePageConfirmStep/AdminPackagePriceDeletePageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminPackagePriceDeletePageRouteParams
{
    packageUID:         string;
    packagePriceUID:    string;
}


export interface AdminPackagePriceDeletePageProps extends RouteComponentProps<AdminPackagePriceDeletePageRouteParams>, WithTranslation, WithLocaleProps
{
}


enum AdminPackagePriceDeletePageStep
{
    Loading = 'LOADING',
    Mode    = 'MODE',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AdminPackagePriceDeletePageState
{
    currStep:       AdminPackagePriceDeletePageStep;
    mode:           DeletePackageV1PriceMode | null;
    package:        PackageV1 | null;
    packagePrice:   PackageV1Price | null;
    error:          Error | null;
}


class $AdminPackagePriceDeletePage extends React.Component<AdminPackagePriceDeletePageProps, AdminPackagePriceDeletePageState>
{
    private readonly _packageService:   PackageService;


    constructor ( props: AdminPackagePriceDeletePageProps )
    {
        super(props);

        this.state = {
            currStep:       AdminPackagePriceDeletePageStep.Loading,
            mode:           null,
            package:        null,
            packagePrice:   null,
            error:          null
        };

        this._packageService = PackageService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishMode = this._finishMode.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   AdminPackagePriceDeletePageStep.Loading,
                error:      null
            });

            const country = localeToCountry(this.props.locale) || Environment.server.default_country;
            const pkg = await this._packageService.getPackage(this.props.router.params.packageUID, country);
            const pkgPrice = pkg.details.prices.find( o => o.uid === this.props.router.params.packagePriceUID );
            if ( ! pkgPrice )
            {
                throw new Error('Price not found');
            }

            this.setState({
                package:        pkg,
                packagePrice:   pkgPrice,
                currStep:       AdminPackagePriceDeletePageStep.Mode
            });
        }
        catch ( err )
        {
            console.error(`Error loading package: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   AdminPackagePriceDeletePageStep.Loading
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishMode ( mode: DeletePackageV1PriceMode ): Promise<void>
    {
        this.setState({
            mode,
            currStep: AdminPackagePriceDeletePageStep.Confirm
        });
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( !this.state.package || !this.state.packagePrice || !this.state.mode )
        {
            return;
        }

        try
        {
            this.setState({
                currStep:   AdminPackagePriceDeletePageStep.Loading,
                error:      null
            });

            await this._packageService.deletePackagePrice(
                this.state.package.uid,
                this.state.packagePrice.uid,
                {
                    mode:                   this.state.mode,
                    new_packageprice_uid:   null  // TODO
                }
            );

            this.setState({
                currStep:   AdminPackagePriceDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting package price: ${(err as Error).message}`, err);

            this.setState({
                currStep:   AdminPackagePriceDeletePageStep.Confirm,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminpackagepricedeletepage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === AdminPackagePriceDeletePageStep.Loading ?
                    <AdminPackagePriceDeletePageLoadingStep />
                : null}

                {this.state.currStep === AdminPackagePriceDeletePageStep.Mode ?
                    <AdminPackagePriceDeletePageModeStep
                        onCancel={this._cancel}
                        onFinish={this._finishMode}
                    />
                : null}

                {this.state.currStep === AdminPackagePriceDeletePageStep.Confirm && this.state.packagePrice ?
                    <AdminPackagePriceDeletePageConfirmStep
                        packagePrice={this.state.packagePrice}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.currStep === AdminPackagePriceDeletePageStep.Success ?
                    <AdminPackagePriceDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminPackagePriceDeletePage = withTranslation()(withLocale(withRouter($AdminPackagePriceDeletePage)));
