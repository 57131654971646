import { BackendService } from './BackendService';


export interface MessageV1
{
    uid:            string;
    email:          string | null;
    phone:          string | null;
    name:           string | null;
    message:        string;
    package_uid:    string | null;
}


export interface AddMessageV1
{
    email:          string | null;
    phone:          string | null;
    name:           string | null;
    message:        string;
    package_uid:    string | null;
}


export class MessageService
{
    private static _instance: MessageService;
    
    
    public static getInstance ( ): MessageService
    {
        if ( ! this._instance )
        {
            this._instance = new MessageService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getMessages ( from: number, size: number ): Promise<Array<MessageV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/message?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.messages;
    }


    public async addMessage ( params: AddMessageV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/message`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
}
