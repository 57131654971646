import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RespAddSfaV1, UserSfaV1Type } from '../../../Services/UserService';
import { AuthContextProps, withAuth } from 'oidc-react';
import { Environment } from '../../../utils/Environment';
import { IcCard, IcCardPadding, IcSpinner } from '@indece-common/ic-ui-lib-react';


export interface AccountAddSfaPageSfaWebAuthNStepProps extends WithTranslation, AuthContextProps
{
    sfa:        RespAddSfaV1;
    sfaType:    UserSfaV1Type;
    onFinish:   ( webauthnCredentialID: string, publicKey: string, publicKeyAlogrithm: number, attestationObject: string ) => any;
    onError:    ( err: Error | null ) => any;
}


interface AccountAddSfaPageSfaWebAuthNStepState
{
}


class $AccountAddSfaPageSfaWebAuthNStep extends React.Component<AccountAddSfaPageSfaWebAuthNStepProps, AccountAddSfaPageSfaWebAuthNStepState>
{
    private _active:    boolean;


    constructor ( props: AccountAddSfaPageSfaWebAuthNStepProps )
    {
        super(props);

        this._generate = this._generate.bind(this);

        this._active = false;
    }


    private async _generate ( ): Promise<void>
    {
        if ( this._active )
        {
            return;
        }

        this._active = true;

        try
        {
            this.props.onError(null);

            if ( !this.props.userData ||
                !this.props.sfa.webauthn_challenge )
            {
                throw new Error('No user data or challenge provided');
            }

            const challenge = Uint8Array.from(this.props.sfa.webauthn_challenge, c => c.charCodeAt(0));

            const credential = await navigator.credentials.create({
                publicKey: {
                    challenge,
                    rp: {
                        name: Environment.server.name,
                        id: window.location.hostname,
                    },
                    user: {
                        id: Uint8Array.from(this.props.sfa.sfa_uid, c => c.charCodeAt(0)),
                        name: this.props.userData.profile.email || '',
                        displayName: this.props.userData.profile.name || '',
                    },
                    pubKeyCredParams: [
                        {
                            type: "public-key",
                            alg: -7 // "ES256" as registered in the IANA COSE Algorithms registry
                        },
                        /*{
                            type: "public-key",
                            alg: -257 // Value registered by this specification for "RS256"
                        }*/
                    ],
                    excludeCredentials: [],
                    timeout: 60000,
                    attestation: 'direct',
                    authenticatorSelection: {
                        requireResidentKey: false,
                        authenticatorAttachment: this.props.sfaType === UserSfaV1Type.WebAuthNCrossPlatform ? 'cross-platform' : 'platform',
                        userVerification: 'discouraged'
                    }
                }
            });

            if ( ! credential )
            {
                this._active = false;

                return;
            }

            const pubKeyCredential = (credential as PublicKeyCredential);
            const response = (pubKeyCredential.response as AuthenticatorAttestationResponse);

            const publicKey = response.getPublicKey();

            const attestationObjectBase64 = btoa(String.fromCharCode(...new Uint8Array(response.attestationObject)));
            const credentialIDBase64 = btoa(String.fromCharCode(...new Uint8Array(pubKeyCredential.rawId)));

            this.props.onFinish(
                credentialIDBase64,
                btoa(String.fromCharCode(...new Uint8Array(publicKey!))),
                response.getPublicKeyAlgorithm(),
                attestationObjectBase64
            );
        }
        catch ( err )
        {
            console.error(`Error creating credential: ${(err as Error).message}`, err);

            this.props.onError(err as Error);
        }
        finally
        {
            this._active = false;
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._generate();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcSpinner />
            </IcCard>
        );
    }
}


export const AccountAddSfaPageSfaWebAuthNStep = withTranslation()(withAuth($AccountAddSfaPageSfaWebAuthNStep));
