import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { ApplicationV1 } from '../../../Services/ApplicationService';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputCheckbox, IcInputMultiSelect, IcInputText, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AdminLicenseAddPageFormStepFormData
{
    tag:                string;
    name:               string;
    assignable:         boolean;
    application_uids:   Array<string>;
}


export interface AdminLicenseAddPageFormStepProps extends WithTranslation, WithLocaleProps
{
    applications:   Array<ApplicationV1>;
    onCancel:       ( ) => any;
    onFinish:       ( formData: AdminLicenseAddPageFormStepFormData ) => any;
}


interface AdminLicenseAddPageFormStepState
{
    initialFormData:    AdminLicenseAddPageFormStepFormData;
}


class $AdminLicenseAddPageFormStep extends React.Component<AdminLicenseAddPageFormStepProps, AdminLicenseAddPageFormStepState>
{
    constructor ( props: AdminLicenseAddPageFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                tag:                '',
                name:               '',
                assignable:         true,
                application_uids:   []
            }
        };
    }


    public render ( )
    {
        const MyFormik = Formik<AdminLicenseAddPageFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='tag'
                                    label={this.props.t('adminlicenseaddpageformstep.inp_tag')}
                                    testID='adminlicenseaddpageformstep.inp_tag'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('adminlicenseaddpageformstep.inp_name')}
                                    testID='adminlicenseaddpageformstep.inp_name'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    label={this.props.t('adminlicenseaddpageformstep.inp_assignable')}
                                    name='assignable'
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputMultiSelect
                                    label={this.props.t('adminlicenseaddpageformstep.inp_applications')}
                                    name='application_uids'
                                    options={this.props.applications.map( ( application ) => ({
                                        label:  application.names.find( o => o.locale === this.props.locale )?.value || '???',
                                        value:  application.uid
                                    }))}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}
                                testID='adminlicenseaddpageformstep.btn_cancel'>
                                {this.props.t('adminlicenseaddpageformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'
                                testID='adminlicenseaddpageformstep.btn_update'>
                                {this.props.t('adminlicenseaddpageformstep.btn_add')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminLicenseAddPageFormStep = withLocale(withTranslation()($AdminLicenseAddPageFormStep));
