import React from 'react';
import { Form, Formik } from 'formik';
import { Country, EnabledCountries, localeToCountry } from '../../../Model/Country';
import { Formatter } from '../../../utils/Formatter';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { ContentFileV1Key } from '../../../Services/ContentService';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { WithLocaleProps, withLocale } from '../../../utils/withLocale';
import { Environment } from '../../../utils/Environment';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { IcButton, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridAlign, IcGridItem, IcGridPadding, IcGridRow, IcInputSelect, IcInputSelectItem, IcInputText, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface RegisterPageOrganisationStepProps extends WithTranslation, WithLocaleProps
{
    onFinish: ( formData: RegisterPageOrganisationStepFormData ) => any;
}


export interface RegisterPageOrganisationStepFormData
{
    name:       string;
    country:    Country;
}


interface RegisterPageOrganisationStepState
{
    initialFormData: RegisterPageOrganisationStepFormData;
}


class $RegisterPageOrganisationStep extends React.Component<RegisterPageOrganisationStepProps, RegisterPageOrganisationStepState>
{
    constructor ( props: RegisterPageOrganisationStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                name:       '',
                country:    localeToCountry(this.props.locale) || Environment.server.default_country
            }
        };

        this._register = this._register.bind(this);
    }


    private async _register ( values: RegisterPageOrganisationStepFormData ): Promise<void>
    {
        this.props.onFinish(values);
    }


    public render ( )
    {
        const MyFormik  = Formik<RegisterPageOrganisationStepFormData>;
        const optionsCountry: Array<IcInputSelectItem<Country>> = EnabledCountries.map( ( country ) => ({
            label:  Formatter.country(country),
            value:  country
        })).sort( ( a, b ) => a.label.localeCompare(b.label) );

        return (
            <IcCard padding={IcCardPadding.None}>
                <MyFormik
                    onSubmit={this._register}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow padding={IcGridPadding.None} align={IcGridAlign.Stretch}>
                            <IcGridItem s={12} m={8} l={6} padding={IcGridPadding.Large}>
                                <IcText size={IcTextSize.Small} bold={true}>
                                    {this.props.t('registerpageorganisationstep.txt_subtitle')}
                                </IcText>

                                <IcText size={IcTextSize.Heading1}>
                                    {this.props.t('registerpageorganisationstep.txt_title')}
                                </IcText>

                                <IcText>
                                    {this.props.t('registerpageorganisationstep.txt_create_organisation')}
                                </IcText>

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='name'
                                            label={this.props.t('registerpageorganisationstep.inp_name')}
                                            required={true}
                                            validators={[
                                                Validator.minLength(1),
                                                Validator.maxLength(100)
                                            ]}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputSelect
                                            name='country'
                                            label={this.props.t('registerpageorganisationstep.inp_country')}
                                            options={optionsCountry}
                                            required={true}
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <RequiredHint />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton type='submit'>
                                        {this.props.t('registerpageorganisationstep.btn_continue')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcGridItem>

                            <IcGridItem s={0} m={4} l={6}>
                                <BoxImage>
                                    <ContentImage contentFileKey={ContentFileV1Key.RegisterOrganisationImage} />

                                    <SupportOverlay />
                                </BoxImage>
                            </IcGridItem>
                        </IcGridRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const RegisterPageOrganisationStep = withLocale(withTranslation()($RegisterPageOrganisationStep));
