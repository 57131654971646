import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TileButton } from '../../Components/TileButton/TileButton';
import { faAlignJustify, faBars, faCogs, faFileInvoice, faLaptopCode, faPlay, faShoppingCart, faSitemap, faTabletButton, faTabletScreenButton, faTags, faTemperatureHalf, faTicket, faUser } from '@fortawesome/free-solid-svg-icons';
import { faFile, faMessage } from '@fortawesome/free-regular-svg-icons';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcGridItem, IcGridRow, IcPageContent, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminPageProps extends WithTranslation
{
}


class $AdminPage extends React.Component<AdminPageProps>
{
    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle title={this.props.t('adminpage.txt_title')} />

                <IcGridRow>
                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faMessage}
                            title={this.props.t('adminpage.btn_messages_title')}
                            text={this.props.t('adminpage.btn_messages_text')}
                            linkTitle={this.props.t('adminpage.btn_messages_link')}
                            to={LinkUtils.make('admin', 'messages')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faFileInvoice}
                            title={this.props.t('adminpage.btn_bills_title')}
                            text={this.props.t('adminpage.btn_bills_text')}
                            linkTitle={this.props.t('adminpage.btn_bills_link')}
                            to={LinkUtils.make('bills')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faTicket}
                            title={this.props.t('adminpage.btn_vouchers_title')}
                            text={this.props.t('adminpage.btn_vouchers_text')}
                            linkTitle={this.props.t('adminpage.btn_vouchers_link')}
                            to={LinkUtils.make('admin', 'vouchers')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faTabletScreenButton}
                            title={this.props.t('adminpage.btn_applications_title')}
                            text={this.props.t('adminpage.btn_applications_text')}
                            linkTitle={this.props.t('adminpage.btn_applications_link')}
                            to={LinkUtils.make('admin', 'applications')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faTabletButton}
                            title={this.props.t('adminpage.btn_applicationcategories_title')}
                            text={this.props.t('adminpage.btn_applicationcategories_text')}
                            linkTitle={this.props.t('adminpage.btn_applicationcategories_link')}
                            to={LinkUtils.make('admin', 'applicationcategories')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faLaptopCode}
                            title={this.props.t('adminpage.btn_clients_title')}
                            text={this.props.t('adminpage.btn_clients_text')}
                            linkTitle={this.props.t('adminpage.btn_clients_link')}
                            to={LinkUtils.make('admin', 'clients')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faShoppingCart}
                            title={this.props.t('adminpage.btn_packages_title')}
                            text={this.props.t('adminpage.btn_packages_text')}
                            linkTitle={this.props.t('adminpage.btn_packages_link')}
                            to={LinkUtils.make('admin', 'packages')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faTags}
                            title={this.props.t('adminpage.btn_licenses_title')}
                            text={this.props.t('adminpage.btn_licenses_text')}
                            linkTitle={this.props.t('adminpage.btn_licenses_link')}
                            to={LinkUtils.make('admin', 'licenses')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faUser}
                            title={this.props.t('adminpage.btn_users_title')}
                            text={this.props.t('adminpage.btn_users_text')}
                            linkTitle={this.props.t('adminpage.btn_users_link')}
                            to={LinkUtils.make('admin', 'users')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faSitemap}
                            title={this.props.t('adminpage.btn_organisations_title')}
                            text={this.props.t('adminpage.btn_organisations_text')}
                            linkTitle={this.props.t('adminpage.btn_organisations_link')}
                            to={LinkUtils.make('organisations')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faAlignJustify}
                            title={this.props.t('adminpage.btn_contents_title')}
                            text={this.props.t('adminpage.btn_contents_text')}
                            linkTitle={this.props.t('adminpage.btn_contents_link')}
                            to={LinkUtils.make('admin', 'contents')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faFile}
                            title={this.props.t('adminpage.btn_contentfiles_title')}
                            text={this.props.t('adminpage.btn_contentfiles_text')}
                            linkTitle={this.props.t('adminpage.btn_contentfiles_link')}
                            to={LinkUtils.make('admin', 'contentfiles')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faCogs}
                            title={this.props.t('adminpage.btn_config_title')}
                            text={this.props.t('adminpage.btn_config_text')}
                            linkTitle={this.props.t('adminpage.btn_config_link')}
                            to={LinkUtils.make('admin', 'config')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faPlay}
                            title={this.props.t('adminpage.btn_jobs_title')}
                            text={this.props.t('adminpage.btn_jobs_text')}
                            linkTitle={this.props.t('adminpage.btn_jobs_link')}
                            to={LinkUtils.make('admin', 'jobs')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faBars}
                            title={this.props.t('adminpage.btn_logs_title')}
                            text={this.props.t('adminpage.btn_logs_text')}
                            linkTitle={this.props.t('adminpage.btn_logs_link')}
                            to={LinkUtils.make('admin', 'logs')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faTemperatureHalf}
                            title={this.props.t('adminpage.btn_status_title')}
                            text={this.props.t('adminpage.btn_status_text')}
                            linkTitle={this.props.t('adminpage.btn_status_link')}
                            to={LinkUtils.make('admin', 'status')}
                        />
                    </IcGridItem>
                </IcGridRow>
            </IcPageContent>
        );
    }
}


export const AdminPage = withTranslation()($AdminPage);
