import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserApiTokenV1, UserService } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { Formatter } from '../../utils/Formatter';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcPageContent, IcPopupMenu, IcPopupMenuItem, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';
import { ApplicationService, ApplicationV1 } from '../../Services/ApplicationService';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';


export interface DeveloperPageProps extends WithTranslation, WithLocaleProps
{
}


interface DeveloperPageState
{
    apiTokens:      Array<UserApiTokenV1>;
    applications:   Record<string, ApplicationV1>;
    error:          Error | null;
    loading:        boolean;
}


class $DeveloperPage extends React.Component<DeveloperPageProps, DeveloperPageState>
{
    private readonly _userService:          UserService;
    private readonly _applicationService:   ApplicationService;


    constructor ( props: DeveloperPageProps )
    {
        super(props);

        this.state = {
            apiTokens:      [],
            applications:   {},
            error:          null,
            loading:        true
        };

        this._userService = UserService.getInstance();
        this._applicationService = ApplicationService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const apiTokens = await this._userService.getOwnApiTokens();
            const applications: Record<string, ApplicationV1> = {};
            for ( const apiToken of apiTokens )
            {
                if ( applications[apiToken.application_uid] )
                {
                    continue;
                }

                const application = await this._applicationService.getApplication(apiToken.application_uid);
                applications[apiToken.application_uid] = application;
            }

            this.setState({
                apiTokens,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading api tokens: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('developerpage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcText size={IcTextSize.Heading3}>
                        {this.props.t('developerpage.txt_apitokens')}
                    </IcText>

                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton
                            color={IcButtonColor.Secondary}
                            to={LinkUtils.make('user', 'apitoken', 'add')}>
                            <FontAwesomeIcon icon={faPlusCircle} />

                            {this.props.t('developerpage.btn_add_apitoken')}
                        </IcButton>
                    </IcFloatRow>

                    <IcTable
                        cols={[
                            IcTableColWidthAuto,
                            IcTableColWidthAuto,
                            IcTableColWidthAuto,
                            IcTableColWidthAuto,
                            IcTableColWidthAuto
                        ]}>
                        <IcTableHead>
                            <IcTableCell>
                                {this.props.t('developerpage.txt_col_name')}
                            </IcTableCell>

                            <IcTableCell>
                                {this.props.t('developerpage.txt_col_application')}
                            </IcTableCell>

                            <IcTableCell>
                                {this.props.t('developerpage.txt_col_created')}
                            </IcTableCell>

                            <IcTableCell>
                                {this.props.t('developerpage.txt_col_last_used')}
                            </IcTableCell>

                            <IcTableCell align={IcTableCellAlign.Right}>
                                {this.props.t('developerpage.txt_col_action')}
                            </IcTableCell>
                        </IcTableHead>

                        <IcTableBody>
                            {this.state.apiTokens.map( ( apiToken ) => (
                                <IcTableRow key={apiToken.uid}>
                                    <IcTableCell>
                                        {apiToken.name}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {this.state.applications[apiToken.application_uid]?.names.find( o => o.locale === this.props.locale )?.value || '...'}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {Formatter.date(apiToken.datetime_created)}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {apiToken.datetime_last_used ? Formatter.date(apiToken.datetime_last_used) : this.props.t('developerpage.txt_last_used_never')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        <IcPopupMenu>
                                            <IcPopupMenuItem to={LinkUtils.make('user', 'apitoken', apiToken.uid, 'delete')}>
                                                {this.props.t('developerpage.btn_delete_apitoken')}
                                            </IcPopupMenuItem>
                                        </IcPopupMenu>
                                    </IcTableCell>
                                </IcTableRow>
                            ))}

                            {this.state.apiTokens.length === 0 && !this.state.loading ?
                                <IcTableRow>
                                    <IcTableCell
                                        colSpan={5}
                                        disabled={true}
                                        align={IcTableCellAlign.Center}>
                                        {this.props.t('developerpage.txt_no_apitokens')}
                                    </IcTableCell>
                                </IcTableRow>
                            : null}
                        </IcTableBody>
                    </IcTable>

                    <IcSpinner active={this.state.loading} />
                </IcCard>
            </IcPageContent>
        );
    }
}


export const DeveloperPage = withLocale(withTranslation()($DeveloperPage));
