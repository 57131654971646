import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { ClientService, ClientV1 } from '../../Services/ClientService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcPageContent, IcPopupMenu, IcPopupMenuItem, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminClientsPageProps extends WithTranslation
{
}


interface AdminClientsPageState
{
    clients:    Array<ClientV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


class $AdminClientsPage extends React.Component<AdminClientsPageProps, AdminClientsPageState>
{
    private readonly BULK_SIZE          = 50;
    private readonly _clientService:    ClientService;


    constructor ( props: AdminClientsPageProps )
    {
        super(props);

        this.state = {
            clients:    [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._clientService = ClientService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const clients = await this._clientService.getClients(
                0,
                this.BULK_SIZE
            );

            this.setState({
                clients,
                hasMore:    clients.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading clients: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }

    
    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const clients = await this._clientService.getClients(
                this.state.clients.length,
                this.BULK_SIZE
            );

            this.setState({
                clients:    [...this.state.clients, ...clients],
                hasMore:    clients.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading more clients: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'clients')}
                        label={this.props.t('adminclientspage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminclientspage.txt_title')} />
                
                <IcCard padding={IcCardPadding.Small}>
                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton
                            to={LinkUtils.make('admin', 'client', 'add')}
                            color={IcButtonColor.Secondary}>
                            <FontAwesomeIcon icon={faPlusCircle} />

                            {this.props.t('adminclientspage.btn_add_client')}
                        </IcButton>
                    </IcFloatRow>

                    <IcErrorBox error={this.state.error} />

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        {this.props.t('adminclientspage.txt_col_client_id')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('adminclientspage.txt_col_action')}
                                    </IcTableCell>
                                </IcTableRow>
                            </IcTableHead>

                            <IcTableBody>
                                {this.state.clients.map( ( client ) => (
                                    <IcTableRow key={client.uid}>
                                        <IcTableCell>
                                            {client.client_id}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcPopupMenu>
                                                <IcPopupMenuItem to={LinkUtils.make('admin', 'client', client.uid, 'edit')}>
                                                    {this.props.t('adminclientspage.btn_edit')}
                                                </IcPopupMenuItem>

                                                <IcPopupMenuItem to={LinkUtils.make('admin', 'client', client.uid, 'delete')}>
                                                    {this.props.t('adminclientspage.btn_delete')}
                                                </IcPopupMenuItem>
                                            </IcPopupMenu>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.clients.length === 0 && !this.state.loading ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={2}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            {this.props.t('adminclientspage.txt_no_clients')}
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>
                    </InfiniteScroll>

                    <IcSpinner active={this.state.loading} />
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminClientsPage = withTranslation()($AdminClientsPage);
