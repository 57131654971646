import React from 'react';
import { faCaretRight, faEnvelope, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderAction } from '../../../Components/List/ListItemHeaderAction';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { UserService, UserV1, isAdmin } from '../../../Services/UserService';
import { Formatter } from '../../../utils/Formatter';
import { Form, Formik } from 'formik';
import { ListEmpty } from '../../../Components/List/ListEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcChip, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcSearchbar, IcSpinner, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { InvitedChip } from '../../../Components/Chip/InvitedChip';
import { LockedChip } from '../../../Components/Chip/LockedChip';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface OrganisationMemberAddPageUserStepInviteFormData
{
    email:      string;
    firstname:  string;
    lastname:   string;
}


export interface OrganisationMemberAddPageUserStepProps extends WithTranslation
{
    organisation:   OrganisationV1;
    onCancel:       ( ) => any;
    onFinish:       ( user: UserV1 | null, invitation: OrganisationMemberAddPageUserStepInviteFormData | null ) => any;
}


interface OrganisationMemberAddPageUserStepState
{
    initialInviteFormData:  OrganisationMemberAddPageUserStepInviteFormData;
    users:                  Array<UserV1>;
    ownUser:                UserV1 | null;
    add:                    boolean;
    loading:                boolean;
    error:                  Error | null;
}


class $OrganisationMemberAddPageUserStep extends React.Component<OrganisationMemberAddPageUserStepProps, OrganisationMemberAddPageUserStepState>
{
    private readonly _userService:          UserService;


    constructor ( props: OrganisationMemberAddPageUserStepProps )
    {
        super(props);

        this.state = {
            initialInviteFormData: {
                email:      '',
                firstname:  '',
                lastname:   ''
            },
            users:      [],
            ownUser:    null,
            add:        false,
            loading:    true,
            error:      null
        };

        this._userService = UserService.getInstance();

        this._showAdd = this._showAdd.bind(this);
        this._load = this._load.bind(this);
        this._submitInvite = this._submitInvite.bind(this);
    }


    private _showAdd ( ): void
    {
        this.setState({
            add:    true
        });
    }


    private async _load ( query: string | null ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const users = await this._userService.getUsers(
                {
                    query
                },
                0, 
                10
            );

            this.setState({
                loading:    false,
                users
            });
        }
        catch ( err )
        {
            console.error(`Error loading users: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _submitInvite ( formData: OrganisationMemberAddPageUserStepInviteFormData ): void
    {
        if ( !formData.email.trim() )
        {
            return;
        }

        this.props.onFinish(null, formData);
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });

        await this._load(null);
    }


    public componentWillUnmount ( ): void 
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        const MyInviteFormik = Formik<OrganisationMemberAddPageUserStepInviteFormData>;
        const admin = this.state.ownUser && isAdmin(this.state.ownUser);

        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {admin ?
                        this.props.t('organisationmemberaddpageuserstep.txt_title_select')
                    :
                        this.props.t('organisationmemberaddpageuserstep.txt_title')
                    }
                </IcText>

                <IcText>
                    {admin ?
                        this.props.t('organisationmemberaddpageuserstep.txt_invite_select_user')
                    :
                        this.props.t('organisationmemberaddpageuserstep.txt_invite_user')
                    }
                </IcText>

                <IcErrorBox error={this.state.error} />

                {admin ?
                    <>
                        <IcSearchbar
                            onSearch={ ( _, query ) => this._load(query) }
                        />

                        <List>
                            {this.state.users.map( ( user ) => (
                                <ListItem key={user.uid}>
                                    <ListItemHeader>
                                        <ListItemHeaderField
                                            subtext={user.email}
                                            grow={true}>
                                            {Formatter.username(user)}
                                            
                                            {user.invitation_pending ? 
                                                <InvitedChip date={user.datetime_invited || ''} />
                                            : null}

                                            {user.organisations.find( o => o.uid === this.props.organisation.uid )?.accepted === false ? 
                                                <IcChip label={this.props.t('organisationmemberaddpageuserstep.txt_join_sent')} />
                                            : null}

                                            {user.locked ? 
                                                <LockedChip />
                                            : null}
                                        </ListItemHeaderField>

                                        {user.organisations.find( o => o.uid === this.props.organisation.uid ) ?
                                            <ListItemHeaderField
                                                text={this.props.t('organisationmemberaddpageuserstep.txt_already_in_organisation')}
                                            />
                                        :
                                            <ListItemHeaderAction
                                                icon={faCaretRight}
                                                title={this.props.t('organisationmemberaddpageuserstep.btn_select')}
                                                onClick={ ( ) => this.props.onFinish(user, null) }
                                            />
                                        }
                                    </ListItemHeader>
                                </ListItem>
                            ))}

                            {this.state.users.length === 0 && !this.state.loading ?
                                <ListEmpty>
                                    {this.props.t('organisationmemberaddpageuserstep.txt_no_users')}
                                </ListEmpty>
                            : null}
                        </List>

                        <IcSpinner active={this.state.loading} />
                    </>
                : null}

                {!admin || this.state.add ?
                    <MyInviteFormik
                        onSubmit={this._submitInvite}
                        initialValues={this.state.initialInviteFormData}
                        enableReinitialize={true}>
                        <Form>
                            <IcGridRow>
                                <IcGridItem s={12}>
                                    <IcInputText
                                        name='firstname'
                                        label={this.props.t('organisationmemberaddpageuserstep.inp_firstname')}
                                        required={true}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12}>
                                    <IcInputText
                                        name='lastname'
                                        label={this.props.t('organisationmemberaddpageuserstep.inp_lastname')}
                                        required={true}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12}>
                                    <IcInputText
                                        type='email'
                                        name='email'
                                        label={this.props.t('organisationmemberaddpageuserstep.inp_email')}
                                        required={true}
                                        validators={[
                                            Validator.email
                                        ]}
                                    />
                                </IcGridItem>
                            </IcGridRow>

                            <RequiredHint />

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton
                                    type='submit'
                                    disabled={this.state.loading}>
                                    {this.props.t('organisationmemberaddpageuserstep.btn_invite')}
                                    
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </IcButton>
                            </IcFloatRow>
                        </Form>
                    </MyInviteFormik>
                : null}

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('organisationmemberaddpageuserstep.btn_cancel')}
                    </IcButton>

                    {admin && !this.state.add ?
                        <IcButton
                            type='button'
                            onClick={this._showAdd}
                            color={IcButtonColor.Secondary}>
                            <FontAwesomeIcon icon={faPlusSquare} />

                            {this.props.t('organisationmemberaddpageuserstep.btn_invite')}
                        </IcButton>
                    : null}
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const OrganisationMemberAddPageUserStep = withTranslation()($OrganisationMemberAddPageUserStep);
