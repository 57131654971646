import { Form, Formik } from 'formik';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { InputTextarea } from '../../../Components/Input/InputTextarea';
import { Box } from '../../../Components/Box/Box';
import { BoxContent, BoxContentPadding } from '../../../Components/Box/BoxContent';
import { AuthContextProps, withAuth } from 'oidc-react';
import { ContentText } from '../../../Components/ContentText/ContentText';
import { ContentFileV1Key, ContentV1Key } from '../../../Services/ContentService';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { IcButton, IcGridItem, IcGridPadding, IcGridRow, IcInputText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface ContactPageMessageStepProps extends WithTranslation, AuthContextProps
{
    onFinish: ( formData: ContactPageMessageStepFormData ) => any;
}


export interface ContactPageMessageStepFormData
{
    name:       string;
    email:      string;
    phone:      string;
    message:    string;
}


interface ContactPageMessageStepState
{
    initialFormData:    ContactPageMessageStepFormData;
    readonly:           boolean;
}


class $ContactPageMessageStep extends React.Component<ContactPageMessageStepProps, ContactPageMessageStepState>
{
    constructor ( props: ContactPageMessageStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                name:       '',
                email:      '',
                phone:      '',
                message:    ''
            },
            readonly:   false
        };

        this._submit = this._submit.bind(this);
    }


    private _submit ( formData: ContactPageMessageStepFormData ): void
    {
        this.props.onFinish(formData);
    }


    public componentDidMount ( ): void
    {
        if ( this.props.userData )
        {
            this.setState({
                initialFormData: {
                    ...this.state.initialFormData,
                    name:   this.props.userData.profile.name || '',
                    email:  this.props.userData.profile.email || '',
                    phone:  this.props.userData.profile.phone_number || ''
                },
                readonly:   true
            });
        }
        else
        {
            this.setState({
                initialFormData: {
                    ...this.state.initialFormData,
                    name:   '',
                    email:  '',
                    phone:  ''
                },
                readonly:   false
            });
        }
    }


    public componentDidUpdate ( prevProps: Readonly<ContactPageMessageStepProps> ): void
    {
        if ( this.props.userData &&
             (!prevProps.userData || this.props.userData.profile.name !== prevProps.userData.profile.name) )
        {
            this.setState({
                initialFormData: {
                    ...this.state.initialFormData,
                    name:   this.props.userData.profile.name || '',
                    email:  this.props.userData.profile.email || '',
                    phone:  this.props.userData.profile.phone_number || '',
                },
                readonly:   true
            });
        }
    }


    public render ( )
    {
        const MyFormik = Formik<ContactPageMessageStepFormData>;
    
        return (
            <Box>
                <IcGridRow padding={IcGridPadding.None}>
                    <IcGridItem s={12} m={8} l={6}>
                        <BoxContent padding={BoxContentPadding.Large}>
                            <ContentText
                                contentKey={ContentV1Key.ContactSubtitle}
                                size={IcTextSize.Small}
                                bold={true}
                            />

                            <ContentText
                                contentKey={ContentV1Key.ContactTitle}
                                size={IcTextSize.Heading1}
                            />

                            <MyFormik
                                initialValues={this.state.initialFormData}
                                onSubmit={this._submit}
                                enableReinitialize={true}>
                                <Form>
                                    <IcGridRow>
                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='name'
                                                label={this.props.t('contactpagemessagestep.inp_name')}
                                                required={true}
                                                disabled={this.state.readonly}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='email'
                                                label={this.props.t('contactpagemessagestep.inp_email')}
                                                required={true}
                                                disabled={this.state.readonly}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='phone'
                                                label={this.props.t('contactpagemessagestep.inp_phone')}
                                                disabled={this.state.readonly}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <InputTextarea
                                                name='message'
                                                label={this.props.t('contactpagemessagestep.inp_message')}
                                                required={true}
                                            />
                                        </IcGridItem>
                                    </IcGridRow>

                                    <RequiredHint />

                                    <IcButton
                                        type='submit'
                                        fullWidth={true}>
                                        {this.props.t('contactpagemessagestep.btn_send')}
                                    </IcButton>
                                </Form>
                            </MyFormik>
                        </BoxContent>
                    </IcGridItem>

                    <IcGridItem s={0} m={4} l={6}>
                        <BoxImage>
                            <ContentImage contentFileKey={ContentFileV1Key.ContactImage} />

                            <SupportOverlay />
                        </BoxImage>
                    </IcGridItem>
                </IcGridRow>
            </Box>
        );
    }
}


export const ContactPageMessageStep = withAuth(withTranslation()($ContactPageMessageStep));
