import React from 'react';
import { PackageV1, PackageV1Price, PackageV1Status, calcAmountForInterval, isFreePrice, maxFreeInterval, minInterval, minPriceInterval } from '../../Services/PackageService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Formatter } from '../../utils/Formatter';
import { Country } from '../../Model/Country';
import { Locale } from '../../Model/Locale';
import { LocaleService } from '../../Services/LocaleService';
import { MarkdownBlock } from '../MarkdownBlock/MarkdownBlock';
import { FreeTag } from '../FreeTag/FreeTag';
import { FeatureTag } from '../FeatureTag/FeatureTag';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcGridItem, IcGridPadding, IcGridRow, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';

import './PackageTile.css';


export interface PackageTileProps extends WithTranslation
{
    country:            Country;
    package:            PackageV1;
    paymentInterval:    string;
    showActions?:       boolean;
    onSelect?:          ( price: PackageV1Price | null) => any;
    onRequestQuote?:    ( ) => any;
}


interface PackageTileState
{
    locale: Locale;
}


class $PackageTile extends React.Component<PackageTileProps, PackageTileState>
{
    private readonly _localeService:    LocaleService;


    constructor ( props: PackageTileProps )
    {
        super(props);

        this.state = {
            locale: LocaleService.getInstance().getLocale().get()
        };

        this._localeService = LocaleService.getInstance();
    }


    public componentDidMount ( ): void
    {
        this._localeService.getLocale().subscribe(this, ( locale ) =>
        {
            this.setState({
                locale
            });
        });

        const locale = this._localeService.getLocale().get();
        this.setState({
            locale
        });
    }


    public componentWillUnmount ( ): void
    {
        this._localeService.getLocale().unsubscribe(this);
    }


    public render ( )
    {
        const prices = this.props.package.details.prices.filter( o => 
            o.countries.includes(this.props.country)
        );
        const pricesWithInterval = prices.filter( o =>
            (!this.props.paymentInterval || (o.payment_interval === this.props.paymentInterval) || isFreePrice(o))
        );

        const price = pricesWithInterval[0] || null;

        const free = !!(price && isFreePrice(price));
        const minIntervalVal = minInterval(prices);
        const minPriceIntervalVal = minPriceInterval(pricesWithInterval);
        const maxFreeIntervalVal = maxFreeInterval(prices);
        const name = this.props.package.names.find( o => o.locale === this.state.locale)?.value || '???';
        const description = this.props.package.details.descriptions.find( o => o.locale === this.state.locale)?.value || null;
        const features = this.props.package.details.features.find( o => o.locale === this.state.locale)?.values || [];
        const pricePerMinInterval = minPriceIntervalVal && minIntervalVal ? calcAmountForInterval(minPriceIntervalVal.pricePeriod.gross || 0, minPriceIntervalVal.price.payment_interval, minIntervalVal) : 0;

        return (
            <IcCard className='PackageTile' padding={IcCardPadding.None}>
                <IcGridRow
                    padding={IcGridPadding.Medium}>
                    <IcGridItem s={12} className='PackageTile-header'>
                        {this.props.package.status === PackageV1Status.ComingSoon ?
                            <div
                                className='PackageTile-header-price'
                                data-testid='packagetile.header.price'>
                                {this.props.t('packagetile.txt_coming_soon')}
                            </div>
                        : null}

                        {this.props.package.status === PackageV1Status.Available && price ? 
                            <div
                                className='PackageTile-header-price'
                                data-testid='packagetile.header.price'>
                                {free ?
                                    this.props.t('packagetile.txt_package_free')
                                : (minPriceIntervalVal ?
                                    Formatter.currencyAmount(pricePerMinInterval, price.currency, minIntervalVal!)
                                : null)}
                            </div>
                        : null}

                        <IcText
                            size={IcTextSize.Heading2}
                            className='PackageTile-header-name'
                            testID='packagetile.header.name'>
                            {name}
                        </IcText>

                        <div className='PackageTile-header-description'>
                            <MarkdownBlock text={description} />
                        </div>
                    </IcGridItem>

                    <IcGridItem s={12} className='PackageTile-body'>
                        <div className='PackageTile-body-features'>
                            {features.map( ( feature, i ) => (
                                <FeatureTag
                                    key={i}
                                    name={feature}
                                />
                            ))}

                            {maxFreeIntervalVal ?
                                <FreeTag
                                    maxDuration={maxFreeIntervalVal}
                                />
                            : null}
                        </div>

                        {!free && minPriceIntervalVal ?
                            <IcText size={IcTextSize.Small} testID='packagetile.body.payment'>
                                {minPriceIntervalVal.price.periods.map( ( pricePeriod, i ) => (
                                    <div key={i}>
                                        {i > 0 ?
                                            this.props.t('packagetile.txt_after') + ' '
                                        : null}

                                        {pricePeriod.duration ?
                                            `${Formatter.duration(pricePeriod.duration)} `
                                        : null}

                                        {(pricePeriod.gross || 0) > 0 ?
                                            `${Formatter.currencyAmount(pricePeriod.gross || 0, price.currency, price.payment_interval)} ${Formatter.vatRate(price.rate_vat || 0, true)}`
                                        :
                                            this.props.t('packagetile.txt_package_free')
                                        }
                                    </div>
                                ))}
                            </IcText>
                        : null}
                    
                        <div className='PackageTile-body-actions'>
                            {this.props.package.status === PackageV1Status.Available && price && this.props.showActions !== false ?
                                <IcButton
                                    fullWidth={true}
                                    onClick={ ( ) => this.props.onSelect && this.props.onSelect(price)}
                                    color={IcButtonColor.Secondary}
                                    testID='packagetile.body.select'>
                                    {this.props.t('packagetile.btn_select')}
                                </IcButton>
                            : null}

                            {this.props.package.status === PackageV1Status.OnRequest && this.props.showActions !== false ?
                                <IcButton
                                    fullWidth={true}
                                    onClick={ ( ) => this.props.onRequestQuote && this.props.onRequestQuote()}>
                                    {this.props.t('packagetile.btn_request_quote')}
                                </IcButton>
                            : null}
                        </div>
                    </IcGridItem>
                </IcGridRow>
            </IcCard>
        );
    }
}


export const PackageTile = withTranslation()($PackageTile);
