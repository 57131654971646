import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PackageService, PackageV1 } from '../../Services/PackageService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { localeToCountry } from '../../Model/Country';
import { Environment } from '../../utils/Environment';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { Formatter } from '../../utils/Formatter';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { faCirclePlus, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcPageContent, IcSpinner, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminPackageDetailsPageRouteParams
{
    packageUID: string;
}


export interface AdminPackageDetailsPageProps extends RouteComponentProps<AdminPackageDetailsPageRouteParams>, WithTranslation, WithLocaleProps
{
}


interface AdminPackageDetailsPageState
{
    package:    PackageV1 | null;
    loading:    boolean;
    error:      Error | null;
}


class $AdminPackageDetailsPage extends React.Component<AdminPackageDetailsPageProps, AdminPackageDetailsPageState>
{
    private readonly _packageService:   PackageService;


    constructor ( props: AdminPackageDetailsPageProps )
    {
        super(props);

        this.state = {
            package:    null,
            loading:    true,
            error:      null
        };

        this._packageService = PackageService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const country = localeToCountry(this.props.locale) || Environment.server.default_country;
            const pkg = await this._packageService.getPackage(this.props.router.params.packageUID, country);

            this.setState({
                package:    pkg,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading package: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminpackagedetailspage.txt_title', {name: this.state.package?.names.find( o => o.locale === this.props.locale )?.value || '...'})} />

                <IcErrorBox error={this.state.error} />

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading2}>
                                {this.props.t('adminpackagedetailspage.txt_general')}
                            </IcText>

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                {this.state.package ?
                                    <IcButton
                                        color={IcButtonColor.Secondary}
                                        to={LinkUtils.make('admin', 'package', this.state.package.uid, 'edit')}>
                                        <FontAwesomeIcon icon={faPen} />

                                        {this.props.t('adminpackagedetailspage.btn_edit')}
                                    </IcButton>
                                : null}

                                {this.state.package ?
                                    <IcButton
                                        color={IcButtonColor.Secondary}
                                        to={LinkUtils.make('admin', 'package', this.state.package.uid, 'delete')}>
                                        <FontAwesomeIcon icon={faTrash} />

                                        {this.props.t('adminpackagedetailspage.btn_delete')}
                                    </IcButton>
                                : null}
                            </IcFloatRow>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading2}>
                                {this.props.t('adminpackagedetailspage.txt_prices')}
                            </IcText>

                            <List>
                                {(this.state.package?.details.prices || []).map( ( pkgPrice ) => (
                                    <ListItem key={pkgPrice.uid}>
                                        <ListItemHeader>
                                            <ListItemHeaderField
                                                text={pkgPrice.countries.map(Formatter.country).join(', ')}
                                                grow={true}
                                            />

                                            <ListItemHeaderField
                                                text={Formatter.interval(pkgPrice.payment_interval)}
                                                grow={true}
                                            />

                                            <ListItemHeaderAction
                                                icon={faPen}
                                                to={LinkUtils.make('admin', 'package', this.state.package!.uid, 'price', pkgPrice.uid, 'edit')}
                                            />

                                            <ListItemHeaderAction
                                                icon={faTrash}
                                                to={LinkUtils.make('admin', 'package', this.state.package!.uid, 'price', pkgPrice.uid, 'delete')}
                                            />
                                        </ListItemHeader>
                                    </ListItem>
                                ))}
                            </List>

                            <IcSpinner active={this.state.loading} />

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                {this.state.package ?
                                    <IcButton
                                        color={IcButtonColor.Secondary}
                                        to={LinkUtils.make('admin', 'package', this.state.package.uid, 'price', 'add')}>
                                        <FontAwesomeIcon icon={faCirclePlus} />

                                        {this.props.t('adminpackagedetailspage.btn_add_price')}
                                    </IcButton>
                                : null}
                            </IcFloatRow>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading3}>
                                {this.props.t('adminpackagedetailspage.txt_licenses')}
                            </IcText>

                            <List>
                                {(this.state.package?.details.licenses || []).map( ( pkgLicense ) => (
                                    <ListItem key={pkgLicense.license.uid}>
                                        <ListItemHeader>
                                            <ListItemHeaderField
                                                text={`${pkgLicense.count}x`}
                                                grow={true}
                                            />
            
                                            <ListItemHeaderField
                                                text={pkgLicense.license.name}
                                                grow={true}
                                            />

                                            <ListItemHeaderAction
                                                icon={faPen}
                                                to={LinkUtils.make('admin', 'package', this.state.package!.uid, 'license', pkgLicense.license.uid, 'edit')}
                                            />

                                            <ListItemHeaderAction
                                                icon={faTrash}
                                                to={LinkUtils.make('admin', 'package', this.state.package!.uid, 'license', pkgLicense.license.uid, 'delete')}
                                            />
                                        </ListItemHeader>
                                    </ListItem>
                                ))}
                            </List>

                            <IcSpinner active={this.state.loading} />

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                {this.state.package ?
                                    <IcButton
                                        color={IcButtonColor.Secondary}
                                        to={LinkUtils.make('admin', 'package', this.state.package.uid, 'license', 'add')}>
                                        <FontAwesomeIcon icon={faCirclePlus} />

                                        {this.props.t('adminpackagedetailspage.btn_add_license')}
                                    </IcButton>
                                : null}
                            </IcFloatRow>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>
            </IcPageContent>
        );
    }
}


export const AdminPackageDetailsPage = withTranslation()(withLocale(withRouter($AdminPackageDetailsPage)));
