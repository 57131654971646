import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { WithLocaleProps, withLocale } from '../../../utils/withLocale';
import { UserApiTokenV1 } from '../../../Services/UserService';
import { Formatter } from '../../../utils/Formatter';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface UserApiTokenDeletePageConfirmStepProps extends WithTranslation, WithLocaleProps
{
    userApiToken:   UserApiTokenV1;
    onCancel:       ( ) => any;
    onFinish:       ( ) => any;
}


class $UserApiTokenDeletePageConfirmStep extends React.Component<UserApiTokenDeletePageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('userapitokendeletepageconfirmstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('userapitokendeletepageconfirmstep.txt_delete_token')}
                </IcText>

                <List>
                    <ListItem>
                        <ListItemHeader>
                            <ListItemHeaderField
                                text={this.props.userApiToken.name}
                                subtext={this.props.t('userapitokendeletepageconfirmstep.txt_apitoken_name')}
                                grow={true}
                            />

                            <ListItemHeaderField
                                text={this.props.userApiToken.application_uid}
                                subtext={this.props.t('userapitokendeletepageconfirmstep.txt_apitoken_application')}
                                grow={true}
                            />

                            <ListItemHeaderField
                                text={Formatter.date(this.props.userApiToken.datetime_created)}
                                subtext={this.props.t('userapitokendeletepageconfirmstep.txt_apitoken_created')}
                                grow={true}
                            />

                            <ListItemHeaderField
                                text={this.props.userApiToken.datetime_last_used ? Formatter.date(this.props.userApiToken.datetime_last_used) : this.props.t('userapitokendeletepageconfirmstep.txt_apitoken_last_used_never')}
                                subtext={this.props.t('userapitokendeletepageconfirmstep.txt_apitoken_last_used')}
                                grow={true}
                            />
                        </ListItemHeader>
                    </ListItem>
                </List>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('userapitokendeletepageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}>
                        {this.props.t('userapitokendeletepageconfirmstep.btn_delete')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const UserApiTokenDeletePageConfirmStep = withLocale(withTranslation()($UserApiTokenDeletePageConfirmStep));
