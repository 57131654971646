import { AuthContextProps, withAuth } from 'oidc-react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';
import { IcButton, IcFloatRow, IcFloatRowAlign } from '@indece-common/ic-ui-lib-react';


export interface RegisterPageSuccessStepProps extends WithTranslation, AuthContextProps
{
}


class $RegisterPageSuccessStep extends React.Component<RegisterPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <div className='RegisterPageSuccessStep'>
                <SuccessBox
                    message={this.props.t('registerpagesuccessstep.txt_success')}
                />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton onClick={ ( ) => this.props.signIn()}>
                        {this.props.t('registerpagesuccessstep.btn_login')}
                    </IcButton>
                </IcFloatRow>
            </div>
        );
    }
}


export const RegisterPageSuccessStep = withTranslation()(withAuth($RegisterPageSuccessStep));
