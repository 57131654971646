import React from 'react';
import { Form, Formik } from 'formik';
import { ConfigPropertyV1Key, ConfigService } from '../../../Services/ConfigService';
import { IcButton, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { WithTranslation, withTranslation } from 'react-i18next';


export interface SetupConfigureSmtpStepProps extends WithTranslation
{
    onFinish:   ( ) => any;
}


interface SetupConfigureSmtpStepFormData
{
    host:       string;
    port:       string;
    user:       string;
    password:   string;
    from:       string;
}


interface SetupConfigureSmtpStepState
{
    initialFormData:    SetupConfigureSmtpStepFormData;
    loading:            boolean;
    error:              Error | null;
}


class $SetupConfigureSmtpStep extends React.Component<SetupConfigureSmtpStepProps, SetupConfigureSmtpStepState>
{
    private readonly _configService: ConfigService;


    constructor ( props: SetupConfigureSmtpStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                host:       '',
                port:       '',
                user:       '',
                password:   '',
                from:       ''
            },
            loading:    false,
            error:      null
        };

        this._configService = ConfigService.getInstance();

        this._submit = this._submit.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const config = await this._configService.getConfig();

            this.setState({
                initialFormData: {
                    host:       config.SMTP_HOST && config.SMTP_HOST.value ? config.SMTP_HOST.value : '',
                    port:       config.SMTP_PORT && config.SMTP_PORT.value ? config.SMTP_PORT.value : '',
                    user:       config.SMTP_USER && config.SMTP_USER.value ? config.SMTP_USER.value : '',
                    password:   config.SMTP_PASSWORD && config.SMTP_PASSWORD.value ? config.SMTP_PASSWORD.value : '',
                    from:       config.SMTP_FROM && config.SMTP_FROM.value ? config.SMTP_FROM.value : ''
                },
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _submit ( values: SetupConfigureSmtpStepFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.SmtpHost,
                values.host.trim()
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.SmtpPort,
                '' + parseInt(values.port, 10)
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.SmtpUser,
                values.user.trim()
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.SmtpPassword,
                values.password.trim()
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.SmtpFrom,
                values.from.trim()
            );

            this.setState({
                loading:    false
            });

            this.props.onFinish();
        }
        catch ( err )
        {
            console.error(`Error updating config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<SetupConfigureSmtpStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small} bold={true}>
                    {this.props.t('setupconfigurevatstep.txt_subtitle')}
                </IcText>

                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('setupconfigurevatstep.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <MyFormik
                    initialValues={this.state.initialFormData}
                    onSubmit={this._submit}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='host'
                                    label='Host'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='port'
                                    label='Port'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='user'
                                    label='User'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    type='password'
                                    name='password'
                                    label='Passwort'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='from'
                                    label='From'
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='submit'
                                disabled={this.state.loading}>
                                Save
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>

                <IcSpinner active={this.state.loading} />
            </IcCard>
        );
    }
}


export const SetupConfigureSmtpStep = withTranslation()($SetupConfigureSmtpStep);
