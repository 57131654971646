import { Form, Formik, FormikErrors } from 'formik';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface ResetPasswordPageCodeStepProps extends WithTranslation
{
    onFinish: ( formData: ResetPasswordPageCodeStepFormData ) => any;
}


export interface ResetPasswordPageCodeStepFormData
{
    code:               string;
    password:           string;
    password_confirm:   string;
}


interface ResetPasswordPageCodeStepState
{
    initialFormData:    ResetPasswordPageCodeStepFormData;
}


class $ResetPasswordPageCodeStep extends React.Component<ResetPasswordPageCodeStepProps, ResetPasswordPageCodeStepState>
{
    constructor ( props: ResetPasswordPageCodeStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                code:               '',
                password:           '',
                password_confirm:   ''
            }
        };

        this._validate = this._validate.bind(this);
        this._submit = this._submit.bind(this);
    }


    private _validate ( formData: ResetPasswordPageCodeStepFormData ): FormikErrors<ResetPasswordPageCodeStepFormData>
    {
        const errors: FormikErrors<ResetPasswordPageCodeStepFormData> = {};
 
        if ( formData.password.trim() !== formData.password_confirm.trim() )
        {
            errors.password = this.props.t('resetpasswordpagecodestep.txt_passwords_dont_match');
            errors.password_confirm = this.props.t('resetpasswordpagecodestep.txt_passwords_dont_match');
        }

        return errors;
    }


    private _submit ( formData: ResetPasswordPageCodeStepFormData ): void
    {
        this.props.onFinish(formData);
    }


    public render ( )
    {
        const MyFormik = Formik<ResetPasswordPageCodeStepFormData>;
    
        return (
            <IcCard padding={IcCardPadding.Large}>
                <MyFormik
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}
                    onSubmit={this._submit}
                    validate={this._validate}>
                    <Form>
                        <IcText size={IcTextSize.Small} bold={true}>
                            {this.props.t('resetpasswordpagecodestep.txt_subtitle')}
                        </IcText>

                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('resetpasswordpagecodestep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('resetpasswordpagecodestep.txt_insert_code_from_email')}
                        </IcText>

                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='code'
                                    autoComplete='off'
                                    label={this.props.t('resetpasswordpagecodestep.inp_code')}
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    type='password'
                                    name='password'
                                    autoComplete='off'
                                    label={this.props.t('resetpasswordpagenewpasswordstep.inp_password')}
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    type='password'
                                    name='password_confirm'
                                    autoComplete='off'
                                    label={this.props.t('resetpasswordpagenewpasswordstep.inp_password_confirm')}
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcText>
                                    {this.props.t('validator.password.policy', {length: Validator.PASSWORD_MIN_LENGTH})}
                                </IcText>
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton type='submit'>
                                {this.props.t('resetpasswordpagecodestep.btn_confirm')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const ResetPasswordPageCodeStep = withTranslation()($ResetPasswordPageCodeStep);
