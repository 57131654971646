import { Environment } from '../utils/Environment';


export enum Locale {
    DE_DE = 'de-DE',
    EN_GB = 'en-GB',
    EN_US = 'en-US',
}


export const Locales: Array<Locale> = [
    Locale.DE_DE,
    Locale.EN_GB,
    Locale.EN_US
];


export interface LocalizedString
{
    locale: Locale;
    value:  string;
}


export interface LocalizedMultiString
{
    locale: Locale;
    values: Array<string>;
}


export const EnabledLocales: Array<Locale> = Environment.server.locales.length > 0 ? 
        Locales.filter( ( l ) => Environment.server.locales.includes(l) )
    :
        Locales;


export function localizedStringsToLocalizedMultiStrings ( items: Array<LocalizedString> ): Array<LocalizedMultiString>
{
    const itemMap: Partial<Record<Locale, Array<string>>> = {};

    for ( const item of items )
    {
        if ( ! itemMap[item.locale as Locale] )
        {
            itemMap[item.locale as Locale] = [];
        }

        itemMap[item.locale as Locale]!.push(item.value);
    }

    const multiItems: Array<LocalizedMultiString> = [];
    for ( const key in itemMap )
    {
        multiItems.push({
            locale: key as Locale,
            values: itemMap[key as Locale]!
        });
    }

    return multiItems;
}


export function localizedMultiStringsToLocalizedStrings ( items: Array<LocalizedMultiString> ): Array<LocalizedString>
{
    const singleItems: Array<LocalizedString> = [];

    for ( const item of items )
    {
        for ( const value of item.values )
        {
            singleItems.push({
                locale: item.locale,
                value
            });
        }
    }

    return singleItems;
}
