import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ClientV1 } from '../../../Services/ClientService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText } from '@indece-common/ic-ui-lib-react';


export interface AdminClientDeletePageConfirmStepProps extends WithTranslation
{
    client:     ClientV1;
    onCancel:   ( ) => any;
    onFinish:   ( ) => any;
}


class $AdminClientDeletePageConfirmStep extends React.Component<AdminClientDeletePageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText>
                    {this.props.t('adminclientdeletepageconfirmstep.txt_confirm')}
                </IcText>
                
                <List>
                    <ListItem>
                        <ListItemHeader>
                            <ListItemHeaderField
                                grow={true}
                                text={this.props.client.client_id}
                            />
                        </ListItemHeader>
                    </ListItem>
                </List>
        
                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('adminclientdeletepageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}>
                        {this.props.t('adminclientdeletepageconfirmstep.btn_delete')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AdminClientDeletePageConfirmStep = withTranslation()($AdminClientDeletePageConfirmStep);
