import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { UserService, UserV1 } from '../../Services/UserService';
import { OrganisationMemberDeletePageLoadingStep } from './OrganisationMemberDeletePageLoadingStep/OrganisationMemberDeletePageLoadingStep';
import { OrganisationMemberDeletePageSuccessStep } from './OrganisationMemberDeletePageSuccessStep/OrganisationMemberDeletePageSuccessStep';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { sleep } from 'ts-delay';
import { OrganisationMemberDeletePageConfirmStep } from './OrganisationMemberDeletePageConfirmStep/OrganisationMemberDeletePageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface OrganisationMemberDeletePageRouteParams
{
    organisationUID:    string;
    userUID:            string;
}


export interface OrganisationMemberDeletePageProps extends WithTranslation, WithLocaleProps, RouteComponentProps<OrganisationMemberDeletePageRouteParams>
{
}


enum OrganisationMemberDeletePageStep
{
    Loading     = 'LOADING',
    Confirm     = 'CONFIRM',
    Success     = 'SUCCESS'
}


interface OrganisationMemberDeletePageState
{
    currStep:       OrganisationMemberDeletePageStep;
    organisation:   OrganisationV1 | null;
    user:           UserV1 | null;
    error:          Error | null;
}


class $OrganisationMemberDeletePage extends React.Component<OrganisationMemberDeletePageProps, OrganisationMemberDeletePageState>
{
    private readonly _userService:          UserService;
    private readonly _organisationService:  OrganisationService;


    constructor ( props: OrganisationMemberDeletePageProps )
    {
        super(props);

        this.state = {
            currStep:       OrganisationMemberDeletePageStep.Loading,
            user:           null,
            organisation:   null,
            error:          null
        };

        this._userService = UserService.getInstance();
        this._organisationService = OrganisationService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( !this.state.organisation || !this.state.user )
        {
            return;
        }
        
        try
        {
            this.setState({
                currStep:   OrganisationMemberDeletePageStep.Loading,
                error:      null
            });

            await this._organisationService.deleteOrganisationMember(
                this.state.organisation.uid,
                this.state.user.uid
            );

            this.setState({
                error:      null,
                currStep:   OrganisationMemberDeletePageStep.Success
            });

            await sleep(700);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting user from organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   OrganisationMemberDeletePageStep.Confirm
            });
        }
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   OrganisationMemberDeletePageStep.Loading,
                error:      null
            });

            const organisation = await this._organisationService.getOrganisation(this.props.router.params.organisationUID);
            const user = await this._userService.getUser(this.props.router.params.userUID);

            this.setState({
                currStep:   OrganisationMemberDeletePageStep.Confirm,
                organisation,
                user
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation and user: ${(err as Error).message}`, err);

            this.setState({
                currStep:   OrganisationMemberDeletePageStep.Loading,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('organisationmemberdeletepage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === OrganisationMemberDeletePageStep.Loading ?
                    <OrganisationMemberDeletePageLoadingStep />
                : null}

                {this.state.currStep === OrganisationMemberDeletePageStep.Confirm && this.state.user && this.state.organisation ?
                    <OrganisationMemberDeletePageConfirmStep
                        user={this.state.user}
                        organisation={this.state.organisation}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.currStep === OrganisationMemberDeletePageStep.Success ?
                    <OrganisationMemberDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const OrganisationMemberDeletePage = withLocale(withTranslation()(withRouter($OrganisationMemberDeletePage)));
