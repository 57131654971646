import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { UserService, UserV1 } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { Formatter } from '../../utils/Formatter';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcChip, IcErrorBox, IcGridAlign, IcGridItem, IcGridRow, IcPageContent, IcPopupMenu, IcPopupMenuItem, IcPopupMenuSeparator, IcSearchbar, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { InvitedChip } from '../../Components/Chip/InvitedChip';
import { LockedChip } from '../../Components/Chip/LockedChip';


export interface AccountUsersPageProps extends WithTranslation
{
}


interface AccountUsersPageState
{
    users:      Array<UserV1>;
    query:      string | null;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


class $AccountUsersPage extends React.Component<AccountUsersPageProps, AccountUsersPageState>
{
    private readonly BULK_SIZE      = 50;
    private readonly _userService:  UserService;


    constructor ( props: AccountUsersPageProps )
    {
        super(props);

        this.state = {
            users:      [],
            hasMore:    true,
            query:      null,
            loading:    true,
            error:      null
        };

        this._userService = UserService.getInstance();

        this._load = this._load.bind(this);
        this._loadMore = this._loadMore.bind(this);
    }

    
    private async _load ( query: string | null ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const users = await this._userService.getUsers(
                {
                    query: query ? query.trim() : null
                },
                0,
                this.BULK_SIZE
            );

            this.setState({
                users,
                query:      query ? query.trim() : null,
                hasMore:    users.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading users: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }

    
    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const users = await this._userService.getUsers(
                {
                    query: this.state.query ? this.state.query.trim() : null
                },
                this.state.users.length,
                this.BULK_SIZE
            );

            this.setState({
                users:       [...this.state.users, ...users],
                hasMore:    users.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading more users: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load(null);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('accountuserspage.txt_users')}
                    hidden={true}
                />

                <IcCard padding={IcCardPadding.Small}>
                    <IcGridRow align={IcGridAlign.Center}>
                        <IcGridItem s={12} m={12} l={4}>
                            <IcText size={IcTextSize.Heading1}>
                                {this.props.t('accountuserspage.txt_users')}
                            </IcText>
                        </IcGridItem>

                        <IcGridItem s={12} m={6} l={5}>
                            <IcSearchbar
                                onSearch={ ( _, query ) => this._load(query || null) }
                            />
                        </IcGridItem>

                        <IcGridItem s={12} m={4} l={3}>
                            <IcButton
                                color={IcButtonColor.Secondary}
                                to={LinkUtils.make('user', 'add')}>
                                <FontAwesomeIcon icon={faPlusSquare} />

                                {this.props.t('accountuserspage.btn_add')}
                            </IcButton>
                        </IcGridItem>
                    </IcGridRow>

                    <IcErrorBox error={this.state.error} />

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        {this.props.t('accountuserspage.txt_col_name')}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {this.props.t('accountuserspage.txt_col_email')}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {this.props.t('accountuserspage.txt_col_roles')}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {this.props.t('accountuserspage.txt_col_licenses')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('accountuserspage.txt_col_action')}
                                    </IcTableCell>
                                </IcTableRow>
                            </IcTableHead>

                            <IcTableBody>
                                {this.state.users.map( ( user ) => (
                                    <IcTableRow key={user.uid}>
                                        <IcTableCell to={LinkUtils.make('user', user.uid)}>
                                            {Formatter.username(user)}

                                            {user.invitation_pending ? 
                                                <InvitedChip date={user.datetime_invited || ''} />
                                            : null}

                                            {user.locked ? 
                                                <LockedChip />
                                            : null}
                                        </IcTableCell>

                                        <IcTableCell to={LinkUtils.make('user', user.uid)}>
                                            {user.email}
                                        </IcTableCell>

                                        <IcTableCell to={LinkUtils.make('user', user.uid)}>
                                            {user.organisations.map( o => (
                                                <IcChip
                                                    key={o.uid}
                                                    label={Formatter.userOrganisationRole(o.role)}
                                                />
                                            ))}
                                        </IcTableCell>

                                        <IcTableCell to={LinkUtils.make('user', user.uid)}>
                                            {user.licenses.map( o => o.license.name ).join(', ') || '-'}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcPopupMenu>
                                                <IcPopupMenuItem to={LinkUtils.make('user', user.uid)}>
                                                    {this.props.t('accountuserspage.btn_show')}
                                                </IcPopupMenuItem>

                                                <IcPopupMenuSeparator />

                                                <IcPopupMenuItem to={LinkUtils.make('user', user.uid, 'edit')}>
                                                    {this.props.t('accountuserspage.btn_edit')}
                                                </IcPopupMenuItem>

                                                <IcPopupMenuItem to={LinkUtils.make('user', user.uid, 'delete')}>
                                                    {this.props.t('accountuserspage.btn_delete')}
                                                </IcPopupMenuItem>
                                            </IcPopupMenu>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.users.length === 0 && !this.state.loading ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={5}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            {this.props.t('accountuserspage.txt_no_users')}
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AccountUsersPage = withTranslation()($AccountUsersPage);
