import React from 'react';

import './Box.css';


export enum BoxAlignment
{
    Vertical    = 'vertical',
    Horizontal  = 'horizontal'
}


export interface BoxProps
{
    alignment?: BoxAlignment;
    className?: string;
    children?:  React.ReactNode | undefined;
    onClick?:   ( ) => any;
}


export class Box extends React.Component<BoxProps>
{
    public render ( )
    {
        return (
            <div
                className={`Box alignment-${this.props.alignment || BoxAlignment.Horizontal} ${this.props.className || ''}`}
                onClick={this.props.onClick}>
                {this.props.children}
            </div>
        );
    }
}
