import React from 'react';
import DayJS from 'dayjs';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PackageV1, PackageService, PackageV1Visibility, PackageV1Status } from '../../Services/PackageService';
import { Formatter } from '../../utils/Formatter';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AuthContextProps, withAuth } from 'oidc-react';
import { PackageTiles } from '../../Components/PackageTile/PackageTiles';
import { PackageTile } from '../../Components/PackageTile/PackageTile';
import { TabButtons } from '../../Components/TabButtons/TabButtons';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { Country, localeToCountry } from '../../Model/Country';
import { UserService, UserV1 } from '../../Services/UserService';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { SessionService } from '../../Services/SessionService';
import { ContentService, ContentV1, ContentV1Key } from '../../Services/ContentService';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { MarkdownBlock } from '../../Components/MarkdownBlock/MarkdownBlock';
import { Box, BoxAlignment } from '../../Components/Box/Box';
import { BoxContent, BoxContentPadding } from '../../Components/Box/BoxContent';
import { ContentText } from '../../Components/ContentText/ContentText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Environment } from '../../utils/Environment';
import { DummyPackageTile } from '../../Components/PackageTile/DummyPackageTile';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcErrorBox, IcGridAlign, IcGridItem, IcGridPadding, IcGridRow, IcPageContent, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './PackagesPage.css';


export interface PackagesPageProps extends RouteComponentProps, WithTranslation, WithLocaleProps, AuthContextProps
{
}


interface PackagesPageState
{
    loading:            boolean;
    error:              Error | null;
    country:            Country;
    organisation:       OrganisationV1 | null;
    packages:           Array<PackageV1>;
    paymentIntervals:   Array<string>;
    pageText:           ContentV1 | null;
    interval:           string;
}


class $PackagesPage extends React.Component<PackagesPageProps, PackagesPageState>
{
    private readonly _contentService:       ContentService;
    private readonly _organisationService:  OrganisationService;
    private readonly _userService:          UserService;
    private readonly _packageService:       PackageService;
    private readonly _sessionService:       SessionService;


    constructor ( props: PackagesPageProps )
    {
        super(props);

        this.state = {
            packages:           [],
            pageText:           null,
            error:              null,
            country:            localeToCountry(props.locale) || Environment.server.default_country,
            organisation:       null,
            loading:            true,
            paymentIntervals:   [],
            interval:           ''
        };

        this._contentService = ContentService.getInstance();
        this._organisationService = OrganisationService.getInstance();
        this._userService = UserService.getInstance();
        this._packageService = PackageService.getInstance();
        this._sessionService = SessionService.getInstance();

        this._setInterval = this._setInterval.bind(this);
    }


    private _setInterval ( interval: string ): void
    {
        this.setState({
            interval
        });
    }


    private async _applyUser ( user: UserV1 | null ): Promise<void>
    {
        if ( !user || user.organisations.length === 0 )
        {
            this.setState({
                organisation:   null,
                country:        localeToCountry(this.props.locale) || Environment.server.default_country
            });

            return;
        }

        try
        {
            const organisation = await this._organisationService.getOrganisation(user.organisations[0].uid);

            this.setState({
                organisation:   organisation,
                country:        organisation.country
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation: ${(err as Error).message}`, err);
        }
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const pageText = await this._contentService.getContent(ContentV1Key.PackagesText, this.props.locale);
            let packages = await this._packageService.getPackages(this.state.country, 0, 100); // TODO
            packages = packages.filter( o =>
                (o.visibility === PackageV1Visibility.Public) &&
                (o.status === PackageV1Status.Available || o.status === PackageV1Status.ComingSoon || o.status === PackageV1Status.OnRequest)
            );
            const paymentIntervalsMap: Record<string, boolean> = {};
            for ( const pkg of packages )
            {
                for ( const pkgPrice of pkg.details.prices )
                {
                    paymentIntervalsMap[pkgPrice.payment_interval] = true;
                }
            }

            const paymentIntervals: Array<string> = [];
            for ( const paymentInterval in paymentIntervalsMap )
            {
                paymentIntervals.push(paymentInterval);
            }

            paymentIntervals.sort( ( a, b ) => DayJS.duration(a).asDays() - DayJS.duration(b).asDays() );

            this.setState({
                loading:    false,
                error:      null,
                packages,
                paymentIntervals,
                interval: paymentIntervals.length > 0 ? paymentIntervals[paymentIntervals.length - 1] : '',
                pageText
            });
        }
        catch ( err )
        {
            console.error(`Error loading packages: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      new Error(`Error loading packages: ${(err as Error).message}`)
            });
        }
    }


    private _selectPackage ( packageUID: string, packagePriceUID: string | null ): void
    {
        if ( ! packagePriceUID )
        {
            return;
        }

        if ( !this.props.userData || !this.props.userData.profile )
        {
            this._sessionService.setPendingPurchase({
                package_uid: packageUID,
                package_price_uid: packagePriceUID,
                organisation_uid: this.state.organisation?.uid || null,
                count: 1,
                voucher_code: null
            });

            this.props.router.navigate(LinkUtils.make('register'));

            return;
        }

        this.props.router.navigate(`/purchase?package_uid=${encodeURIComponent(packageUID)}&package_price_uid=${encodeURIComponent(packagePriceUID)}`);
    }


    private _requestQuote ( packageUID: string ): void
    {
        this.props.router.navigate(`/contact?type=quote&package_uid=${encodeURIComponent(packageUID)}`);
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, async ( user ) =>
        {
            await this._applyUser(user);
        });

        this.setState({
            country: this.state.organisation?.country || localeToCountry(this.props.locale) || Environment.server.default_country
        });
        
        const user = this._userService.isLoggedIn().get();
        await this._applyUser(user);

        await this._load();
    }


    public async componentDidUpdate ( prevProps: PackagesPageProps ): Promise<void>
    {
        if ( this.props.locale !== prevProps.locale )
        {
            this.setState({
                country: this.state.organisation?.country || localeToCountry(this.props.locale) || Environment.server.default_country
            });
        }
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('packages')}
                        label={this.props.t('packagespage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle
                    titleContentKey={ContentV1Key.PackagesSubtitle}
                    subtitleContentKey={ContentV1Key.PackagesTitle}
                />

                <IcErrorBox error={this.state.error} />

                <MarkdownBlock
                    className='PackagesPage-text'
                    text={this.state.pageText?.content}
                />

                {this.state.paymentIntervals.length > 1 ?
                    <TabButtons
                        value={this.state.interval}
                        options={this.state.paymentIntervals.map( ( paymentInterval ) => ({
                            label:  Formatter.interval(paymentInterval),
                            value:  paymentInterval
                        }))}
                        onSelect={this._setInterval}
                    />
                : null}

                <PackageTiles>
                    {this.state.loading ?
                        <DummyPackageTile />
                    : null}

                    {this.state.packages.map( ( pkg ) => (
                        <PackageTile
                            key={pkg.uid}
                            country={this.state.country}
                            package={pkg}
                            paymentInterval={this.state.interval}
                            onSelect={ ( pkgPrice ) => this._selectPackage(pkg.uid, pkgPrice?.uid || null)}
                            onRequestQuote={ ( ) => this._requestQuote(pkg.uid)}
                        />
                    ))}
                </PackageTiles>

                {this.state.packages.length === 0 && !this.state.loading ?
                    <ListEmpty>
                        {this.props.t('packagespage.txt_no_packages')}
                    </ListEmpty>
                : null}

                <IcGridRow>
                    <IcGridItem s={12}>
                        <Box
                            className='PackagesPage-contact'
                            alignment={BoxAlignment.Horizontal}>
                            <BoxContent
                                padding={BoxContentPadding.Medium}>
                                <IcGridRow padding={IcGridPadding.Small} align={IcGridAlign.Center}>
                                    <IcGridItem s={12} m={6} l={8}>
                                        <ContentText
                                            contentKey={ContentV1Key.PackagesContactTitle}
                                            size={IcTextSize.Heading3}
                                        />
                                        
                                        <ContentText
                                            contentKey={ContentV1Key.PackagesContactText}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12} m={6} l={4}>
                                        <div className='PackagesPage-contact-content-action'>
                                            <IcButton
                                                to={LinkUtils.make('contact')}
                                                color={IcButtonColor.Secondary}>
                                                {this.props.t('packagespage.btn_contact')}

                                                <FontAwesomeIcon icon={faAngleRight} />
                                            </IcButton>
                                        </div>
                                    </IcGridItem>
                                </IcGridRow>
                            </BoxContent>
                        </Box>
                    </IcGridItem>
                </IcGridRow>
            </IcPageContent>
        );
    }
}


export const PackagesPage = withLocale(withTranslation()(withAuth(withRouter($PackagesPage))));
