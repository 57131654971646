import React from 'react';
import MarkdownIt from 'markdown-it';

import './MarkdownBlock.css';


export interface MarkdownBlockProps
{
    text:       string | null | undefined;
    className?: string;
}


export class MarkdownBlock extends React.Component<MarkdownBlockProps>
{
    private readonly _markdown:     MarkdownIt;


    constructor ( props: MarkdownBlockProps )
    {
        super(props);

        this._markdown = new MarkdownIt({
            html: true
        });
    }


    public render ( )
    {
        return (
            <div className={`MarkdownBlock ${this.props.className || ''}`}>
                {this.props.text ?
                    <div dangerouslySetInnerHTML={{__html: this._markdown.render(this.props.text)}} />
                : null}
            </div>
        );
    }
}
