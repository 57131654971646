import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcCard, IcCardPadding } from '@indece-common/ic-ui-lib-react';

import './SuccessBox.css';


export interface SuccessBoxProps
{
    message?:   string | null;
    testID?:    string;
    children?:  React.ReactNode | null;
}


export class SuccessBox extends React.Component<SuccessBoxProps>
{
    public render ( )
    {
        if ( !this.props.message && !this.props.children )
        {
            return null;
        }

        return (
            <IcCard
                className='SuccessBox'
                padding={IcCardPadding.Large}>
                <div className='SuccessBox-icon'>
                    <FontAwesomeIcon icon={faCheck} />
                </div>

                <div
                    className='SuccessBox-message'
                    data-testid={this.props.testID}>
                    {this.props.message}
                    {this.props.children}
                </div>
            </IcCard>
        );
    }
}
