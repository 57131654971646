import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PurchaseService, PurchaseV1 } from '../../Services/PurchaseService';
import { PackageService, PackageV1, PackageV1Price } from '../../Services/PackageService';
import { OrganisationService, OrganisationV1, PaymentMethodV1 } from '../../Services/OrganisationService';
import { PurchasePageLoadingStep } from './PurchasePageLoadingStep/PurchasePageLoadingStep';
import { PurchasePageSuccessStep } from './PurchasePageSuccessStep/PurchasePageSuccessStep';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { sleep } from 'ts-delay';
import { SessionService } from '../../Services/SessionService';
import { UserService, UserV1OrganisationRole } from '../../Services/UserService';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { VoucherService, VoucherV1 } from '../../Services/VoucherService';
import { Environment } from '../../utils/Environment';
import { PurchasePageFormStep } from './PurchasePageFormStep/PurchasePageFormStep';
import { PurchasePageFormStepAssignmentBoxAssignment } from './PurchasePageFormStep/PurchasePageFormStepAssignmentBox';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './PurchasePage.css';


export interface PurchasePageRouteParams
{
    paymentMethodUID?:  string;
}


export interface PurchasePageProps extends RouteComponentProps<PurchasePageRouteParams>, WithTranslation, WithLocaleProps
{
}


enum PurchasePageStep
{
    Form    = 'FORM',
    Loading = 'LOADING',
    Success = 'SUCCESS'
}


interface PurchasePageState
{
    step:                   PurchasePageStep;
    organisation:           OrganisationV1 | null;
    package:                PackageV1 | null;
    packagePrice:           PackageV1Price | null;
    paymentMethod:          PaymentMethodV1 | null;
    voucher:                VoucherV1 | null;
    assignments:            Array<PurchasePageFormStepAssignmentBoxAssignment>;
    purchase:               PurchaseV1 | null;
    count:                  number;
    finished:               boolean;
    error:                  Error | null;
}


class $PurchasePage extends React.Component<PurchasePageProps, PurchasePageState>
{
    private readonly _packageService:       PackageService;
    private readonly _purchaseService:      PurchaseService;
    private readonly _organisationService:  OrganisationService;
    private readonly _userService:          UserService;
    private readonly _voucherService:       VoucherService;
    private readonly _sessionService:       SessionService;


    constructor ( props: PurchasePageProps )
    {
        super(props);

        this.state = {
            step:                   PurchasePageStep.Loading,
            package:                null,
            packagePrice:           null,
            organisation:           null,
            paymentMethod:          null,
            voucher:                null,
            assignments:            [],
            purchase:               null,
            count:                  1,
            finished:               false,
            error:                  null
        };

        this._packageService        = PackageService.getInstance();
        this._purchaseService       = PurchaseService.getInstance();
        this._organisationService   = OrganisationService.getInstance();
        this._userService           = UserService.getInstance();
        this._voucherService        = VoucherService.getInstance();
        this._sessionService        = SessionService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._updateCount = this._updateCount.bind(this);
        this._updateOrganisation = this._updateOrganisation.bind(this);
        this._updateVoucher = this._updateVoucher.bind(this);
        this._updatePaymentMethod = this._updatePaymentMethod.bind(this);
        this._updateAssignments = this._updateAssignments.bind(this);
        this._finishForm = this._finishForm.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( packageUID: string,
                          packagePriceUID: string,
                          organisationUID: string | null,
                          count: number,
                          voucherCode: string | null ): Promise<void>
    {
        try
        {
            this.setState({
                step:   PurchasePageStep.Loading,
                error:  null
            });

            let organisation: OrganisationV1 | null = null;
            if ( organisationUID )
            {
                organisation = await this._organisationService.getOrganisation(organisationUID);
            }

            const country = organisation?.country || Environment.server.default_country;
            const pkg = await this._packageService.getPackage(packageUID, country);
            const pkgPrice = pkg.details.prices.find( o => o.uid === packagePriceUID ) || null;
            if ( ! pkgPrice )
            {
                throw new Error('Package price not found');
            }

            let voucher: VoucherV1 | null = null;
            if ( voucherCode )
            {
                voucher = await this._voucherService.getVoucherByCode(voucherCode);
            }

            this._sessionService.setPendingPurchase({
                package_uid:        pkg.uid,
                package_price_uid:  pkgPrice?.uid,
                organisation_uid:   organisation?.uid || null,
                count:              count,
                voucher_code:       voucher?.code || null
            });

            this.setState({
                package: pkg,
                packagePrice: pkgPrice,
                organisation,
                count,
                step: PurchasePageStep.Form
            });
        }
        catch ( err )
        {
            console.error(`Error loading package & organisation: ${(err as Error).message}`, err);

            this.setState({
                error:  err as Error
            });
        }
    }


    private _updateCount ( count: number ): void
    {
        this.setState({
            count
        });
    }


    private _updateOrganisation ( organisation: OrganisationV1 ): void
    {
        this.setState({
            organisation
        });
    }


    private _updateVoucher ( voucher: VoucherV1 ): void
    {
        this.setState({
            voucher
        });
    }


    private _updatePaymentMethod ( paymentMethod: PaymentMethodV1 ): void
    {
        this.setState({
            paymentMethod
        });
    }


    private _updateAssignments ( assignments: Array<PurchasePageFormStepAssignmentBoxAssignment> ): void
    {
        this.setState({
            assignments
        });
    }


    private async _finishForm ( ): Promise<void>
    {
        try
        {
            if ( !this.state.package ||
                 !this.state.packagePrice ||
                 !this.state.organisation || 
                 !this.state.count )
            {
                throw new Error('Params missing');
            }

            this.setState({
                step:   PurchasePageStep.Loading,
                error:  null
            });

            const resp = await this._purchaseService.addPurchase({
                organisation_uid:   this.state.organisation.uid,
                package_uid:        this.state.package.uid,
                packageprice_uid:   this.state.packagePrice.uid,
                count:              this.state.count,
                voucher_code:       this.state.voucher?.code || null
            });

            this._sessionService.deletePendingPurchase();

            let purchase: PurchaseV1 | null = null;

            for ( let i = 0; i < 10; i++ )
            {
                purchase = await this._purchaseService.getPurchase(resp.purchase_uid);
                if ( purchase.datetime_activated )
                {
                    break;
                }

                await sleep(2000);
            }

            if ( ! purchase )
            {
                throw new Error('Purchase missing - why?');
            }

            if ( purchase!.datetime_activated )
            {
                // TODO: Notify user when activated
            }

            for ( const assignment of this.state.assignments )
            {
                let userUID: string;

                if ( assignment.user )
                {
                    userUID = assignment.user.uid;
                }
                else
                {
                    const addUserResult = await this._userService.addUser({
                        locale:                 this.props.locale,
                        gender:                 null,
                        title:                  null,
                        firstname:              assignment.firstname!,
                        lastname:               assignment.lastname!,
                        email:                  assignment.email!,
                        password:               null,
                        global_roles:           [],
                        organisation_country:   null,
                        organisation_name:      null,
                        organisation_uid:       this.state.organisation.uid,
                        organisation_role:      UserV1OrganisationRole.Member,
                        invitation_code:        null,
                        invitation_user_uid:    null,
                        accept_marketing:       null
                    });

                    userUID = addUserResult.user_uid;
                }

                for ( const license of purchase.package.details.licenses )
                {
                    await this._organisationService.assignOrganisationMemberLicense(
                        this.state.organisation.uid,
                        userUID,
                        {
                            license_uid: license.license.uid
                        }
                    );
                }
            }

            this.setState({
                purchase:   purchase,
                step:       PurchasePageStep.Success
            });

            await this._userService.load();

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('organisation', this.state.organisation.uid));
        }
        catch ( err )
        {
            console.error(`Error purchasing package: ${(err as Error).message}`, err);

            this.setState({
                step:   PurchasePageStep.Form,
                error:  err as Error,
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        const pendingPurchase = this._sessionService.getPendingPurchase();
        const search = new URLSearchParams(this.props.router.location.search);
    
        const packageUID = search.get('package_uid') || (pendingPurchase?.package_uid);
        const packagePriceUID = search.get('package_price_uid') || (pendingPurchase?.package_price_uid);
        const organisationUID = search.get('organisation_uid') || (pendingPurchase?.organisation_uid) || null;
        const count = search.get('count') || (pendingPurchase?.count);
        const voucherCode = search.get('voucher_code') || (pendingPurchase?.voucher_code) || null;

        if ( !packageUID || !packagePriceUID )
        {
            this.props.router.navigate(LinkUtils.make('packages'));
            
            return;
        }

        await this._load(
            packageUID,
            packagePriceUID,
            organisationUID,
            count ? parseInt(`${count}`, 10) : 1,
            voucherCode
        );
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('packages')}
                        label={this.props.t('packagespage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('purchase')}
                        label={this.props.t('purchasepage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle title={this.props.t('purchasepage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                {this.state.step === PurchasePageStep.Loading ?
                    <PurchasePageLoadingStep />
                : null}

                {this.state.step === PurchasePageStep.Form && this.state.package && this.state.packagePrice ?
                    <PurchasePageFormStep
                        package={this.state.package}
                        packagePrice={this.state.packagePrice}
                        organisation={this.state.organisation}
                        paymentMethod={this.state.paymentMethod}
                        count={this.state.count}
                        voucher={this.state.voucher}
                        assignments={this.state.assignments}
                        verifyPaymentMethodUID={this.props.router.params.paymentMethodUID || null}
                        onUpdateOrganisation={this._updateOrganisation}
                        onUpdatePaymentMethod={this._updatePaymentMethod}
                        onUpdateCount={this._updateCount}
                        onUpdateVoucher={this._updateVoucher}
                        onUpdateAssignments={this._updateAssignments}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.step === PurchasePageStep.Success ?
                    <PurchasePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const PurchasePage = withLocale(withTranslation()(withRouter($PurchasePage)));
