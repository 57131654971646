import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LicenseService, LicenseV1 } from '../../Services/LicenseService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminLicenseEditPageLoadingStep } from './AdminLicenseEditPageLoadingStep/AdminLicenseEditPageLoadingStep';
import { AdminLicenseEditPageSuccessStep } from './AdminLicenseEditPageSuccessStep/AdminLicenseEditPageSuccessStep';
import { AdminLicenseEditPageFormStep } from './AdminLicenseEditPageFormStep/AdminLicenseEditPageFormStep';
import { ApplicationService, ApplicationV1 } from '../../Services/ApplicationService';
import { AdminLicenseEditPageFormStepFormData } from './AdminLicenseEditPageFormStep/AdminLicenseEditPageFormStep';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminLicenseEditPageRouteParams
{
    licenseUID: string;
}


export interface AdminLicenseEditPageProps extends RouteComponentProps<AdminLicenseEditPageRouteParams>, WithTranslation
{
}


enum AdminLicenseEditPageStep
{
    Loading = 'LOADING',
    Form    = 'FORM',
    Success = 'SUCCESS'
}


interface AdminLicenseEditPageState
{
    step:           AdminLicenseEditPageStep;
    license:        LicenseV1 | null;
    applications:   Array<ApplicationV1>;
    error:          Error | null;
}


class $AdminLicenseEditPage extends React.Component<AdminLicenseEditPageProps, AdminLicenseEditPageState>
{
    private readonly _applicationService:   ApplicationService;
    private readonly _licenseService:       LicenseService;
    

    constructor ( props: AdminLicenseEditPageProps )
    {
        super(props);

        this.state = {
            step:           AdminLicenseEditPageStep.Loading,
            applications:   [],
            license:        null,
            error:          null
        };

        this._applicationService = ApplicationService.getInstance();
        this._licenseService = LicenseService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishForm = this._finishForm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminLicenseEditPageStep.Loading,
                error:  null
            });

            const license = await this._licenseService.getLicense(this.props.router.params.licenseUID);
            const applications = await this._applicationService.getApplications(0, 100); // TODO

            this.setState({
                step:   AdminLicenseEditPageStep.Form,
                license,
                applications
            });
        }
        catch ( err )
        {
            console.error(`Error loading license: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishForm ( formData: AdminLicenseEditPageFormStepFormData ): Promise<void>
    {
        if ( ! this.state.license )
        {
            return;
        }

        try
        {
            this.setState({
                step:   AdminLicenseEditPageStep.Loading,
                error:  null
            });

            await this._licenseService.updateLicense(
                this.state.license.uid,
                {
                    tag:                formData.tag.trim(),
                    name:               formData.name.trim(),
                    application_uids:   formData.application_uids
                }
            );

            this.setState({
                step:   AdminLicenseEditPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating license: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminLicenseEditPageStep.Form,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'licenses')}
                        label={this.props.t('adminlicensespage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'license', this.props.router.params.licenseUID, 'edit')}
                        label={this.props.t('adminlicenseeditpage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle
                    title={this.props.t('adminlicenseeditpage.txt_title')}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminLicenseEditPageStep.Loading ?
                    <AdminLicenseEditPageLoadingStep />
                : null}

                {this.state.step === AdminLicenseEditPageStep.Form && this.state.license ?
                    <AdminLicenseEditPageFormStep
                        license={this.state.license}
                        applications={this.state.applications}
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.step === AdminLicenseEditPageStep.Success ?
                    <AdminLicenseEditPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminLicenseEditPage = withTranslation()(withRouter($AdminLicenseEditPage));
