import React from 'react';
import { AuthContextProps, withAuth } from 'oidc-react';
import { Environment } from '../../utils/Environment';
import { BackendService } from '../../Services/BackendService';
import { UserV1OrganisationRole } from '../../Services/UserService';
import { Error403Page } from '../../Pages/Error403Page/Error403Page';
import { IcSpinner } from '@indece-common/ic-ui-lib-react';


export enum UserProfileRole {
    Loggedin    = 'loggedin',
    Admin       = 'admin'
}


class EmptyPage extends React.Component
{
    public render ( )
    {
        return (
            <IcSpinner />
        );
    }
}


export interface RouteGuardProps extends AuthContextProps
{
    globalRoles?:       Array<UserProfileRole>;
    organisationRole?:  UserV1OrganisationRole;
    component:          React.JSXElementConstructor<any>;
}


class $RouteGuard extends React.Component<RouteGuardProps>
{
    private readonly _backendService:   BackendService;


    constructor ( props: RouteGuardProps )
    {
        super(props);

        this._backendService = BackendService.getInstance();
    }


    public render ( )
    {
        if ( Environment.stage !== 'local' &&
             (!this.props.userData ||
               this.props.isLoading ||
              (this.props.globalRoles && (!this.props.userData.profile.groups || !(this.props.userData.profile.groups as Array<UserProfileRole>).find( r => this.props.globalRoles!.includes(r) )) )))
        {
            return (
                <EmptyPage />
            );
        }

        if ( this.props.userData )
        {
            this._backendService.setAccessToken(this.props.userData.access_token);
        }

        if ( Environment.stage !== 'local' &&
             this.props.organisationRole &&
             !(this.props.userData?.profile?.organisations as any)?.find( ( o: any ) => o.role === this.props.organisationRole!.toLowerCase() ) &&
             (!this.props.userData?.profile?.groups || !(this.props.userData.profile.groups as Array<UserProfileRole>).includes(UserProfileRole.Admin)) )
        {
            return (
                <Error403Page />
            );
        }

        const Component = this.props.component;

        return (
            <>
                <Component />
            </>
        );
    }
}


export const RouteGuard = withAuth($RouteGuard);
