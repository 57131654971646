import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { UserService, UserV1, UserV1OrganisationRole } from '../../Services/UserService';
import { OrganisationMemberAddPageLoadingStep } from './OrganisationMemberAddPageLoadingStep/OrganisationMemberAddPageLoadingStep';
import { OrganisationMemberAddPageSuccessStep } from './OrganisationMemberAddPageSuccessStep/OrganisationMemberAddPageSuccessStep';
import { OrganisationMemberAddPageUserStep, OrganisationMemberAddPageUserStepInviteFormData } from './OrganisationMemberAddPageUserStep/OrganisationMemberAddPageUserStep';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { sleep } from 'ts-delay';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface OrganisationMemberAddPageRouteParams
{
    organisationUID:    string;
}


export interface OrganisationMemberAddPageProps extends WithTranslation, WithLocaleProps, RouteComponentProps<OrganisationMemberAddPageRouteParams>
{
}


enum OrganisationMemberAddPageStep
{
    Loading     = 'LOADING',
    User        = 'USER',
    Success     = 'SUCCESS'
}


interface OrganisationMemberAddPageState
{
    currStep:       OrganisationMemberAddPageStep;
    user:           UserV1 | null;
    organisation:   OrganisationV1 | null;
    error:          Error | null;
}


class $OrganisationMemberAddPage extends React.Component<OrganisationMemberAddPageProps, OrganisationMemberAddPageState>
{
    private readonly _userService:          UserService;
    private readonly _organisationService:  OrganisationService;


    constructor ( props: OrganisationMemberAddPageProps )
    {
        super(props);

        this.state = {
            currStep:       OrganisationMemberAddPageStep.Loading,
            user:           null,
            organisation:   null,
            error:          null
        };

        this._userService = UserService.getInstance();
        this._organisationService = OrganisationService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishUser = this._finishUser.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishUser ( user: UserV1 | null, invitation: OrganisationMemberAddPageUserStepInviteFormData | null ): Promise<void>
    {
        if ( ! this.state.organisation )
        {
            return;
        }
        
        try
        {
            this.setState({
                currStep:   OrganisationMemberAddPageStep.Loading,
                error:      null,
                user
            });

            if ( user )
            {
                await this._organisationService.addOrganisationMember(
                    this.state.organisation.uid,
                    {
                        user_uid:   user.uid,
                        role:       UserV1OrganisationRole.Member
                    }
                );
            }
            else if ( invitation )
            {
                await this._userService.addUser({
                    locale:                 this.props.locale,
                    gender:                 null,
                    title:                  null,
                    firstname:              invitation.firstname.trim(),
                    lastname:               invitation.lastname.trim(),
                    email:                  invitation.email.trim(),
                    password:               null,
                    global_roles:           [],
                    organisation_name:      null,
                    organisation_country:   null,
                    organisation_uid:       this.state.organisation.uid,
                    organisation_role:      UserV1OrganisationRole.Member,
                    invitation_code:        null,
                    invitation_user_uid:    null,
                    accept_marketing:       null
                });
            }
            else
            {
                throw new Error('No user selected or invited');
            }

            this.setState({
                error:      null,
                currStep:   OrganisationMemberAddPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error adding user to organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   OrganisationMemberAddPageStep.User
            });
        }
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   OrganisationMemberAddPageStep.Loading,
                error:      null
            });

            const organisation = await this._organisationService.getOrganisation(this.props.router.params.organisationUID);

            this.setState({
                currStep:   OrganisationMemberAddPageStep.User,
                organisation
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation: ${(err as Error).message}`, err);

            this.setState({
                currStep:   OrganisationMemberAddPageStep.Loading,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('organisationmemberaddpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === OrganisationMemberAddPageStep.Loading ?
                    <OrganisationMemberAddPageLoadingStep />
                : null}

                {this.state.currStep === OrganisationMemberAddPageStep.User && this.state.organisation ?
                    <OrganisationMemberAddPageUserStep
                        organisation={this.state.organisation }
                        onCancel={this._cancel}
                        onFinish={this._finishUser}
                    />
                : null}

                {this.state.currStep === OrganisationMemberAddPageStep.Success ?
                    <OrganisationMemberAddPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const OrganisationMemberAddPage = withLocale(withTranslation()(withRouter($OrganisationMemberAddPage)));
