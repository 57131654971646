import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface AdminClientDeletePageSuccessStepProps extends WithTranslation
{
}


class $AdminClientDeletePageSuccessStep extends React.Component<AdminClientDeletePageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                message={this.props.t('adminclientdeletepagesuccessstep.txt_success')}
            />
        );
    }
}


export const AdminClientDeletePageSuccessStep = withTranslation()($AdminClientDeletePageSuccessStep);
