import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PackageV1, PackageV1Price, applyVoucherOnPrice, isFreePrice } from '../../../Services/PackageService';
import { VoucherService, VoucherV1 } from '../../../Services/VoucherService';
import { WithLocaleProps, withLocale } from '../../../utils/withLocale';
import { LabelValue } from '../../../Components/LabelValueList/LabelValue';
import { LabelValueList } from '../../../Components/LabelValueList/LabelValueList';
import { Form, Formik } from 'formik';
import { Formatter } from '../../../utils/Formatter';
import { OrganisationV1, PaymentMethodV1 } from '../../../Services/OrganisationService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcInputText, IcSeparator, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface PurchasePageFormStepTotalBoxProps extends WithTranslation, WithLocaleProps
{
    package:        PackageV1;
    packagePrice:   PackageV1Price;
    organisation:   OrganisationV1 | null;
    paymentMethod:  PaymentMethodV1 | null;
    count:          number;
    voucher:        VoucherV1 | null;
    onPurchase:     ( ) => any;
    onAddVoucher:   ( voucher: VoucherV1 ) => any;
}


interface PurchasePageFormStepTotalBoxFormData
{
    voucher_code:      string;
}


interface PurchasePageFormStepTotalBoxState
{
    initialFormData:    PurchasePageFormStepTotalBoxFormData;
    loading:            boolean;
    error:              Error | null;
}


class $PurchasePageFormStepTotalBox extends React.Component<PurchasePageFormStepTotalBoxProps, PurchasePageFormStepTotalBoxState>
{
    private readonly _voucherService:   VoucherService;


    constructor ( props: PurchasePageFormStepTotalBoxProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                voucher_code:   ''
            },
            loading:    false,
            error:      null
        };

        this._voucherService = VoucherService.getInstance();
        
        this._addVoucher = this._addVoucher.bind(this);
    }


    private async _addVoucher ( formData: PurchasePageFormStepTotalBoxFormData ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const voucher = await this._voucherService.getVoucherByCode(formData.voucher_code.trim());

            this.props.onAddVoucher(voucher);

            this.setState({
                loading: false
            });
        }
        catch ( err )
        {
            console.error(`Error loading voucher: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public render ( )
    {
        const MyFormik = Formik<PurchasePageFormStepTotalBoxFormData>;

        const name = this.props.package.names.find( o => o.locale === this.props.locale )?.value || '???';
        const finalPackagePrice = this.props.voucher ? applyVoucherOnPrice(this.props.packagePrice, this.props.voucher) : this.props.packagePrice;

        return (
            <IcCard padding={IcCardPadding.Medium}>
                <IcText size={IcTextSize.Heading3}>
                    {name}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <LabelValueList>
                    <LabelValue label={this.props.t('purchasepageformsteptotalbox.txt_count')}>
                        {this.props.count}
                    </LabelValue>

                    {this.props.voucher ?
                        <LabelValue label={this.props.t('purchasepageformsteptotalbox.txt_voucher', {code: this.props.voucher.code})}>
                            - {this.props.voucher.details.price_reduction_percent} % {this.props.voucher.details.max_duration ? `${this.props.t('purchasepageformsteptotalbox.txt_for')} ${Formatter.duration(this.props.voucher.details.max_duration)}` : ''}
                        </LabelValue>
                    : null}
                </LabelValueList>

                <IcSeparator />

                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('purchasepageformsteptotalbox.txt_total')}
                </IcText>

                <LabelValueList>
                    {finalPackagePrice.periods.map( ( pricePeriod, i ) => (
                        <LabelValue
                            key={i}
                            label={(i === 0 ? '' : (this.props.t('purchasepageformsteptotalbox.txt_then') + ' ')) + Formatter.duration(pricePeriod.duration)}>
                            {pricePeriod.gross > 0 ? Formatter.currencyAmount(this.props.count*pricePeriod.gross, finalPackagePrice.currency, finalPackagePrice.payment_interval) : this.props.t('purchasepageformsteptotalbox.txt_free')}
                        </LabelValue>
                    ))}
                </LabelValueList>

                {!this.props.voucher && !isFreePrice(this.props.packagePrice) && !this.state.loading ?
                    <>
                        <IcSeparator />
                        
                        <MyFormik
                            initialValues={this.state.initialFormData}
                            enableReinitialize={true}
                            onSubmit={this._addVoucher}>
                            <Form>
                                <IcInputText
                                    name='voucher_code'
                                    label={this.props.t('purchasepageformsteptotalbox.inp_voucher_code')}
                                />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='submit'
                                        color={IcButtonColor.Secondary}>
                                        {this.props.t('purchasepageformsteptotalbox.btn_add_voucher')}
                                    </IcButton>
                                </IcFloatRow>
                            </Form>
                        </MyFormik>
                    </>
                : null}

                <IcSpinner active={this.state.loading} />

                <IcSeparator />

                {!isFreePrice(finalPackagePrice) ?
                    <IcText size={IcTextSize.Small}>
                        {Formatter.vatRate(finalPackagePrice.rate_vat, true)}
                    </IcText>
                : null}

                <IcButton
                    onClick={this.props.onPurchase}
                    disabled={!this.props.organisation || (!isFreePrice(finalPackagePrice) && !this.props.paymentMethod)}
                    fullWidth={true}>
                    {isFreePrice(finalPackagePrice) ?
                        this.props.t('purchasepagepaystep.btn_book_free')
                    :
                        this.props.t('purchasepagepaystep.btn_book')
                    }
                </IcButton>
            </IcCard>
        );
    }
}


export const PurchasePageFormStepTotalBox = withLocale(withTranslation()($PurchasePageFormStepTotalBox));
