import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { OrganisationService, OrganisationV1, PaymentMethodV1 } from '../../Services/OrganisationService';
import { OrganisationPaymentMethodAddPageLoadingStep } from './OrganisationPaymentMethodAddPageLoadingStep/OrganisationPaymentMethodAddPageLoadingStep';
import { OrganisationPaymentMethodAddPageSuccessStep } from './OrganisationPaymentMethodAddPageSuccessStep/OrganisationPaymentMethodAddPageSuccessStep';
import { OrganisationPaymentMethodAddPageSelectStep } from './OrganisationPaymentMethodAddPageSelectStep/OrganisationPaymentMethodAddPageSelectStep';
import { StripeService } from '../../Services/StripeService';
import { Stripe } from '@stripe/stripe-js';
import { PendingPaymentMethod, SessionService } from '../../Services/SessionService';
import { sleep } from 'ts-delay';
import { IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

// https://stripe.com/docs/payments/save-and-reuse?platform=web&ui=elements&client=react


export interface OrganisationPaymentMethodAddPageRouteParams
{
    organisationUID:    string;
    paymentMethodUID?:  string;
}


export interface OrganisationPaymentMethodAddPageProps extends WithTranslation, RouteComponentProps<OrganisationPaymentMethodAddPageRouteParams>
{
}


enum OrganisationPaymentMethodAddPageStep
{
    Loading     = 'LOADING',
    Select      = 'SELECT',
    Success     = 'SUCCESS'
}


interface OrganisationPaymentMethodAddPageState
{
    currStep:               OrganisationPaymentMethodAddPageStep;
    stripe:                 Stripe | null;
    paymentMethod:          PaymentMethodV1 | null;
    pendingPaymentMethod:   PendingPaymentMethod | null;
    organisation:           OrganisationV1 | null;
    error:                  Error | null;
}


class $OrganisationPaymentMethodAddPage extends React.Component<OrganisationPaymentMethodAddPageProps, OrganisationPaymentMethodAddPageState>
{
    private readonly _organisationService:  OrganisationService;
    private readonly _stripeService:        StripeService;
    private readonly _sessionService:       SessionService;
    private _retrived:      boolean;


    constructor ( props: OrganisationPaymentMethodAddPageProps )
    {
        super(props);

        this.state = {
            currStep:               OrganisationPaymentMethodAddPageStep.Loading,
            stripe:                 null,
            paymentMethod:          null,
            pendingPaymentMethod:   null,
            organisation:           null,
            error:                  null
        };

        this._stripeService = StripeService.getInstance();
        this._organisationService = OrganisationService.getInstance();
        this._sessionService = SessionService.getInstance();
        this._retrived = false;

        this._finishSelect = this._finishSelect.bind(this);
    }


    private async _create ( ): Promise<void>
    {
        if ( !this.state.organisation )
        {
            return;
        }
        
        try
        {
            this.setState({
                currStep:   OrganisationPaymentMethodAddPageStep.Loading,
                error:      null
            });

            const result = await this._organisationService.addOrganisationPaymentMethod(
                this.state.organisation.uid,
                {}
            );

            const paymentMethods = await this._organisationService.getOrganisationPaymentMethods(this.state.organisation.uid);
            const paymentMethod = paymentMethods.find( o => o.uid === result.paymentmethod_uid );
            if ( ! paymentMethod )
            {
                throw new Error(`Payment method was added but not found`);
            }

            const pendingPaymentMethod: PendingPaymentMethod = {
                paymentmethod_uid:  result.paymentmethod_uid,
                client_secret:      result.stripe_client_secret
            };

            this._sessionService.setPendingPaymentMethod(pendingPaymentMethod);

            this.setState({
                error:      null,
                currStep:   OrganisationPaymentMethodAddPageStep.Select,
                paymentMethod,
                pendingPaymentMethod
            });
        }
        catch ( err )
        {
            console.error(`Error creating payment method: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   OrganisationPaymentMethodAddPageStep.Loading
            });
        }
    }


    private async _finishSelect ( error: Error | null ): Promise<void>
    {
        if ( !this.state.organisation || !this.state.stripe || !this.state.paymentMethod )
        {
            return;
        }
        
        try
        {
            this.setState({
                currStep:   OrganisationPaymentMethodAddPageStep.Loading,
                error:      null
            });

            if ( error )
            {
                throw error;
            }

            /*
            TODO: const result = await this._organisationService.addOrganisationPaymentMethod(
                this.state.organisation.uid,
                {
                    payment_service:    formData.paymentService
                }
            );*/

            /*const paymentMethods = await this._organisationService.getOrganisationPaymentMethods(this.state.organisation.uid);
            const paymentMethod = paymentMethods.find( o => o.uid === result.paymentmethod_uid );
            if ( ! paymentMethod )
            {
                throw new Error(`Payment method was added but not found`);
            }

            this.setState({
                error:      null,
                currStep:   OrganisationPaymentMethodAddPageStep.Success,
                paymentMethod
            });*/

            // TODO
        }
        catch ( err )
        {
            console.error(`Error adding user to organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   OrganisationPaymentMethodAddPageStep.Select
            });
        }
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   OrganisationPaymentMethodAddPageStep.Loading,
                error:      null
            });

            const stripe = await this._stripeService.getStripe();
            const organisation = await this._organisationService.getOrganisation(this.props.router.params.organisationUID);

            await new Promise<void>( ( resolve ) => {
                this.setState({
                    currStep:   OrganisationPaymentMethodAddPageStep.Loading,
                    stripe,
                    organisation
                }, resolve);
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation: ${(err as Error).message}`, err);

            this.setState({
                currStep:   OrganisationPaymentMethodAddPageStep.Loading,
                error:      err as Error
            });
        }
    }


    private async _finishVerify ( pendingPaymentMethod: PendingPaymentMethod ): Promise<void>
    {
        if ( !this.state.stripe || !this.state.organisation )
        {
            return;
        }

        try
        {
            this.setState({
                currStep:   OrganisationPaymentMethodAddPageStep.Loading
            });

            const result = await this.state.stripe.retrieveSetupIntent(pendingPaymentMethod.client_secret);
            if ( !result.setupIntent )
            {
                throw new Error(`No setup intent could be retrieved: ${result.error.message}`);
            }
            
            switch (result.setupIntent.status ) {
                case 'succeeded':
                console.log('Success! Your payment method has been saved.');
                break;

                case 'processing':
                console.log("Processing payment details. We'll update you when processing is complete.");
                break;

                case 'requires_payment_method':
                // Redirect your user back to your payment page to attempt collecting
                // payment again
                console.log('Failed to process payment details. Please try another payment method.');
                break;
            }

            await this._organisationService.verifyOrganisationPaymentMethod(
                this.state.organisation.uid,
                pendingPaymentMethod.paymentmethod_uid,
                {}
            );

            this._sessionService.deletePendingPaymentMethod();

            const paymentMethods = await this._organisationService.getOrganisationPaymentMethods(this.state.organisation.uid);
            const paymentMethod = paymentMethods.find( o => o.uid === pendingPaymentMethod.paymentmethod_uid );
            if ( ! paymentMethod )
            {
                throw new Error(`Payment method was added but not found`);
            }

            this.setState({
                currStep:   OrganisationPaymentMethodAddPageStep.Success,
                paymentMethod
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('organisation', this.props.router.params.organisationUID));
        }
        catch ( err )
        {
            console.error(`Error verifying payment method: ${(err as Error).message}`, err);

            this.setState({
                currStep:   OrganisationPaymentMethodAddPageStep.Loading,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();

        const pendingPaymentMethod = this._sessionService.getPendingPaymentMethod();

        if ( this._retrived )
        {
            this.setState({
                currStep: OrganisationPaymentMethodAddPageStep.Success
            });

            return;
        }

        if ( pendingPaymentMethod &&
             this.props.router.params.paymentMethodUID &&
             pendingPaymentMethod.paymentmethod_uid === this.props.router.params.paymentMethodUID )
        {
            this._retrived = true;
            await this._finishVerify(pendingPaymentMethod);
        }
        else
        {
            await this._create();
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('organisationpaymentmethodaddpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === OrganisationPaymentMethodAddPageStep.Loading ?
                    <OrganisationPaymentMethodAddPageLoadingStep />
                : null}

                {this.state.currStep === OrganisationPaymentMethodAddPageStep.Select && this.state.organisation && this.state.pendingPaymentMethod && this.state.stripe ?
                    <OrganisationPaymentMethodAddPageSelectStep
                        stripe={this.state.stripe}
                        organisation={this.state.organisation}
                        pendingPaymentMethod={this.state.pendingPaymentMethod}
                        onFinish={this._finishSelect}
                    />
                : null}

                {this.state.currStep === OrganisationPaymentMethodAddPageStep.Success ?
                    <OrganisationPaymentMethodAddPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const OrganisationPaymentMethodAddPage = withTranslation()(withRouter($OrganisationPaymentMethodAddPage));
