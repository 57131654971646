import { BackendService } from './BackendService';


export enum JobV1Type
{
    SendEmail               = 'SEND_EMAIL',
    SyncUser                = 'SYNC_USER',
    SyncAllUsers            = 'SYNC_ALL_USERS',
    SyncOrganisation        = 'SYNC_ORGANISATION',
    SyncAllOrganisations    = 'SYNC_ALL_ORGANISATIONS'
}


export enum JobV1Status
{
    Pending     = 'PENDING',
    Running     = 'RUNNING',
    Success     = 'SUCCESS',
    ErrorRetry  = 'ERROR_RETRY',
    Failed      = 'FAILED',
    Canceled    = 'CANCELED',
}


export interface JobV1
{
    uid:                string;
    type:               JobV1Type;
    status:             JobV1Status;
    details:            string;
    datetime_created:   string;
}


export class JobService
{
    private static _instance: JobService;
    
    
    public static getInstance ( ): JobService
    {
        if ( ! this._instance )
        {
            this._instance = new JobService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getJobs ( from: number, size: number ): Promise<Array<JobV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/job?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.jobs;
    }


    public async addJobSyncAllUsers ( ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/job/sync-all-users`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify({})
            }
        );

        return resp.job_uid;
    }


    public async addJobSyncAllOrganisations ( ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/job/sync-all-organisations`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify({})
            }
        );

        return resp.job_uid;
    }
}
