import React from 'react';
import { To } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';

import './TileButton.css';


export interface TileButtonProps
{
    icon:       IconProp;
    title:      string;
    text:       string;
    linkTitle:  string;
    type?:      'button' | 'reset' | 'submit';
    disabled?:  boolean;
    active?:    boolean;
    to?:        To;
    href?:      string;
    onClick?:   ( evt: any ) => any;
}


export class TileButton extends React.Component<TileButtonProps>
{
    public render ( )
    {
        return (
            <IcCard
                padding={IcCardPadding.Medium}
                className='TileButton-content'>
                    <div className='TileButton-content-icon'>
                        <FontAwesomeIcon icon={this.props.icon} />
                    </div>
                    
                    <div className='TileButton-content-text'>
                        <IcText size={IcTextSize.Heading3}>
                            {this.props.title}
                        </IcText>

                        <IcText className='TileButton-content-text-description'>
                            {this.props.text}
                        </IcText>

                        <IcButton
                            color={IcButtonColor.Secondary}
                            to={this.props.to}
                            href={this.props.href}
                            onClick={this.props.onClick}>
                            {this.props.linkTitle} <FontAwesomeIcon icon={faAngleRight} />
                        </IcButton>
                    </div>
            </IcCard>
        );
    }
}
