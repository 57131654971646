import React from 'react';
import { Form, Formik } from 'formik';
import { BackendService } from '../../../Services/BackendService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface SetupCodeStepProps extends WithTranslation
{
    onFinish:   ( ) => any;
}


interface SetupCodeStepFormData
{
    code:   string;
}


interface SetupCodeStepState
{
    initialFormData:    SetupCodeStepFormData;
    loading:            boolean;
    error:              Error | null;
}


class $SetupCodeStep extends React.Component<SetupCodeStepProps, SetupCodeStepState>
{
    private readonly _backendService: BackendService;


    constructor ( props: SetupCodeStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                code:   ''
            },
            loading:    false,
            error:      null
        };

        this._backendService = BackendService.getInstance();

        this._submit = this._submit.bind(this);
    }


    private async _submit ( values: SetupCodeStepFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            this._backendService.setAccessToken(values.code.trim());

            this.setState({
                loading:    false
            });

            this.props.onFinish();
        }
        catch ( err )
        {
            console.error(`Error setting access token: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small} bold={true}>
                    {this.props.t('setupcodestep.txt_subtitle')}
                </IcText>

                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('setupcodestep.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <Formik
                    initialValues={this.state.initialFormData}
                    onSubmit={this._submit}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='code'
                                    label={this.props.t('setupcodestep.inp_code')}
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='submit'
                                disabled={this.state.loading}>
                                {this.props.t('setupcodestep.btn_continue')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </Formik>

                <IcSpinner active={this.state.loading} />
            </IcCard>
        );
    }
}


export const SetupCodeStep = withTranslation()($SetupCodeStep);
