import React from 'react';
import { AuthContextProps, withAuth } from 'oidc-react';
import { BackendService } from '../../Services/BackendService';
import Cookies from 'universal-cookie';
import { UserService } from '../../Services/UserService';
import { Environment } from '../../utils/Environment';


export interface AuthControllerProps extends AuthContextProps
{
}


class $AuthController extends React.Component<AuthControllerProps>
{
    private readonly _backendService:   BackendService;
    private readonly _userService:      UserService;
    private readonly _cookies:          Cookies;
    private readonly _wasSignedIn:      boolean;
    private _triedSignIn:               boolean;


    constructor ( props: AuthControllerProps )
    {
        super(props);

        this._backendService = BackendService.getInstance();
        this._userService = UserService.getInstance();
        this._cookies = new Cookies(null, {path: '/'});

        // eslint-disable-next-line 
        this._wasSignedIn = this._cookies.get('ic-licenser-signedin') == '1';
        this._triedSignIn = false;
    }


    private async _trySignIn ( ): Promise<void>
    {
        if ( this._triedSignIn || !this._wasSignedIn || this.props.userData )
        {
            if ( (this.props.userData || Environment.stage === 'local') && !this._userService.isLoggedIn().get() )
            {
                await this._userService.load();
            }

            return;
        }

        try
        {
            if ( !this.props.isLoading )
            {
                this._triedSignIn = true;

                window.sessionStorage.setItem('ic-licenser-afterloginpath', window.location.pathname);

                await this.props.signIn();
                
                const userData = await this.props.userManager.getUser();
                if ( userData )
                {
                    this._backendService.setAccessToken(userData.access_token);

                    await this._userService.load();

                    return;
                }
            }

            this._backendService.deleteAccessToken();
            this._userService.unload();
        }
        catch ( err )
        {
            this._triedSignIn = true;

            this._backendService.deleteAccessToken();
            this._userService.unload();

            console.error(`Error during auto login: ${(err as Error).message}`, err);
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        if ( this.props.userData )
        {
            this._backendService.setAccessToken(this.props.userData.access_token);
        }
        else
        {
            this._backendService.deleteAccessToken();
        }

        await this._trySignIn();
    }

    
    public async componentDidUpdate ( ): Promise<void>
    {
        if ( this.props.userData )
        {
            this._backendService.setAccessToken(this.props.userData.access_token);
        }
        else
        {
            this._backendService.deleteAccessToken();
        }

        await this._trySignIn();
    }


    public render ( )
    {
        return null;
    }
}


export const AuthController = withAuth($AuthController);
