import React from 'react';
import { Form, Formik } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AuthContextProps, withAuth } from 'oidc-react';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { ContentFileV1Key } from '../../../Services/ContentService';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { IcButton, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridAlign, IcGridItem, IcGridPadding, IcGridRow, IcInputText, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface RegisterPageConfirmEmailStepProps extends WithTranslation, AuthContextProps
{
    onFinish: ( formData: RegisterPageConfirmEmailStepFormData ) => any;
}


export interface RegisterPageConfirmEmailStepFormData
{
    code:   string;
}


interface RegisterPageConfirmEmailStepState
{
    initialFormData: RegisterPageConfirmEmailStepFormData;
}


class $RegisterPageConfirmEmailStep extends React.Component<RegisterPageConfirmEmailStepProps, RegisterPageConfirmEmailStepState>
{
    constructor ( props: RegisterPageConfirmEmailStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
               code:    ''
            }
        };
    }


    public render ( )
    {
        const MyFormik  = Formik<RegisterPageConfirmEmailStepFormData>;

        return (
            <IcCard padding={IcCardPadding.None}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow padding={IcGridPadding.None} align={IcGridAlign.Stretch}>
                            <IcGridItem s={12} m={8} l={6} padding={IcGridPadding.Large}>
                                <IcText size={IcTextSize.Small} bold={true}>
                                    {this.props.t('registerpageconfirmemailstep.txt_subtitle')}
                                </IcText>

                                <IcText size={IcTextSize.Heading1}>
                                    {this.props.t('registerpageconfirmemailstep.txt_title')}
                                </IcText>

                                <IcText>
                                    {this.props.t('registerpageconfirmemailstep.txt_confirm_email')}
                                </IcText>

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='code'
                                            autoComplete='off'
                                            label={this.props.t('registerpageconfirmemailstep.inp_code')}
                                            required={true}
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <RequiredHint />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton type='submit'>
                                        {this.props.t('registerpageconfirmemailstep.btn_confirm')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcGridItem>

                            <IcGridItem s={0} m={4} l={6}>
                                <BoxImage>
                                    <ContentImage contentFileKey={ContentFileV1Key.RegisterConfirmEmailImage} />

                                    <SupportOverlay />
                                </BoxImage>
                            </IcGridItem>
                        </IcGridRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const RegisterPageConfirmEmailStep = withTranslation()(withAuth($RegisterPageConfirmEmailStep));
