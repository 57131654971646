import { BackendService } from './BackendService';


export interface LicenseV1
{
    uid:                string;
    tag:                string;
    name:               string;
    assignable:         boolean;
    application_uids:   Array<string>;
}


export interface AddLicenseV1
{
    tag:                string;
    name:               string;
    assignable:         boolean;
    application_uids:   Array<string>;
}


export interface UpdateLicenseV1
{
    tag:                string;
    name:               string;
    application_uids:   Array<string>;
}


export class LicenseService
{
    private static _instance: LicenseService;
    
    
    public static getInstance ( ): LicenseService
    {
        if ( ! this._instance )
        {
            this._instance = new LicenseService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getLicenses ( from: number, size: number ): Promise<Array<LicenseV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/license?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.licenses;
    }
    

    public async getLicense ( licenseUID: string ): Promise<LicenseV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/license/${encodeURIComponent(licenseUID)}`
        );

        return resp.license;
    }
    

    public async addLicense ( params: AddLicenseV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/license`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.license_uid;
    }


    public async updateLicense ( licenseUID: string, params: UpdateLicenseV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/license/${encodeURIComponent(licenseUID)}`,
            {
                method:     'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async deleteLicense ( licenseUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/license/${encodeURIComponent(licenseUID)}`,
            {
                method:     'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
