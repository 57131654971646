import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import { ApplicationService, ApplicationCategoryV1 } from '../../Services/ApplicationService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { EnabledLocales, Locale } from '../../Model/Locale';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { Formatter } from '../../utils/Formatter';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { List } from '../../Components/List/List';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcInputSelect, IcInputText, IcPageContent, IcSeparator, IcSpinner, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { RequiredHint } from '../../Components/RequiredHint/RequiredHint';


export interface AdminEditApplicationCategoryRouteParams
{
    applicationCategoryUID:  string;
}


export interface AdminApplicationCategoryEditPageProps extends RouteComponentProps<AdminEditApplicationCategoryRouteParams>, WithTranslation, WithLocaleProps
{
}


interface AdminApplicationCategoryEditPageFormDataLocalizedString
{
    locale: string;
    value:  string;
}


interface AdminApplicationCategoryEditPageFormData
{
    names:      Array<AdminApplicationCategoryEditPageFormDataLocalizedString>;
    slogans:    Array<AdminApplicationCategoryEditPageFormDataLocalizedString>;
}


interface AdminApplicationCategoryEditPageState
{
    initialFormData:        AdminApplicationCategoryEditPageFormData;
    applicationCategory:    ApplicationCategoryV1 | null;
    error:                  Error | null;
    loading:                boolean;
    success:                string | null;
}


class $AdminApplicationCategoryEditPage extends React.Component<AdminApplicationCategoryEditPageProps, AdminApplicationCategoryEditPageState>
{
    private readonly _applicationService:   ApplicationService;
    

    constructor ( props: AdminApplicationCategoryEditPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                names:                      [],
                slogans:                    [],
            },
            applicationCategory:    null,
            error:                  null,
            loading:                true,
            success:                null
        };

        this._applicationService = ApplicationService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._update    = this._update.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const applicationCategory = await this._applicationService.getApplicationCategory(this.props.router.params.applicationCategoryUID);

            this.setState({
                error:      null,
                loading:    false,
                applicationCategory,
                initialFormData: {
                    names:          applicationCategory.names.map( ( o ) => ({
                        locale: o.locale,
                        value:  o.value
                    })),
                    slogans:        applicationCategory.slogans.map( ( o ) => ({
                        locale: o.locale,
                        value:  o.value
                    }))
                }
            });
        }
        catch ( err )
        {
            console.error(`Error loading application category: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _update ( formData: AdminApplicationCategoryEditPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.applicationCategory )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            await this._applicationService.updateApplicationCategory(
                this.state.applicationCategory.uid,
                {
                    names:          formData.names.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                        locale:         o.locale as Locale,
                        value:          o.value.trim()
                    })),
                    slogans:        formData.slogans.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                        locale:         o.locale as Locale,
                        value:          o.value.trim()
                    }))
                }
            );

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('adminapplicationcategoryeditpage.txt_success')
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'applicationcategories'));
        }
        catch ( err )
        {
            console.error(`Error updating application category: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<AdminApplicationCategoryEditPageFormData>;

        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminapplicationcategoryeditpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._update}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    {({ values }) => (
                        <Form>
                            <IcCard padding={IcCardPadding.Small}>
                                <FieldArray name='names'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminapplicationcategoryeditpage.txt_names')}
                                            </IcText>

                                            <List>
                                                {values.names.map( ( name, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`names.${index}.locale`}
                                                                    label={this.props.t('adminapplicationcategoryeditpage.inp_name_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>
                                                                
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`names.${index}.value`}
                                                                    label={this.props.t('adminapplicationcategoryeditpage.inp_name_name')}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationcategoryeditpage.btn_delete_name')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.names.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationcategoryeditpage.txt_no_names')}
                                                    </ListEmpty>
                                                : null}
                                            </List>
                                            
                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusSquare} />
                                                    
                                                    {this.props.t('adminapplicationcategoryeditpage.btn_add_name')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='slogans'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminapplicationcategoryeditpage.txt_slogans')}
                                            </IcText>

                                            <List>
                                                {values.slogans.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`slogans.${index}.locale`}
                                                                    label={this.props.t('adminapplicationcategoryeditpage.inp_slogan_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>
                                                                
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`slogans.${index}.value`}
                                                                    label={this.props.t('adminapplicationcategoryeditpage.inp_slogan_slogan')}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminapplicationcategoryeditpage.btn_delete_slogan')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.slogans.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminapplicationcategoryeditpage.txt_no_slogans')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusSquare} />
                                                    
                                                    {this.props.t('adminapplicationcategoryeditpage.btn_add_slogan')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <RequiredHint />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        {this.props.t('adminapplicationcategoryeditpage.btn_cancel')}
                                    </IcButton>

                                    <IcButton
                                        type='submit'
                                        disabled={this.state.loading}>
                                        {this.props.t('adminapplicationcategoryeditpage.btn_update')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcCard>
                        </Form>
                    )}
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const AdminApplicationCategoryEditPage = withLocale(withTranslation()(withRouter($AdminApplicationCategoryEditPage)));
