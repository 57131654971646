import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface PurchaseCancelPageSuccessStepProps extends WithTranslation
{
}


class $PurchaseCancelPageSuccessStep extends React.Component<PurchaseCancelPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                message={this.props.t('purchasecancelpagesuccessstep.txt_success')}
            />
        );
    }
}


export const PurchaseCancelPageSuccessStep = withTranslation()($PurchaseCancelPageSuccessStep);
