import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { UserService, UserV1 } from '../../Services/UserService';
import { OrganisationMemberAcceptPageLoadingStep } from './OrganisationMemberAcceptPageLoadingStep/OrganisationMemberAcceptPageLoadingStep';
import { OrganisationMemberAcceptPageSuccessStep } from './OrganisationMemberAcceptPageSuccessStep/OrganisationMemberAcceptPageSuccessStep';
import { withLocale, WithLocaleProps } from '../../utils/withLocale';
import { sleep } from 'ts-delay';
import { OrganisationMemberAcceptPageConfirmStep } from './OrganisationMemberAcceptPageConfirmStep/OrganisationMemberAcceptPageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface OrganisationMemberAcceptPageRouteParams
{
    organisationUID:    string;
}


export interface OrganisationMemberAcceptPageProps extends WithTranslation, WithLocaleProps, RouteComponentProps<OrganisationMemberAcceptPageRouteParams>
{
}


enum OrganisationMemberAcceptPageStep
{
    Loading     = 'LOADING',
    Confirm     = 'CONFIRM',
    Success     = 'SUCCESS'
}


interface OrganisationMemberAcceptPageState
{
    currStep:       OrganisationMemberAcceptPageStep;
    ownUser:        UserV1 | null;
    organisation:   OrganisationV1 | null;
    error:          Error | null;
}


class $OrganisationMemberAcceptPage extends React.Component<OrganisationMemberAcceptPageProps, OrganisationMemberAcceptPageState>
{
    private readonly _organisationService:  OrganisationService;
    private readonly _userService:          UserService;


    constructor ( props: OrganisationMemberAcceptPageProps )
    {
        super(props);

        this.state = {
            currStep:       OrganisationMemberAcceptPageStep.Loading,
            ownUser:        null,
            organisation:   null,
            error:          null
        };

        this._organisationService = OrganisationService.getInstance();
        this._userService = UserService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   OrganisationMemberAcceptPageStep.Loading,
                error:      null
            });

            if ( ! this.state.organisation )
            {
                throw new Error('No organisation loaded');
            }

            if ( ! this.state.ownUser )
            {
                throw new Error('No user loaded');
            }

            await this._organisationService.acceptOrganisationMember(
                this.state.organisation.uid,
                this.state.ownUser!.uid
            );

            this.setState({
                error:      null,
                currStep:   OrganisationMemberAcceptPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error accepting user to organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   OrganisationMemberAcceptPageStep.Confirm
            });
        }
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   OrganisationMemberAcceptPageStep.Loading,
                error:      null
            });

            const organisation = await this._organisationService.getOrganisation(this.props.router.params.organisationUID);

            this.setState({
                currStep:   OrganisationMemberAcceptPageStep.Confirm,
                organisation
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation: ${(err as Error).message}`, err);

            this.setState({
                currStep:   OrganisationMemberAcceptPageStep.Loading,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });

        await this._load();
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('organisationmemberacceptpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === OrganisationMemberAcceptPageStep.Loading ?
                    <OrganisationMemberAcceptPageLoadingStep />
                : null}

                {this.state.currStep === OrganisationMemberAcceptPageStep.Confirm && this.state.organisation ?
                    <OrganisationMemberAcceptPageConfirmStep
                        organisation={this.state.organisation}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.currStep === OrganisationMemberAcceptPageStep.Success ?
                    <OrganisationMemberAcceptPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const OrganisationMemberAcceptPage = withLocale(withTranslation()(withRouter($OrganisationMemberAcceptPage)));
