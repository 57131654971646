import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserV1, isAdmin, isOwner } from '../../Services/UserService';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface UserLicensesBoxProps extends WithTranslation
{
    user:       UserV1 | null;
    ownUser:    UserV1 | null;
}


class $UserLicensesBox extends React.Component<UserLicensesBoxProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('userlicensesbox.txt_title')}
                </IcText>

                <List>
                    {this.props.user?.licenses.map( ( userLicense, i ) => (
                        <ListItem key={i}>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    grow={true}
                                    text={userLicense.license.name}
                                />

                                {this.props.ownUser && (isAdmin(this.props.ownUser) || isOwner(this.props.ownUser, userLicense.organisation_uid)) ?
                                    <ListItemHeaderAction
                                        icon={faTimes}
                                        to={LinkUtils.make('organisation', userLicense.organisation_uid, 'user', this.props.user!.uid, 'license', userLicense.license.uid, 'unassign')}
                                        title={this.props.t('userlicensesbox.btn_unassign')}
                                    />
                                : null}
                            </ListItemHeader>
                        </ListItem>
                    ))}

                    {this.props.user && this.props.user.licenses.length === 0 ?
                        <ListEmpty>
                            {this.props.t('userlicensesbox.txt_no_licenses')}
                        </ListEmpty>
                    : null}
                </List>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    {this.props.user && this.props.ownUser && (isAdmin(this.props.ownUser) || isOwner(this.props.ownUser)) ?
                        <IcButton
                            to={LinkUtils.make('user', this.props.user.uid, 'license', 'assign')}
                            color={IcButtonColor.Secondary}>
                            <FontAwesomeIcon icon={faPlusCircle} />

                            {this.props.t('userlicensesbox.btn_assign')}
                        </IcButton>
                    : null}
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const UserLicensesBox = withTranslation()($UserLicensesBox);
