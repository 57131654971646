import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { JobService } from '../../Services/JobService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcPageContent, IcSpinner, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminJobAddSyncAllOrganisationsPageProps extends RouteComponentProps, WithTranslation
{
}


interface AdminJobAddSyncAllOrganisationsPageState
{
    error:      Error | null;
    loading:    boolean;
    success:    boolean;
}


class $AdminJobAddSyncAllOrganisationsPage extends React.Component<AdminJobAddSyncAllOrganisationsPageProps, AdminJobAddSyncAllOrganisationsPageState>
{
    private readonly _jobService:   JobService;
    

    constructor ( props: AdminJobAddSyncAllOrganisationsPageProps )
    {
        super(props);

        this.state = {
            error:          null,
            loading:        false,
            success:        false
        };

        this._jobService = JobService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._add = this._add.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _add ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    false
            });

            await this._jobService.addJobSyncAllOrganisations();

            this.setState({
                error:      null,
                loading:    false,
                success:    true
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'jobs'));
        }
        catch ( err )
        {
            console.error(`Error adding job: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('adminjobaddsyncallorganisationspage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.loading ?
                    <IcCard padding={IcCardPadding.Large}>
                        <IcSpinner />
                    </IcCard>
                : null}

                {this.state.success ?
                    <SuccessBox
                        message={this.props.t('adminjobaddsyncallorganisationspage.txt_success')}
                    />
                : null}

                {!this.state.success && !this.state.loading ?
                    <IcCard padding={IcCardPadding.Large}>
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('adminjobaddsyncallorganisationspage.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('adminjobaddsyncallorganisationspage.txt_confirm')}
                        </IcText>

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this._cancel}>
                                {this.props.t('adminjobaddsyncallorganisationspage.btn_cancel')}
                            </IcButton>

                            <IcButton
                                onClick={this._add}>
                                {this.props.t('adminjobaddsyncallorganisationspage.btn_confirm')}
                            </IcButton>
                        </IcFloatRow>
                    </IcCard>
                : null}
            </IcPageContent>
        );
    } 
}


export const AdminJobAddSyncAllOrganisationsPage = withTranslation()(withRouter($AdminJobAddSyncAllOrganisationsPage));
