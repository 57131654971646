import React from 'react';
import DayJS from 'dayjs';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApplicationV1, ApplicationV1AssetType } from '../../Services/ApplicationService';
import { PackageV1, PackageV1Price, PackageV1Status, PackageV1Visibility, isFreePrice, maxFreeInterval, minInterval, minPriceInterval } from '../../Services/PackageService';
import { UserV1 } from '../../Services/UserService';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { Country } from '../../Model/Country';
import { Box, BoxAlignment } from '../../Components/Box/Box';
import { Link } from 'react-router-dom';
import { BoxContent, BoxContentPadding } from '../../Components/Box/BoxContent';
import { Formatter } from '../../utils/Formatter';
import { FreeTag } from '../../Components/FreeTag/FreeTag';
import { FileService } from '../../Services/FileService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { TrackingEventType, TrackingService } from '../../Services/TrackingService';
import { IcButton, IcButtonColor, IcLink, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './HomePageApplicationBox.css';


export interface HomePageApplicationBoxProps extends WithTranslation, WithLocaleProps, RouteComponentProps
{
    user:           UserV1 | null;
    country:        Country;
    application:    ApplicationV1;
    packages:       Array<PackageV1>;
}


interface PriceWithPackage
{
    package:    PackageV1;
    price:      PackageV1Price;
}


class $HomePageApplicationBox extends React.Component<HomePageApplicationBoxProps>
{
    private readonly _fileService:      FileService;
    private readonly _trackingService:  TrackingService;


    constructor ( props: HomePageApplicationBoxProps )
    {
        super(props);

        this._fileService = FileService.getInstance();
        this._trackingService = TrackingService.getInstance();

        this._showPackages = this._showPackages.bind(this);
        this._trackApplicationStarted = this._trackApplicationStarted.bind(this);
    }


    private _showPackages ( ): void
    {
        this.props.router.navigate(LinkUtils.make('packages') + `?application_uid=${encodeURIComponent(this.props.application.uid)}`);
    }


    private _trackApplicationStarted ( ): void
    {
        this._trackingService.push({
            event:      TrackingEventType.ApplicationStarted,
            message:    `Started application "${this.props.application.names.find( o => o.locale === this.props.locale )?.value || ''}"`,
            value:      this.props.application.uid
        });
    }


    public render ( )
    {
        const hasLicense = !!(this.props.user && this.props.user.licenses.find( o => o.license.application_uids.includes(this.props.application.uid) ));
        const name = this.props.application.names.find( o => o.locale === this.props.locale )?.value;
        const outline = this.props.application.outlines.find( o => o.locale === this.props.locale )?.value;
        const availablePackages = this.props.packages.filter( ( pkg ) => pkg.status === PackageV1Status.Available && pkg.visibility === PackageV1Visibility.Public && pkg.details.licenses.find( ( lic ) => lic.license.application_uids.includes(this.props.application.uid) ) );
        const availablePackagePrices: Array<PriceWithPackage> = [];
        for ( const pkg of availablePackages )
        {
            for ( const pkgPrice of pkg.details.prices )
            {
                if ( !pkgPrice.countries.includes(this.props.country) )
                {
                    continue;
                }

                availablePackagePrices.push({
                    package:    pkg,
                    price:      pkgPrice
                });
            }
        }

        const freePrice = availablePackagePrices.find( o => isFreePrice(o.price)) || null;
        const minIntervalVal = minInterval(availablePackagePrices.map( o => o.price ));
        const maxFreeIntervalVal = maxFreeInterval(availablePackagePrices.map( o => o.price ));
        const minPriceIntervalVal = minPriceInterval(availablePackagePrices.map( o => o.price ));
        const minPriceIntervalPkg = minPriceIntervalVal ? availablePackages.find( o => o.details.prices.find( p => p.uid === minPriceIntervalVal.price.uid )) : null;

        return (
            <Box
                alignment={BoxAlignment.Vertical}
                className='HomePageApplicationBox'>
                <BoxContent>
                    <Link
                        className='HomePageApplicationBox-preview'
                        to={LinkUtils.make('application', this.props.application.uid)}>
                        {this.props.application.assets.filter( o => o.type === ApplicationV1AssetType.Preview && o.locales.includes(this.props.locale)).map( ( asset ) => (
                            <img src={this._fileService.getFileDataURI(asset.file_uid)} alt='' />
                        ))}
                    </Link>
                </BoxContent>

                <BoxContent padding={BoxContentPadding.Small} className='HomePageApplicationBox-info'>
                    <Link
                        to={LinkUtils.make('application', this.props.application.uid)}>
                        <IcText bold={true}>
                            {name}
                        </IcText>
                    </Link>

                    <Link
                        to={LinkUtils.make('application', this.props.application.uid)}
                        className='HomePageApplicationBox-outline'>
                        {outline}
                    </Link>

                    <div>
                        <IcLink
                            to={LinkUtils.make('application', this.props.application.uid)}
                            className='HomePageApplicationBox-info-more'
                            testID='homepage.applicationbox.info.more'>
                            {this.props.t('homepageapplicationbox.btn_more')}

                            <FontAwesomeIcon icon={faAnglesRight} />
                        </IcLink>
                    </div>

                    <div className='HomePageApplicationBox-actions'>
                        {hasLicense ?
                            <IcButton
                                href={this.props.application.home_uri}
                                fullWidth={true}
                                target='_blank'
                                testID='homepage.applicationbox.actions.start'
                                color={IcButtonColor.Secondary}
                                onClick={this._trackApplicationStarted}>
                                {this.props.t('homepageapplicationbox.btn_start')}
                            </IcButton>
                        : null}

                        {!hasLicense && minPriceIntervalVal ?
                            <IcButton
                                to={LinkUtils.make('packages') + `?application_uid=${encodeURIComponent(this.props.application.uid)}`}
                                allowWrap={true}
                                testID='homepage.applicationbox.actions.purchase'>
                                {minPriceIntervalPkg && minPriceIntervalPkg.details.is_bundle ?
                                    this.props.t('homepageapplicationbox.btn_purchase_bundle', {price: Formatter.currencyAmount(minPriceIntervalVal.pricePeriod.gross * (DayJS.duration(minIntervalVal!).asSeconds() / DayJS.duration(minPriceIntervalVal.price.payment_interval).asSeconds()), minPriceIntervalVal.price.currency, minIntervalVal!)})
                                :
                                    this.props.t('homepageapplicationbox.btn_purchase', {price: Formatter.currencyAmount(minPriceIntervalVal.pricePeriod.gross * (DayJS.duration(minIntervalVal!).asSeconds() / DayJS.duration(minPriceIntervalVal.price.payment_interval).asSeconds()), minPriceIntervalVal.price.currency, minIntervalVal!)})
                                }
                            </IcButton>
                        : null}

                        {!hasLicense && freePrice && !minPriceIntervalVal ?
                            <IcButton
                                to={LinkUtils.make('packages') + `?application_uid=${encodeURIComponent(this.props.application.uid)}`}
                                allowWrap={true}
                                testID='homepage.applicationbox.actions.purchase_free'>
                                {this.props.t('homepageapplicationbox.btn_purchase_free')}
                            </IcButton>
                        : null}
                        
                        {!hasLicense && (freePrice || maxFreeIntervalVal) ?
                            <FreeTag
                                showIcon={false}
                                maxDuration={freePrice ? freePrice.package.details.max_duration : maxFreeIntervalVal}
                                onClick={this._showPackages}
                            />
                        : null}
                    </div>

                    {!hasLicense && minPriceIntervalVal ?
                        <IcText size={IcTextSize.Small}>
                            {Formatter.vatRate(minPriceIntervalVal.price.rate_vat || 0, true)}
                        </IcText>
                    : null}
                </BoxContent>
            </Box>
        );
    }
}


export const HomePageApplicationBox = withRouter(withLocale(withTranslation()($HomePageApplicationBox)));
