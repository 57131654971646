import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface AdminPackagePriceEditPageSuccessStepProps extends WithTranslation
{
}


class $AdminPackagePriceEditPageSuccessStep extends React.Component<AdminPackagePriceEditPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                testID='adminpackagepriceeditpagesuccessstep.txt_success'
                message={this.props.t('adminpackagepriceeditpagesuccessstep.txt_success')}
            />
        );
    }
}


export const AdminPackagePriceEditPageSuccessStep = withTranslation()($AdminPackagePriceEditPageSuccessStep);
