import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationService, OrganisationV1 } from '../../../Services/OrganisationService';
import { Gender, Genders } from '../../../Model/Gender';
import { Form, Formik } from 'formik';
import { Formatter } from '../../../utils/Formatter';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridPadding, IcGridRow, IcInputSelect, IcInputText, IcSpinner, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface PurchasePageFormStepEditOrganisationBoxProps extends WithTranslation
{
    organisation:   OrganisationV1;
    onCancel:       ( ) => any;
    onChange:       ( organisation: OrganisationV1 ) => any;
}


export interface PurchasePageFormStepEditOrganisationBoxFormData
{
    payment_address_title:      string;
    payment_address_gender:     Gender | '';
    payment_address_firstname:  string;
    payment_address_lastname:   string;
    payment_address_street:     string;
    payment_address_zip:        string;
    payment_address_city:       string;
    payment_email:              string;
}


interface PurchasePageFormStepEditOrganisationBoxState
{
    initialFormData:    PurchasePageFormStepEditOrganisationBoxFormData;
    loading:            boolean;
    error:              Error | null;
}


class $PurchasePageFormStepEditOrganisationBox extends React.Component<PurchasePageFormStepEditOrganisationBoxProps, PurchasePageFormStepEditOrganisationBoxState>
{
    private readonly _organisationService:  OrganisationService;


    constructor ( props: PurchasePageFormStepEditOrganisationBoxProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                payment_address_title:      '',
                payment_address_gender:     '',
                payment_address_firstname:  '',
                payment_address_lastname:   '',
                payment_address_street:     '',
                payment_address_zip:        '',
                payment_address_city:       '',
                payment_email:              ''
            },
            loading:    false,
            error:      null
        };

        this._organisationService = OrganisationService.getInstance();

        this._submit = this._submit.bind(this);
    }


    private async _submit ( formData: PurchasePageFormStepEditOrganisationBoxFormData ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            await this._organisationService.updateOrganisation(
                this.props.organisation.uid,
                {
                    name:               this.props.organisation.name,
                    country:            this.props.organisation.country,
                    payment_address:    {
                        title:              formData.payment_address_title.trim() || null,
                        gender:             formData.payment_address_gender || null,
                        firstname:          formData.payment_address_firstname.trim(),
                        lastname:           formData.payment_address_lastname.trim(),
                        street:             formData.payment_address_street.trim(),
                        zip:                formData.payment_address_zip.trim(),
                        city:               formData.payment_address_city.trim(),
                    },
                    payment_email:      formData.payment_email.trim() || null
                }
            );

            const organisation = await this._organisationService.getOrganisation(this.props.organisation.uid);

            this.setState({
                loading:    false
            });

            this.props.onChange(organisation);
        }
        catch ( err )
        {
            console.error(`Error updating organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public componentDidMount ( ): void
    {
        this.setState({
            initialFormData: {
                payment_address_title:      this.props.organisation.payment_address?.title || '',
                payment_address_gender:     this.props.organisation.payment_address?.gender || '',
                payment_address_firstname:  this.props.organisation.payment_address?.firstname || '',
                payment_address_lastname:   this.props.organisation.payment_address?.lastname || '',
                payment_address_street:     this.props.organisation.payment_address?.street || '',
                payment_address_zip:        this.props.organisation.payment_address?.zip || '',
                payment_address_city:       this.props.organisation.payment_address?.city || '',
                payment_email:              this.props.organisation.payment_email || ''
            }
        });
    }


    public componentDidUpdate ( prevProps: Readonly<PurchasePageFormStepEditOrganisationBoxProps> ): void
    {
        if ( prevProps.organisation !== this.props.organisation )
        {
            this.setState({
                initialFormData: {
                    payment_address_title:      this.props.organisation.payment_address?.title || '',
                    payment_address_gender:     this.props.organisation.payment_address?.gender || '',
                    payment_address_firstname:  this.props.organisation.payment_address?.firstname || '',
                    payment_address_lastname:   this.props.organisation.payment_address?.lastname || '',
                    payment_address_street:     this.props.organisation.payment_address?.street || '',
                    payment_address_zip:        this.props.organisation.payment_address?.zip || '',
                    payment_address_city:       this.props.organisation.payment_address?.city || '',
                    payment_email:              this.props.organisation.payment_email || ''
                }
            });
        }
    }


    public render ( )
    {
        const MyFormik = Formik<PurchasePageFormStepEditOrganisationBoxFormData>;
    
        return (
            <IcCard padding={IcCardPadding.Medium}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('purchasepageformstepeditorganisationbox.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('purchasepageformstepeditorganisationbox.txt_payment_address')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <IcSpinner active={this.state.loading} />

                {!this.state.loading ?
                    <MyFormik
                        initialValues={this.state.initialFormData}
                        onSubmit={this._submit}
                        enableReinitialize={true}>
                        <Form>
                            <IcGridRow padding={IcGridPadding.Small}>
                                <IcGridItem s={6}>
                                    <IcInputText
                                        name='payment_address_title'
                                        label={this.props.t('purchasepageformstepeditorganisationbox.inp_payment_address_title')}
                                        validators={[
                                            Validator.maxLength(20)
                                        ]}
                                    />
                                </IcGridItem>

                                <IcGridItem s={6}>
                                    <IcInputSelect
                                        name='payment_address_gender'
                                        label={this.props.t('purchasepageformstepeditorganisationbox.inp_payment_address_gender')}
                                        options={Genders.map( ( gender ) => ({
                                            label:  Formatter.gender(gender),
                                            value:  gender
                                        }))}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputText
                                        name='payment_address_firstname'
                                        label={this.props.t('purchasepageformstepeditorganisationbox.inp_payment_address_firstname')}
                                        required={true}
                                        validators={[
                                            Validator.minLength(1),
                                            Validator.maxLength(32)
                                        ]}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputText
                                        name='payment_address_lastname'
                                        label={this.props.t('purchasepageformstepeditorganisationbox.inp_payment_address_lastname')}
                                        required={true}
                                        validators={[
                                            Validator.minLength(1),
                                            Validator.maxLength(32)
                                        ]}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputText
                                        name='payment_address_street'
                                        label={this.props.t('purchasepageformstepeditorganisationbox.inp_payment_address_street')}
                                        required={true}
                                        validators={[
                                            Validator.minLength(1),
                                            Validator.maxLength(100)
                                        ]}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputText
                                        name='payment_address_zip'
                                        label={this.props.t('purchasepageformstepeditorganisationbox.inp_payment_address_zip')}
                                        required={true}
                                        validators={[
                                            Validator.minLength(1),
                                            Validator.maxLength(8)
                                        ]}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputText
                                        name='payment_address_city'
                                        label={this.props.t('purchasepageformstepeditorganisationbox.inp_payment_address_city')}
                                        required={true}
                                        validators={[
                                            Validator.minLength(1),
                                            Validator.maxLength(100)
                                        ]}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12} m={6}>
                                    <IcInputText
                                        type='email'
                                        name='payment_email'
                                        label={this.props.t('purchasepageformstepeditorganisationbox.inp_payment_email')}
                                        validators={[
                                            Validator.email
                                        ]}
                                    />
                                </IcGridItem>
                            </IcGridRow>

                            <RequiredHint />

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton
                                    type='button'
                                    color={IcButtonColor.Link}
                                    onClick={this.props.onCancel}>
                                    {this.props.t('purchasepageformstepeditorganisationbox.btn_cancel')}
                                </IcButton>

                                <IcButton
                                    type='submit'>
                                    {this.props.t('purchasepageformstepeditorganisationbox.btn_update')}
                                </IcButton>
                            </IcFloatRow>
                        </Form>
                    </MyFormik>
                : null}
            </IcCard>
        );
    }
}


export const PurchasePageFormStepEditOrganisationBox = withTranslation()($PurchasePageFormStepEditOrganisationBox);
