import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from '../../Components/List/List';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { Formatter } from '../../utils/Formatter';
import { faPlusCircle, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { OrganisationService, PaymentMethodV1 } from '../../Services/OrganisationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface OrganisationPagePaymentMethodsBoxProps extends WithTranslation
{
    organisationUID:    string;
}


interface OrganisationPagePaymentMethodsBoxState
{
    paymentMethods: Array<PaymentMethodV1>;
    loading:        boolean;
    error:          Error | null;
}


class $OrganisationPagePaymentMethodsBox extends React.Component<OrganisationPagePaymentMethodsBoxProps, OrganisationPagePaymentMethodsBoxState>
{
    private readonly _organisationService:  OrganisationService;


    constructor ( props: OrganisationPagePaymentMethodsBoxProps )
    {
        super(props);

        this.state = {
            paymentMethods: [],
            loading:        true,
            error:          null
        };

        this._organisationService = OrganisationService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            let paymentMethods = await this._organisationService.getOrganisationPaymentMethods(this.props.organisationUID);

            paymentMethods = paymentMethods.filter( o => o.verified );

            this.setState({
                paymentMethods,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading payment methods: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('organisationpagepaymentmethodsbox.txt_paymentmethods')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <List>
                    {this.state.paymentMethods.map( ( paymentMethod ) => (
                        <ListItem key={paymentMethod.uid}>
                            <ListItemHeader>
                                <ListItemHeaderField>
                                    {paymentMethod.default ?
                                        <FontAwesomeIcon
                                            icon={faStar}
                                            title={this.props.t('organisationpagepaymentmethodsbox.txt_default')}
                                        />
                                    : null}
                                </ListItemHeaderField>

                                <ListItemHeaderField
                                    grow={true}
                                    text={paymentMethod.title}
                                    subtext={Formatter.paymentService(paymentMethod.payment_service)}
                                />
                                
                                {paymentMethod.expiry_label ?
                                    <ListItemHeaderField
                                        grow={true}
                                        text={paymentMethod.expiry_label}
                                        subtext={this.props.t('organisationpagepaymentmethodsbox.txt_expiry')}
                                    />
                                : null}
                                
                                {!paymentMethod.default ?
                                    <ListItemHeaderAction
                                        icon={faStar}
                                        title={this.props.t('organisationpagepaymentmethodsbox.btn_set_default')}
                                        to={LinkUtils.make('organisation', this.props.organisationUID, 'paymentmethod', paymentMethod.uid, 'set_default')}
                                    />
                                : null}

                                {!paymentMethod.default ?
                                    <ListItemHeaderAction
                                        icon={faTrash}
                                        title={this.props.t('organisationpagepaymentmethodsbox.btn_delete')}
                                        to={LinkUtils.make('organisation', this.props.organisationUID, 'paymentmethod', paymentMethod.uid, 'delete')}
                                    />
                                : null}
                            </ListItemHeader>
                        </ListItem>
                    ))}

                    {this.state.paymentMethods.length === 0 && !this.state.loading ?
                        <ListEmpty>
                            {this.props.t('organisationpagepaymentmethodsbox.txt_no_paymentmethods')}
                        </ListEmpty>
                    : null}
                </List>

                <IcSpinner active={this.state.loading} />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    {this.state.paymentMethods.length > 0 ?
                        <IcButton
                            to={LinkUtils.make('organisation', this.props.organisationUID, 'paymentmethod', 'add')}
                            color={IcButtonColor.Secondary}>
                            <FontAwesomeIcon icon={faPlusCircle} />

                            {this.props.t('organisationpagepaymentmethodsbox.btn_add_paymentmethod')}
                        </IcButton>
                    : null}
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const OrganisationPagePaymentMethodsBox = withTranslation()($OrganisationPagePaymentMethodsBox);
