import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcCard, IcCardPadding, IcSpinner } from '@indece-common/ic-ui-lib-react';


export interface AdminPackagePriceAddPageLoadingStepProps extends WithTranslation
{
}


class $AdminPackagePriceAddPageLoadingStep extends React.Component<AdminPackagePriceAddPageLoadingStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcSpinner
                    message={this.props.t('adminpackagepriceaddpageloadingstep.txt_loading')}
                />
            </IcCard>
        );
    }
}


export const AdminPackagePriceAddPageLoadingStep = withTranslation()($AdminPackagePriceAddPageLoadingStep);
