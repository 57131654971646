import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { ApplicationService, ApplicationV1 } from '../../Services/ApplicationService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcPageContent, IcPopupMenu, IcPopupMenuItem, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';


export interface AdminApplicationsPageProps extends WithTranslation, WithLocaleProps
{
}


interface AdminApplicationsPageState
{
    applications:   Array<ApplicationV1>;
    hasMore:        boolean;
    loading:        boolean;
    error:          Error | null;
}


class $AdminApplicationsPage extends React.Component<AdminApplicationsPageProps, AdminApplicationsPageState>
{
    private readonly BULK_SIZE              = 50;
    private readonly _applicationService:   ApplicationService;


    constructor ( props: AdminApplicationsPageProps )
    {
        super(props);

        this.state = {
            applications:   [],
            hasMore:        true,
            loading:        true,
            error:          null
        };

        this._applicationService = ApplicationService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const applications = await this._applicationService.getApplications(
                0,
                this.BULK_SIZE
            );

            this.setState({
                applications,
                hasMore:    applications.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading applications: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }

    
    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const applications = await this._applicationService.getApplications(
                this.state.applications.length,
                this.BULK_SIZE
            );

            this.setState({
                applications:   [...this.state.applications, ...applications],
                hasMore:        applications.length >= this.BULK_SIZE,
                loading:        false,
                error:          null
            });
        }
        catch ( err )
        {
            console.error(`Error loading more applications: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {   
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'applications')}
                        label={this.props.t('adminapplicationspage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle title={this.props.t('adminapplicationspage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton
                            to={LinkUtils.make('admin', 'application', 'add')}
                            color={IcButtonColor.Secondary}>
                            <FontAwesomeIcon icon={faPlusCircle} />

                            {this.props.t('adminapplicationspage.btn_add_application')}
                        </IcButton>
                    </IcFloatRow>

                    <IcErrorBox error={this.state.error} />

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        {this.props.t('adminapplicationspage.txt_col_name')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('adminapplicationspage.txt_col_action')}
                                    </IcTableCell>
                                </IcTableRow>
                            </IcTableHead>

                            <IcTableBody>
                                {this.state.applications.map( ( application ) => (
                                    <IcTableRow key={application.uid}>
                                        <IcTableCell>
                                            {application.names.find( o => o.locale === this.props.locale)?.value || '???'}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcPopupMenu>
                                                <IcPopupMenuItem to={LinkUtils.make('admin', 'application', application.uid, 'edit')}>
                                                    {this.props.t('adminapplicationspage.btn_edit')}
                                                </IcPopupMenuItem>

                                                <IcPopupMenuItem to={LinkUtils.make('admin', 'application', application.uid, 'delete')}>
                                                    {this.props.t('adminapplicationspage.btn_delete')}
                                                </IcPopupMenuItem>
                                            </IcPopupMenu>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.applications.length === 0 && !this.state.loading ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={2}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            {this.props.t('adminapplicationspage.txt_no_applications')}
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>
                        
                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminApplicationsPage = withLocale(withTranslation()($AdminApplicationsPage));
