import React from 'react';
import DayJS from 'dayjs';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApplicationService, ApplicationV1, ApplicationV1Asset, ApplicationV1AssetType } from '../../Services/ApplicationService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { Box } from '../../Components/Box/Box';
import { BoxContent, BoxContentPadding } from '../../Components/Box/BoxContent';
import { UserService, UserV1 } from '../../Services/UserService';
import { PackageService, PackageV1, PackageV1Price, PackageV1Status, PackageV1Visibility, isFreePrice, maxFreeInterval, minInterval, minPriceInterval } from '../../Services/PackageService';
import { PriceTag } from '../../Components/PriceTag/PriceTag';
import { FreeTag } from '../../Components/FreeTag/FreeTag';
import { localeToCountry } from '../../Model/Country';
import { Formatter } from '../../utils/Formatter';
import { FileService } from '../../Services/FileService';
import { SupportBox } from '../../BusinessComponents/SupportBox/SupportBox';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { Environment } from '../../utils/Environment';
import { FeatureTag } from '../../Components/FeatureTag/FeatureTag';
import { TrackingEventType, TrackingService } from '../../Services/TrackingService';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcDummyContent, IcErrorBox, IcGridItem, IcGridPadding, IcGridRow, IcPageContent, IcSeparator, IcText, IcTextSize, IcVSpacer, IcVSpacerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './ApplicationDetailsPage.css';



export interface ApplicationDetailsPageRouteParams
{
    applicationUID: string;
}


export interface ApplicationDetailsPageProps extends RouteComponentProps<ApplicationDetailsPageRouteParams>, WithTranslation, WithLocaleProps
{
}


interface PriceWithPackage
{
    package:    PackageV1;
    price:      PackageV1Price;
}


interface ApplicationDetailsPageState
{
    application:            ApplicationV1 | null;
    assets:                 Array<ApplicationV1Asset>;
    selectedAsset:          ApplicationV1Asset | null;
    availablePackagePrices: Array<PriceWithPackage>;
    user:                   UserV1 | null;
    loading:                boolean;
    error:                  Error | null;
}


class $ApplicationDetailsPage extends React.Component<ApplicationDetailsPageProps, ApplicationDetailsPageState>
{
    private readonly _applicationService:   ApplicationService;
    private readonly _packageService:       PackageService;
    private readonly _userService:          UserService;
    private readonly _fileService:          FileService;
    private readonly _trackingService:      TrackingService;


    constructor ( props: ApplicationDetailsPageProps )
    {
        super(props);

        this.state = {
            application:            null,
            assets:                 [],
            selectedAsset:          null,
            availablePackagePrices: [],
            user:                   null,
            loading:                true,
            error:                  null
        };

        this._applicationService = ApplicationService.getInstance();
        this._packageService = PackageService.getInstance();
        this._userService = UserService.getInstance();
        this._fileService = FileService.getInstance();
        this._trackingService = TrackingService.getInstance();

        this._trackApplicationStarted = this._trackApplicationStarted.bind(this);
    }


    private _selectAsset ( asset: ApplicationV1Asset ): void
    {
        this.setState({
            selectedAsset: asset
        });
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const country = this.state.user?.organisations[0].country || localeToCountry(this.props.locale) || Environment.server.default_country;

            const application = await this._applicationService.getApplication(this.props.router.params.applicationUID);
            const packages = await this._packageService.getPackages(country, 0, 100); // TODO
            const availablePackages = packages.filter( ( pkg ) => pkg.status === PackageV1Status.Available && pkg.visibility === PackageV1Visibility.Public && pkg.details.licenses.find( ( lic ) => lic.license.application_uids.includes(application.uid) ) );
            const availablePackagePrices: Array<PriceWithPackage> = [];
            for ( const pkg of availablePackages )
            {
                for ( const pkgPrice of pkg.details.prices )
                {
                    if ( !pkgPrice.countries.includes(country) )
                    {
                        continue;
                    }

                    availablePackagePrices.push({
                        package:    pkg,
                        price:      pkgPrice
                    });
                }
            }

            const videoAssets = application.assets
                .filter( o => o.type === ApplicationV1AssetType.Video && o.locales.includes(this.props.locale) );
            
            const imageAssets = application.assets
                .filter( o => o.type === ApplicationV1AssetType.Image && o.locales.includes(this.props.locale) );

            const assets = [...videoAssets, ...imageAssets];
            const selectedAsset = assets.length > 0 ? assets[0] : null;

            this.setState({
                application,
                availablePackagePrices,
                assets,
                selectedAsset,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading application: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _trackApplicationStarted ( ): void
    {
        this._trackingService.push({
            event:      TrackingEventType.ApplicationStarted,
            message:    `Started application "${this.state.application?.names.find( o => o.locale === this.props.locale )?.value || ''}"`,
            value:      this.state.application?.uid
        });
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( user ) => 
        {
            this.setState({
                user
            });
        });

        const user = this._userService.isLoggedIn().get();
        this.setState({
            user
        });

        await this._load();
    }


    public async componentDidUpdate ( prevProps: Readonly<ApplicationDetailsPageProps> ): Promise<void>
    {
        if ( this.props.locale !== prevProps.locale )
        {
            await this._load();
        }
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        const hasLicense = !!(this.state.user && this.state.application && this.state.user.licenses.find( o => o.license.application_uids.includes(this.state.application!.uid) ));
        const name = this.state.application ? this.state.application.names.find( o => o.locale === this.props.locale )?.value : null;
        const slogan = this.state.application ? this.state.application.slogans.find( o => o.locale === this.props.locale )?.value : null;
        const description = this.state.application ? this.state.application.descriptions.find( o => o.locale === this.props.locale )?.value : '';
        const features = this.state.application ? this.state.application.features.find( o => o.locale === this.props.locale )?.values || [] : [];
        const freePrice = this.state.availablePackagePrices.find( o => isFreePrice(o.price)) || null;
        const minIntervalVal = minInterval(this.state.availablePackagePrices.map( o => o.price ));
        const maxFreeIntervalVal = maxFreeInterval(this.state.availablePackagePrices.map( o => o.price ));
        const minPriceIntervalVal = minPriceInterval(this.state.availablePackagePrices.map( o => o.price ));

        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('application', this.props.router.params.applicationUID)}
                        label={name || '...'}
                    />
                </IcBreadcrumbs>

                <PageTitle
                    title={this.state.application ? this.state.application.names.find( ( o ) => o.locale === this.props.locale )?.value || '???' : ''}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                <IcGridRow>
                    <IcGridItem s={12}>
                        <Box>
                            <IcGridRow
                                padding={IcGridPadding.None}
                                wrapReverse={true}>
                                <IcGridItem s={12} m={5} l={6} className='ApplicationDetailsBox-griditem'>
                                    <BoxContent
                                        padding={BoxContentPadding.Large}
                                        className='ApplicationDetailsBox-images'>
                                        {this.state.selectedAsset ?
                                            <div
                                                className='ApplicationDetailsBox-image large'>
                                                {this.state.selectedAsset.type === ApplicationV1AssetType.Image ?
                                                    <img src={this._fileService.getFileDataURI(this.state.selectedAsset.file_uid)} alt='' />
                                                : null}
                                                
                                                {this.state.selectedAsset.type === ApplicationV1AssetType.Video ?
                                                    <video autoPlay={false} controls={true}>
                                                        <source src={this._fileService.getFileDataURI(this.state.selectedAsset.file_uid)} />
                                                    </video>
                                                : null}
                                            </div>
                                        : null}
                                            
                                        {this.state.assets.map( ( asset ) => (
                                            <div
                                                className='ApplicationDetailsBox-image small'
                                                key={asset.file_uid}
                                                onClick={ ( ) => this._selectAsset(asset) }>
                                                {asset.type === ApplicationV1AssetType.Image ?
                                                    <img src={this._fileService.getFileDataURI(asset.file_uid)} alt='' />
                                                : null}
                                                
                                                {asset.type === ApplicationV1AssetType.Video ?
                                                    <video autoPlay={false} controls={true}>
                                                        <source src={this._fileService.getFileDataURI(asset.file_uid)} />
                                                    </video>
                                                : null}
                                            </div>
                                        ))}

                                        {this.state.assets.length === 0 ?
                                            <div className='ApplicationDetailsBox-image large' />
                                        : null}
                                    </BoxContent>
                                </IcGridItem>

                                <IcGridItem s={12} m={7} l={6} className='ApplicationDetailsBox-griditem'>
                                    <BoxContent padding={BoxContentPadding.Large}>
                                        {!this.state.application || slogan ?
                                            <IcText size={IcTextSize.Small} bold={true}>
                                                {this.state.application ?
                                                    slogan
                                                :
                                                    <IcDummyContent />
                                                }
                                            </IcText>
                                        : null}

                                        <IcText size={IcTextSize.Heading1}>
                                            {this.state.application ?
                                                name
                                            :
                                                <IcDummyContent />
                                            }
                                        </IcText>

                                        <IcText bold={true}>
                                            {this.props.t('applicationdetailspage.txt_description')}
                                        </IcText>

                                        <IcText>
                                            {this.state.application ?
                                                description
                                            :
                                                <>
                                                    <IcDummyContent /><br />
                                                    <IcDummyContent /><br />
                                                    <IcDummyContent /><br />
                                                    <IcDummyContent />
                                                </>
                                            }
                                        </IcText>

                                        <IcVSpacer size={IcVSpacerSize.Small} />

                                        {features.map( ( feature, i ) => (
                                            <FeatureTag
                                                key={i}
                                                name={feature}
                                            />
                                        ))}

                                        {hasLicense && this.state.application ?
                                            <IcButton
                                                href={this.state.application.login_uri}
                                                fullWidth={true}
                                                color={IcButtonColor.Secondary}
                                                onClick={this._trackApplicationStarted}
                                                target='_blank'>
                                                {this.props.t('applicationdetailspage.btn_start')}
                                            </IcButton>
                                        : null}
                                    
                                        {!hasLicense && this.state.application ?
                                            <div>
                                                <div className='ApplicationDetailsPage-price'>
                                                    {minPriceIntervalVal ?
                                                        <PriceTag
                                                            amount={minPriceIntervalVal.pricePeriod.gross * (DayJS.duration(minIntervalVal!).asSeconds() / DayJS.duration(minPriceIntervalVal.price.payment_interval).asSeconds())}
                                                            currency={minPriceIntervalVal.price.currency}
                                                            paymentInterval={minIntervalVal!}
                                                            startingAt={minPriceIntervalVal.price.periods.length > 1 || this.state.availablePackagePrices.length > 1}
                                                        />
                                                    : null}

                                                    {freePrice || maxFreeIntervalVal ?
                                                        <FreeTag maxDuration={freePrice ? freePrice.package.details.max_duration : maxFreeIntervalVal} />
                                                    : null}
                                                </div>

                                                <IcSeparator />

                                                {freePrice || maxFreeIntervalVal?
                                                    <IcButton
                                                        to={LinkUtils.make('packages') + `?application_uid=${encodeURIComponent(this.state.application.uid)}`}
                                                        fullWidth={true}>
                                                        {this.props.t('applicationdetailspage.btn_try_now')}
                                                    </IcButton>
                                                : null}

                                                {!freePrice && !maxFreeIntervalVal && minPriceIntervalVal ?
                                                    <IcButton
                                                        to={LinkUtils.make('packages') + `?application_uid=${encodeURIComponent(this.state.application.uid)}`}
                                                        fullWidth={true}>
                                                        {this.props.t('applicationdetailspage.btn_purchase_now')}
                                                    </IcButton>
                                                : null}

                                                {minPriceIntervalVal ?
                                                    <IcText size={IcTextSize.Small}>
                                                        {Formatter.vatRate(minPriceIntervalVal.price.rate_vat || 0, true)}
                                                    </IcText>
                                                : null}
                                            </div>
                                        : null}
                                    </BoxContent>
                                </IcGridItem>
                            </IcGridRow>
                        </Box>
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12} m={6}>
                        <SupportBox />
                    </IcGridItem>
                </IcGridRow>
            </IcPageContent>
        ); 
    }
}


export const ApplicationDetailsPage = withLocale(withTranslation()(withRouter($ApplicationDetailsPage)));
