import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { Form, Formik } from 'formik';
import { OrganisationService } from '../../Services/OrganisationService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { Country, EnabledCountries } from '../../Model/Country';
import { Formatter } from '../../utils/Formatter';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcInputSelect, IcInputSelectItem, IcSpinner, LinkUtils, Validator, RouteComponentProps, withRouter, IcGridRow, IcGridItem, IcFloatRow, IcFloatRowAlign, IcInputText, IcPageContent } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../Components/RequiredHint/RequiredHint';


export interface OrganisationAddPageProps extends RouteComponentProps, WithTranslation
{
}


interface OrganisationAddPageFormData
{
    name:       string;
    country:    Country | '';
}


interface OrganisationAddPageState
{
    initialFormData:    OrganisationAddPageFormData;
    error:              Error | null;
    loading:            boolean;
    success:            string | null;
}


class $OrganisationAddPage extends React.Component<OrganisationAddPageProps, OrganisationAddPageState>
{
    private readonly _organisationService:  OrganisationService;
    

    constructor ( props: OrganisationAddPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                name:       '',
                country:    ''
            },
            error:      null,
            loading:    false,
            success:    null
        };

        this._organisationService = OrganisationService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._add       = this._add.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _add ( formData: OrganisationAddPageFormData ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const organisationUID = await this._organisationService.addOrganisation({
                name:               formData.name.trim(),
                country:            formData.country as Country,
                payment_address:    null,
                payment_email:      null
            });

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('organisationaddpage.txt_success')
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('organisation', organisationUID));
        }
        catch ( err )
        {
            console.error(`Error adding organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public render ( )
    {
        const MyFormik = Formik<OrganisationAddPageFormData>;
        const optionsCountry: Array<IcInputSelectItem> = EnabledCountries.map( ( country ) => ({
            label:  Formatter.country(country),
            value:  country
        })).sort( ( a, b ) => a.label.localeCompare(b.label) );

        return (
            <IcPageContent>
                <PageTitle title={this.props.t('organisationaddpage.txt_add_organisation')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._add}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcGridRow>
                                <IcGridItem s={12}>
                                    <IcInputText
                                        label={this.props.t('organisationaddpage.inp_name')}
                                        name='name'
                                        required={true}
                                        validators={[
                                            Validator.minLength(1),
                                            Validator.maxLength(100)
                                        ]}
                                    />
                                </IcGridItem>

                                <IcGridItem s={12}>
                                    <IcInputSelect
                                        name='country'
                                        label={this.props.t('organisationaddpage.inp_country')}
                                        options={optionsCountry}
                                        required={true}
                                    />
                                </IcGridItem>
                            </IcGridRow>

                            <RequiredHint />

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton
                                    type='button'
                                    color={IcButtonColor.Link}
                                    onClick={this._cancel}>
                                    {this.props.t('organisationaddpage.btn_cancel')}
                                </IcButton>

                                <IcButton
                                    type='submit'
                                    disabled={this.state.loading}>
                                    {this.props.t('organisationaddpage.btn_add')}
                                </IcButton>
                            </IcFloatRow>
                        </IcCard>
                    </Form>
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const OrganisationAddPage = withTranslation()(withRouter($OrganisationAddPage));
