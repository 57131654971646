import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationService, OrganisationV1 } from '../../../Services/OrganisationService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeaderAction } from '../../../Components/List/ListItemHeaderAction';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { IcCard, IcCardPadding, IcSearchbar, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface PurchasePageFormStepSelectOrganisationBoxProps extends WithTranslation
{
    onChange:   ( organisation: OrganisationV1 ) => any;
}


interface PurchasePageFormStepSelectOrganisationBoxState
{
    organisations:      Array<OrganisationV1>;
    query:              string | null;
    loading:            boolean;
    error:              Error | null;
}



class $PurchasePageFormStepSelectOrganisationBox extends React.Component<PurchasePageFormStepSelectOrganisationBoxProps, PurchasePageFormStepSelectOrganisationBoxState>
{
    private readonly _organisationService:  OrganisationService;


    constructor ( props: PurchasePageFormStepSelectOrganisationBoxProps )
    {
        super(props);

        this.state = {
            organisations:  [],
            query:          null,
            loading:        true,
            error:          null
        };

        this._organisationService = OrganisationService.getInstance();

        this._load = this._load.bind(this);
    }


    private async _load ( query: string | null ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const organisations = await this._organisationService.getOrganisations(
                {
                    query: query ? query.trim() : null
                },
                0,
                5
            );

            this.setState({
                organisations,
                query: query ? query.trim() : null,
                loading: false,
                error:  null
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisations: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load(null);
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Medium}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('purchasepageformstepselectorganisationbox.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('purchasepageformstepselectorganisationbox.txt_select_organisation')}
                </IcText>

                <IcSearchbar
                    onSearch={ ( _, query ) => this._load(query) }
                />

                <List>
                    {this.state.organisations.map( ( organisation ) => (
                        <ListItem key={organisation.uid}>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    text={organisation.name}
                                    grow={true}
                                    onClick={ ( ) => this.props.onChange(organisation) }
                                />

                                <ListItemHeaderAction
                                    icon={faAngleRight}
                                    title={this.props.t('purchasepageformstepselectorganisationbox.btn_select')}
                                    onClick={ ( ) => this.props.onChange(organisation) }
                                />
                            </ListItemHeader>
                        </ListItem>
                    ))}
                </List>
            
                <IcSpinner active={this.state.loading} />
            </IcCard>
        );
    }
}


export const PurchasePageFormStepSelectOrganisationBox = withTranslation()($PurchasePageFormStepSelectOrganisationBox);
