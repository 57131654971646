import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContentService, ContentV1, ContentV1Key } from '../../Services/ContentService';
import { LocaleService } from '../../Services/LocaleService';
import { MarkdownBlock } from '../../Components/MarkdownBlock/MarkdownBlock';
import { CookieHintService } from '../../Services/CookieHintService';
import { IcButton, IcCard, IcCardPadding, IcDummyContent, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcPageContent } from '@indece-common/ic-ui-lib-react';


export interface PrivacyPolicyPageProps extends WithTranslation
{
}


interface PrivacyPolicyPageState
{
    content:    ContentV1 | null;
    loading:    boolean;
    error:      Error | null;
}


class $PrivacyPolicyPage extends React.Component<PrivacyPolicyPageProps, PrivacyPolicyPageState>
{
    private readonly _contentService:       ContentService;
    private readonly _localeService:        LocaleService;
    private readonly _cookieHintService:    CookieHintService;


    constructor ( props: PrivacyPolicyPageProps )
    {
        super(props);

        this.state = {
            content:    null,
            loading:    true,
            error:      null
        };

        this._contentService = ContentService.getInstance();
        this._localeService = LocaleService.getInstance();
        this._cookieHintService = CookieHintService.getInstance();

        this._editCookies = this._editCookies.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const content = await this._contentService.getContent(
                ContentV1Key.PrivacyPolicy,
                this._localeService.getLocale().get()
            );

            this.setState({
                content,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading content ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _editCookies ( ): void
    {
        this._cookieHintService.show();
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
        
        this._localeService.getLocale().subscribe(this, async ( ) =>
        {
            await this._load();
        });
    }


    public componentWillUnmount ( ): void
    {
        this._localeService.getLocale().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('privacypolicypage.txt_privacypolicy')} />

                <IcErrorBox error={this.state.error} />

                <IcCard padding={IcCardPadding.Small}>
                    {this.state.content ?
                        <MarkdownBlock text={this.state.content.content} />
                    :
                        <>
                            <IcDummyContent /><br />
                            <IcDummyContent /><br />
                            <IcDummyContent />
                        </>
                    }
                </IcCard>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                <IcButton onClick={this._editCookies}>
                                    {this.props.t('privacypolicypage.btn_edit_cookies')}
                                </IcButton>
                            </IcFloatRow>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>
            </IcPageContent>
        );
    }
}


export const PrivacyPolicyPage = withTranslation()($PrivacyPolicyPage);
