import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface AdminPackageDeletePageSuccessStepProps extends WithTranslation
{
}


class $AdminPackageDeletePageSuccessStep extends React.Component<AdminPackageDeletePageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                message={this.props.t('adminpackagedeletepagesuccessstep.txt_success')}
            />
        );
    }
}


export const AdminPackageDeletePageSuccessStep = withTranslation()($AdminPackageDeletePageSuccessStep);
