import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Environment } from '../../../utils/Environment';
import { PendingPaymentMethod } from '../../../Services/SessionService';
import { IcButton, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface OrganisationPaymentMethodAddPageSelectStepProps extends WithTranslation
{
    organisation:           OrganisationV1;
    pendingPaymentMethod:   PendingPaymentMethod;
    stripe:                 Stripe;
    onFinish:               ( error: Error | null ) => any;
}


interface OrganisationPaymentMethodAddPageSelectStepState
{
    loading:    boolean;
}


class $OrganisationPaymentMethodAddPageSelectStep extends React.Component<OrganisationPaymentMethodAddPageSelectStepProps, OrganisationPaymentMethodAddPageSelectStepState>
{
    constructor ( props: OrganisationPaymentMethodAddPageSelectStepProps )
    {
        super(props);

        this.state = {
            loading:    false
        };
    }


    private async _submit ( evt: React.FormEvent, elements: StripeElements ): Promise<void>
    {
        evt.preventDefault();

        this.setState({
            loading:    true
        });

        try
        {
            const result = await this.props.stripe.confirmSetup({
                elements: elements!,
                confirmParams: {
                    return_url: `${Environment.server.baseUrl}/organisation/${encodeURIComponent(this.props.organisation.uid)}/paymentmethod/${encodeURIComponent(this.props.pendingPaymentMethod.paymentmethod_uid)}/verify`,
                }
            });

            if ( result.error )
            {
                throw new Error(result.error.message);
            }

            this.setState({
                loading:    false
            });

            this.props.onFinish(null);
        }
        catch ( err )
        {
            console.error(`Error confirming stripe setup: ${(err as Error).message}`, err);

            this.setState({
                loading:    false
            });

            this.props.onFinish(err as Error);
        }
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('organisationpaymentmethodaddpageselectstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('organisationpaymentmethodaddpageselectstep.txt_select_paymentservice')}
                </IcText>

                <Elements
                    stripe={this.props.stripe}
                    options={{
                        clientSecret: this.props.pendingPaymentMethod.client_secret
                    }}>
                    <ElementsConsumer>
                        {({ elements }) => (
                            <form onSubmit={ ( evt ) => this._submit(evt, elements!) }>
                                <PaymentElement />

                                <IcSpinner active={this.state.loading} />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='submit'
                                        disabled={this.state.loading}>
                                        {this.props.t('organisationpaymentmethodaddpageselectstep.btn_enable')}
                                    </IcButton>
                                </IcFloatRow>
                            </form>
                        )}
                    </ElementsConsumer>
                </Elements>
            </IcCard>
        );
    }
}


export const OrganisationPaymentMethodAddPageSelectStep = withTranslation()($OrganisationPaymentMethodAddPageSelectStep);
