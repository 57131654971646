import { Locale, LocalizedMultiString, LocalizedString } from '../Model/Locale';
import { BackendService } from './BackendService';


export enum ApplicationV1AssetType
{
    Preview     = 'PREVIEW',
    Image       = 'IMAGE',
    Video       = 'VIDEO',
    Document    = 'DOCUMENT'
}


export const ApplicationV1AssetTypes: Array<ApplicationV1AssetType> = [
    ApplicationV1AssetType.Preview,
    ApplicationV1AssetType.Image,
    ApplicationV1AssetType.Video,
    ApplicationV1AssetType.Document
];


export interface ApplicationV1Asset
{
    file_uid:   string;
    type:       ApplicationV1AssetType;
    locales:    Array<Locale>;
}


export interface ApplicationV1
{
    uid:                        string;
    names:                      Array<LocalizedString>;
    slogans:                    Array<LocalizedString>;
    outlines:                   Array<LocalizedString>;
    descriptions:               Array<LocalizedString>;
    features:                   Array<LocalizedMultiString>;
    assets:                     Array<ApplicationV1Asset>;
    creator:                    string;
    home_uri:                   string;
    login_uri:                  string;
    support_uri:                string | null;
    applicationcategory_uid:    string;
    client_uid:                 string | null;
}


export interface AddApplicationV1
{
    names:                      Array<LocalizedString>;
    slogans:                    Array<LocalizedString>;
    outlines:                   Array<LocalizedString>;
    descriptions:               Array<LocalizedString>;
    features:                   Array<LocalizedMultiString>;
    assets:                     Array<ApplicationV1Asset>;
    creator:                    string;
    home_uri:                   string;
    login_uri:                  string;
    support_uri:                string | null;
    applicationcategory_uid:    string;
    client_uid:                 string | null;
}


export interface UpdateApplicationV1
{
    names:                      Array<LocalizedString>;
    slogans:                    Array<LocalizedString>;
    outlines:                   Array<LocalizedString>;
    descriptions:               Array<LocalizedString>;
    features:                   Array<LocalizedMultiString>;
    assets:                     Array<ApplicationV1Asset>;
    creator:                    string;
    home_uri:                   string;
    login_uri:                  string;
    support_uri:                string | null;
    applicationcategory_uid:    string;
    client_uid:                 string | null;
}


export interface ApplicationCategoryV1
{
    uid:        string;
    names:      Array<LocalizedString>;
    slogans:    Array<LocalizedString>;
}


export interface AddApplicationCategoryV1
{
    names:      Array<LocalizedString>;
    slogans:    Array<LocalizedString>;
}


export interface UpdateApplicationCategoryV1
{
    names:      Array<LocalizedString>;
    slogans:    Array<LocalizedString>;
}


export class ApplicationService
{
    private static _instance: ApplicationService;
    
    
    public static getInstance ( ): ApplicationService
    {
        if ( ! this._instance )
        {
            this._instance = new ApplicationService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getApplications ( from: number, size: number ): Promise<Array<ApplicationV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/application?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.applications;
    }


    public async getApplication ( applicationUID: string ): Promise<ApplicationV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/application/${encodeURIComponent(applicationUID)}`
        );

        return resp.application;
    }
    

    public async addApplication ( params: AddApplicationV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/application`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.application_uid;
    }
    

    public async updateApplication ( applicationUID: string, params: UpdateApplicationV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/application/${encodeURIComponent(applicationUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
    

    public async deleteApplication ( applicationUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/application/${encodeURIComponent(applicationUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }


    public async getApplicationCategories ( from: number, size: number ): Promise<Array<ApplicationCategoryV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/applicationcategory?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.applicationcategories;
    }


    public async getApplicationCategory ( applicationCategoryUID: string ): Promise<ApplicationV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/applicationcategory/${encodeURIComponent(applicationCategoryUID)}`
        );

        return resp.applicationcategory;
    }
    

    public async addApplicationCategory ( params: AddApplicationCategoryV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/applicationcategory`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.applicationcategory_uid;
    }
    

    public async updateApplicationCategory ( applicationCategoryUID: string, params: UpdateApplicationCategoryV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/applicationcategory/${encodeURIComponent(applicationCategoryUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
    

    public async deleteApplicationCategory ( applicationCategoryUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/applicationcategory/${encodeURIComponent(applicationCategoryUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
