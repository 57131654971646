import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { VoucherService, VoucherV1 } from '../../Services/VoucherService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminVoucherDeletePageLoadingStep } from './AdminVoucherDeletePageLoadingStep/AdminVoucherDeletePageLoadingStep';
import { AdminVoucherDeletePageSuccessStep } from './AdminVoucherDeletePageSuccessStep/AdminVoucherDeletePageSuccessStep';
import { AdminVoucherDeletePageConfirmStep } from './AdminVoucherDeletePageConfirmStep/AdminVoucherDeletePageConfirmStep';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminVoucherDeletePageRouteParams
{
    voucherUID: string;
}


export interface AdminVoucherDeletePageProps extends RouteComponentProps<AdminVoucherDeletePageRouteParams>, WithTranslation
{
}


enum AdminVoucherDeletePageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AdminVoucherDeletePageState
{
    step:       AdminVoucherDeletePageStep;
    voucher:    VoucherV1 | null;
    error:      Error | null;
}


class $AdminVoucherDeletePage extends React.Component<AdminVoucherDeletePageProps, AdminVoucherDeletePageState>
{
    private readonly _voucherService:  VoucherService;
    

    constructor ( props: AdminVoucherDeletePageProps )
    {
        super(props);

        this.state = {
            step:       AdminVoucherDeletePageStep.Loading,
            voucher:    null,
            error:      null
        };

        this._voucherService = VoucherService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminVoucherDeletePageStep.Loading,
                error:  null
            });

            const voucher = await this._voucherService.getVoucher(this.props.router.params.voucherUID);

            this.setState({
                step:   AdminVoucherDeletePageStep.Confirm,
                voucher
            });
        }
        catch ( err )
        {
            console.error(`Error loading voucher: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.voucher )
        {
            return;
        }

        try
        {
            this.setState({
                step:   AdminVoucherDeletePageStep.Loading,
                error:  null
            });

            await this._voucherService.deleteVoucher(this.state.voucher.uid);

            this.setState({
                step:   AdminVoucherDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting voucher: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminVoucherDeletePageStep.Confirm,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'vouchers')}
                        label={this.props.t('adminvoucherspage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'voucher', this.props.router.params.voucherUID, 'delete')}
                        label={this.props.t('adminvoucherdeletepage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle title={this.props.t('adminvoucherdeletepage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminVoucherDeletePageStep.Loading ?
                    <AdminVoucherDeletePageLoadingStep />
                : null}

                {this.state.step === AdminVoucherDeletePageStep.Confirm && this.state.voucher ?
                    <AdminVoucherDeletePageConfirmStep
                        voucher={this.state.voucher}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === AdminVoucherDeletePageStep.Success ?
                    <AdminVoucherDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminVoucherDeletePage = withTranslation()(withRouter($AdminVoucherDeletePage));
