import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { UserV1, UserV1License } from '../../../Services/UserService';
import { Formatter } from '../../../utils/Formatter';
import { UserDetails } from '../../../Components/UserDetails/UserDetails';
import { OrganisationDetails } from '../../../Components/OrganisationDetails/OrganisationDetails';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface UserLicenseUnassignPageConfirmStepProps extends WithTranslation
{
    organisation:   OrganisationV1;
    user:           UserV1;
    license:        UserV1License;
    onCancel:       ( ) => any;
    onFinish:       ( ) => any;
}


class $UserLicenseUnassignPageConfirmStep extends React.Component<UserLicenseUnassignPageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('userlicenseunassignpageconfirmstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('userlicenseunassignpageconfirmstep.txt_confirm')}
                </IcText>

                <OrganisationDetails organisation={this.props.organisation} />

                <UserDetails user={this.props.user} />

                <List>
                    <ListItem>
                        <ListItemHeader>
                            <ListItemHeaderField
                                grow={true}
                                text={this.props.license.license.name}
                            />
                            
                            <ListItemHeaderField
                                grow={true}
                                text={Formatter.username(this.props.user)}
                            />
                        </ListItemHeader>
                    </ListItem>
                </List>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}
                        testID='userlicenseunassignpageconfirmstep.btn_cancel'>
                        {this.props.t('userlicenseunassignpageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}
                        testID='userlicenseunassignpageconfirmstep.btn_unassign'>
                        {this.props.t('userlicenseunassignpageconfirmstep.btn_unassign')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const UserLicenseUnassignPageConfirmStep = withTranslation()($UserLicenseUnassignPageConfirmStep);
