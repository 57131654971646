import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcCard, IcCardPadding, IcSpinner } from '@indece-common/ic-ui-lib-react';


export interface AdminVoucherDeletePageLoadingStepProps extends WithTranslation
{
}


class $AdminVoucherDeletePageLoadingStep extends React.Component<AdminVoucherDeletePageLoadingStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}> 
                <IcSpinner
                    message={this.props.t('adminvoucherdeletepageloadingstep.txt_loading')}
                />
            </IcCard>
        );
    }
}


export const AdminVoucherDeletePageLoadingStep = withTranslation()($AdminVoucherDeletePageLoadingStep);
