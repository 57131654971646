import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PackageService, PackageV1, PackageV1Price, UpdatePackageV1PriceMode } from '../../Services/PackageService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { localeToCountry } from '../../Model/Country';
import { Environment } from '../../utils/Environment';
import { AdminPackagePriceEditPageFormStep, AdminPackagePriceEditPageFormStepFormData } from './AdminPackagePriceEditPageFormStep/AdminPackagePriceEditPageFormStep';
import { AdminPackagePriceEditPageLoadingStep } from './AdminPackagePriceEditPageLoadingStep/AdminPackagePriceEditPageLoadingStep';
import { AdminPackagePriceEditPageSuccessStep } from './AdminPackagePriceEditPageSuccessStep/AdminPackagePriceEditPageSuccessStep';
import { sleep } from 'ts-delay';
import { AdminPackagePriceEditPageModeStep } from './AdminPackagePriceEditPageModeStep/AdminPackagePriceEditPageModeStep';
import { AdminPackagePriceEditPageConfirmStep } from './AdminPackagePriceEditPageConfirmStep/AdminPackagePriceEditPageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminPackagePriceEditPageRouteParams
{
    packageUID:         string;
    packagePriceUID:    string;
}


export interface AdminPackagePriceEditPageProps extends RouteComponentProps<AdminPackagePriceEditPageRouteParams>, WithTranslation, WithLocaleProps
{
}


enum AdminPackagePriceEditPageStep
{
    Loading = 'LOADING',
    Mode    = 'MODE',
    Form    = 'FORM',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AdminPackagePriceEditPageState
{
    currStep:       AdminPackagePriceEditPageStep;
    mode:           UpdatePackageV1PriceMode | null;
    package:        PackageV1 | null;
    packagePrice:   PackageV1Price | null;
    error:          Error | null;
}


class $AdminPackagePriceEditPage extends React.Component<AdminPackagePriceEditPageProps, AdminPackagePriceEditPageState>
{
    private readonly _packageService:   PackageService;


    constructor ( props: AdminPackagePriceEditPageProps )
    {
        super(props);

        this.state = {
            currStep:       AdminPackagePriceEditPageStep.Loading,
            mode:           null,
            package:        null,
            packagePrice:   null,
            error:          null
        };

        this._packageService = PackageService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishMode = this._finishMode.bind(this);
        this._finishForm = this._finishForm.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   AdminPackagePriceEditPageStep.Loading,
                error:      null
            });

            const country = localeToCountry(this.props.locale) || Environment.server.default_country;
            const pkg = await this._packageService.getPackage(this.props.router.params.packageUID, country);
            const pkgPrice = pkg.details.prices.find( o => o.uid === this.props.router.params.packagePriceUID );
            if ( ! pkgPrice )
            {
                throw new Error('Price not found');
            }

            this.setState({
                package:        pkg,
                packagePrice:   pkgPrice,
                currStep:       AdminPackagePriceEditPageStep.Mode
            });
        }
        catch ( err )
        {
            console.error(`Error loading package: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                currStep:   AdminPackagePriceEditPageStep.Loading
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishMode ( mode: UpdatePackageV1PriceMode ): Promise<void>
    {
        this.setState({
            mode,
            currStep: AdminPackagePriceEditPageStep.Form
        });
    }


    private async _finishForm ( formData: AdminPackagePriceEditPageFormStepFormData ): Promise<void>
    {
        if ( !this.state.package || !this.state.packagePrice )
        {
            return;
        }

        // TODO

        this.setState({
            currStep:   AdminPackagePriceEditPageStep.Confirm
        });
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( !this.state.package || !this.state.packagePrice )
        {
            return;
        }

        try
        {
            this.setState({
                currStep:   AdminPackagePriceEditPageStep.Loading,
                error:      null
            });

            /* TODO
            await this._licenseService.updateLicense(
                this.state.license.uid,
                {
                    tag:                formData.tag.trim(),
                    name:               formData.name.trim(),
                    application_uids:   formData.application_uids
                }
            );*/

            this.setState({
                currStep:   AdminPackagePriceEditPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating package price: ${(err as Error).message}`, err);

            this.setState({
                currStep:   AdminPackagePriceEditPageStep.Form,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminpackagepriceeditpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === AdminPackagePriceEditPageStep.Loading ?
                    <AdminPackagePriceEditPageLoadingStep />
                : null}

                {this.state.currStep === AdminPackagePriceEditPageStep.Mode ?
                    <AdminPackagePriceEditPageModeStep
                        onCancel={this._cancel}
                        onFinish={this._finishMode}
                    />
                : null}

                {this.state.currStep === AdminPackagePriceEditPageStep.Form && this.state.packagePrice ?
                    <AdminPackagePriceEditPageFormStep
                        packagePrice={this.state.packagePrice}
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.currStep === AdminPackagePriceEditPageStep.Confirm && this.state.packagePrice ?
                    <AdminPackagePriceEditPageConfirmStep
                        packagePrice={this.state.packagePrice}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.currStep === AdminPackagePriceEditPageStep.Success ?
                    <AdminPackagePriceEditPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminPackagePriceEditPage = withTranslation()(withLocale(withRouter($AdminPackagePriceEditPage)));
