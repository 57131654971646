import { Country } from '../Model/Country';
import { Currency } from '../Model/Currency';
import { Locale } from '../Model/Locale';
import { BackendService } from './BackendService';


export enum ConfigPropertyV1Key
{
    Vat                             = 'VAT',
    Favicon32x32                    = 'FAVICON_32X32',
    Logo                            = 'LOGO',
    CustomCSS                       = 'CUSTOM_CSS',
    StripePublishablekey            = 'STRIPE_PUBLISHABLEKEY',
    StripeAccesskey                 = 'STRIPE_ACCESSKEY',
    CompanyName                     = 'COMPANY_NAME',
    CompanySlogan                   = 'COMPANY_SLOGAN',
    CompanyAddressStreet            = 'COMPANY_ADDRESS_STREET',
    CompanyAddressZip               = 'COMPANY_ADDRESS_ZIP',
    CompanyAddressCity              = 'COMPANY_ADDRESS_CITY',
    ServerName                      = 'SERVER_NAME',
    ServerBaseURL                   = 'SERVER_BASE_URL',
    ServerMaxOrganisationsPerUser   = 'SERVER_MAX_ORGANISATIONS_PER_USER',
    ServerRegistrationDisabled      = 'SERVER_REGISTRATION_DISABLED',
    ServerDeveloperToolsEnabled     = 'SERVER_DEVELOPERTOOLS_ENABLED',
    SmtpHost                        = 'SMTP_HOST',
    SmtpPort                        = 'SMTP_PORT',
    SmtpUser                        = 'SMTP_USER',
    SmtpPassword                    = 'SMTP_PASSWORD',
    SmtpFrom                        = 'SMTP_FROM',
    Locales                         = 'LOCALES',
    Countries                       = 'COUNTRIES',
    DefaultCountry                  = 'DEFAULT_COUNTRY',
    EmailFooterText                 = 'EMAIL_FOOTER_TEXT',
    EmailFooterHTML                 = 'EMAIL_FOOTER_HTML',
    LinkSupportUrl                  = 'LINK_SUPPORT_URL',
    LinkImprintUrl                  = 'LINK_IMPRINT_URL',
    BillFooter                      = 'BILL_FOOTER',
    BillNumberPrefix                = 'BILL_NUMBER_PREFIX',
    BillNumberNext                  = 'BILL_NUMBER_NEXT',
    CustomerNumberPrefix            = 'CUSTOMER_NUMBER_PREFIX',
    CustomerNumberNext              = 'CUSTOMER_NUMBER_NEXT',
    GtmID                           = 'GTM_ID',
    MatomoURL                       = 'MATOMO_URL',
    MatomoID                        = 'MATOMO_ID',
    DatevAccountExtern              = 'DATEV_ACCOUNT_EXTERN',
    ZohoEnabled                     = 'ZOHO_ENABLED',
    ZohoClientID                    = 'ZOHO_CLIENTID',
    ZohoUser                        = 'ZOHO_USER'
}


export enum ConfigPropertyV1Bool
{
    True    = 'true',
    False   = 'false'
}


export interface ConfigPropertyV1
{
    key:        ConfigPropertyV1Key;
    value:      string;
    editable:   boolean;
}


export interface ConfigPropertyV1Locales
{
    locales:    Array<Locale>;
}


export interface ConfigPropertyV1Countries
{
    countries:  Array<Country>;
}


export interface ConfigPropertyV1VatCountry
{
    currency:   Lowercase<Currency>;
    rate_vat:   number;
}


export interface ConfigPropertyV1Vat
{
    default:    ConfigPropertyV1VatCountry;
    countries:  Partial<Record<Country, ConfigPropertyV1VatCountry>>;
}


export interface SetConfigPropertyV1Request
{
    key:    ConfigPropertyV1Key;
    value:  string;
}


export class ConfigService
{
    private static _instance:           ConfigService;
    private readonly _backendService:   BackendService;
    
    
    public static getInstance ( ): ConfigService
    {
        if ( ! this._instance )
        {
            this._instance = new ConfigService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getConfig ( ): Promise<Partial<Record<ConfigPropertyV1Key, ConfigPropertyV1>>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/config`,
            {
                method: 'GET',
                headers:    {
                    'Accept':       'application/json'
                }
            }
        );

        const config: Partial<Record<ConfigPropertyV1Key, ConfigPropertyV1>> = {};
        for ( const property of resp.properties )
        {
            config[property.key as ConfigPropertyV1Key] = property;
        }

        return config;
    }

    
    public async finishSetup ( ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/setup/finish`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:   JSON.stringify({})
            }
        );
    }
   
   
    public async setConfigProperty ( key: ConfigPropertyV1Key, value: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/config/${encodeURIComponent(key)}`,
            {
                method:     'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:   JSON.stringify({
                    value
                })
            }
        );
    }
}
