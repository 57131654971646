import { StateSubject } from 'ts-subject';
import { Address } from '../Model/Address';
import { Country } from '../Model/Country';
import { BackendService } from './BackendService';
import { LicenseV1 } from './LicenseService';
import { PaymentService } from './PackageService';
import { UserV1OrganisationRole } from './UserService';


export interface OrganisationV1
{
    uid:                string;
    name:               string;
    country:            Country;
    payment_address:    Address | null;
    payment_email:      string | null;
}


export interface OrganisationV1License
{
    license:    LicenseV1;
    assigned:   boolean;
}


export interface PaymentMethodV1
{
    uid:                string;
    payment_service:    PaymentService | null;
    title:              string;
    expiry_label:       string | null;
    verified:           boolean;
    default:            boolean;
}


export interface ReqGetOrganisationsV1
{
    query?: string | null;
}


export interface AddOrganisationV1
{
    name:               string;
    country:            Country;
    payment_address:    Address | null;
    payment_email:      string | null;
}


export interface UpdateOrganisationV1
{
    name:               string;
    country:            Country | null;
    payment_address:    Address | null;
    payment_email:      string | null;
}


export interface AddOrganisationPaymentMethodV1
{
}


export interface AddOrganisationPaymentMethodV1Result
{
    paymentmethod_uid:      string;
    stripe_client_secret:   string;
}


export interface VerifyOrganisationPaymentMethodV1
{
}


export interface AddOrganisationMemberV1
{
    user_uid:   string;
    role:       UserV1OrganisationRole;
}


export interface UpdateOrganisationMemberV1
{
    role:   UserV1OrganisationRole;
}


export interface ReqAssignOrganisationMemberLicenseV1
{
    license_uid:    string;
}


export class OrganisationService
{
    private static _instance: OrganisationService;
    
    
    public static getInstance ( ): OrganisationService
    {
        if ( ! this._instance )
        {
            this._instance = new OrganisationService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;
    private readonly _subjectSelectedOrganisation: StateSubject<OrganisationV1 | null>;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
        this._subjectSelectedOrganisation = new StateSubject<OrganisationV1 | null>(null);
    }


    public async getOrganisations ( params: ReqGetOrganisationsV1, from: number, size: number ): Promise<Array<OrganisationV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/organisation?` +
            `query=${encodeURIComponent(params.query || '')}&` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.organisations;
    }


    public async getOrganisation ( organisationUID: string ): Promise<OrganisationV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}`
        );

        return resp.organisation;
    }
    

    public async addOrganisation ( params: AddOrganisationV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/organisation`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.organisation_uid;
    }
    

    public async updateOrganisation ( organisationUID: string, params: UpdateOrganisationV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        if ( this._subjectSelectedOrganisation.get()?.uid === organisationUID )
        {
            const organisation = await this.getOrganisation(organisationUID);

            this._subjectSelectedOrganisation.next(organisation);
        }
    }
    

    public async deleteOrganisation ( organisationUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        if ( this._subjectSelectedOrganisation.get()?.uid === organisationUID )
        {
            this._subjectSelectedOrganisation.next(null);
        }
    }


    public getSelectedOrganisation ( ): StateSubject<OrganisationV1 | null>
    {
        return this._subjectSelectedOrganisation;
    }


    public selectedOrganisation ( organisation: OrganisationV1 ): void
    {
        this._subjectSelectedOrganisation.next(organisation);
    }


    public async getOrganisationLicenses ( organisationUID: string, from: number, size: number ): Promise<Array<OrganisationV1License>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/license?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.licenses;
    }


    public async addOrganisationMember ( organisationUID: string, params: AddOrganisationMemberV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/user`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async acceptOrganisationMember ( organisationUID: string, userUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/user/${encodeURIComponent(userUID)}/accept`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify({})
            }
        );
    }


    public async updateOrganisationMember ( organisationUID: string, userUID: string, params: UpdateOrganisationMemberV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/user/${encodeURIComponent(userUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async deleteOrganisationMember ( organisationUID: string, userUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/user/${encodeURIComponent(userUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }


    public async getOrganisationPaymentMethods ( organisationUID: string ): Promise<Array<PaymentMethodV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/paymentmethod`
        );

        return resp.paymentmethods;
    }



    public async addOrganisationPaymentMethod ( organisationUID: string, params: AddOrganisationPaymentMethodV1 ): Promise<AddOrganisationPaymentMethodV1Result>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/paymentmethod`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }


    public async verifyOrganisationPaymentMethod ( organisationUID: string, paymentMethodUID: string, params: VerifyOrganisationPaymentMethodV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/paymentmethod/${encodeURIComponent(paymentMethodUID)}/verify`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async setDefaultOrganisationPaymentMethod ( organisationUID: string, paymentMethodUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/paymentmethod/${encodeURIComponent(paymentMethodUID)}/default`,
            {
                method: 'POST',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }


    public async deleteOrganisationPaymentMethod ( organisationUID: string, paymentMethodUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/paymentmethod/${encodeURIComponent(paymentMethodUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }


    public async assignOrganisationMemberLicense ( organisationUID: string, userUID: string, params: ReqAssignOrganisationMemberLicenseV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/user/${encodeURIComponent(userUID)}/license`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }


    public async unassignOrganisationMemberLicense ( organisationUID: string, userUID: string, licenseUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/organisation/${encodeURIComponent(organisationUID)}/user/${encodeURIComponent(userUID)}/license/${encodeURIComponent(licenseUID)}`,
            {
                method:     'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
