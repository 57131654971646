import React from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Formatter } from '../../utils/Formatter';
import { IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';

import './FreeTag.css';


export interface FreeTagProps extends WithTranslation
{
    maxDuration:    string | null;
    showIcon?:      boolean;
    onClick?:       ( ) => any;
}


class $FreeTag extends React.Component<FreeTagProps>
{
    public render ( )
    {
        return (
            <div
                className={`FreeTag ${this.props.onClick ? 'clickable' : ''}`}
                onClick={this.props.onClick}>
                {this.props.showIcon !== false ?
                    <div className='FreeTag-icon'>
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                : null}

                <div className='FreeTag-text'>
                    <IcText size={IcTextSize.Extrasmall} bold={true}>
                        {this.props.maxDuration ?
                            this.props.t('freetag.txt_part1_duration', {duration: Formatter.duration(this.props.maxDuration)})
                        :
                            this.props.t('freetag.txt_part1')
                        }
                    </IcText>

                    <IcText size={IcTextSize.Extrasmall}>
                        {this.props.t('freetag.txt_part2')}
                    </IcText>
                </div>
            </div>
        );
    }
}


export const FreeTag = withTranslation()($FreeTag);
