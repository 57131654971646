import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ClientService, ClientV1 } from '../../Services/ClientService';
import { ConfigPropertyV1Key, ConfigService } from '../../Services/ConfigService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { IcErrorBox, IcPageContent, IcSpinner, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminClientDetailsPageRouteParams
{
    clientUID: string;
}


export interface AdminClientDetailsPageProps extends RouteComponentProps<AdminClientDetailsPageRouteParams>, WithTranslation
{
}


interface AdminClientDetailsPageState
{
    client:     ClientV1 | null;
    loading:    boolean;
    error:      Error | null;
    serverBase: string;
}


class $AdminClientDetailsPage extends React.Component<AdminClientDetailsPageProps, AdminClientDetailsPageState>
{
    private readonly _clientService:    ClientService;
    private readonly _configService:    ConfigService;


    constructor ( props: AdminClientDetailsPageProps )
    {
        super(props);

        this.state = {
            client:     null,
            loading:    true,
            error:      null,
            serverBase: ''
        }

        this._clientService = ClientService.getInstance();
        this._configService = ConfigService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const client = await this._clientService.getClient(this.props.router.params.clientUID);
            const config = await this._configService.getConfig();

            this.setState({
                client,
                serverBase: config[ConfigPropertyV1Key.ServerBaseURL]?.value || '',
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading client: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={`Client ${this.state.client?.client_id || '???'}`}
                />

                <IcErrorBox error={this.state.error} />

                <IcSpinner active={this.state.loading} />
            </IcPageContent>
        );
    }
}


export const AdminClientDetailsPage = withTranslation()(withRouter($AdminClientDetailsPage));
