import { BackendService } from './BackendService';


export interface VoucherV1Details
{
    package_uids:               Array<string>;
    max_count:                  number | null;
    max_duration:               string | null;
    price_reduction_percent:    number;
}


export enum VoucherV1Type
{
    SingleUse   = 'SINGLE_USE',
    MultiUse    = 'MULTI_USE'
}


export const VoucherV1Types: Array<VoucherV1Type> = [
    VoucherV1Type.SingleUse,
    VoucherV1Type.MultiUse
];


export interface VoucherV1
{
    uid:        string;
    type:       VoucherV1Type;
    code:       string;
    details:    VoucherV1Details;
    activated:  boolean;
}


export interface AddVoucherV1
{
    type:       VoucherV1Type;
    code:       string | null;
    details:    VoucherV1Details;
}


export interface AddVoucherV1Response
{
    voucher_uid:    string;
    code:           string;
}


export interface UpdateVoucherV1
{
    details:    VoucherV1Details;
}


export class VoucherService
{
    private static _instance: VoucherService;
    
    
    public static getInstance ( ): VoucherService
    {
        if ( ! this._instance )
        {
            this._instance = new VoucherService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getVouchers ( from: number, size: number ): Promise<Array<VoucherV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/voucher?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.vouchers;
    }


    public async getVoucher ( voucherUID: string ): Promise<VoucherV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/voucher/${encodeURIComponent(voucherUID)}`
        );

        return resp.voucher;
    }


    public async getVoucherByCode ( voucherCode: string ): Promise<VoucherV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/voucher/by-code/${encodeURIComponent(voucherCode)}`
        );

        return resp.voucher;
    }
    

    public async addVoucher ( params: AddVoucherV1 ): Promise<AddVoucherV1Response>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/voucher`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'voucher/json',
                    'Content-Type': 'voucher/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp;
    }
    

    public async updateVoucher ( voucherUID: string, params: UpdateVoucherV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/voucher/${encodeURIComponent(voucherUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'voucher/json',
                    'Content-Type': 'voucher/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
    

    public async deleteVoucher ( voucherUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/voucher/${encodeURIComponent(voucherUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'voucher/json'
                }
            }
        );
    }
}
