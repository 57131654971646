import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface AdminPackagePriceDeletePageSuccessStepProps extends WithTranslation
{
}


class $AdminPackagePriceDeletePageSuccessStep extends React.Component<AdminPackagePriceDeletePageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                testID='adminpackagepricedeletepagesuccessstep.txt_success'
                message={this.props.t('adminpackagepricedeletepagesuccessstep.txt_success')}
            />
        );
    }
}


export const AdminPackagePriceDeletePageSuccessStep = withTranslation()($AdminPackagePriceDeletePageSuccessStep);
