import React from 'react';
import DayJS from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { LogService, LogV1 } from '../../Services/LogService';
import { ListItemBody } from '../../Components/List/ListItemBody';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcCard, IcCardPadding, IcErrorBox, IcPageContent, IcSpinner, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminLogsPageProps extends WithTranslation
{
}


interface AdminLogsPageState
{
    logs:       Array<LogV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


class $AdminLogsPage extends React.Component<AdminLogsPageProps, AdminLogsPageState>
{
    private readonly BULK_SIZE      = 50;
    private readonly _logService:   LogService;


    constructor ( props: AdminLogsPageProps )
    {
        super(props);

        this.state = {
            logs:       [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._logService = LogService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const logs = await this._logService.getLogs(0, this.BULK_SIZE);

            this.setState({
                logs,
                hasMore:    logs.length >= this.BULK_SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading logs: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const logs = await this._logService.getLogs(this.state.logs.length, this.BULK_SIZE);

            this.setState({
                logs:       [...this.state.logs, ...logs],
                hasMore:    logs.length >= this.BULK_SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more logs: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'logs')}
                        label={this.props.t('adminlogspage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminlogspage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcErrorBox error={this.state.error} />

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <List>
                            {this.state.logs.map( ( log ) => (
                                <ListItem key={log.uid}>
                                    <ListItemHeader>
                                        <ListItemHeaderField
                                            grow={true}
                                            text={log.type}
                                            subtext={DayJS(log.datetime_created).format('YYYY-MM-DD HH:mm')}
                                        />

                                        <ListItemHeaderField
                                            text={log.user ? `${log.user.firstname} ${log.user.lastname}` : ''}
                                        />
                                    </ListItemHeader>

                                    <ListItemBody>
                                        {JSON.stringify(JSON.parse(log.details), null, 4)}
                                    </ListItemBody>
                                </ListItem>
                            ))}
                        </List>

                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminLogsPage = withTranslation()($AdminLogsPage);
