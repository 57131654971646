import { t } from 'i18next';
import { BackendError, BackendNetworkError } from '../Services/BackendService';


export enum ErrorCode {
    NetworkError                = 'NETWORK_ERROR',
    InternalError               = 'INTERNAL_ERROR',
    InternalServerError         = 'INTERNAL_SERVER_ERROR',
    PasswordWrong               = 'PASSWORD_WRONG',
    EmailPasswordWrong          = 'EMAIL_PASSWORD_WRONG',
    UserLocked                  = 'USER_LOCKED',
    EmailAlreadyInUse           = 'EMAIL_ALREADY_IN_USE',
    EmailCodeWrong              = 'EMAIL_CODE_WRONG',
	TotpCodeWrong               = 'TOTP_CODE_WRONG',
	RecoveryCodeWrong           = 'RECOVERY_CODE_WRONG',
	WebAuthNSignatureWrong      = 'WEBAUTHN_SIGNATURE_WRONG',
    AtLeastOneNameRequired      = 'AT_LEAST_ONE_NAME_REQUIRED',
    NoNewPurchaseCount          = 'NO_NEW_PURCHASE_COUNT',
    InvitationCodeWrong         = 'INVITATION_CODE_WRONG',
    InvitationCodeExpired       = 'INVITATION_CODE_EXPIRED',
	MaxTotalCountExceeded       = 'MAX_TOTAL_COUNT_EXCEEDED',
	MaxConcurrentCountExceeded  = 'MAX_CONCURRENT_COUNT_EXCEEDED',
	VoucherCodeWrong            = 'VOUCHER_CODE_WRONG',
    SessionStatusInvalid        = 'SESSION_STATUS_INVALID',
	ConfirmationCodeWrong       = 'CONFIRMATION_CODE_WRONG',
	ConfirmationCodeExpired     = 'CONFIRMATION_CODE_EXPIRED',
    LicenseAlreadyAssigned      = 'LICENSE_ALREADY_ASSIGNED',
    NoLicensesAvailable         = 'NO_LICENSES_AVAILABLE'
}


export const ErrorCodes: Array<ErrorCode> = [
    ErrorCode.NetworkError,
    ErrorCode.InternalError,
    ErrorCode.InternalServerError,
    ErrorCode.PasswordWrong,
    ErrorCode.EmailPasswordWrong,
    ErrorCode.UserLocked,
    ErrorCode.EmailAlreadyInUse,
    ErrorCode.EmailCodeWrong,
    ErrorCode.TotpCodeWrong,
    ErrorCode.RecoveryCodeWrong,
    ErrorCode.WebAuthNSignatureWrong,
    ErrorCode.AtLeastOneNameRequired,
    ErrorCode.NoNewPurchaseCount,
    ErrorCode.InvitationCodeWrong,
    ErrorCode.InvitationCodeExpired,
    ErrorCode.MaxTotalCountExceeded,
    ErrorCode.MaxConcurrentCountExceeded,
    ErrorCode.VoucherCodeWrong,
    ErrorCode.SessionStatusInvalid,
    ErrorCode.ConfirmationCodeWrong,
    ErrorCode.ConfirmationCodeExpired,
    ErrorCode.LicenseAlreadyAssigned,
    ErrorCode.NoLicensesAvailable
];


export class ErrorWithCode extends Error
{
    public readonly code: ErrorCode;


    constructor ( code: ErrorCode, message?: string )
    {
        super(message);

        this.code = code;
    }


    public static hasCode ( err: Error, code: ErrorCode ): boolean
    {
        return err && (err as ErrorWithCode).code === code;
    }


    public static isErrorWithCode ( err: Error )
    {
        return err && (err as ErrorWithCode).code && ErrorCodes.includes((err as ErrorWithCode).code);
    }
}


export class ErrorTranslator
{
    public static translate ( err: Error | BackendError | BackendNetworkError | null ): string | null
    {
        if ( ! err )
        {
            return null;
        }

        let code: ErrorCode = ErrorCode.InternalError;

        if ( ErrorWithCode.isErrorWithCode(err) )
        {
            code = (err as ErrorWithCode).code;
        }
        else if ( BackendNetworkError.isBackendNetworkError(err) )
        {
            code = ErrorCode.NetworkError;
        }
        else if ( BackendError.isBackendError(err) &&
             (err as BackendError).code &&
             ErrorCodes.includes(((err as BackendError).code) as ErrorCode) )
        {
            code = ((err as BackendError).code) as ErrorCode;
        }

        return t(`errorcode.${code.toLowerCase()}`);
    }
}
