import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { OrganisationEditPageFormStep, OrganisationEditPageFormStepFormData } from './OrganisationEditPageFormStep/OrganisationEditPageFormStep';
import { OrganisationEditPageSuccessStep } from './OrganisationEditPageSuccessStep/OrganisationEditPageSuccessStep';
import { OrganisationEditPageLoadingStep } from './OrganisationEditPageLoadingStep/OrganisationEditPageLoadingStep';
import { isAdmin, UserService, UserV1 } from '../../Services/UserService';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface OrganisationEditPageRouteParams
{
    organisationUID:  string;
}


export interface OrganisationEditPageProps extends RouteComponentProps<OrganisationEditPageRouteParams>, WithTranslation
{
}


enum OrganisationEditPageStep
{
    Loading = 'LOADING',
    Form    = 'FORM',
    Success = 'SUCCESS'
}


interface OrganisationEditPageState
{
    currStep:       OrganisationEditPageStep;
    ownUser:        UserV1 | null;
    organisation:   OrganisationV1 | null;
    error:          Error | null;
}


class $OrganisationEditPage extends React.Component<OrganisationEditPageProps, OrganisationEditPageState>
{
    private readonly _organisationService:  OrganisationService;
    private readonly _userService:          UserService;
    

    constructor ( props: OrganisationEditPageProps )
    {
        super(props);

        this.state = {
            currStep:       OrganisationEditPageStep.Loading,
            ownUser:        null,
            organisation:   null,
            error:          null
        };

        this._organisationService = OrganisationService.getInstance();
        this._userService = UserService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishForm = this._finishForm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                currStep:   OrganisationEditPageStep.Loading,
                error:      null
            });

            const organisation = await this._organisationService.getOrganisation(this.props.router.params.organisationUID);

            this.setState({
                error:      null,
                currStep:   OrganisationEditPageStep.Form,
                organisation
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishForm ( formData: OrganisationEditPageFormStepFormData ): Promise<void>
    {
        try
        {
            if ( ! this.state.organisation )
            {
                throw new Error('No organisation loaded');
            }
         
            this.setState({
                currStep:   OrganisationEditPageStep.Loading,
                error:      null
            });

            let country = this.state.organisation.country;

            if ( this.state.ownUser && isAdmin(this.state.ownUser) )
            {
                country = formData.country;
            }

            await this._organisationService.updateOrganisation(
                this.state.organisation.uid,
                {
                    name:               formData.name.trim(),
                    country,
                    payment_address:    formData.payment_address_street && formData.payment_address_zip && formData.payment_address_city ? {
                        title:              formData.payment_address_title.trim() || null,
                        gender:             formData.payment_address_gender || null,
                        firstname:          formData.payment_address_firstname.trim(),
                        lastname:           formData.payment_address_lastname.trim(),
                        street:             formData.payment_address_street.trim(),
                        zip:                formData.payment_address_zip.trim(),
                        city:               formData.payment_address_city.trim(),
                    } : null,
                    payment_email:      formData.payment_email.trim() || null
                }
            );

            this.setState({
                currStep:   OrganisationEditPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating organisation: ${(err as Error).message}`, err);

            this.setState({
                currStep:   OrganisationEditPageStep.Form,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });
        
        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });
    
        await this._load();
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('organisationeditpage.txt_edit_organisation')} />

                <IcErrorBox error={this.state.error} />

                {this.state.currStep === OrganisationEditPageStep.Loading ?
                    <OrganisationEditPageLoadingStep />
                : null}

                {this.state.currStep === OrganisationEditPageStep.Form && this.state.organisation ?
                    <OrganisationEditPageFormStep
                        organisation={this.state.organisation}
                        ownUser={this.state.ownUser}
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.currStep === OrganisationEditPageStep.Success ?
                    <OrganisationEditPageSuccessStep />
                : null}
            </IcPageContent>
        );
    } 
}


export const OrganisationEditPage = withTranslation()(withRouter($OrganisationEditPage));
