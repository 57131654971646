import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';
import { Form, Formik } from 'formik';
import { FormObserver } from '../../../utils/FormUtils';
import { PackageV1 } from '../../../Services/PackageService';
import { IcCard, IcCardPadding, IcInputText, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';


export interface PurchasePageFormStepCountBoxProps extends WithTranslation
{
    count:      number;
    package:    PackageV1;
    onChange:   ( count: number ) => any;
}


interface PurchasePageFormStepCountBoxFormData
{
    count:      string;
}


class $PurchasePageFormStepCountBox extends React.Component<PurchasePageFormStepCountBoxProps>
{
    public render ( )
    {
        const MyFormik = Formik<PurchasePageFormStepCountBoxFormData>;

        return (
            <IcCard padding={IcCardPadding.Medium}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('purchasepageformstepcountbox.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('purchasepageformstepcountbox.txt_select_count')}
                </IcText>

                <MyFormik
                    initialValues={{
                        count:  `${this.props.count}`
                    }}
                    enableReinitialize={true}
                    onSubmit={ ( ) => { } }>
                    <Form>
                        <FormObserver
                            onChange={ ( formData ) => formData.count.trim().match(/^\d+$/) ? this.props.onChange(parseInt(formData.count, 10)) : null }
                        />

                        <IcInputText
                            name='count'
                            label={this.props.t('purchasepageformstepcountbox.inp_count')}
                            required={true}
                            validators={[
                                Validator.min(1),
                                Validator.max(this.props.package.details.max_concurrent_count || 100000)
                            ]}
                        />
                    </Form>
                </MyFormik>

                <RequiredHint />
            </IcCard>
        );
    }
}


export const PurchasePageFormStepCountBox = withTranslation()($PurchasePageFormStepCountBox);
