import DayJS from 'dayjs';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LabelValue } from '../../Components/LabelValueList/LabelValue';
import { LabelValueList } from '../../Components/LabelValueList/LabelValueList';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { PurchaseService, PurchaseV1 } from '../../Services/PurchaseService';
import { Formatter } from '../../utils/Formatter';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { List } from '../../Components/List/List';
import { LocaleService } from '../../Services/LocaleService';
import { Locale } from '../../Model/Locale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcDummyContent, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcPageContent, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface PurchaseDetailsPageRouteParams
{
    purchaseUID: string;
}


export interface PurchaseDetailsPageProps extends WithTranslation, RouteComponentProps<PurchaseDetailsPageRouteParams>
{
}


interface PurchaseDetailsPageState
{
    purchase:   PurchaseV1 | null;
    locale:     Locale;
    loading:    boolean;
    error:      Error | null;
}


class $PurchaseDetailsPage extends React.Component<PurchaseDetailsPageProps, PurchaseDetailsPageState>
{
    private readonly _purchaseService: PurchaseService;
    private readonly _localeService: LocaleService;


    constructor ( props: PurchaseDetailsPageProps )
    {
        super(props);

        this.state = {
            purchase:   null,
            locale:     LocaleService.getInstance().getLocale().get(),
            loading:    true,
            error:      null
        };

        this._purchaseService = PurchaseService.getInstance();
        this._localeService = LocaleService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const purchase = await this._purchaseService.getPurchase(this.props.router.params.purchaseUID);

            this.setState({
                loading:    false,
                purchase
            });
        }
        catch ( err )
        {
            console.error(`Error loading purchase: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _renderState ( ): string | null
    {
        if ( ! this.state.purchase )
        {
            return null;
        }

        if ( this.state.purchase.datetime_cancel_at && DayJS(this.state.purchase.datetime_cancel_at).isBefore('now') )
        {
            return 'Canceled';
        }

        if ( this.state.purchase.datetime_cancel_at && DayJS(this.state.purchase.datetime_cancel_at).isAfter('now') )
        {
            return `Will be canceled at ${Formatter.date(this.state.purchase.datetime_cancel_at)}`;
        }
        
        if ( this.state.purchase.datetime_activated )
        {
            return 'Active';
        }

        return 'Inactive';
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._localeService.getLocale().subscribe(this, ( locale ) =>
        {
            this.setState({
                locale
            });
        });

        const locale = this._localeService.getLocale().get();
        this.setState({
            locale
        });

        await this._load();
    }


    public componentWillUnmount ( ): void
    {
        this._localeService.getLocale().unsubscribe(this);
    }


    public render ( )
    {
        const name = this.state.purchase ? this.state.purchase.package.names.find( o => o.locale === this.state.locale)?.value || '???' : '';
    
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('purchasedetailspage.txt_title', {name})}
                />
                
                <IcErrorBox error={this.state.error} />

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <LabelValueList>
                                <LabelValue label={this.props.t('purchasedetailspage.txt_date_purchase')}>
                                    {this.state.purchase ?
                                        Formatter.date(this.state.purchase.datetime_created)
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>

                                <LabelValue label={this.props.t('purchasedetailspage.txt_date_active')}>
                                    {this.state.purchase ?
                                        (this.state.purchase.datetime_activated ? Formatter.date(this.state.purchase.datetime_activated) : this.props.t('purchasedetailspage.txt_inactive'))
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>
                                
                                <LabelValue label={this.props.t('purchasedetailspage.txt_count')}>
                                    {this.state.purchase ?
                                        this.state.purchase.count
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>
                                
                                <LabelValue label={this.props.t('purchasedetailspage.txt_state')}>
                                    {this.state.purchase ?
                                        this._renderState()
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>
                                
                                <LabelValue label={this.props.t('purchasedetailspage.txt_price')}>
                                    {this.state.purchase ?
                                        (this.state.purchase.price_periods.find( o => o.is_current )?.price_net === 0 ?
                                            this.props.t('purchasedetailspage.txt_free')
                                        :
                                            Formatter.currencyAmount(this.state.purchase.price_periods.find( o => o.is_current )?.price_gross || 0, this.state.purchase.currency, this.state.purchase.payment_interval) + ' ' + Formatter.vatRate(this.state.purchase.rate_vat, true)
                                        )
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>
                            </LabelValueList>
                            
                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                {this.state.purchase ?
                                    <IcButton
                                        to={LinkUtils.make('purchase', this.state.purchase.uid, 'update_count')}
                                        color={IcButtonColor.Secondary}>
                                        {this.props.t('purchasedetailspage.btn_update_count')}
                                    </IcButton>
                                : null}

                                {this.state.purchase ?
                                    <IcButton
                                        to={LinkUtils.make('purchase', this.state.purchase.uid, 'cancel')}
                                        color={IcButtonColor.Secondary}>
                                        {this.props.t('purchasedetailspage.btn_cancel_purchase')}
                                    </IcButton>
                                : null}
                            </IcFloatRow>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading3}>
                                {this.props.t('purchasedetailspage.txt_licenses')}
                            </IcText>

                            <List>
                                {this.state.purchase ?
                                    this.state.purchase.package.details.licenses.map( ( license ) => (
                                        <ListItem key={license.license.uid}>
                                            <ListItemHeader>
                                                <ListItemHeaderField
                                                    text={license.license.name}
                                                    grow={true}
                                                />
                                                
                                                <ListItemHeaderField
                                                    text={`${license.count * this.state.purchase!.count}x`}
                                                    subtext={this.props.t('purchasedetailspage.txt_license_count')}
                                                    grow={true}
                                                />
                                            </ListItemHeader>
                                        </ListItem>
                                    ))
                                : null}
                            </List>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>
            </IcPageContent>
        );
    }
}


export const PurchaseDetailsPage = withTranslation()(withRouter($PurchaseDetailsPage));
