import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ConfigPropertyV1, ConfigPropertyV1Bool, ConfigPropertyV1Key, ConfigService } from '../../Services/ConfigService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcInputCheckbox, IcInputText, IcSpinner, IcSpinnerSize, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export interface AdminConfigPageCrmBoxProps extends WithTranslation
{
}


interface AdminConfigPageCrmBoxFormData
{
    zoho_enabled:   boolean;
    zoho_clientid:  string;
}


interface AdminConfigPageCrmBoxState
{
    initialFormData:    AdminConfigPageCrmBoxFormData;
    config:             Partial<Record<ConfigPropertyV1Key, ConfigPropertyV1>>;
    edit:               boolean;
    loading:            boolean;
    error:              Error | null;
}


class $AdminConfigPageCrmBox extends React.Component<AdminConfigPageCrmBoxProps, AdminConfigPageCrmBoxState>
{
    private readonly _configService:    ConfigService;


    constructor ( props: AdminConfigPageCrmBoxProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                zoho_enabled:   false,
                zoho_clientid:  ''
            },
            config:     {},
            edit:       false,
            loading:    true,
            error:      null
        };

        this._configService = ConfigService.getInstance();

        this._enableEdit = this._enableEdit.bind(this);
        this._disableEdit = this._disableEdit.bind(this);
        this._submit = this._submit.bind(this);
        this._zohoLogin = this._zohoLogin.bind(this);
    }


    private _enableEdit ( )
    {
        this.setState({
            edit:   true,
            initialFormData: {
                zoho_enabled:   this.state.config[ConfigPropertyV1Key.ZohoEnabled]?.value === ConfigPropertyV1Bool.True,
                zoho_clientid:  this.state.config[ConfigPropertyV1Key.ZohoClientID]?.value || ''
            }
        });
    }


    private _disableEdit ( )
    {
        this.setState({
            edit:   false
        });
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const config = await this._configService.getConfig();

            this.setState({
                error:      null,
                loading:    false,
                config
            });
        }
        catch ( err )
        {
            console.error(`Error loading config: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _submit ( values: AdminConfigPageCrmBoxFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.ZohoEnabled,
                values.zoho_enabled ? ConfigPropertyV1Bool.True : ConfigPropertyV1Bool.False
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.ZohoClientID,
                values.zoho_clientid.trim()
            );

            this.setState({
                loading:    false,
                edit:       false
            });

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error storing config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _zohoLogin ( ): Promise<void>
    {
        // TODO
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading2}>
                    {this.props.t('adminconfigpagecrmbox.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <Formik
                    onSubmit={this._submit}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <List>
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.ZohoEnabled]?.value || ''}
                                            subtext={this.props.t('adminconfigpagecrmbox.txt_zoho_enabled')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputCheckbox
                                                label={this.props.t('adminconfigpagecrmbox.inp_zoho_enabled')}
                                                name='zoho_enabled'
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>

                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.ZohoClientID]?.value || ''}
                                            subtext={this.props.t('adminconfigpagecrmbox.txt_zoho_clientid')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpagecrmbox.inp_zoho_clientid')}
                                                name='zoho_clientid'
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>

                            <ListItem>
                                <ListItemHeader>
                                    <ListItemHeaderField
                                        text={this.state.config[ConfigPropertyV1Key.ZohoUser]?.value || ''}
                                        subtext={this.props.t('adminconfigpagecrmbox.txt_zoho_login')}
                                        grow={true}
                                    />
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcButton
                                                type='button'
                                                onClick={this._zohoLogin}>
                                                {this.props.t('adminconfigpagecrmbox.btn_zoho_login')}
                                            </IcButton>
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                        </List>

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcSpinner
                                size={IcSpinnerSize.Small}
                                active={this.state.loading}
                            />

                            {!this.state.edit && !this.state.loading ?
                                <IcButton
                                    color={IcButtonColor.Secondary}
                                    type='button'
                                    onClick={this._enableEdit}>
                                    <FontAwesomeIcon icon={faPen} />

                                    {this.props.t('adminconfigpagecrmbox.btn_edit')}
                                </IcButton>
                            : null}

                            {this.state.edit && !this.state.loading ? 
                                <IcButton
                                    color={IcButtonColor.Link}
                                    type='button'
                                    onClick={this._disableEdit}>
                                    {this.props.t('adminconfigpagecrmbox.btn_cancel')}
                                </IcButton>
                            : null}

                            {this.state.edit && !this.state.loading ? 
                                <IcButton type='submit'>
                                    <FontAwesomeIcon icon={faCheck} />

                                    {this.props.t('adminconfigpagecrmbox.btn_save')}
                                </IcButton>
                            : null}
                        </IcFloatRow>
                    </Form>
                </Formik>
            </IcCard>
        );
    }
}


export const AdminConfigPageCrmBox = withTranslation()($AdminConfigPageCrmBox);
