import React from 'react';
import { Form, Formik } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IcButton, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface ResetPasswordPageSfaEmailStepProps extends WithTranslation
{
    onFinish:   ( formData: ResetPasswordPageSfaEmailStepFormData ) => any;
}


export interface ResetPasswordPageSfaEmailStepFormData
{
    code:  string;
}


interface ResetPasswordPageSfaEmailStepState
{
    initialFormData: ResetPasswordPageSfaEmailStepFormData;
}


class $ResetPasswordPageSfaEmailStep extends React.Component<ResetPasswordPageSfaEmailStepProps, ResetPasswordPageSfaEmailStepState>
{
    constructor ( props: ResetPasswordPageSfaEmailStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                code:  ''
            }
        };

        this._confirm = this._confirm.bind(this);
        this._resend = this._resend.bind(this);
    }


    private _confirm ( values: ResetPasswordPageSfaEmailStepFormData ): void
    {
        this.props.onFinish(values);
    }


    private async _resend ( ): Promise<void>
    {
        // TODO
    }


    public render ( )
    {
        const MyFormik  = Formik<ResetPasswordPageSfaEmailStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small} bold={true}>
                    {this.props.t('resetpasswordpagesfaemailstep.txt_subtitle')}
                </IcText>
                
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('resetpasswordpagesfaemailstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('resetpasswordpagesfaemailstep.txt_code')}
                </IcText>

                <MyFormik
                    onSubmit={this._confirm}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='code'
                                    autoComplete='off'
                                    label={this.props.t('resetpasswordpagesfaemailstep.inp_code')}
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton type='submit'>
                                {this.props.t('resetpasswordpagesfaemailstep.btn_confirm')}
                            </IcButton>
                        </IcFloatRow>

                        {/*<GridRow padding={GridPadding.Small}>
                            <GridItem s={6}>
                                <Button
                                    type='button'
                                    style={ButtonStyle.Secondary}
                                    fullWidth={true}
                                    onClick={this._resend}>
                                    {this.props.t('resetpasswordpagesfaemailstep.btn_resend')}
                                </Button>
                            </GridItem>
                        </GridRow>*/}
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const ResetPasswordPageSfaEmailStep = withTranslation()($ResetPasswordPageSfaEmailStep);
