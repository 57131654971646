import * as React from 'react';
import { Link, To } from 'react-router-dom';

import './Button.css';


export enum ButtonStyle
{
    Primary     = 'primary',
    Secondary   = 'secondary',
    Link        = 'link',
    LinkInline  = 'link-inline'
}


export interface ButtonProps
{
    style?:     ButtonStyle;
    type?:      'button' | 'reset' | 'submit';
    fullWidth?: boolean;
    allowWrap?: boolean;
    target?:    React.HTMLAttributeAnchorTarget;
    title?:     string;
    disabled?:  boolean;
    active?:    boolean;
    to?:        To;
    href?:      string;
    onClick?:   ( evt: any ) => any;
    className?: string;
    testID?:    string;
    children?:  React.ReactNode | null;
}


export class Button extends React.Component<ButtonProps>
{
    public render ( )
    {
        if ( this.props.href )
        {
            return (
                <a
                    title={this.props.title}
                    className={`Button ${this.props.fullWidth ? 'fullwidth' : ''} ${this.props.disabled ? 'disabled' : ''} ${this.props.active ? 'active' : ''}  ${this.props.allowWrap ? 'allow-wrap' : ''} style-${this.props.style || ButtonStyle.Primary} ${this.props.className || ''}`}
                    href={this.props.href}
                    target={this.props.target}
                    onClick={this.props.onClick}
                    data-testid={this.props.testID}>
                    {this.props.children}
                </a>
            );
        }

        if ( this.props.to )
        {
            return (
                <Link
                    title={this.props.title}
                    className={`Button ${this.props.fullWidth ? 'fullwidth' : ''} ${this.props.disabled ? 'disabled' : ''} ${this.props.active ? 'active' : ''} ${this.props.allowWrap ? 'allow-wrap' : ''} style-${this.props.style || ButtonStyle.Primary} ${this.props.className || ''}`}
                    to={this.props.to}
                    onClick={this.props.onClick}
                    data-testid={this.props.testID}>
                    {this.props.children}
                </Link>
            );
        }

        return (
            <button
                title={this.props.title}
                className={`Button ${this.props.fullWidth ? 'fullwidth' : ''} ${this.props.disabled ? 'disabled' : ''} ${this.props.active ? 'active' : ''} ${this.props.allowWrap ? 'allow-wrap' : ''} style-${this.props.style || ButtonStyle.Primary} ${this.props.className || ''}`}
                type={this.props.type || 'button'}
                onClick={this.props.onClick}
                data-testid={this.props.testID}>
                {this.props.children}
            </button>
        );
    }
}
