import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import { PackageService, PackageV1, PackageV1Status, PackageV1Statuses, PackageV1Visibilities, PackageV1Visibility } from '../../Services/PackageService';
import { sleep } from 'ts-delay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { Formatter } from '../../utils/Formatter';
import { LicenseService, LicenseV1 } from '../../Services/LicenseService';
import { ListItemBody } from '../../Components/List/ListItemBody';
import { EnabledLocales, Locale, localizedMultiStringsToLocalizedStrings, localizedStringsToLocalizedMultiStrings } from '../../Model/Locale';
import { InputDuration } from '../../Components/Input/InputDuration';
import { InputTextarea } from '../../Components/Input/InputTextarea';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputSelect, IcInputText, IcSeparator, IcSpinner, LinkUtils, Validator, RouteComponentProps, withRouter, IcPageContent, IcInputCheckbox, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface AdminEditPackageRouteParams
{
    packageUID:  string;
}


export interface AdminPackageEditPageProps extends RouteComponentProps<AdminEditPackageRouteParams>, WithTranslation
{
}


interface AdminPackageEditPageFormDataLocalizedString
{
    locale: string;
    value:  string;
}


interface AdminPackageEditPageFormData
{
    visibility:             PackageV1Visibility;
    status:                 PackageV1Status;
    names:                  Array<AdminPackageEditPageFormDataLocalizedString>;
    descriptions:           Array<AdminPackageEditPageFormDataLocalizedString>;
    features:               Array<AdminPackageEditPageFormDataLocalizedString>;
    max_total_count:        string;
    max_concurrent_count:   string;
    max_duration:           string;
    max_repeat:             string;
    is_bundle:              boolean;
}


interface AdminPackageEditPageState
{
    initialFormData:    AdminPackageEditPageFormData;
    licenses:           Array<LicenseV1>;
    package:            PackageV1 | null;
    error:              Error | null;
    loading:            boolean;
    success:            string | null;
}


class $AdminPackageEditPage extends React.Component<AdminPackageEditPageProps, AdminPackageEditPageState>
{
    private readonly _packageService:  PackageService;
    private readonly _licenseService:  LicenseService;
    

    constructor ( props: AdminPackageEditPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                visibility:             PackageV1Visibility.Public,
                status:                 PackageV1Status.Available,
                names:                  [],
                descriptions:           [],
                features:               [],
                max_total_count:        '',
                max_concurrent_count:   '',
                max_duration:           '',
                max_repeat:             '',
                is_bundle:              false
            },
            licenses:   [],
            package:    null,
            error:      null,
            loading:    true,
            success:    null
        };

        this._packageService = PackageService.getInstance();
        this._licenseService = LicenseService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._update    = this._update.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const licenses = await this._licenseService.getLicenses(0, 100);

            const pkg = await this._packageService.getPackage(this.props.router.params.packageUID, 'DE');   // TODO

            this.setState({
                error:      null,
                loading:    false,
                package:    pkg,
                licenses,
                initialFormData: {
                    visibility:     pkg.visibility,
                    status:         pkg.status,
                    names:          pkg.names.map( ( o ) => ({
                        locale: o.locale,
                        value:  o.value
                    })),
                    descriptions:   pkg.details.descriptions.map( ( o ) => ({
                        locale: o.locale,
                        value:  o.value
                    })),
                    features:       localizedMultiStringsToLocalizedStrings(pkg.details.features.map( ( o ) => ({
                        locale: o.locale,
                        values: o.values
                    }))),
                    max_total_count:        '' + (pkg.details.max_total_count || ''),
                    max_concurrent_count:   '' + (pkg.details.max_concurrent_count || ''),
                    max_duration:           (pkg.details.max_duration || ''),
                    max_repeat:             '' + (pkg.details.max_repeat || ''),
                    is_bundle:              pkg.details.is_bundle
                }
            });
        }
        catch ( err )
        {
            console.error(`Error loading package: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _update ( formData: AdminPackageEditPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.package )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            await this._packageService.updatePackage(
                this.state.package.uid,
                {
                    visibility:     formData.visibility,
                    status:         formData.status,
                    names:          formData.names.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                        locale:         o.locale as Locale,
                        value:          o.value.trim()
                    })),
                    details:    {
                        descriptions:   formData.descriptions.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                            locale:         o.locale as Locale,
                            value:          o.value.trim()
                        })),
                        features:   localizedStringsToLocalizedMultiStrings(formData.features.filter( o => o.locale && o.value.trim() ).map( ( o ) => ({
                            locale:         o.locale as Locale,
                            value:          o.value.trim()
                        }))),
                        licenses:               this.state.package.details.licenses.map( ( o ) => ({
                            count:          o.count,
                            license_uid:    o.license.uid
                        })),
                        prices:                 this.state.package.details.prices,
                        max_total_count:        formData.max_total_count.trim() ? parseInt(formData.max_total_count.trim(), 10) : null,
                        max_concurrent_count:   formData.max_concurrent_count.trim() ? parseInt(formData.max_concurrent_count.trim(), 10) : null,
                        max_duration:           formData.max_duration.trim() || null,
                        max_repeat:             formData.max_repeat.trim() ? parseInt(formData.max_repeat.trim(), 10) : null,
                        is_bundle:              formData.is_bundle
                    }
                }
            );

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('adminpackageeditpage.txt_success')
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'packages'));
        }
        catch ( err )
        {
            console.error(`Error updating package: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<AdminPackageEditPageFormData>;

        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminpackageeditpage.txt_edit_package')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._update}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    {({ values }) => (
                        <Form>
                            <IcCard padding={IcCardPadding.Small}>
                                <FieldArray name='names'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminpackageeditpage.txt_names')}
                                            </IcText>

                                            <List>
                                                {values.names.map( ( name, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`names.${index}.locale`}
                                                                    label={this.props.t('adminpackageeditpage.inp_name_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>
                                                                
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`names.${index}.value`}
                                                                    label={this.props.t('adminpackageeditpage.inp_name_name')}
                                                                    required={true}
                                                                    validators={[
                                                                        Validator.minLength(1),
                                                                        Validator.maxLength(32)
                                                                    ]}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminpackageeditpage.btn_delete_name')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.names.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminpackageeditpage.txt_no_names')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminpackageeditpage.btn_add_name')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>
                            
                                <IcSeparator />
                            
                                <FieldArray name='features'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminpackageeditpage.txt_features')}
                                            </IcText>

                                            <List>
                                                {values.features.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`features.${index}.locale`}
                                                                    label={this.props.t('adminpackageeditpage.inp_feature_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`features.${index}.value`}
                                                                    label={this.props.t('adminpackageeditpage.inp_feature_feature')}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminpackageeditpage.btn_delete_feature')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.features.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminpackageeditpage.txt_no_features')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />

                                                    {this.props.t('adminpackageeditpage.btn_add_feature')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='descriptions'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminpackageeditpage.txt_descriptions')}
                                            </IcText>

                                            <List>
                                                {values.descriptions.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputSelect
                                                                    name={`descriptions.${index}.locale`}
                                                                    label={this.props.t('adminpackageeditpage.inp_description_locale')}
                                                                    options={EnabledLocales.map( ( locale ) => ({
                                                                        label: Formatter.locale(locale),
                                                                        value: locale
                                                                    }))}
                                                                    required={true}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminpackageeditpage.btn_delete_description')}
                                                            />
                                                        </ListItemHeader>

                                                        <ListItemBody>
                                                            <InputTextarea
                                                                name={`descriptions.${index}.value`}
                                                                label={this.props.t('adminpackageeditpage.inp_description_description')}
                                                                required={true}
                                                            />
                                                        </ListItemBody>
                                                    </ListItem>
                                                ))}

                                                {values.descriptions.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminpackageeditpage.txt_no_descriptions')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    color={IcButtonColor.Secondary}
                                                    onClick={() => arrayHelpers.push({
                                                        locale: EnabledLocales.length === 1 ? EnabledLocales[0] : '',
                                                        value: ''
                                                    })}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />

                                                    {this.props.t('adminpackageeditpage.btn_add_description')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputSelect
                                            label={this.props.t('adminpackageeditpage.inp_visibility')}
                                            name='visibility'
                                            required={true}
                                            options={PackageV1Visibilities.map( ( packageVisibility ) => ({
                                                label:  Formatter.packageVisibility(packageVisibility),
                                                value:  packageVisibility
                                            }))}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputSelect
                                            label={this.props.t('adminpackageeditpage.inp_status')}
                                            name='status'
                                            required={true}
                                            options={PackageV1Statuses.map( ( packageStatus ) => ({
                                                label:  Formatter.packageStatus(packageStatus),
                                                value:  packageStatus
                                            }))}
                                        />
                                    </IcGridItem>
                                    
                                    <IcGridItem s={12}>
                                        <IcInputCheckbox
                                            label={this.props.t('adminpackageeditpage.inp_is_bundle')}
                                            name='is_bundle'
                                        />
                                    </IcGridItem>
                                    
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label={this.props.t('adminpackageeditpage.inp_max_total_count')}
                                            name='max_total_count'
                                        />
                                    </IcGridItem>
                                    
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label={this.props.t('adminpackageeditpage.inp_max_concurrent_count')}
                                            name='max_concurrent_count'
                                        />
                                    </IcGridItem>
                                    
                                    <IcGridItem s={12}>
                                        <InputDuration
                                            label={this.props.t('adminpackageeditpage.inp_max_duration')}
                                            name='max_duration'
                                        />
                                    </IcGridItem>
                                    
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label={this.props.t('adminpackageeditpage.inp_max_repeat')}
                                            name='max_repeat'
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        {this.props.t('adminpackageeditpage.btn_cancel')}
                                    </IcButton>

                                    <IcButton
                                        type='submit'
                                        disabled={this.state.loading}>
                                        {this.props.t('adminpackageeditpage.btn_update')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcCard>
                        </Form>
                    )}
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const AdminPackageEditPage = withTranslation()(withRouter($AdminPackageEditPage));
