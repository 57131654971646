import React from 'react';
import { Link } from 'react-router-dom';

import './Box.css';


export enum BoxContentPadding
{
    None    = 'none',
    Small   = 'small',
    Medium  = 'medium',
    Large   = 'large'
}


export interface BoxContentProps
{
    padding?:   BoxContentPadding;
    href?:      string;
    className?: string;
    children?:  React.ReactNode | undefined;
}


export class BoxContent extends React.Component<BoxContentProps>
{
    public render ( )
    {
        if ( this.props.href )
        {
            return (
                <Link
                    to={this.props.href}
                    className={`BoxContent padding-${this.props.padding || BoxContentPadding.None} ${this.props.className || ''}`}>
                    {this.props.children}
                </Link>
            );
        }

        return (
            <div className={`BoxContent padding-${this.props.padding || BoxContentPadding.None} ${this.props.className || ''}`}>
                {this.props.children}
            </div>
        );
    }
}
