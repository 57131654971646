import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { ApplicationV1 } from '../../../Services/ApplicationService';
import { WithLocaleProps, withLocale } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputSelect, IcInputText, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface UserApiTokenAddPageFormStepFormData
{
    application_uid:    string;
    name:               string;
}


export interface UserApiTokenAddPageFormStepProps extends WithTranslation, WithLocaleProps
{
    applications:   Array<ApplicationV1>;
    onCancel:       ( ) => any;
    onFinish:       ( formData: UserApiTokenAddPageFormStepFormData ) => any;
}


interface UserApiTokenAddPageFormStepState
{
    initialInviteFormData:  UserApiTokenAddPageFormStepFormData;
}


class $UserApiTokenAddPageFormStep extends React.Component<UserApiTokenAddPageFormStepProps, UserApiTokenAddPageFormStepState>
{
    constructor ( props: UserApiTokenAddPageFormStepProps )
    {
        super(props);

        this.state = {
            initialInviteFormData: {
                application_uid:    '',
                name:               ''
            }
        };
    }


    public render ( )
    {
        const MyFormik = Formik<UserApiTokenAddPageFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('userapitokenaddpageformstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('userapitokenaddpageformstep.txt_add_token')}
                </IcText>

                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialInviteFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputSelect
                                    name='application_uid'
                                    label={this.props.t('userapitokenaddpageformstep.inp_application')}
                                    required={true}
                                    options={this.props.applications.map( ( application ) => ({
                                        label:  application.names.find( o => o.locale === this.props.locale )?.value || '???',
                                        value:  application.uid
                                    }))}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}> 
                                <IcInputText
                                    name='name'
                                    label={this.props.t('userapitokenaddpageformstep.inp_name')}
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(100)
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}>
                                {this.props.t('userapitokenaddpageformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'>
                                {this.props.t('userapitokenaddpageformstep.btn_add')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const UserApiTokenAddPageFormStep = withLocale(withTranslation()($UserApiTokenAddPageFormStep));
