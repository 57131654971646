import React from 'react';
import { Currency } from '../../Model/Currency';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Formatter } from '../../utils/Formatter';

import './PriceTag.css';


export interface PriceTagProps extends WithTranslation
{
    amount:             number;
    currency:           Currency;
    paymentInterval:    string;
    startingAt:         boolean;
}


class $PriceTag extends React.Component<PriceTagProps>
{
    public render ( )
    {
        return (
            <div className='PriceTag'>
                {this.props.startingAt ?
                    <div className='PriceTag-starting-at'>
                        {this.props.t('pricetag.txt_starting_at')}
                    </div>
                : null}

                <div className='PriceTag-int'>
                    {Math.floor(this.props.amount * 0.01)}
                </div>

                <div className='PriceTag-suffix'>
                    <div className='PriceTag-suffix-float'>
                        ,{this.props.amount % 100 ? Math.round(this.props.amount % 100).toString().padStart(2, '0') : '-'} {Formatter.currency(this.props.currency)}
                    </div>

                    <div className='PriceTag-suffix-duration'>
                        {Formatter.interval(this.props.paymentInterval)}
                    </div>
                </div>
            </div>
        );
    }
}


export const PriceTag = withTranslation()($PriceTag);
