import DayJS from 'dayjs';
import { t } from 'i18next';
import { Currency } from '../Model/Currency';
import { Gender } from '../Model/Gender';
import { UserSfaV1Type, UserV1, UserV1GlobalRole, UserV1OrganisationRole} from '../Services/UserService';
import { Country } from '../Model/Country';
import { PackageV1License, PackageV1Status, PackageV1Visibility, PaymentService } from '../Services/PackageService';
import { Locale } from '../Model/Locale';
import { ContentFileV1Key, ContentV1Key } from '../Services/ContentService';
import { ApplicationV1AssetType } from '../Services/ApplicationService';
import { LocaleService } from '../Services/LocaleService';
import { VoucherV1Type } from '../Services/VoucherService';


interface CachedDisplayNames
{
    locale:         Locale;
    displayNames:   Intl.DisplayNames
}


export class Formatter
{
    private static _cachedCountryNames: CachedDisplayNames | null;


    public static currency ( currency: Currency | null | undefined ): string
    {
        if ( ! currency )
        {
            return '';
        }

        return t(`formatter.currency.${currency.toLowerCase()}`);
    }


    public static duration ( duration: string | null ): string
    {
        if ( ! duration )
        {
            return '';
        }

        const count = parseInt(duration, 10);

        return  t(`formatter.duration.${duration.toLowerCase()}`, {count});
    }


    public static interval ( duration: string | null ): string
    {
        if ( ! duration )
        {
            return '';
        }

        const count = parseInt(duration, 10);

        return  t(`formatter.interval.${duration.toLowerCase()}`, {count});
    }
    

    public static country ( country: Country | null | undefined ): string
    {
        if ( ! country )
        {
            return '';
        }

        const locale = LocaleService.getInstance().getLocale().get();

        if ( !Formatter._cachedCountryNames ||
             Formatter._cachedCountryNames.locale !== locale )
        {
            Formatter._cachedCountryNames = {
                locale,
                displayNames: new Intl.DisplayNames([locale], {type: 'region'})
            };
        }

        return Formatter._cachedCountryNames.displayNames.of(country) || country;
    }
    

    public static locale ( locale: Locale | null | undefined ): string
    {
        if ( ! locale )
        {
            return '';
        }

        return t(`formatter.locale.${locale.toLowerCase()}`);
    }
    
    
    public static gender ( gender: Gender ): string
    {
        return t(`formatter.gender.${gender.toLowerCase()}`);
    }

    
    public static voucherType ( voucherType: VoucherV1Type ): string
    {
        return t(`formatter.voucherv1type.${voucherType.toLowerCase()}`);
    }

    
    public static sfaType ( sfaType: UserSfaV1Type ): string
    {
        return t(`formatter.usersfav1type.${sfaType.toLowerCase()}`);
    }

    
    public static applicationAssetType ( applicationAssetType: ApplicationV1AssetType ): string
    {
        return t(`formatter.applicationv1assettype.${applicationAssetType.toLowerCase()}`);
    }
    

    public static contentKey ( contentKey: ContentV1Key ): string
    {
        return t(`formatter.contentkey.${contentKey.toLowerCase()}`);
    }


    public static contentFileKey ( contentFileKey: ContentFileV1Key ): string
    {
        return t(`formatter.contentfilekey.${contentFileKey.toLowerCase()}`);
    }


    public static packageVisibility ( packageVisibility: PackageV1Visibility ): string
    {
        return t(`formatter.packagev1visibility.${packageVisibility.toLowerCase()}`);
    }


    public static packageStatus ( packageStatus: PackageV1Status ): string
    {
        return t(`formatter.packagev1status.${packageStatus.toLowerCase()}`);
    }


    public static currencyAmount ( cents: number, currency: Currency, paymentInterval?: string ): string
    {
        let amount = '';

        cents = Math.round(cents);
        
        amount = (cents * 0.01).toLocaleString(LocaleService.getInstance().getLocale().get(), {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        
        if ( (cents % 100) === 0 )
        {
            amount = amount.substring(0, amount.length - 2) + '-'
        }

        if ( paymentInterval )
        {
            return t('formatter.currency_amount_per', {
                amount,
                currency: t(`formatter.currency.${currency.toLowerCase()}`),
                interval: this.interval(paymentInterval)
            });
        }
        else
        {
            return t('formatter.currency_amount', {
                amount,
                currency: t(`formatter.currency.${currency.toLowerCase()}`)
            });
        }
    }


    public static percent ( rate: number | undefined | null ): string
    {
        if ( rate === null || rate === undefined )
        {
            return '- %';
        }

        rate *= 100.0;

        return `${rate} %`;
    }


    public static vatRate ( rate: number, included: boolean ): string
    {
        rate *= 100.0;

        if ( included )
        {
            return t('formatter.vatrate_included', {
                rate
            });
        }
        else
        {
            return t('formatter.vatrate_excluded', {
                rate
            });
        }
    }


    public static username ( user: UserV1 | null | undefined ): string
    {
        if ( ! user )
        {
            return '???';
        }

        return `${user.firstname} ${user.lastname}`;
    }
    
    
    public static userGlobalRole ( userGlobalRole: UserV1GlobalRole | null | undefined ): string
    {
        if ( ! userGlobalRole )
        {
            return '';
        }

        return t(`formatter.userv1globalrole.${userGlobalRole.toLowerCase()}`);
    }
    
    
    public static userOrganisationRole ( userOrganisationRole: UserV1OrganisationRole | null | undefined ): string
    {
        if ( ! userOrganisationRole )
        {
            return '';
        }

        return t(`formatter.userv1organisationrole.${userOrganisationRole.toLowerCase()}`);
    }
    
    
    public static paymentService ( paymentService: PaymentService | null | undefined ): string
    {
        if ( ! paymentService )
        {
            return '';
        }

        return t(`formatter.paymentservice.${paymentService.toLowerCase()}`);
    }


    public static packageLicense ( packageLicense: PackageV1License ): string
    {
        if ( packageLicense.license.assignable )
        {
            return `${packageLicense.count}x ${packageLicense.license.name}`;
        }
        else
        {
            return packageLicense.license.name;
        }
    }


    public static date ( date: DayJS.Dayjs | string ): string
    {
        return DayJS(date).format('YYYY-MM-DD');
    }
}
