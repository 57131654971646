import React from 'react';
import { Form, Formik } from 'formik';
import { ConfigPropertyV1Countries, ConfigPropertyV1Key, ConfigPropertyV1Locales, ConfigService } from '../../../Services/ConfigService';
import { LocaleService } from '../../../Services/LocaleService';
import { Locale, Locales } from '../../../Model/Locale';
import { Formatter } from '../../../utils/Formatter';
import { Countries, Country } from '../../../Model/Country';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputMultiSelect, IcInputSelect, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface SetupConfigureLocalesStepProps extends WithTranslation
{
    onFinish:   ( ) => any;
}


interface SetupConfigureLocalesStepFormData
{
    locales:            Array<Locale>;
    countries:          Array<Country>;
    default_country:    Country;
}


interface SetupConfigureLocalesStepState
{
    initialFormData:    SetupConfigureLocalesStepFormData;
    loading:            boolean;
    error:              Error | null;
}


class $SetupConfigureLocalesStep extends React.Component<SetupConfigureLocalesStepProps, SetupConfigureLocalesStepState>
{
    private readonly _configService: ConfigService;
    private readonly _localeService: LocaleService;


    constructor ( props: SetupConfigureLocalesStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                locales:            [],
                countries:          [],
                default_country:    '' as Country
            },
            loading:    false,
            error:      null
        };

        this._configService = ConfigService.getInstance();
        this._localeService = LocaleService.getInstance();

        this._submit = this._submit.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const browserLocale = this._localeService.getBrowserLocale();

            this.setState({
                initialFormData: {
                    locales:            browserLocale ? [browserLocale] : [Locale.EN_US],
                    countries:          [],
                    default_country:    '' as any
                },
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _submit ( values: SetupConfigureLocalesStepFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.Locales,
                JSON.stringify({
                    locales: values.locales
                } as ConfigPropertyV1Locales)
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.Countries,
                JSON.stringify({
                    countries: values.countries
                } as ConfigPropertyV1Countries)
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.DefaultCountry,
                values.default_country
            );

            this.setState({
                loading:    false
            });

            this.props.onFinish();
        }
        catch ( err )
        {
            console.error(`Error updating config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<SetupConfigureLocalesStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small} bold={true}>
                    {this.props.t('setupconfigurelocalesstep.txt_subtitle')}
                </IcText>

                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('setupconfigurelocalesstep.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <MyFormik
                    initialValues={this.state.initialFormData}
                    onSubmit={this._submit}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputMultiSelect
                                    name='locales'
                                    label={this.props.t('setupconfigurelocalesstep.inp_locales')}
                                    options={Locales.map( ( locale ) => ({
                                        value: locale,
                                        label: Formatter.locale(locale)
                                    }))}
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputMultiSelect
                                    name='countries'
                                    label={this.props.t('setupconfigurelocalesstep.inp_countries')}
                                    options={Countries.map( ( country ) => ({
                                        value: country,
                                        label: Formatter.country(country)
                                    }))}
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputSelect
                                    name='default_country'
                                    label={this.props.t('setupconfigurelocalesstep.inp_default_country')}
                                    options={Countries.map( ( country ) => ({
                                        value: country,
                                        label: Formatter.country(country)
                                    }))}
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='submit'
                                disabled={this.state.loading}>
                                {this.props.t('setupconfigurelocalesstep.btn_continue')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>

                <IcSpinner active={this.state.loading} />
            </IcCard>
        );
    }
}


export const SetupConfigureLocalesStep = withTranslation()($SetupConfigureLocalesStep);
