import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PurchaseV1 } from '../../../Services/PurchaseService';
import { PurchaseDetails } from '../../../BusinessComponents/PurchaseDetails/PurchaseDetails';
import { Form, Formik } from 'formik';
import { UserV1, isAdmin } from '../../../Services/UserService';
import { InputDate } from '../../../Components/Input/InputDate';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcSeparator, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface PurchaseCancelPageConfirmStepFormData
{
    cancel_at:  string;
}


export interface PurchaseCancelPageConfirmStepProps extends WithTranslation
{
    purchase:   PurchaseV1;
    ownUser:    UserV1 | null;
    onCancel:   ( ) => any;
    onFinish:   ( formData: PurchaseCancelPageConfirmStepFormData ) => any;
}


class $PurchaseCancelPageConfirmStep extends React.Component<PurchaseCancelPageConfirmStepProps>
{

    public render ( )
    {
        const MyFormik = Formik<PurchaseCancelPageConfirmStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('purchasecancelpageconfirmstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('purchasecancelpageconfirmstep.txt_confirm')}
                </IcText>

                <IcSeparator />

                <PurchaseDetails purchase={this.props.purchase} />

                <IcSeparator />

                <MyFormik
                    initialValues={{
                        cancel_at: ''
                    }}
                    onSubmit={this.props.onFinish}>
                    <Form>
                        {this.props.ownUser && isAdmin(this.props.ownUser) ?
                            <IcGridRow>
                                <IcGridItem s={12}>
                                    <InputDate
                                        name='cancel_at'
                                        label={this.props.t('purchasecancelpageconfirmstep.inp_cancel_at')}
                                    />
                                </IcGridItem>
                            </IcGridRow>
                        : null}

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}>
                                {this.props.t('purchasecancelpageconfirmstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                onClick={this.props.onFinish}>
                                {this.props.t('purchasecancelpageconfirmstep.btn_confirm')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const PurchaseCancelPageConfirmStep = withTranslation()($PurchaseCancelPageConfirmStep);
