import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { isAdmin, isOwner, UserService, UserV1 } from '../../Services/UserService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcGridAlign, IcGridItem, IcGridRow, IcPageContent, IcPopupMenu, IcPopupMenuItem, IcPopupMenuSeparator, IcSearchbar, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';


export interface OrganisationsPageProps extends WithTranslation
{
}


interface OrganisationsPageState
{
    organisations:  Array<OrganisationV1>;
    user:           UserV1 | null;
    query:          string | null;
    hasMore:        boolean;
    loading:        boolean;
    error:          Error | null;
}


class $OrganisationsPage extends React.Component<OrganisationsPageProps, OrganisationsPageState>
{
    private readonly BULK_SIZE              = 50;
    private readonly _userService:          UserService;
    private readonly _organisationService:  OrganisationService;


    constructor ( props: OrganisationsPageProps )
    {
        super(props);

        this.state = {
            organisations:  [],
            user:           null,
            query:          null,
            hasMore:        true,
            loading:        true,
            error:          null
        };

        this._userService = UserService.getInstance();
        this._organisationService = OrganisationService.getInstance();

        this._load = this._load.bind(this);
        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( query: string | null ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const organisations = await this._organisationService.getOrganisations(
                {
                    query: query ? query.trim() : null
                },
                0,
                this.BULK_SIZE
            );

            this.setState({
                organisations,
                query:      query ? query.trim() : null,
                hasMore:    organisations.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisations: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const organisations = await this._organisationService.getOrganisations(
                {
                    query: this.state.query ? this.state.query.trim() : null
                },
                this.state.organisations.length,
                this.BULK_SIZE
            );

            this.setState({
                organisations:  [...this.state.organisations, ...organisations],
                hasMore:        organisations.length >= this.BULK_SIZE,
                loading:        false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more organisations: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( user ) => 
        {
            this.setState({
                user
            });
        });

        const user =  this._userService.isLoggedIn().get();
        this.setState({
            user
        });
        
        await this._load(null);
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('organisationspage.txt_organisations')} />

                <IcErrorBox error={this.state.error} />

                <IcCard padding={IcCardPadding.Small}>
                    {this.state.user && isAdmin(this.state.user) ?
                        <IcGridRow align={IcGridAlign.Center}>
                            <IcGridItem s={12} m={7} l={9}>
                                <IcSearchbar
                                    onSearch={ ( _, query ) => this._load(query || null) }
                                />
                            </IcGridItem>

                            <IcGridItem s={12} m={5} l={3}>
                                <IcButton
                                    color={IcButtonColor.Secondary}
                                    to={LinkUtils.make('organisation', 'add')}>
                                    <FontAwesomeIcon icon={faPlusSquare} />

                                    {this.props.t('organisationspage.btn_add_organisation')}
                                </IcButton>
                            </IcGridItem>
                        </IcGridRow>
                    : null}

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        {this.props.t('organisationspage.txt_col_name')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('organisationspage.txt_col_action')}
                                    </IcTableCell>
                                </IcTableRow>
                            </IcTableHead>

                            <IcTableBody>
                                {this.state.organisations.map( ( organisation ) => (
                                    <IcTableRow key={organisation.uid}>
                                        <IcTableCell to={LinkUtils.make('organisation', organisation.uid)}>
                                            {organisation.name}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcPopupMenu>
                                                <IcPopupMenuItem to={LinkUtils.make('organisation', organisation.uid)}>
                                                    {this.props.t('organisationspage.btn_show')}
                                                </IcPopupMenuItem>

                                                {this.state.user && (isAdmin(this.state.user) || isOwner(this.state.user, organisation.uid)) ?
                                                    <>
                                                        <IcPopupMenuSeparator />

                                                        <IcPopupMenuItem to={LinkUtils.make('organisation', organisation.uid, 'edit')}>
                                                            {this.props.t('organisationspage.btn_edit')}
                                                        </IcPopupMenuItem>
                                                    </>
                                                : null}

                                                {this.state.user && isAdmin(this.state.user) ?
                                                    <IcPopupMenuItem to={LinkUtils.make('organisation', organisation.uid, 'delete')}>
                                                        {this.props.t('organisationspage.btn_delete')}
                                                    </IcPopupMenuItem>
                                                : null}
                                            </IcPopupMenu>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.organisations.length === 0 && !this.state.loading ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={2}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            {this.props.t('organisationspage.txt_no_organisations')}
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const OrganisationsPage = withTranslation()($OrganisationsPage);
