import React from 'react';
import { Form, Formik } from 'formik';
import { ConfigPropertyV1Key, ConfigService } from '../../../Services/ConfigService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcSpinner, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface SetupServerInfoStepProps extends WithTranslation
{
    onFinish:   ( ) => any;
}


interface SetupServerInfoStepFormData
{
    server_name:        string;
    server_base_url:    string;
}


interface SetupServerInfoStepState
{
    initialFormData:    SetupServerInfoStepFormData;
    loading:            boolean;
    error:              Error | null;
}


class $SetupServerInfoStep extends React.Component<SetupServerInfoStepProps, SetupServerInfoStepState>
{
    private readonly _configService: ConfigService;


    constructor ( props: SetupServerInfoStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                server_name:    'License Portal',
                server_base_url: `${window.location.protocol}//${window.location.host}`,
            },
            loading:    false,
            error:      null
        };

        this._configService = ConfigService.getInstance();

        this._submit = this._submit.bind(this);
    }


    private async _submit ( values: SetupServerInfoStepFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.ServerName,
                values.server_name.trim()
            );
            
            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.ServerBaseURL,
                values.server_base_url.trim()
            );

            this.setState({
                loading:    false
            });

            this.props.onFinish();
        }
        catch ( err )
        {
            console.error(`Error storing config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small} bold={true}>
                    {this.props.t('setupserverinfostep.txt_subtitle')}
                </IcText>

                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('setupserverinfostep.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <Formik
                    initialValues={this.state.initialFormData}
                    onSubmit={this._submit}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='server_name'
                                    label={this.props.t('setupserverinfostep.inp_server_name')}
                                    required={true}
                                />
                            </IcGridItem>
                            
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='server_base_url'
                                    label={this.props.t('setupserverinfostep.inp_server_base_url')}
                                    required={true}
                                    validators={[
                                        Validator.url
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='submit'
                                disabled={this.state.loading}>
                                {this.props.t('setupserverinfostep.btn_continue')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </Formik>

                <IcSpinner active={this.state.loading} />
            </IcCard>
        );
    }
}


export const SetupServerInfoStep = withTranslation()($SetupServerInfoStep);
