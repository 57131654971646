import React from 'react';
import { ContentFileV1, ContentFileV1Key, ContentService } from '../../Services/ContentService';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { FileService } from '../../Services/FileService';

import './ContentImage.css';


export interface ContentImageProps extends WithLocaleProps
{
    contentFileKey: ContentFileV1Key;
}


interface ContentImageState
{
    contentFile:    ContentFileV1 | null;
    loaded:         boolean;
}


class $ContentImage extends React.Component<ContentImageProps, ContentImageState>
{
    private readonly _contentService:   ContentService;
    private readonly _fileService:      FileService;


    constructor ( props: ContentImageProps )
    {
        super(props);

        this.state = {
            contentFile:    null,
            loaded:         false
        };

        this._contentService = ContentService.getInstance();
        this._fileService = FileService.getInstance();

        this._loaded = this._loaded.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            const contentFile = await this._contentService.getContentFile(this.props.contentFileKey, this.props.locale);

            this.setState({
                contentFile,
                loaded: false,
            });
        }
        catch ( err )
        {
            console.error(`Error loading content file: ${(err as Error).message}`, err);

            this.setState({
                contentFile:    null,
                loaded:         false
            });
        }
    }


    private _loaded ( ): void
    {
        this.setState({
            loaded: true
        });
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();    
    }


    public async componentDidUpdate ( prevProps: Readonly<ContentImageProps> ): Promise<void>
    {
        if ( this.props.contentFileKey !== prevProps.contentFileKey ||
             this.props.locale !== prevProps.locale )
        {
            await this._load();
        }
    }


    public render ( )
    {
        return (
            <div className={`ContentImage ${this.state.loaded ? 'loaded' : ''}`}>
                {this.state.contentFile && this.state.contentFile.file_uid ?
                    <img
                        src={this._fileService.getFileDataURI(this.state.contentFile.file_uid)}
                        alt=''
                        onLoad={this._loaded}
                    />
                : null}
            </div>
        );
    }
}


export const ContentImage = withLocale($ContentImage);
