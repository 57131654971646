export interface LoginV1Request
{
    email:      string;
    password:   string;
    client:     string;
}


/*export interface LoginV1Response
{
    sfa_required:   boolean;
    sfa_methods:    Array<LoginV1SfaMethod>;
}
*/


export interface PendingPaymentMethod
{
    paymentmethod_uid:  string;
    client_secret:      string;
}


export interface PendingPurchase
{
    package_uid:        string;
    package_price_uid:  string;
    organisation_uid:   string | null;
    voucher_code:       string | null;
    count:              number;
}


export class SessionService
{
    private static _instance: SessionService;
    
    
    public static getInstance ( ): SessionService
    {
        if ( ! this._instance )
        {
            this._instance = new SessionService();
        }
        
        return this._instance;
    }

    
    public setPendingPaymentMethod ( pendingPaymentMethod: PendingPaymentMethod ): void
    {
        window.sessionStorage.setItem('ic-licenser-pending-payment-method', JSON.stringify(pendingPaymentMethod));
    }
    

    public getPendingPaymentMethod ( ): PendingPaymentMethod | null
    {
        const data = window.sessionStorage.getItem('ic-licenser-pending-payment-method');
        if ( ! data )
        {
            return null;
        }

        return JSON.parse(data);
    }


    public deletePendingPaymentMethod ( ): void
    {
        window.sessionStorage.removeItem('ic-licenser-pending-payment-method');
    }

    
    public setPendingPurchase ( pendingPurchase: PendingPurchase ): void
    {
        window.sessionStorage.setItem('ic-licenser-pending-purchase', JSON.stringify(pendingPurchase));
    }
    

    public getPendingPurchase ( ): PendingPurchase | null
    {
        const data = window.sessionStorage.getItem('ic-licenser-pending-purchase');
        if ( ! data )
        {
            return null;
        }

        return JSON.parse(data);
    }


    public deletePendingPurchase ( ): void
    {
        window.sessionStorage.removeItem('ic-licenser-pending-purchase');
    }
}
