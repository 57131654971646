import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Environment } from '../../utils/Environment';
import { ContentText } from '../../Components/ContentText/ContentText';
import { ContentV1Key } from '../../Services/ContentService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcGridAlign, IcGridItem, IcGridPadding, IcGridRow, IcTextSize, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './SupportBox.css';


export interface SupportBoxProps extends WithTranslation, RouteComponentProps
{
}


class $SupportBox extends React.Component<SupportBoxProps>
{
    public render ( )
    {
        if ( ! Environment.links.supportUrl )
        {
            return null;
        }

        return (
            <IcCard
                className='SupportBox'
                padding={IcCardPadding.Medium}>
                <IcGridRow
                    padding={IcGridPadding.Small}
                    align={IcGridAlign.Center}>
                    <IcGridItem s={12} m={12} l={6}>
                        <ContentText
                            contentKey={ContentV1Key.SupportTitle}
                            size={IcTextSize.Heading3}
                        />
                        
                        <ContentText
                            contentKey={ContentV1Key.SupportText}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={12} l={6}>
                        <div className='SupportBox-content-action'>
                            <IcButton
                                href={Environment.links.supportUrl}
                                color={IcButtonColor.Secondary}
                                target='_blank'>
                                {this.props.t('supportbox.btn_support')}

                                <FontAwesomeIcon icon={faAngleRight} />
                            </IcButton>
                        </div>
                    </IcGridItem>
                </IcGridRow>
            </IcCard>
        );
    }
}


export const SupportBox = withTranslation()(withRouter($SupportBox));
