import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Box } from '../../Components/Box/Box';
import { BoxContent, BoxContentPadding } from '../../Components/Box/BoxContent';
import { ApplicationCategoryV1, ApplicationService, ApplicationV1 } from '../../Services/ApplicationService';
import { LocaleService } from '../../Services/LocaleService';
import { UserService, UserV1, hasLicenseForApplication } from '../../Services/UserService';
import { PackageService, PackageV1 } from '../../Services/PackageService';
import { Formatter } from '../../utils/Formatter';
import { ContentFileV1Key, ContentService, ContentV1, ContentV1Key } from '../../Services/ContentService';
import { HomePageApplicationBox } from './HomePageApplicationBox';
import { Country, localeToCountry } from '../../Model/Country';
import { ContentImage } from '../../Components/ContentImage/ContentImage';
import { Environment } from '../../utils/Environment';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { sleep } from 'ts-delay';
import { IcButton, IcButtonColor, IcDummyContent, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridPadding, IcGridRow, IcPageContent, IcSpinner, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './HomePage.css';


export interface HomePageProps extends WithTranslation, WithLocaleProps
{
}


interface HomePageState
{
    applications:               Array<ApplicationV1>;
    applicationCategories:      Array<ApplicationCategoryV1>;
    applicationsSorted:         Record<string, Array<ApplicationV1>>;
    packages:                   Array<PackageV1>;
    user:                       UserV1 | null;
    teaserTitle:                ContentV1 | null;
    teaserSubtitle:             ContentV1 | null;
    country:                    Country;
    loading:                    boolean;
    error:                      Error | null;
}


class $HomePage extends React.Component<HomePageProps, HomePageState>
{
    private readonly _contentService:       ContentService;
    private readonly _applicationService:   ApplicationService;
    private readonly _packageService:       PackageService;
    private readonly _userService:          UserService;
    private readonly _localeService:        LocaleService;


    constructor ( props: HomePageProps )
    {
        super(props);

        this.state = {
            applications:               [],
            applicationCategories:      [],
            applicationsSorted:         {},
            packages:                   [],
            user:                       null,
            teaserTitle:                null,
            teaserSubtitle:             null,
            country:                    localeToCountry(this.props.locale) || Environment.server.default_country,
            loading:                    true,
            error:                      null
        };

        this._contentService = ContentService.getInstance();
        this._applicationService = ApplicationService.getInstance();
        this._packageService = PackageService.getInstance();
        this._userService = UserService.getInstance();
        this._localeService = LocaleService.getInstance();
    }


    private _apply ( user: UserV1 | null, applications: Array<ApplicationV1> ): void
    {
        if ( user )
        {

            applications = [...applications].sort( ( a, b ) =>
                (hasLicenseForApplication(user, a.uid) ? 1 : 0) - (hasLicenseForApplication(user, b.uid) ? 1 : 0)
            );
        }

        const applicationsSorted: Record<string, Array<ApplicationV1>> = {};
        for ( const application of applications )
        {
            if ( ! applicationsSorted[application.applicationcategory_uid] )
            {
                applicationsSorted[application.applicationcategory_uid] = [];
            }

            applicationsSorted[application.applicationcategory_uid].push(application);
        }

        const country = this.state.user?.organisations[0]?.country || localeToCountry(this.props.locale) || Environment.server.default_country;
            
        this.setState({
            user,
            country,
            applicationsSorted
        });
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const country = this.state.user?.organisations[0]?.country || localeToCountry(this.props.locale) || Environment.server.default_country;
            const applications = await this._applicationService.getApplications(0, 100); // TODO
            const applicationCategories = await this._applicationService.getApplicationCategories(0, 100); // TODO
            const packages = await this._packageService.getPackages(country, 0, 100); // TODO
            const teaserTitle = await this._contentService.getContent(ContentV1Key.TeaserTitle, this.props.locale);
            const teaserSubtitle = await this._contentService.getContent(ContentV1Key.TeaserSubtitle, this.props.locale);

            this.setState({
                applications,
                applicationCategories,
                packages,
                teaserTitle,
                teaserSubtitle,
                country,
                loading:    false
            });

            this._apply(this.state.user, applications);
        }
        catch ( err )
        {
            console.error(`Error adding license: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, async ( user ) => 
        {
            this._apply(user, this.state.applications);

            await sleep(100);

            await this._load();
        });

        const user =  this._userService.isLoggedIn().get();
        this._apply(user, this.state.applications);
        
        await this._load();
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
        this._localeService.getLocale().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.state.user ? this.props.t('homepage.txt_welcome_user', {username: Formatter.username(this.state.user)}) : this.props.t('homepage.txt_welcome')}
                    metaTitle=''
                    hidden={!this.state.user}
                />

                <IcErrorBox error={this.state.error} />

                {!this.state.user ?
                    <IcGridRow>
                        <IcGridItem s={12}>
                            <Box>
                                <IcGridRow padding={IcGridPadding.None} wrapReverse={true}>
                                    <IcGridItem s={12} m={8} l={6}>
                                        <BoxContent padding={BoxContentPadding.Large}>
                                            <IcText size={IcTextSize.Heading1}>
                                                {this.state.teaserTitle ?
                                                    this.state.teaserTitle.content
                                                :
                                                    <IcDummyContent />
                                                }
                                            </IcText>

                                            <IcText>
                                                {this.state.teaserSubtitle ?
                                                    this.state.teaserSubtitle.content
                                                :
                                                    <IcDummyContent />
                                                }
                                            </IcText>

                                            <IcGridRow padding={IcGridPadding.Small}>
                                                <IcGridItem s={5}>
                                                    <IcButton
                                                        to={LinkUtils.make('packages')}
                                                        fullWidth={true}
                                                        color={IcButtonColor.Secondary}>
                                                        {this.props.t('homepage.btn_packages')}
                                                    </IcButton>
                                                </IcGridItem>

                                                <IcGridItem s={5}>
                                                    <IcButton
                                                        to={LinkUtils.make('packages')}
                                                        fullWidth={true}
                                                        color={IcButtonColor.Primary}>
                                                        {this.props.t('homepage.btn_packages_free')}
                                                    </IcButton>
                                                </IcGridItem>
                                            </IcGridRow>
                                        </BoxContent>
                                    </IcGridItem>

                                    <IcGridItem s={12} m={4} l={6}>
                                        <BoxContent className='HomePage-teaser-imagebox'>
                                            <div className='HomePage-teaser-image'>
                                                <ContentImage contentFileKey={ContentFileV1Key.TeaserImage} />
                                            </div>
                                        </BoxContent>
                                    </IcGridItem>
                                </IcGridRow>
                            </Box>
                        </IcGridItem>
                    </IcGridRow>
                : null}

                {this.state.applicationCategories.map( ( applicationCategory ) => (
                    <>
                        {applicationCategory.slogans.find( ( o ) => o.locale === this.props.locale)?.value ?
                            <IcText size={IcTextSize.Small} bold={true}>
                                {applicationCategory.slogans.find( ( o ) => o.locale === this.props.locale)?.value}
                            </IcText>
                        : null}

                        <IcText size={IcTextSize.Heading2}>
                            {applicationCategory.names.find( ( o ) => o.locale === this.props.locale)?.value}
                        </IcText>

                        <IcFloatRow
                            align={IcFloatRowAlign.Left}
                            stretch={true}
                            wrap={true}>
                            {(this.state.applicationsSorted[applicationCategory.uid] || []).map( ( application ) => (
                                <HomePageApplicationBox
                                    key={application.uid}
                                    application={application}
                                    packages={this.state.packages}
                                    user={this.state.user}
                                    country={this.state.country}
                                />
                            ))}
                        </IcFloatRow>
                    </>
                ))}

                <IcSpinner active={this.state.loading} />
            </IcPageContent>
        );
    }
}


export const HomePage = withLocale(withTranslation()($HomePage));
