import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserV1 } from '../../../Services/UserService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { LicenseV1 } from '../../../Services/LicenseService';
import { OrganisationV1, OrganisationV1License } from '../../../Services/OrganisationService';
import { ListItemHeaderCheckbox } from '../../../Components/List/ListItemHeaderCheckbox';
import { Form, Formik } from 'formik';
import { ListEmpty } from '../../../Components/List/ListEmpty';
import { UserDetails } from '../../../Components/UserDetails/UserDetails';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface UserLicenseAssignPageSelectLicensesStepProps extends WithTranslation
{
    organisations:          Array<OrganisationV1>;
    licenses:               Array<OrganisationV1License>;
    user:                   UserV1;
    onSelectOrganisation:   ( ) => any;
    onCancel:               ( ) => any;
    onFinish:               ( licenses: Array<LicenseV1> ) => any;
}


interface AgreggatedLicense
{
    count_total:        number;
    count_assigned:     number;
    license:            LicenseV1;
}


interface UserLicenseAssignPageSelectLicensesStepFormData
{
    selected:   Record<string, boolean>;
}


class $UserLicenseAssignPageSelectLicensesStep extends React.Component<UserLicenseAssignPageSelectLicensesStepProps>
{
    constructor ( props: UserLicenseAssignPageSelectLicensesStepProps )
    {
        super(props);

        this._submit = this._submit.bind(this);
    }


    private _submit ( formData: UserLicenseAssignPageSelectLicensesStepFormData )
    {
        const licenses: Array<LicenseV1> = [];
        for ( const licenseUID in formData.selected )
        {
            if ( ! formData.selected[licenseUID] )
            {
                continue;
            }

            const license = this.props.licenses.find( o => o.license.uid === licenseUID );
            if ( ! license )
            {
                continue;
            }

            licenses.push(license.license);
        }

        this.props.onFinish(licenses);
    } 


    public render ( )
    {
        const agreggatedLicenses: Array<AgreggatedLicense> = [];
        const agreggatedLicensesMap: Record<string, AgreggatedLicense> = {};

        for ( const license of this.props.licenses )
        {
            if ( ! license.license.assignable )
            {
                continue;
            }

            if ( ! agreggatedLicensesMap[license.license.uid] )
            {
                agreggatedLicensesMap[license.license.uid] = {
                    count_total: 0,
                    count_assigned: 0,
                    license: license.license
                };
            }

            agreggatedLicensesMap[license.license.uid].count_total++;
            if ( license.assigned )
            {
                agreggatedLicensesMap[license.license.uid].count_assigned++;
            }
        }

        for ( const key in agreggatedLicensesMap )
        {
            agreggatedLicenses.push(agreggatedLicensesMap[key]);
        }

        const MyFormik = Formik<UserLicenseAssignPageSelectLicensesStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('userlicenseassignpageselectlicensesstep.txt_title')}
                </IcText>
            
                <IcText>
                    {this.props.t('userlicenseassignpageselectlicensesstep.txt_select_licenses')}
                </IcText>

                <UserDetails user={this.props.user} />

                <MyFormik
                    onSubmit={this._submit}
                    initialValues={{selected:{}}}>
                    <Form>
                        <List>
                            {agreggatedLicenses.map( ( agreggatedLicense ) => (
                                <ListItem key={agreggatedLicense.license.uid}>
                                    <ListItemHeader>
                                        <ListItemHeaderCheckbox
                                            name={`selected.${agreggatedLicense.license.uid}`}
                                            disabled={agreggatedLicense.count_assigned === agreggatedLicense.count_total}
                                        />

                                        <ListItemHeaderField
                                            grow={true}
                                            text={agreggatedLicense.license.name}
                                        />
                                        
                                        <ListItemHeaderField
                                            grow={true}
                                            text={`${agreggatedLicense.count_assigned} / ${agreggatedLicense.count_total} zugewiesen`}
                                        />
                                    </ListItemHeader>
                                </ListItem>
                            ))}

                            {agreggatedLicenses.length === 0 ?
                                <ListEmpty>
                                    {this.props.t('userlicenseassignpageselectlicensesstep.txt_no_licenses')}
                                </ListEmpty>
                            :null}
                        </List>

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            {this.props.organisations.length > 1 ?
                                <IcButton
                                    color={IcButtonColor.Link}
                                    onClick={this.props.onSelectOrganisation}>
                                    {this.props.t('userlicenseassignpageselectlicensesstep.btn_select_organisation')}
                                </IcButton>
                            :
                                <IcButton
                                    color={IcButtonColor.Link}
                                    onClick={this.props.onCancel}>
                                    {this.props.t('userlicenseassignpageselectlicensesstep.btn_cancel')}
                                </IcButton>
                            }

                            <IcButton
                                type='submit'
                                disabled={agreggatedLicenses.length === 0}>
                                {this.props.t('userlicenseassignpageselectlicensesstep.btn_assign')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const UserLicenseAssignPageSelectLicensesStep = withTranslation()($UserLicenseAssignPageSelectLicensesStep);
