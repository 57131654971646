import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderAction } from '../../../Components/List/ListItemHeaderAction';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { UserDetails } from '../../../Components/UserDetails/UserDetails';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { UserV1 } from '../../../Services/UserService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface UserLicenseAssignPageSelectOrganisationStepProps extends WithTranslation
{
    user:           UserV1;
    organisations:  Array<OrganisationV1>;
    onCancel:       ( ) => any;
    onFinish:       ( organisation: OrganisationV1 ) => any;
}


class $UserLicenseAssignPageSelectOrganisationStep extends React.Component<UserLicenseAssignPageSelectOrganisationStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('userlicenseassignpageselectorganisationstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('userlicenseassignpageselectorganisationstep.txt_select_organisation')}
                </IcText>
                
                <UserDetails user={this.props.user} />

                <List>
                    {this.props.organisations.map( ( organisation ) => (
                        <ListItem key={organisation.uid}>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    text={organisation.name}
                                    grow={true}
                                    onClick={ ( ) => this.props.onFinish(organisation) }
                                />

                                <ListItemHeaderAction
                                    icon={faCaretRight}
                                    title={this.props.t('userlicenseassignpageselectorganisationstep.btn_select')}
                                    onClick={ ( ) => this.props.onFinish(organisation) }
                                />
                            </ListItemHeader>
                        </ListItem>
                    ))}
                </List>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('userlicenseassignpageselectorganisationstep.btn_cancel')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const UserLicenseAssignPageSelectOrganisationStep = withTranslation()($UserLicenseAssignPageSelectOrganisationStep);
