import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DeletePackageV1PriceMode } from '../../../Services/PackageService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcInputRadio } from '@indece-common/ic-ui-lib-react';


export interface AdminPackagePriceDeletePageModeStepProps extends WithTranslation
{
    onFinish: ( mode: DeletePackageV1PriceMode ) => any;
    onCancel: ( ) => any;
}


class $AdminPackagePriceDeletePageModeStep extends React.Component<AdminPackagePriceDeletePageModeStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcFloatRow align={IcFloatRowAlign.Center}>
                    <IcInputRadio
                        value={DeletePackageV1PriceMode.Immediately}
                        onClick={ ( ) => this.props.onFinish(DeletePackageV1PriceMode.Immediately)}
                        label={this.props.t('adminpackagepricedeletepagemodestep.btn_immediately')}
                    />

                    <IcInputRadio
                        value={DeletePackageV1PriceMode.PhaseOut}
                        onClick={ ( ) => this.props.onFinish(DeletePackageV1PriceMode.PhaseOut)}
                        label={this.props.t('adminpackagepricedeletepagemodestep.btn_phase_out')}
                    />

                    <IcInputRadio
                        value={DeletePackageV1PriceMode.Replace}
                        onClick={ ( ) => this.props.onFinish(DeletePackageV1PriceMode.Replace)}
                        label={this.props.t('adminpackagepricedeletepagemodestep.btn_replace')}
                    />
                </IcFloatRow>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        type='button'
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}
                        testID='adminpackagepricedeletepagemodestep.btn_cancel'>
                        {this.props.t('adminpackagepricedeletepagemodestep.btn_cancel')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AdminPackagePriceDeletePageModeStep = withTranslation()($AdminPackagePriceDeletePageModeStep);
