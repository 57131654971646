import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContentService, ContentV1, ContentV1Key } from '../../Services/ContentService';
import { LocaleService } from '../../Services/LocaleService';
import { MarkdownBlock } from '../../Components/MarkdownBlock/MarkdownBlock';
import { IcCard, IcCardPadding, IcDummyContent, IcErrorBox, IcPageContent } from '@indece-common/ic-ui-lib-react';


export interface TermsPageProps extends WithTranslation
{
}


interface TermsPageState
{
    content:    ContentV1 | null;
    loading:    boolean;
    error:      Error | null;
}


class $TermsPage extends React.Component<TermsPageProps, TermsPageState>
{
    private readonly _contentService:   ContentService;
    private readonly _localeService:    LocaleService;


    constructor ( props: TermsPageProps )
    {
        super(props);

        this.state = {
            content:    null,
            loading:    true,
            error:      null
        };

        this._contentService = ContentService.getInstance();
        this._localeService = LocaleService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const content = await this._contentService.getContent(
                ContentV1Key.Terms,
                this._localeService.getLocale().get()
            );

            this.setState({
                content,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading content ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
        
        this._localeService.getLocale().subscribe(this, async ( ) =>
        {
            await this._load();
        });
    }


    public componentWillUnmount ( ): void
    {
        this._localeService.getLocale().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('termspage.txt_terms')} />

                <IcErrorBox error={this.state.error} />

                <IcCard padding={IcCardPadding.Small}>
                    {this.state.content ?
                        <MarkdownBlock text={this.state.content.content} />
                    :
                        <>
                            <IcDummyContent /><br />
                            <IcDummyContent /><br />
                            <IcDummyContent />
                        </>
                    }
                </IcCard>
            </IcPageContent>
        );
    }
}


export const TermsPage = withTranslation()($TermsPage);
