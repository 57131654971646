import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

import './FeatureTag.css';


export interface FeatureTagProps
{
    name:       string;
    showIcon?:  boolean;
}


export class FeatureTag extends React.Component<FeatureTagProps>
{
    public render ( )
    {
        return (
            <div className='FeatureTag'>
                {this.props.showIcon !== false ?
                    <div className='FeatureTag-icon'>
                        <FontAwesomeIcon icon={faArrowCircleRight} />
                    </div>
                : null}

                <div className='FeatureTag-text'>
                    {this.props.name}
                </div>
            </div>
        );
    }
}
