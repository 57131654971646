import React from 'react';
import { ContentService, ContentV1, ContentV1Key } from '../../Services/ContentService';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { IcDummyContent, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface ContentTextProps extends WithLocaleProps
{
    contentKey: ContentV1Key;
    size?:      IcTextSize;
    bold?:      boolean;
    className?: string;
    testID?:    string;
}


interface ContentTextState
{
    content:    ContentV1 | null;
    loading:    boolean;
}


class $ContentText extends React.Component<ContentTextProps, ContentTextState>
{
    private readonly _contentService:   ContentService;


    constructor ( props: ContentTextProps )
    {
        super(props);

        this.state = {
            content:    null,
            loading:    true
        };

        this._contentService = ContentService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            const content = await this._contentService.getContent(this.props.contentKey, this.props.locale);

            this.setState({
                content,
                loading: false,
            });
        }
        catch ( err )
        {
            console.error(`Error loading content: ${(err as Error).message}`, err);

            this.setState({
                content:    null,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();    
    }


    public async componentDidUpdate ( prevProps: Readonly<ContentTextProps> ): Promise<void>
    {
        if ( this.props.contentKey !== prevProps.contentKey ||
             this.props.locale !== prevProps.locale )
        {
            await this._load();
        }
    }


    public render ( )
    {
        return (
            <IcText
                size={this.props.size}
                bold={this.props.bold}
                className={this.props.className}
                testID={this.props.testID}>
                {this.state.loading ?
                    <IcDummyContent />
                :
                    this.state.content?.content
                }
            </IcText>
        );
    }
}


export const ContentText = withLocale($ContentText);
