import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';


export interface FormObserverProps
{
    onChange:   ( values: any ) => any;
}


export const FormObserver: React.FC<FormObserverProps> = ( props: FormObserverProps ) =>
{
    const { values } = useFormikContext();
  
    useEffect( ( ) =>
    {
        props.onChange(values);
    }, [values]);
  
    return null;
};
