import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RespAddSfaV1 } from '../../../Services/UserService';
import { IcButton, IcCard, IcCardPadding, IcCode, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface AccountAddSfaPageSfaRecoveryStepProps extends WithTranslation
{
    sfa:        RespAddSfaV1;
    onFinish:   ( ) => any;
}


class $AccountAddSfaPageSfaRecoveryStep extends React.Component<AccountAddSfaPageSfaRecoveryStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small} bold={true}>
                    {this.props.t('accountaddsfapagesfarecoverystep.txt_subtitle')}
                </IcText>

                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('accountaddsfapagesfarecoverystep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('accountaddsfapagesfarecoverystep.txt_recovery')}
                </IcText>

                <IcCode
                    code={(this.props.sfa.recovery_codes || []).join('\n')}
                />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        onClick={ ( ) => this.props.onFinish()}>
                        {this.props.t('accountaddsfapagesfarecoverystep.btn_confirm')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AccountAddSfaPageSfaRecoveryStep = withTranslation()($AccountAddSfaPageSfaRecoveryStep);
