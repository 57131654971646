import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputTextarea } from '../../Components/Input/InputTextarea';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ConfigPropertyV1, ConfigPropertyV1Key, ConfigService } from '../../Services/ConfigService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcSpinnerSize, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export interface AdminConfigPageEmailFooterBoxProps extends WithTranslation
{
}


interface AdminConfigPageEmailFooterBoxFormData
{
    text:   string;
    html:   string;
}


interface AdminConfigPageEmailFooterBoxState
{
    initialFormData:    AdminConfigPageEmailFooterBoxFormData;
    config:             Partial<Record<ConfigPropertyV1Key, ConfigPropertyV1>>;
    edit:               boolean;
    loading:            boolean;
    error:              Error | null;
}


class $AdminConfigPageEmailFooterBox extends React.Component<AdminConfigPageEmailFooterBoxProps, AdminConfigPageEmailFooterBoxState>
{
    private readonly _configService:    ConfigService;


    constructor ( props: AdminConfigPageEmailFooterBoxProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                text:   '',
                html:   ''
            },
            config:     {},
            edit:       false,
            loading:    true,
            error:      null
        };

        this._configService = ConfigService.getInstance();

        this._enableEdit = this._enableEdit.bind(this);
        this._disableEdit = this._disableEdit.bind(this);
        this._submit = this._submit.bind(this);
    }


    private _enableEdit ( )
    {
        this.setState({
            edit:   true,
            initialFormData: {
                text:   this.state.config[ConfigPropertyV1Key.EmailFooterText]?.value || '',
                html:   this.state.config[ConfigPropertyV1Key.EmailFooterHTML]?.value || ''
            }
        });
    }


    private _disableEdit ( )
    {
        this.setState({
            edit:   false
        });
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const config = await this._configService.getConfig();

            this.setState({
                error:      null,
                loading:    false,
                config
            });
        }
        catch ( err )
        {
            console.error(`Error loading config: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _submit ( values: AdminConfigPageEmailFooterBoxFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.EmailFooterText,
                values.text.trim()
            );
            
            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.EmailFooterHTML,
                values.html.trim()
            );

            this.setState({
                loading:    false,
                edit:       false
            });

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error storing config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading2}>
                    {this.props.t('adminconfigpageemailfooterbox.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <Formik
                    onSubmit={this._submit}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <List>
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.EmailFooterText]?.value ? '...' : ''}
                                            subtext={this.props.t('adminconfigpageemailfooterbox.txt_footer_text')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField
                                            grow={true}>
                                            <InputTextarea
                                                label={this.props.t('adminconfigpageemailfooterbox.inp_footer_text')}
                                                name='text'
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.EmailFooterHTML]?.value ? '...' : ''}
                                            subtext={this.props.t('adminconfigpageemailfooterbox.txt_footer_html')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField
                                            grow={true}>
                                            <InputTextarea
                                                label={this.props.t('adminconfigpageemailfooterbox.inp_footer_html')}
                                                name='html'
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                        </List>

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcSpinner
                                size={IcSpinnerSize.Small}
                                active={this.state.loading}
                            />

                            {!this.state.edit && !this.state.loading ?
                                <IcButton
                                    color={IcButtonColor.Secondary}
                                    type='button'
                                    onClick={this._enableEdit}>
                                    <FontAwesomeIcon icon={faPen} />

                                    {this.props.t('adminconfigpageemailfooterbox.btn_edit')}
                                </IcButton>
                            : null}

                            {this.state.edit && !this.state.loading ? 
                                <IcButton
                                    color={IcButtonColor.Link}
                                    type='button'
                                    onClick={this._disableEdit}>
                                    {this.props.t('adminconfigpageemailfooterbox.btn_cancel')}
                                </IcButton>
                            : null}

                            {this.state.edit && !this.state.loading ? 
                                <IcButton type='submit'>
                                    <FontAwesomeIcon icon={faCheck} />

                                    {this.props.t('adminconfigpageemailfooterbox.btn_save')}
                                </IcButton>
                            : null}
                        </IcFloatRow>
                    </Form>
                </Formik>
            </IcCard>
        );
    }
}


export const AdminConfigPageEmailFooterBox = withTranslation()($AdminConfigPageEmailFooterBox);
