import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserService } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AccountUpdateEmailPageLoadingStep } from './AccountUpdateEmailPageLoadingStep/AccountUpdateEmailPageLoadingStep';
import { AccountUpdateEmailPageSuccessStep } from './AccountUpdateEmailPageSuccessStep/AccountUpdateEmailPageSuccessStep';
import { AccountUpdateEmailPageEmailStep, AccountUpdateEmailPageEmailStepFormData } from './AccountUpdateEmailPageEmailStep/AccountUpdateEmailPageEmailStep';
import { AccountUpdateEmailPageConfirmEmailStep, AccountUpdateEmailPageConfirmEmailStepFormData } from './AccountUpdateEmailPageConfirmEmailStep/AccountUpdateEmailPageConfirmEmailStep';
import { Delay, sleep } from 'ts-delay';
import { IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AccountUpdateEmailPageProps extends RouteComponentProps, WithTranslation
{
}


enum AccountUpdateEmailPageStep
{
    Email           = 'EMAIL',
    ConfirmEmail    = 'CONFIRM_EMAIL',
    Loading         = 'LOADING',
    Success         = 'SUCCESS'
}


interface AccountUpdateEmailPageState
{
    step:   AccountUpdateEmailPageStep;
    error:  Error | null;
}


class $AccountUpdateEmailPage extends React.Component<AccountUpdateEmailPageProps, AccountUpdateEmailPageState>
{
    private readonly _userService:  UserService;
    
    
    constructor ( props: AccountUpdateEmailPageProps )
    {
        super(props);
        
        this.state = {
            step:   AccountUpdateEmailPageStep.Email,
            error:  null
        };
        
        this._userService = UserService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishEmail = this._finishEmail.bind(this);
        this._finishConfirmEmail = this._finishConfirmEmail.bind(this);
    }
    

    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishEmail ( formData: AccountUpdateEmailPageEmailStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AccountUpdateEmailPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.updateOwnUserEmail({
                password:   formData.password,
                email:      formData.email,
            });

            await delay.sleep();

            this.setState({
                step:   AccountUpdateEmailPageStep.ConfirmEmail
            });
        }
        catch ( err )
        {
            console.error(`Error updating email in: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountUpdateEmailPageStep.Email,
                error:  err as Error
            });
        }
    }
    

    private async _finishConfirmEmail ( formData: AccountUpdateEmailPageConfirmEmailStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AccountUpdateEmailPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.confirmEmail({
                code:   formData.code
            });

            await delay.sleep();

            this.setState({
                step:   AccountUpdateEmailPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('account'));
        }
        catch ( err )
        {
            console.error(`Error confirming email in: ${(err as Error).message}`, err);

            this.setState({
                step:   AccountUpdateEmailPageStep.Email,
                error:  err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('accountupdateemailpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AccountUpdateEmailPageStep.Email ?
                    <AccountUpdateEmailPageEmailStep
                        onCancel={this._cancel}
                        onFinish={this._finishEmail}
                    />
                : null}

                {this.state.step === AccountUpdateEmailPageStep.ConfirmEmail ?
                    <AccountUpdateEmailPageConfirmEmailStep
                        onCancel={this._cancel}
                        onFinish={this._finishConfirmEmail}
                    />
                : null}

                {this.state.step === AccountUpdateEmailPageStep.Loading ?
                    <AccountUpdateEmailPageLoadingStep />
                : null}

                {this.state.step === AccountUpdateEmailPageStep.Success ?
                    <AccountUpdateEmailPageSuccessStep />
                : null}
            </IcPageContent>
        );
    } 
}


export const AccountUpdateEmailPage = withTranslation()(withRouter($AccountUpdateEmailPage));
