import React from 'react';
import { Locale } from '../Model/Locale';
import { LocaleService } from '../Services/LocaleService';


export interface WithLocaleProps
{
    locale: Locale;
}


interface WithLocaleWrapperProps
{
    component:  React.ComponentType<React.ComponentProps<any> & WithLocaleProps>;
    props:      React.ComponentProps<any>;
}


interface WithLocaleWrapperState
{
    locale: Locale;
}


class WithLocaleWrapper extends React.Component<WithLocaleWrapperProps, WithLocaleWrapperState>
{
    private readonly _localeService: LocaleService;
    
    
    constructor ( props: WithLocaleWrapperProps )
    {
        super(props);

        this.state = {
            locale: LocaleService.getInstance().getLocale().get()
        };

        this._localeService = LocaleService.getInstance();
    }


    public componentDidMount ( ): void
    {
        this._localeService.getLocale().subscribe(this, ( locale ) =>
        {
            this.setState({
                locale
            });
        });

        const locale = this._localeService.getLocale().get();
        this.setState({
            locale
        });
    }


    public componentWillUnmount ( ): void
    {
        this._localeService.getLocale().unsubscribe(this);
    }


    public render ( )
    {
        const WrappedComponent = this.props.component;
        
        return <WrappedComponent {...this.props.props} locale={this.state.locale} />;
    }
}


export function withLocale <
    C extends React.ComponentType<React.ComponentProps<any> & WithLocaleProps>,
    ResolvedProps = JSX.LibraryManagedAttributes<C, Omit<React.ComponentProps<C>, keyof WithLocaleProps>>,
> (WrappedComponent: C): React.ComponentType<ResolvedProps> {
    return ( props ) => <WithLocaleWrapper component={WrappedComponent} props={props} />;
}
