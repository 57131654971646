import DayJS from 'dayjs';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PurchaseService, PurchaseV1 } from '../../Services/PurchaseService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { PurchaseCancelPageLoadingStep } from './PurchaseCancelPageLoadingStep/PurchaseCancelPageLoadingStep';
import { PurchaseCancelPageSuccessStep } from './PurchaseCancelPageSuccessStep/PurchaseCancelPageSuccessStep';
import { PurchaseCancelPageConfirmStep, PurchaseCancelPageConfirmStepFormData } from './PurchaseCancelPageConfirmStep/PurchaseCancelPageConfirmStep';
import { isAdmin, UserService, UserV1 } from '../../Services/UserService';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface PurchaseCancelPageRouteParams
{
    purchaseUID: string;
}


export interface PurchaseCancelPageProps extends RouteComponentProps<PurchaseCancelPageRouteParams>, WithTranslation
{
}


enum PurchaseCancelPageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface PurchaseCancelPageState
{
    step:       PurchaseCancelPageStep;
    purchase:   PurchaseV1 | null;
    ownUser:    UserV1 | null;
    error:      Error | null;
}


class $PurchaseCancelPage extends React.Component<PurchaseCancelPageProps, PurchaseCancelPageState>
{
    private readonly _purchaseService:  PurchaseService;
    private readonly _userService:      UserService;
    

    constructor ( props: PurchaseCancelPageProps )
    {
        super(props);

        
        this.state = {
            step:       PurchaseCancelPageStep.Loading,
            purchase:   null,
            ownUser:    null,
            error:      null
        };
        
        this._purchaseService   = PurchaseService.getInstance();
        this._userService       = UserService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:       PurchaseCancelPageStep.Loading,
                error:      null
            });

            const purchase = await this._purchaseService.getPurchase(this.props.router.params.purchaseUID);

            this.setState({
                step:   PurchaseCancelPageStep.Confirm,
                purchase
            });
        }
        catch ( err )
        {
            console.error(`Error loading purchase: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( formData: PurchaseCancelPageConfirmStepFormData ): Promise<void>
    {
        if ( ! this.state.purchase )
        {
            return;
        }

        try
        {
            this.setState({
                step:       PurchaseCancelPageStep.Loading,
                error:      null
            });

            await this._purchaseService.cancelPurchase(this.state.purchase.uid, {
                cancel_at:  (this.state.ownUser && isAdmin(this.state.ownUser) && formData.cancel_at.trim()) ? DayJS(formData.cancel_at.trim()).format('YYYY-MM-DD') : null
            });

            this.setState({
                step:   PurchaseCancelPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting purchase: ${(err as Error).message}`, err);

            this.setState({
                step:       PurchaseCancelPageStep.Confirm,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });

        await this._load();
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('purchasecancelpage.txt_title')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === PurchaseCancelPageStep.Loading ?
                    <PurchaseCancelPageLoadingStep />
                : null}

                {this.state.step === PurchaseCancelPageStep.Confirm && this.state.purchase ?
                    <PurchaseCancelPageConfirmStep
                        purchase={this.state.purchase}
                        ownUser={this.state.ownUser}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === PurchaseCancelPageStep.Success ?
                    <PurchaseCancelPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const PurchaseCancelPage = withTranslation()(withRouter($PurchaseCancelPage));
