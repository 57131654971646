import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface UserLicenseAssignPagePurchaseStepProps extends WithTranslation
{
    organisations:          Array<OrganisationV1>;
    organisation:           OrganisationV1;
    onCancel:               ( ) => any;
    onSelectOrganisation:   ( ) => any;
}


class $UserLicenseAssignPagePurchaseStep extends React.Component<UserLicenseAssignPagePurchaseStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('userlicenseassignpagepurchasestep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('userlicenseassignpagepurchasestep.txt_purchase')}
                </IcText>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    {this.props.organisations.length > 1 ?
                        <IcButton
                            color={IcButtonColor.Link}
                            onClick={this.props.onSelectOrganisation}>
                            {this.props.t('userlicenseassignpagepurchasestep.btn_select_organisation')}
                        </IcButton>
                    :
                        <IcButton
                            color={IcButtonColor.Link}
                            onClick={this.props.onCancel}>
                            {this.props.t('userlicenseassignpagepurchasestep.btn_cancel')}
                        </IcButton>
                    }

                    <IcButton
                        to={LinkUtils.make('packages') + `?organisation_uid=${encodeURIComponent(this.props.organisation.uid)}`}>
                        {this.props.t('userlicenseassignpagepurchasestep.btn_purchase')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const UserLicenseAssignPagePurchaseStep = withTranslation()($UserLicenseAssignPagePurchaseStep);
