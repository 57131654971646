import React from 'react';
import { PackageV1, PackageV1Price, isFreePrice } from '../../Services/PackageService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Country } from '../../Model/Country';
import { Formatter } from '../../utils/Formatter';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';

import './PackageDetails.css';
import { IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface PackageDetailsProps extends WithTranslation, WithLocaleProps
{
    package:        PackageV1;
    packagePrice:   PackageV1Price;
    country:        Country;
    count:          number;
}


class $PackageDetails extends React.Component<PackageDetailsProps>
{
    public render ( )
    {
        const name = this.props.package.names.find( o => o.locale === this.props.locale)?.value || '???';
        const features = this.props.package.details.features.find( o => o.locale === this.props.locale)?.values || [];
        const free = isFreePrice(this.props.packagePrice);
    
        return (
            <div>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.count}x {name} ({Formatter.country(this.props.country)})
                </IcText>
                
                <IcText>
                    <div className='PackageDetails-features'>
                        {features.map( ( feature, i ) => (
                            <div className='PackageDetails-feature' key={i}>
                                {feature}
                            </div>
                        ))}
                    </div>

                    <div className='PackageDetails-pricing'>
                        {this.props.packagePrice.periods.map( ( pricePeriod, i ) => (
                            <div className='PackageDetails-pricing-period'>
                                {i === 0 ? '' : this.props.t('packagedetails.txt_then')} {Formatter.duration(pricePeriod.duration)} {pricePeriod.gross > 0 ? Formatter.currencyAmount(this.props.count*pricePeriod.gross, this.props.packagePrice.currency, this.props.packagePrice.payment_interval) : this.props.t('packagedetails.txt_free')}
                            </div>
                        ))}

                        {!free ?
                            <div>
                                {Formatter.vatRate(this.props.packagePrice.rate_vat || 0, true)}
                            </div>
                        : null}
                    </div>
                </IcText>
            </div>
        );
    }
}


export const PackageDetails = withLocale(withTranslation()($PackageDetails));
