import { BackendService } from './BackendService';


export interface AddFileV1Request
{
    file:   File;
}


export interface FileV1
{
    uid:        string;
    filename:   string;
    mimetype:   string;
    size:       number;
}


export class FileService
{
    private static _instance: FileService;
    private readonly _backendService:   BackendService;
    
    
    public static getInstance ( ): FileService
    {
        if ( ! this._instance )
        {
            this._instance = new FileService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async addFile ( params: AddFileV1Request ): Promise<string>
    {
        const body = new FormData();
        body.append('file', params.file);

        const resp = await this._backendService.fetchJson(
            `/api/v1/file`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                },
                body
            }
        );

        return resp.file_uid;
    }


    public getFileDataURI ( fileUID: string ): string
    {
        return `/api/v1/file/${encodeURIComponent(fileUID)}/data`;
    }


    public async getFileData ( fileUID: string ): Promise<Blob>
    {
        const resp = await this._backendService.fetchBlob(
            `/api/v1/file/${encodeURIComponent(fileUID)}/data`,
            {
                method: 'GET'
            }
        );

        return resp;
    }

   
    /*public async deleteFile ( fileUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/file/${encodeURIComponent(fileUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
   

    public async getFile ( fileUID: string ): Promise<FileV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/file/${encodeURIComponent(fileUID)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.file;
    }


    public async getFiles ( from: number, size: number ): Promise<Array<FileV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/file?from=${encodeURIComponent(from)}&size=${encodeURIComponent(size)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.files;
    }*/
}
