import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { MessageService, MessageV1 } from '../../Services/MessageService';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ListItemBody } from '../../Components/List/ListItemBody';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcCard, IcCardPadding, IcErrorBox, IcPageContent, IcSpinner, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminMessagesPageProps extends WithTranslation
{
}


interface AdminMessagesPageState
{
    messages:   Array<MessageV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


class $AdminMessagesPage extends React.Component<AdminMessagesPageProps, AdminMessagesPageState>
{
    private readonly BULK_SIZE      = 50;
    private readonly _messageService:  MessageService;


    constructor ( props: AdminMessagesPageProps )
    {
        super(props);

        this.state = {
            messages:   [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._messageService = MessageService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }

    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const messages = await this._messageService.getMessages(0, this.BULK_SIZE);

            this.setState({
                messages,
                hasMore:    messages.length >= this.BULK_SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading messages: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const messages = await this._messageService.getMessages(this.state.messages.length, this.BULK_SIZE);

            this.setState({
                messages:   [...this.state.messages, ...messages],
                hasMore:    messages.length >= this.BULK_SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more messages: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'messages')}
                        label={this.props.t('adminmessagespage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle title={this.props.t('adminmessagespage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcErrorBox error={this.state.error} />

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <List>
                            {this.state.messages.map( ( message ) => (
                                <ListItem key={message.uid}>
                                    <ListItemHeader>
                                        <ListItemHeaderField
                                            grow={true}
                                            text={message.name || ''}
                                            subtext={message.email || ''}
                                        />

                                        <ListItemHeaderAction
                                            icon={faTrash}
                                            to={LinkUtils.make('admin', 'message', message.uid, 'delete')}
                                            title={this.props.t('adminmessagespage.btn_delete')}
                                        />
                                    </ListItemHeader>

                                    <ListItemBody>
                                        {message.message}
                                    </ListItemBody>
                                </ListItem>
                            ))}

                            {this.state.messages.length === 0 && !this.state.loading ?
                                <ListEmpty>
                                    {this.props.t('adminmessagespage.txt_no_messages')}
                                </ListEmpty>
                            : null}
                        </List>

                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminMessagesPage = withTranslation()($AdminMessagesPage);
