import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { isAdmin, isOwner, UserService, UserV1, UserV1OrganisationRole } from '../../Services/UserService';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { Formatter } from '../../utils/Formatter';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { faEye, faPen, faPenToSquare, faPlusCircle, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { LabelValueList } from '../../Components/LabelValueList/LabelValueList';
import { LabelValue } from '../../Components/LabelValueList/LabelValue';
import { OrganisationPaymentDetails } from '../../Components/OrganisationPaymentDetails/OrganisationPaymentDetails';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { OrganisationPagePurchasesBox } from './OrganisationPagePurchasesBox';
import { BillsBox } from '../../BusinessComponents/BillsBox/BillsBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrganisationPagePaymentMethodsBox } from './OrganisationPagePaymentMethodsBox';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcChip, IcDummyContent, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcPageContent, IcSpinner, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { InvitedChip } from '../../Components/Chip/InvitedChip';
import { LockedChip } from '../../Components/Chip/LockedChip';


export interface AdminEditOrganisationRouteParams
{
    organisationUID:  string;
}


export interface OrganisationPageProps extends RouteComponentProps<AdminEditOrganisationRouteParams>, WithTranslation
{
}


interface OrganisationPageFormDataUser
{
    role:   UserV1OrganisationRole;
}


interface OrganisationPageFormData
{
    users:  Record<string, OrganisationPageFormDataUser>;
}


interface OrganisationPageState
{
    initialFormData:    OrganisationPageFormData;
    organisation:       OrganisationV1 | null;
    users:              Array<UserV1>;
    user:               UserV1 | null;
    error:              Error | null;
    loading:            boolean;
}


class $OrganisationPage extends React.Component<OrganisationPageProps, OrganisationPageState>
{
    private readonly _organisationService:  OrganisationService;
    private readonly _userService:  UserService;
    

    constructor ( props: OrganisationPageProps )
    {
        super(props);

        this.state = {
            initialFormData:    {
                users:              {}
            },
            organisation:       null,
            users:              [],
            user:               null,
            error:              null,
            loading:            true
        };

        this._organisationService = OrganisationService.getInstance();
        this._userService = UserService.getInstance();

        this._update    = this._update.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const organisation = await this._organisationService.getOrganisation(this.props.router.params.organisationUID);
            const users = await this._userService.getUsers(
                {
                    organisation_uid: organisation.uid,
                },
                0,
                100
            );   // TODO

            const initialFormData: OrganisationPageFormData = {
                users:  {}
            };

            for ( const user of users )
            {
                initialFormData.users[user.uid] = {
                    role: user.organisations.find( o => o.uid === organisation.uid )!.role
                };
            }

            this.setState({
                error:      null,
                loading:    false,
                organisation,
                users,
                initialFormData
            });
        }
        catch ( err )
        {
            console.error(`Error loading organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _update ( formData: OrganisationPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.organisation )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true
            });


            for ( const user of this.state.users )
            {
                const prevRole = user.organisations.find( o => o.uid === this.state.organisation!.uid )!.role;

                if ( prevRole === formData.users[user.uid].role )
                {
                    continue;
                }

                await this._organisationService.updateOrganisationMember(
                    this.state.organisation.uid,
                    user.uid,
                    {
                        role: formData.users[user.uid].role
                    }
                );
            }

            this.setState({
                error:      null,
                loading:    false,
            });
        }
        catch ( err )
        {
            console.error(`Error updating organisation: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( user ) => 
        {
            this.setState({
                user
            });
        });
        
        const user = this._userService.isLoggedIn().get();
        this.setState({
            user
        });

        await this._load();
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('organisationpage.txt_organisation')} />

                <IcErrorBox error={this.state.error} />

                <IcSpinner active={this.state.loading} />

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading3}>
                                {this.props.t('organisationpage.txt_general')}
                            </IcText>

                            <LabelValueList>
                                <LabelValue label={this.props.t('organisationpage.txt_name')}>
                                    {this.state.organisation ?
                                        this.state.organisation.name
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>

                                <LabelValue label={this.props.t('organisationpage.txt_country')}>
                                    {this.state.organisation ?
                                        Formatter.country(this.state.organisation.country)
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>
                            </LabelValueList>
                    
                            <OrganisationPaymentDetails
                                organisation={this.state.organisation}
                            />

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                {this.state.user && this.state.organisation && (isAdmin(this.state.user) || isOwner(this.state.user, this.state.organisation.uid)) ?
                                    <IcButton
                                        to={LinkUtils.make('organisation', this.state.organisation.uid, 'edit')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faPenToSquare} />

                                        {this.props.t('organisationpage.btn_edit')}
                                    </IcButton>
                                : null}

                                {this.state.organisation && this.state.user && isAdmin(this.state.user) ?
                                    <IcButton
                                        to={LinkUtils.make('organisation', this.state.organisation.uid, 'delete')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faTrash} />

                                        {this.props.t('organisationpage.btn_delete')}
                                    </IcButton>
                                : null}
                            </IcFloatRow>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>

                {this.state.user && this.state.organisation && (isAdmin(this.state.user) || isOwner(this.state.user, this.state.organisation.uid)) ?
                    <IcGridRow>
                        <IcGridItem s={12}>
                            <OrganisationPagePaymentMethodsBox
                                organisationUID={this.props.router.params.organisationUID}
                            />
                        </IcGridItem>
                    </IcGridRow>
                : null}
            
                {this.state.user && this.state.organisation && (isAdmin(this.state.user) || isOwner(this.state.user, this.state.organisation.uid)) ?
                    <IcGridRow>
                        <IcGridItem s={12}>
                            <OrganisationPagePurchasesBox
                                organisationUID={this.props.router.params.organisationUID}
                            />
                        </IcGridItem>
                    </IcGridRow>
                : null}

                {this.state.user && this.state.organisation && (isAdmin(this.state.user) || isOwner(this.state.user, this.state.organisation.uid)) ?
                    <IcGridRow>
                        <IcGridItem s={12}>
                            <BillsBox
                                organisationUID={this.props.router.params.organisationUID}
                            />
                        </IcGridItem>
                    </IcGridRow>
                : null}

                {this.state.user && this.state.organisation && (isAdmin(this.state.user) || isOwner(this.state.user, this.state.organisation.uid)) ?
                    <IcGridRow>
                        <IcGridItem s={12}>
                            <IcCard padding={IcCardPadding.Small}>
                                <IcText size={IcTextSize.Heading3}>
                                    {this.props.t('organisationpage.txt_members')}
                                </IcText>

                                <List>
                                    {this.state.users.map( ( user ) => (
                                        <ListItem key={user.uid}>
                                            <ListItemHeader>
                                                <ListItemHeaderField
                                                    grow={true}
                                                    to={LinkUtils.make('user', user.uid)}
                                                    subtext={user.email}>
                                                    {Formatter.username(user)}
        
                                                    {user.invitation_pending ? 
                                                        <InvitedChip date={user.datetime_invited || ''} />
                                                    : null}
        
                                                    {!user.organisations.find( o => o.uid === this.props.router.params.organisationUID )?.accepted ? 
                                                        <IcChip label={this.props.t('organisationpage.txt_join_sent')} />
                                                    : null}

                                                    {user.locked ? 
                                                        <LockedChip />
                                                    : null}
                                                </ListItemHeaderField>

                                                <ListItemHeaderField
                                                    grow={true}
                                                    to={LinkUtils.make('user', user.uid)}
                                                    text={user.licenses.map( o => o.license.name ).join(', ') || '-'}
                                                    subtext={this.props.t('organisationpage.txt_user_licenses')}
                                                />

                                                <ListItemHeaderField
                                                    grow={true}
                                                    text={Formatter.userOrganisationRole(user.organisations.find( o => o.uid === this.props.router.params.organisationUID )?.role)}
                                                    subtext={this.props.t('organisationpage.inp_user_role')}
                                                />

                                                <ListItemHeaderAction
                                                    icon={faEye}
                                                    to={LinkUtils.make('user', user.uid)}
                                                    title={this.props.t('organisationpage.btn_user_show')}
                                                />

                                                <ListItemHeaderAction
                                                    icon={faPen}
                                                    to={LinkUtils.make('user', user.uid, 'edit')}
                                                    title={this.props.t('organisationpage.btn_user_edit')}
                                                />

                                                <ListItemHeaderAction
                                                    icon={faTimes}
                                                    to={LinkUtils.make('organisation', this.state.organisation!.uid, 'user', user.uid, 'delete')}
                                                    title={this.props.t('organisationpage.btn_user_remove')}
                                                />
                                            </ListItemHeader>
                                        </ListItem>
                                    ))}

                                    {this.state.users.length === 0 ?
                                        <ListEmpty>
                                            {this.props.t('organisationpage.txt_no_members')}
                                        </ListEmpty>
                                    : null}
                                </List>

                                {this.state.organisation ?
                                    <IcFloatRow align={IcFloatRowAlign.Right}>
                                        <IcButton
                                            type='button'
                                            to={LinkUtils.make('organisation', this.state.organisation.uid, 'addmember')}
                                            color={IcButtonColor.Secondary}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                            
                                            {this.props.t('organisationpage.btn_add_member')}
                                        </IcButton>
                                    </IcFloatRow>
                                : null}
                            </IcCard>
                        </IcGridItem>
                    </IcGridRow>
                : null}
            </IcPageContent>
        );
    } 
}


export const OrganisationPage = withTranslation()(withRouter($OrganisationPage));
