import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserService, UserSfaV1 } from '../../Services/UserService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AccountDeleteSfaPageLoadingStep } from './AccountDeleteSfaPageLoadingStep/AccountDeleteSfaPageLoadingStep';
import { AccountDeleteSfaPageSuccessStep } from './AccountDeleteSfaPageSuccessStep/AccountDeleteSfaPageSuccessStep';
import { AccountDeleteSfaPageConfirmStep } from './AccountDeleteSfaPageConfirmStep/AccountDeleteSfaPageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AccountDeleteSfaPageRouteParams
{
    sfaUID: string;
}


export interface AccountDeleteSfaPageProps extends RouteComponentProps<AccountDeleteSfaPageRouteParams>, WithTranslation
{
}


enum AccountDeleteSfaPageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AccountDeleteSfaPageState
{
    step:       AccountDeleteSfaPageStep;
    sfa:        UserSfaV1 | null;
    error:      Error | null;
}


class $AccountDeleteSfaPage extends React.Component<AccountDeleteSfaPageProps, AccountDeleteSfaPageState>
{
    private readonly _userService:      UserService;
    

    constructor ( props: AccountDeleteSfaPageProps )
    {
        super(props);

        this.state = {
            step:       AccountDeleteSfaPageStep.Loading,
            sfa:        null,
            error:      null
        };

        this._userService = UserService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:       AccountDeleteSfaPageStep.Loading,
                error:      null
            });

            const sfas = await this._userService.getSfas();
            const sfa = sfas.find( ( o ) => o.uid === this.props.router.params.sfaUID );
            if ( ! sfa )
            {
                throw new Error('Not found');
            }

            this.setState({
                step:   AccountDeleteSfaPageStep.Confirm,
                sfa
            });
        }
        catch ( err )
        {
            console.error(`Error loading sfa: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.sfa )
        {
            return;
        }

        try
        {
            this.setState({
                step:       AccountDeleteSfaPageStep.Loading,
                error:      null
            });

            await this._userService.deleteSfa(this.state.sfa.uid);

            this.setState({
                step:   AccountDeleteSfaPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting sfa: ${(err as Error).message}`, err);

            this.setState({
                step:       AccountDeleteSfaPageStep.Confirm,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('accountdeletesfapage.txt_title')} 
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AccountDeleteSfaPageStep.Loading ?
                    <AccountDeleteSfaPageLoadingStep />
                : null}

                {this.state.step === AccountDeleteSfaPageStep.Confirm && this.state.sfa ?
                    <AccountDeleteSfaPageConfirmStep
                        sfa={this.state.sfa}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === AccountDeleteSfaPageStep.Success ?
                    <AccountDeleteSfaPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AccountDeleteSfaPage = withTranslation()(withRouter($AccountDeleteSfaPage));
