import { StateSubject } from 'ts-subject';
import { EnabledLocales, Locale } from '../Model/Locale';


export class LocaleService
{
    private static _instance:           LocaleService;
    private readonly _subjectLocale:    StateSubject<Locale>;
    
    
    public static getInstance ( ): LocaleService
    {
        if ( ! this._instance )
        {
            this._instance = new LocaleService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        const browserLocale = this.getBrowserLocale();

        let currLocale: Locale;
        
        if ( EnabledLocales.length === 1 )
        {
            currLocale = EnabledLocales[0];
        }
        else if ( browserLocale && EnabledLocales.includes(browserLocale) )
        {
            currLocale = browserLocale;
        }
        else if ( EnabledLocales.length > 0 )
        {
            currLocale = EnabledLocales[0];
        }
        else
        {
            currLocale = Locale.EN_US;
        }

        this._subjectLocale = new StateSubject(currLocale);
    }


    public getBrowserLocale ( ): Locale | null
    {
        const locale = (navigator.language || navigator.languages[0] || '').replace('_', '-') as Locale;
        if ( ! locale )
        {
            return null;
        }

        return locale;
    }


    public getLocale ( ): StateSubject<Locale>
    {
        return this._subjectLocale;
    }


    public setLocale ( locale: Locale ): void
    {
        this._subjectLocale.next(locale);
    }
}
