import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ListItem } from '../../Components/List/ListItem';
import { List } from '../../Components/List/List';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { ContentFileV1Keys } from '../../Services/ContentService';
import { EnabledLocales } from '../../Model/Locale';
import { Formatter } from '../../utils/Formatter';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcCard, IcCardPadding, IcPageContent, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminContentFilesPageProps extends WithTranslation
{
}


class $AdminContentFilesPage extends React.Component<AdminContentFilesPageProps>
{
    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'contentfiles')}
                        label={this.props.t('admincontentfilespage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('admincontentfilespage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <List>
                        {ContentFileV1Keys.map( ( contentFileKey ) => EnabledLocales.map( ( locale ) => (
                            <ListItem key={`${contentFileKey}-${locale}`}>
                                <ListItemHeader>
                                    <ListItemHeaderField
                                        grow={true}
                                        text={Formatter.contentFileKey(contentFileKey)}
                                    />

                                    <ListItemHeaderField
                                        grow={true}
                                        text={Formatter.locale(locale)}
                                    />

                                    <ListItemHeaderAction
                                        icon={faPen}
                                        to={LinkUtils.make('admin', 'contentfile', contentFileKey, locale, 'edit')}
                                        title={this.props.t('admincontentfilespage.btn_edit')}
                                    />
                                </ListItemHeader>
                            </ListItem>
                        )))}
                    </List>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminContentFilesPage = withTranslation()($AdminContentFilesPage);
