import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { OrganisationDetails } from '../../../Components/OrganisationDetails/OrganisationDetails';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { PurchaseV1 } from '../../../Services/PurchaseService';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { WithLocaleProps, withLocale } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcSeparator, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { CanceledChip } from '../../../Components/Chip/CanceledChip';


export interface OrganisationDeletePageConfirmStepProps extends WithTranslation, WithLocaleProps
{
    organisation:   OrganisationV1;
    purchases:      Array<PurchaseV1>;
    onCancel:       ( ) => any;
    onFinish:       ( ) => any;
}


class $OrganisationDeletePageConfirmStep extends React.Component<OrganisationDeletePageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('organisationdeletepageconfirmstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('organisationdeletepageconfirmstep.txt_delete')}
                </IcText>

                <OrganisationDetails
                    organisation={this.props.organisation}
                />

                <IcSeparator />

                {this.props.purchases.length > 0 ?
                    <>
                        <IcText>
                            {this.props.t('organisationdeletepageconfirmstep.txt_cancel_purchases')}
                        </IcText>

                        <List>
                            {this.props.purchases.map( ( purchase ) => (
                                <ListItem key={purchase.uid}>
                                    <ListItemHeader>
                                        <ListItemHeaderField
                                            grow={true}>
                                            {purchase.package.names.find( o => o.locale === this.props.locale)?.value || '???'}

                                            {purchase.datetime_cancel_at ?
                                                <CanceledChip date={purchase.datetime_cancel_at} />
                                            : null}
                                        </ListItemHeaderField>

                                        <ListItemHeaderField
                                            grow={true}
                                            text={`${purchase.count}x`}
                                        />
                                    </ListItemHeader>
                                </ListItem>
                            ))}
                        </List>
                    </>
                : null}

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        type='button'
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}>
                        {this.props.t('organisationdeletepageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}>
                        {this.props.t('organisationdeletepageconfirmstep.btn_delete')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const OrganisationDeletePageConfirmStep = withLocale(withTranslation()($OrganisationDeletePageConfirmStep));
