import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PurchaseService, PurchaseV1 } from '../../Services/PurchaseService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { PurchaseUpdateCountPageLoadingStep } from './PurchaseUpdateCountPageLoadingStep/PurchaseUpdateCountPageLoadingStep';
import { PurchaseUpdateCountPageSuccessStep } from './PurchaseUpdateCountPageSuccessStep/PurchaseUpdateCountPageSuccessStep';
import { PurchaseUpdateCountPageSelectCountStep, PurchaseUpdateCountPageSelectCountStepFormData } from './PurchaseUpdateCountPageSelectCountStep/PurchaseUpdateCountPageSelectCountStep';
import { PurchaseUpdateCountPageConfirmStep } from './PurchaseUpdateCountPageConfirmStep/PurchaseUpdateCountPageConfirmStep';
import { OrganisationService, OrganisationV1 } from '../../Services/OrganisationService';
import { ErrorCode, ErrorWithCode } from '../../utils/ErrorTranslator';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface PurchaseUpdateCountPageRouteParams
{
    purchaseUID: string;
}


export interface PurchaseUpdateCountPageProps extends RouteComponentProps<PurchaseUpdateCountPageRouteParams>, WithTranslation
{
}


enum PurchaseUpdateCountPageStep
{
    Loading     = 'LOADING',
    SelectCount = 'SELECT_COUNT',
    Confirm     = 'CONFIRM',
    Success     = 'SUCCESS'
}


interface PurchaseUpdateCountPageFormData
{
    count:  number;
}


interface PurchaseUpdateCountPageState
{
    step:           PurchaseUpdateCountPageStep;
    formData:       PurchaseUpdateCountPageFormData;
    purchase:       PurchaseV1 | null;
    organisation:   OrganisationV1 | null;
    error:          Error | null;
}


class $PurchaseUpdateCountPage extends React.Component<PurchaseUpdateCountPageProps, PurchaseUpdateCountPageState>
{
    private readonly _purchaseService:      PurchaseService;
    private readonly _organisationService:  OrganisationService;
    

    constructor ( props: PurchaseUpdateCountPageProps )
    {
        super(props);

        
        this.state = {
            step:           PurchaseUpdateCountPageStep.Loading,
            formData:       {
                count:          0,
            },
            purchase:       null,
            organisation:   null,
            error:          null
        };
        
        this._purchaseService       = PurchaseService.getInstance();
        this._organisationService   = OrganisationService.getInstance();

        this._cancel            = this._cancel.bind(this);
        this._finishSelectCount = this._finishSelectCount.bind(this);
        this._finishConfirm     = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:       PurchaseUpdateCountPageStep.Loading,
                error:      null
            });

            const purchase = await this._purchaseService.getPurchase(this.props.router.params.purchaseUID);
            const organisation = await this._organisationService.getOrganisation(purchase.organisation_uid);

            this.setState({
                step:   PurchaseUpdateCountPageStep.SelectCount,
                purchase,
                organisation
            });
        }
        catch ( err )
        {
            console.error(`Error loading purchase: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishSelectCount ( formData: PurchaseUpdateCountPageSelectCountStepFormData ): Promise<void>
    {
        if ( ! this.state.purchase )
        {
            return;
        }

        const count = parseInt(formData.count, 10);

        if ( count === this.state.purchase.count )
        {
            this.setState({
                step:       PurchaseUpdateCountPageStep.SelectCount,
                error:      new ErrorWithCode(ErrorCode.NoNewPurchaseCount)
            });

            return;
        }

        this.setState({
            step:   PurchaseUpdateCountPageStep.Confirm,
            formData: {
                count
            },
            error: null
        });
    }


    private async _finishConfirm ( ): Promise<void>
    {
        
        try
        {
            if ( ! this.state.purchase )
            {
                throw new Error('No purchase loaded');
            }

            if ( ! this.state.formData.count )
            {
                throw new Error('No count selected loaded');
            }

            this.setState({
                step:       PurchaseUpdateCountPageStep.Loading,
                error:      null
            });

            await this._purchaseService.updatePurchase(this.state.purchase.uid, {
                count: this.state.formData.count
            });

            this.setState({
                step:   PurchaseUpdateCountPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating purchase: ${(err as Error).message}`, err);

            this.setState({
                step:       PurchaseUpdateCountPageStep.SelectCount,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('purchaseupdatecountpage.txt_update_count')} />

                <IcErrorBox error={this.state.error} />

                {this.state.step === PurchaseUpdateCountPageStep.Loading ?
                    <PurchaseUpdateCountPageLoadingStep />
                : null}

                {this.state.step === PurchaseUpdateCountPageStep.SelectCount && this.state.purchase ?
                    <PurchaseUpdateCountPageSelectCountStep
                        purchase={this.state.purchase}
                        onCancel={this._cancel}
                        onFinish={this._finishSelectCount}
                    />
                : null}

                {this.state.step === PurchaseUpdateCountPageStep.Confirm && this.state.organisation && this.state.purchase ?
                    <PurchaseUpdateCountPageConfirmStep
                        count={this.state.formData.count}
                        purchase={this.state.purchase}
                        organisation={this.state.organisation}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === PurchaseUpdateCountPageStep.Success ?
                    <PurchaseUpdateCountPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const PurchaseUpdateCountPage = withTranslation()(withRouter($PurchaseUpdateCountPage));
