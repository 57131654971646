import React from 'react';
import DayJS from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { JobService, JobV1 } from '../../Services/JobService';
import { ListItemBody } from '../../Components/List/ListItemBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcPageContent, IcSpinner, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminJobsPageProps extends WithTranslation
{
}


interface AdminJobsPageState
{
    jobs:       Array<JobV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


class $AdminJobsPage extends React.Component<AdminJobsPageProps, AdminJobsPageState>
{
    private readonly BULK_SIZE      = 50;
    private readonly _jobService: JobService;


    constructor ( props: AdminJobsPageProps )
    {
        super(props);

        this.state = {
            jobs:       [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._jobService = JobService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const jobs = await this._jobService.getJobs(0, this.BULK_SIZE);

            this.setState({
                jobs,
                hasMore:    jobs.length >= this.BULK_SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading jobs: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const jobs = await this._jobService.getJobs(this.state.jobs.length, this.BULK_SIZE);

            this.setState({
                jobs:       [...this.state.jobs, ...jobs],
                hasMore:    jobs.length >= this.BULK_SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more jobs: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'jobs')}
                        label={this.props.t('adminjobspage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminjobspage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcErrorBox error={this.state.error} />

                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton
                            color={IcButtonColor.Secondary}
                            to={LinkUtils.make('admin', 'job', 'add', 'sync-all-users')}>
                            {this.props.t('adminjobspage.btn_add_sync_all_users')}

                            <FontAwesomeIcon icon={faPlay} />
                        </IcButton>

                        <IcButton
                            color={IcButtonColor.Secondary}
                            to={LinkUtils.make('admin', 'job', 'add', 'sync-all-organisations')}>
                            {this.props.t('adminjobspage.btn_add_sync_all_organisations')}

                            <FontAwesomeIcon icon={faPlay} />
                        </IcButton>
                    </IcFloatRow>

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <List>
                            {this.state.jobs.map( ( job ) => (
                                <ListItem key={job.uid}>
                                    <ListItemHeader>
                                        <ListItemHeaderField
                                            grow={true}
                                            text={job.type}
                                            subtext={DayJS(job.datetime_created).format('YYYY-MM-DD HH:mm')}
                                        />
                                        
                                        <ListItemHeaderField
                                            text={job.status}
                                        />
                                    </ListItemHeader>

                                    <ListItemBody>
                                        {JSON.stringify(JSON.parse(job.details), null, 4)}
                                    </ListItemBody>
                                </ListItem>
                            ))}
                        </List>

                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminJobsPage = withTranslation()($AdminJobsPage);
