import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Gender, Genders } from '../../../Model/Gender';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { Formatter } from '../../../utils/Formatter';
import { UserV1, isAdmin } from '../../../Services/UserService';
import { Country, EnabledCountries } from '../../../Model/Country';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridPadding, IcGridRow, IcInputSelect, IcInputSelectItem, IcInputText, IcText, IcTextSize, IcVSpacer, IcVSpacerSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface OrganisationEditPageFormStepFormData
{
    name:                       string;
    country:                    Country;
    payment_address_title:      string;
    payment_address_gender:     Gender | '';
    payment_address_firstname:  string;
    payment_address_lastname:   string;
    payment_address_street:     string;
    payment_address_zip:        string;
    payment_address_city:       string;
    payment_email:              string;
}


export interface OrganisationEditPageFormStepProps extends WithTranslation
{
    organisation:   OrganisationV1;
    ownUser:        UserV1 | null;
    onCancel:       ( ) => any;
    onFinish:       ( formData: OrganisationEditPageFormStepFormData ) => any;
}


interface OrganisationEditPageFormStepState
{
    initialFormData:    OrganisationEditPageFormStepFormData;
}


class $OrganisationEditPageFormStep extends React.Component<OrganisationEditPageFormStepProps, OrganisationEditPageFormStepState>
{
    constructor ( props: OrganisationEditPageFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                name:                       '',
                country:                    '' as Country,
                payment_address_title:      '',
                payment_address_gender:     '',
                payment_address_firstname:  '',
                payment_address_lastname:   '',
                payment_address_street:     '',
                payment_address_zip:        '',
                payment_address_city:       '',
                payment_email:              ''
            }
        };
    }


    private _applyOrganisation ( organisation: OrganisationV1 ): void
    {
        this.setState({
            initialFormData: {
                name:                       organisation.name,
                country:                    organisation.country,
                payment_address_title:      organisation.payment_address?.title || '',
                payment_address_gender:     organisation.payment_address?.gender || '',
                payment_address_firstname:  organisation.payment_address?.firstname || '',
                payment_address_lastname:   organisation.payment_address?.lastname || '',
                payment_address_street:     organisation.payment_address?.street || '',
                payment_address_zip:        organisation.payment_address?.zip || '',
                payment_address_city:       organisation.payment_address?.city || '',
                payment_email:              organisation.payment_email || ''
            }
        });
    }


    public componentDidMount ( ): void
    {
        this._applyOrganisation(this.props.organisation);
    }


    public componentDidUpdate ( prevProps: OrganisationEditPageFormStepProps ): void
    {
        if ( prevProps.organisation !== this.props.organisation )
        {
            this._applyOrganisation(this.props.organisation);
        }
    }


    public render ( )
    {
        const MyFormik = Formik<OrganisationEditPageFormStepFormData>;
        const optionsCountry: Array<IcInputSelectItem<Country>> = EnabledCountries.map( ( country ) => ({
            label:  Formatter.country(country),
            value:  country
        })).sort( ( a, b ) => a.label.localeCompare(b.label) );
    
        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcText size={IcTextSize.Heading3}>
                            {this.props.t('organisationeditpageformstep.txt_general')}
                        </IcText>

                        <IcGridRow padding={IcGridPadding.Small}>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('organisationeditpageformstep.inp_name')}
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(100)
                                    ]}
                                />
                            </IcGridItem>

                            {this.props.ownUser && isAdmin(this.props.ownUser) ?
                                <IcGridItem s={12}>
                                    <IcInputSelect
                                        name='country'
                                        label={this.props.t('organisationeditpageformstep.inp_country')}
                                        options={optionsCountry}
                                        required={true}
                                    />
                                </IcGridItem>
                            : null}
                        </IcGridRow>

                        <IcVSpacer size={IcVSpacerSize.Small} />
                        
                        <IcText size={IcTextSize.Heading3}>
                            {this.props.t('organisationeditpageformstep.txt_payment_address')}
                        </IcText>

                        <IcGridRow padding={IcGridPadding.Small}>
                            <IcGridItem s={6}>
                                <IcInputText
                                    name='payment_address_title'
                                    label={this.props.t('organisationeditpageformstep.inp_payment_address_title')}
                                    validators={[
                                        Validator.maxLength(20)
                                    ]}
                                />
                            </IcGridItem>
                            
                            <IcGridItem s={6}>
                                <IcInputSelect
                                    name='payment_address_gender'
                                    label={this.props.t('organisationeditpageformstep.inp_payment_address_gender')}
                                    options={Genders.map( ( gender ) => ({
                                        label:  Formatter.gender(gender),
                                        value:  gender
                                    }))}
                                />
                            </IcGridItem>

                            <IcGridItem s={12} m={6}>
                                <IcInputText
                                    name='payment_address_firstname'
                                    label={this.props.t('organisationeditpageformstep.inp_payment_address_firstname')}
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(32)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12} m={6}>
                                <IcInputText
                                    name='payment_address_lastname'
                                    label={this.props.t('organisationeditpageformstep.inp_payment_address_lastname')}
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(32)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12} m={6}>
                                <IcInputText
                                    name='payment_address_street'
                                    label={this.props.t('organisationeditpageformstep.inp_payment_address_street')}
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(100)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12} m={6}>
                                <IcInputText
                                    name='payment_address_zip'
                                    label={this.props.t('organisationeditpageformstep.inp_payment_address_zip')}
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(8)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12} m={6}>
                                <IcInputText
                                    name='payment_address_city'
                                    label={this.props.t('organisationeditpageformstep.inp_payment_address_city')}
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(100)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12} m={6}>
                                <IcInputText
                                    type='email'
                                    name='payment_email'
                                    label={this.props.t('organisationeditpageformstep.inp_payment_email')}
                                    validators={[
                                        Validator.email
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}>
                                {this.props.t('organisationeditpageformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'>
                                {this.props.t('organisationeditpageformstep.btn_update')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const OrganisationEditPageFormStep = withTranslation()($OrganisationEditPageFormStep);
