import React from 'react';
import { Box, BoxAlignment } from '../Box/Box';
import { BoxContent, BoxContentPadding } from '../Box/BoxContent';

import './PackageTile.css';


export interface DummyPackageTileProps
{
}


export class DummyPackageTile extends React.Component<DummyPackageTileProps>
{
    public render ( )
    {
        return (
            <Box
                alignment={BoxAlignment.Vertical}
                className='DummyPackageTile'>
                <BoxContent
                    padding={BoxContentPadding.Medium}
                    className='DummyPackageTile-header'
                />

                <BoxContent
                    padding={BoxContentPadding.Medium}
                    className='DummyPackageTile-body'
                />
            </Box>
        );
    }
}
