import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LicenseService } from '../../Services/LicenseService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminLicenseAddPageLoadingStep } from './AdminLicenseAddPageLoadingStep/AdminLicenseAddPageLoadingStep';
import { AdminLicenseAddPageSuccessStep } from './AdminLicenseAddPageSuccessStep/AdminLicenseAddPageSuccessStep';
import { AdminLicenseAddPageFormStep } from './AdminLicenseAddPageFormStep/AdminLicenseAddPageFormStep';
import { ApplicationService, ApplicationV1 } from '../../Services/ApplicationService';
import { AdminLicenseAddPageFormStepFormData } from './AdminLicenseAddPageFormStep/AdminLicenseAddPageFormStep';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminLicenseAddPageProps extends RouteComponentProps, WithTranslation
{
}


enum AdminLicenseAddPageStep
{
    Loading = 'LOADING',
    Form    = 'FORM',
    Success = 'SUCCESS'
}


interface AdminLicenseAddPageState
{
    step:           AdminLicenseAddPageStep;
    applications:   Array<ApplicationV1>;
    error:          Error | null;
}


class $AdminLicenseAddPage extends React.Component<AdminLicenseAddPageProps, AdminLicenseAddPageState>
{
    private readonly _applicationService:   ApplicationService;
    private readonly _licenseService:       LicenseService;
    

    constructor ( props: AdminLicenseAddPageProps )
    {
        super(props);

        this.state = {
            step:           AdminLicenseAddPageStep.Loading,
            applications:   [],
            error:          null
        };

        this._applicationService = ApplicationService.getInstance();
        this._licenseService = LicenseService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishForm = this._finishForm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminLicenseAddPageStep.Loading,
                error:  null
            });

            const applications = await this._applicationService.getApplications(0, 100); // TODO

            this.setState({
                step:   AdminLicenseAddPageStep.Form,
                applications
            });
        }
        catch ( err )
        {
            console.error(`Error loading license: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishForm ( formData: AdminLicenseAddPageFormStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminLicenseAddPageStep.Loading,
                error:  null
            });

            await this._licenseService.addLicense(
                {
                    tag:                formData.tag.trim(),
                    name:               formData.name.trim(),
                    assignable:         formData.assignable,
                    application_uids:   formData.application_uids
                }
            );

            this.setState({
                step:   AdminLicenseAddPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating license: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminLicenseAddPageStep.Form,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'licenses')}
                        label={this.props.t('adminlicensespage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'license', 'add')}
                        label={this.props.t('adminlicenseaddpage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle
                    title={this.props.t('adminlicenseaddpage.txt_title')}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminLicenseAddPageStep.Loading ?
                    <AdminLicenseAddPageLoadingStep />
                : null}

                {this.state.step === AdminLicenseAddPageStep.Form ?
                    <AdminLicenseAddPageFormStep
                        applications={this.state.applications}
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.step === AdminLicenseAddPageStep.Success ?
                    <AdminLicenseAddPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminLicenseAddPage = withTranslation()(withRouter($AdminLicenseAddPage));
