import React from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationDetails } from '../../../Components/OrganisationDetails/OrganisationDetails';
import { Gender, Genders } from '../../../Model/Gender';
import { EnabledLocales, Locale } from '../../../Model/Locale';
import { OrganisationV1 } from '../../../Services/OrganisationService';
import { Formatter } from '../../../utils/Formatter';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputSelect, IcInputText, IcText, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface UserAddPageDataStepFormData
{
    locale:             Locale;
    gender:             Gender;
    title:              string | null;
    firstname:          string;
    lastname:           string;
    email:              string;
    password:           string | null;
    password_confirm:   string | null;
}


export interface UserAddPageDataStepProps extends WithTranslation
{
    organisation:   OrganisationV1 | null;
    invite:         boolean;
    formData:       UserAddPageDataStepFormData;
    onCancel:       ( ) => any;
    onFinish:       ( formData: UserAddPageDataStepFormData ) => any;
}


class $UserAddPageDataStep extends React.Component<UserAddPageDataStepProps>
{
    constructor ( props: UserAddPageDataStepProps )
    {
        super(props);

        this._validate = this._validate.bind(this);
    }


    private _validate ( formData: UserAddPageDataStepFormData ): FormikErrors<UserAddPageDataStepFormData>
    {
        const errors: FormikErrors<UserAddPageDataStepFormData> = {};

        if ( !this.props.invite &&
             (formData.password || '').trim() !== (formData.password_confirm || '').trim() )
        {
            errors.password_confirm = this.props.t('useraddpagedatastep.txt_password_mismatch');
        }

        return errors;
    }


    public render ( )
    {
        const MyFormik = Formik<UserAddPageDataStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                {this.props.organisation ?
                    <OrganisationDetails organisation={this.props.organisation} />
                : null}
    
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.props.formData}
                    validate={this._validate}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            {EnabledLocales.length > 0 ?
                                <IcGridItem s={12}>
                                    <IcInputSelect
                                        label={this.props.t('useraddpagedatastep.inp_locale')}
                                        name='locale'
                                        required={true}
                                        options={EnabledLocales.map( ( locale ) => ({
                                            label:  Formatter.locale(locale),
                                            value:  locale
                                        }))}
                                    />
                                </IcGridItem>
                            : null}

                            <IcGridItem s={12}>
                                <IcInputSelect
                                    label={this.props.t('useraddpagedatastep.inp_gender')}
                                    name='gender'
                                    required={true}
                                    options={Genders.map( ( gender ) => ({
                                        label:  Formatter.gender(gender),
                                        value:  gender
                                    }))}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    label={this.props.t('useraddpagedatastep.inp_title')}
                                    name='title'
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    label={this.props.t('useraddpagedatastep.inp_firstname')}
                                    name='firstname'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    label={this.props.t('useraddpagedatastep.inp_lastname')}
                                    name='lastname'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    label={this.props.t('useraddpagedatastep.inp_email')}
                                    name='email'
                                    required={true}
                                    validators={[Validator.email]}
                                />
                            </IcGridItem>

                            {!this.props.invite ?
                                <IcGridItem s={12}>
                                    <IcInputText
                                        type='password'
                                        label={this.props.t('useraddpagedatastep.inp_password')}
                                        name='password'
                                        required={true}
                                        validators={[Validator.password]}
                                    />
                                </IcGridItem>
                            : null}
                            
                            {!this.props.invite ?
                                <IcGridItem s={12}>
                                    <IcInputText
                                        type='password'
                                        label={this.props.t('useraddpagedatastep.inp_password_confirm')}
                                        name='password_confirm'
                                        required={true}
                                    />
                                </IcGridItem>
                            : null}

                            {!this.props.invite ?
                                <IcGridItem s={12}>
                                    <IcText>
                                        {this.props.t('validator.password.policy', {length: Validator.PASSWORD_MIN_LENGTH})}
                                    </IcText>
                                </IcGridItem>
                            : null}
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton 
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}>
                                {this.props.t('useraddpagedatastep.btn_cancel')}
                            </IcButton>

                            <IcButton type='submit'>
                                {this.props.invite ?
                                    this.props.t('useraddpagedatastep.btn_invite')
                                :
                                    this.props.t('useraddpagedatastep.btn_create')
                                }
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}

                        
export const UserAddPageDataStep = withTranslation()($UserAddPageDataStep);
